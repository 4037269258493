// away hours
export const AWAY_OPTION_ONLINE = 'online';
export const AWAY_OPTION_OFFLINE = 'offline';
export const AWAY_OPTION_ALL_DAY = 'all-day';
export const DEFAULT_AWAY_START = '09:00';
export const DEFAULT_AWAY_END = '17:00';
export const DEFAULT_AWAY_OFFLINE = '00:00';
export const MIDNIGHT = '00:00';
export const END_OF_DAY = '24:00';

// quiet hours
export const DEFAULT_QUIET_START = '21:00';
export const DEFAULT_QUIET_END = '08:00';

export const MAX_CHAR_LIMIT_PER_MSG = 1600;
export const MMS_SEGMENT_RATIO_TO_SMS = 5;

export const MMS_MAX_IMG_SIZE_MB = 5;
export const MMS_RECOMMENDED_IMG_SIZE_MB = 0.6;
export const MAX_NUMBER_OF_SUBSCRIBERS = 5000;
export const MAX_NUMBER_OF_SUBSCRIBERS_V2 = 100000;

export const DEFAULT_SUPPORT_SESSION_DURATION = 72;
