export const TYPE_ORDER_CONFIRMED = 'confirmed';
export const TYPE_FIRST_ORDER_CONFIRMED = 'first-order-confirmed';
export const TYPE_POST_ORDER_CHECKIN = 'post-order-checkin';
export const TYPE_REORDER_PROMPT = 'reorder-prompt';
export const TYPE_ORDER_FULFILLED = 'fulfilled';
export const TYPE_ABANDONED_STAGE1 = 'abandoned-1';
export const TYPE_ABANDONED_STAGE2 = 'abandoned-2';
export const TYPE_ABANDONED_STAGE3 = 'abandoned-3';
export const TYPE_RECHARGE_NEW_SUBSCRIPTION = 'new-subscription';
export const TYPE_RECHARGE_NEW_SUBSCRIPTION_AFTER_X_DAYS = 'new-subscription-after-x-days';
export const TYPE_RECHARGE_UPCOMING_CHARGE = 'upcoming-charge';
export const TYPE_RECHARGE_RECURRING_CHARGE = 'recurring-charge';
export const TYPE_RECHARGE_ABANDONED_STAGE1 = 'subscription-abandoned-1';
