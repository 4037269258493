import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import {
  useQueryClient,
  useMutation,
} from 'react-query';
import {
  useHistory,
} from 'react-router-dom';

import { createCustomDomain } from '../../../../mutations/CustomDomain';

const defaultValues = {
  customDomain: '',
};

// eslint-disable-next-line max-len
const domainNameRegex = /^(?=.{0,253}$)(([a-z0-9_][a-z0-9_-]{0,61}[a-z0-9_]|[a-z0-9_])\.)+((?=.*[^0-9])([a-z0-9][a-z0-9-]{0,61}[a-z0-9]|[a-z0-9]))$/i;

const CustomDomainEntry = ({
  formRootUrl,
}) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const queryClient = useQueryClient();
  const mutation = useMutation(createCustomDomain, {
    onSuccess: () => {
      queryClient.resetQueries('customDomain');
    },
  });

  const {
    register, handleSubmit, formState,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { isValid, isSubmitting } = formState;

  const handleFormSubmit = async (formData) => {
    try {
      await mutation.mutateAsync(formData);
    } catch (err) {
      return addToast(err.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    return history.push(`${formRootUrl}/review`);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="form-group">
        <label htmlFor="customDomain">Custom Domain</label>
        <div className="field mt-2">
          <input
            id="customDomain"
            type="text"
            className="form-control"
            placeholder="sms.yourbrand.com"
            {...register('customDomain', {
              pattern: domainNameRegex,
              required: true,
            })}
          />
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-primary mr-2"
        disabled={isSubmitting || !isValid}
      >
        {isSubmitting ? (
          <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            <span>&nbsp;Requesting Domain...</span>
          </>
        ) : (
          <span>Request Domain</span>
        )}
      </button>
    </form>
  );
};

export default memo(CustomDomainEntry);
