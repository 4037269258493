import React from 'react';
import { useLocation } from 'react-router-dom';
import { PageBuilder } from '../components/global/PageBuilder';
import { SEO } from '../components/global/SEO';
import Contacts from '../components/main/Contacts';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ContactsPage = () => {
  const query = useQuery();

  return (
    <PageBuilder>
      <SEO title="Contacts | Blueprint" />
      <div className="a-view contacts">
        <div className="container">
          <h2 className="title">Contacts</h2>
          <Contacts optin={query.get('optin') || 'all'} />
        </div>
      </div>
    </PageBuilder>
  );
};

export default ContactsPage;
