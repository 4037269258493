import React, { memo } from 'react';
import ComparatorSelector from '../../../global/Predicates/ComparatorSelector';
import Negate, { NEGATE_MODES } from '../../../global/Predicates/Negate';
import TypeSelector from '../../../global/Predicates/TypeSelector';

/** @typedef {import('../../../global/Predicates/TypeSelector').Type} Type */
/** @typedef {import('../../../global/Predicates/ComparatorSelector').Comparator} Comparator */

export const createFilterChangeHandler = (props) => {
  function onFilterChange(prop, newValue) {
    const {
      id, negate, type, comparator, value, onChange,
    } = props;

    let mappedPropName = prop;

    if (prop === 'comparator') {
      mappedPropName = 'op';
    }

    const updatedFilter = {
      id,
      negate: negate.mode === NEGATE_MODES.yes,
      type,
      op: comparator,
      value,
    };

    /**
     * Reset the comparator and value when type changes,
     * because some comparators and values are not compatible with some types
     */
    if (prop === 'type') {
      delete updatedFilter.op;
      delete updatedFilter.value;
    }

    updatedFilter[mappedPropName] = newValue;

    onChange(updatedFilter);
  }

  return onFilterChange;
};

/**
 *
 * @param {Object} props
 * @param {Function} props.onChange
 * @param {*} props.value
 * @param {import('../../../global/Predicates/Negate').NegateMode} props.negate
 * @param {String} props.type
 * @param {String} props.comparator
 * @param {Type[]} props.types
 * @param {Comparator[]} props.comparators
 */
const Filter = ({
  negate, type, comparator, onChange, types, comparators, value,
}) => (
  <>
    <div className="col-md-2">
      <Negate value={negate} onChange={(newValue) => { onChange('negate', newValue.mode === NEGATE_MODES.yes); }} />
    </div>
    <div className="col-md-3">
      <TypeSelector value={type} onChange={(newValue) => { onChange('type', newValue); }} types={types} />
    </div>
    { /* ComparatorSelector needs to be responsible for returning its div wrapper in case it doesn't render anything */ }
    <ComparatorSelector
      value={comparator}
      onChange={(newValue) => { onChange('comparator', newValue); }}
      comparators={comparators}
    />
    {value}
  </>
);

export default memo(Filter);
