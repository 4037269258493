import React, {
  useCallback, memo, useState, useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import GroupDetails from '../GroupDetails';
import MessageGroupSubscribers from '../MessageGroupSubscribers';
import {
  createGroup,
  updateGroup,
} from '../../../../redux/actions/GroupMessagingAPI';

const isInSubscriberList = (id, list) => list.find((sub) => sub.customerPhoneId === id);
const filterForSubscriberId = (id, list) => list.filter((sub) => sub.customerPhoneId !== id);

export const editMode = {
  create: {
    title: 'Create New',
    class: 'create',
    showDateAdded: false,
  },
  edit: {
    title: 'Save',
    class: 'edit',
    showDateAdded: true,
  },
};

const EditList = ({ allCustomers, group: originalGroup }) => {
  const [group, setGroup] = useState(originalGroup);
  const [saving, setSaving] = useState(false);
  const history = useHistory();
  const mode = group.id ? editMode.edit : editMode.create;
  const { addToast } = useToasts();

  useEffect(() => {
    setGroup(originalGroup);
  }, [originalGroup]);

  const toggleSubscribers = useCallback(
    (subscriber) => {
      const { customerPhoneId } = subscriber;
      const notSubscribed = !isInSubscriberList(
        customerPhoneId,
        group.customers,
      );

      const newSubscriberList = notSubscribed
        ? [...group.customers, { ...subscriber }]
        : filterForSubscriberId(customerPhoneId, group.customers);

      setGroup({
        ...group,
        customers: newSubscriberList,
      });
    },
    [group],
  );

  const handleNameChange = useCallback(
    ({ target: { value } }) => setGroup({ ...group, name: value }),
    [group],
  );

  const handleDescriptionChange = useCallback(
    ({ target: { value } }) => setGroup({ ...group, description: value }),
    [group],
  );

  const onSubmit = useCallback(async () => {
    if (!group.name || !group.description) {
      addToast('Please provide a name and a description.', {
        appearance: 'warning',
        autoDismiss: true,
      });

      return;
    }

    setSaving(true);

    try {
      const newGroup = {
        id: group.id,
        name: group.name,
        description: group.description,
        type: group.type,
        customerPhoneIds: group.customers.map(
          (subscriber) => subscriber.customerPhoneId,
        ),
      };

      if (group.id) {
        await updateGroup(newGroup);
      } else {
        await createGroup(newGroup);
      }

      addToast('The list has been successfully saved.', {
        appearance: 'success',
        autoDismiss: true,
      });

      history.push('/group-messaging');
    } catch (e) {
      addToast('The list could not be saved.', {
        appearance: 'error',
        autoDismiss: true,
      });

      setSaving(false);
    }
  }, [addToast, group.customers, group.description, group.id, group.name, group.type, history]);

  return (
    <div className="row">
      <div className="col-md-5">
        <form>
          <GroupDetails
            disabled={group.readOnly}
            description={group.description}
            name={group.name}
            setName={handleNameChange}
            setDescription={handleDescriptionChange}
            type="list"
          />
          <div className="subscribers-selected-count">
            <p>
              {group.customers.length}
              {' '}
              Subscribers selected
            </p>
          </div>
          <div className="actions">
            <button
              type="button"
              className="btn btn-primary"
              onClick={onSubmit}
            >
              {saving ? 'Saving...' : (
                <>
                  {mode.title}
                  {' '}
                  List
                </>
              )}
            </button>
          </div>
        </form>
      </div>

      <div className="group-subscribers col-md-6 offset-md-1">
        <h3 className="title">Add Subscribers</h3>
        <div className="group-subscriber-list">
          { /* @ts-ignore */ }
          <MessageGroupSubscribers
            customers={allCustomers}
            subscribers={group.customers}
            toggleSubscribers={toggleSubscribers}
            showDateAdded={mode.showDateAdded}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(EditList);
