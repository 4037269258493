import React, { memo } from 'react';

/**
 *
 * @param {Object} props
 * @param {String} props.value
 * @param {Function} props.onChange
 * @returns
 */
const TextField = ({ onChange, value }) => (
  <div className="col-md-3">
    <input
      id="value"
      type="text"
      className="form-control"
      value={value}
      onChange={(e) => {
        const newValue = e.target.value;

        onChange('value', newValue);
      }}
    />
  </div>
);

export default memo(TextField);
