import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FeatureFlagProvider, useFeatureFlags } from '../contexts';
import { CookieMessage } from './global/CookieMessage';
import Header from './global/Header';
import { AuthActionCreators } from '../redux/actions/auth';
import Analytics from '../pages/Analytics';
import CustomerAnalyticsPage from '../pages/CustomerAnalyics/CustomerAnalyticsPage';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import { Settings } from '../pages/Settings';
import { AbandonedCarts } from '../pages/AbandonedCarts';
import Dashboard from '../pages/Dashboard';
import Messaging from '../pages/Messaging';
import { AutomatedMessages } from '../pages/AutomatedMessages';
import { UpSellsPage } from '../pages/UpSellsPage';
import { CrossSellsPage } from '../pages/CrossSellsPage';
import { ReplenishmentsPage } from '../pages/ReplenishmentsPage';
import { SEO } from './global/SEO';
import { PageBuilder } from './global/PageBuilder';
import MessageGroupsList from '../pages/GroupMessaging/MessageGroupsList';
import ScheduledList from '../pages/GroupMessaging/ScheduledList';
import GroupMessaging from '../pages/GroupMessaging/GroupMessaging';
import GroupMessageAnalytics from '../pages/GroupMessaging/GroupMessageAnalytics';
import BlueprintValueSnapshot from '../pages/BlueprintValueSnapshot/BlueprintValueSnapshotPage';
import { MerchantBilling } from '../pages/MerchantBilling';
import SMSAnalytics from '../pages/SMSAnalytics/SMSAnalyticsPage';
import AttributedOrdersPage from '../pages/SMSAnalytics/AttributedOrders/AttributedOrdersPage';
import GroupSegment from '../pages/GroupMessaging/GroupSegment';
import GroupList from '../pages/GroupMessaging/GroupList';
import ContactsList from '../pages/ContactsPage';
import RepliesAnalyticsPage from '../pages/RepliesAnalytics/RepliesAnalyticsPage';
import RepliesPage from '../pages/Replies/RepliesPage';
import IntegrationsHubPage from '../pages/Integrations/IntegrationsHubPage';
import IntegrationsHubKlayvioPage from '../pages/Integrations/IntegrationsHubKlaviyoPage';
import IntegrationsHubPrivyPage from '../pages/Integrations/IntegrationsHubPrivyPage';
import IntegrationsHubZendeskPage from '../pages/Integrations/IntegrationsHubZendeskPage';
import IntegrationsHubGorgiasPage from '../pages/Integrations/IntegrationsHubGorgiasPage';
import IntegrationsHubSmartrrPage from '../pages/Integrations/IntegrationsHubSmartrrPage';
import IntegrationsHubRechargePage from '../pages/Integrations/IntegrationsHubRechargePage';
import IntegrationsHubBoldPage from '../pages/Integrations/IntegrationsHubBoldPage';
import IntegrationsHubCustomAPIPage from '../pages/Integrations/IntegrationsHubCustomAPIPage';
import SingleCrossSell from './main/CrossSells/SingleCrossSell';
import CrossSells from '../pages/CrossSells';
import Replenishments from '../pages/Replenishments';
import CustomFlows from '../pages/CustomFlows';
import SmartLinksSummary from '../pages/SmartLinks/Summary';
import SmartLinksWizard from '../pages/SmartLinks/Wizard';

/** @typedef {import('../redux/reducers').RootState} RootState */

const { checkToken: checkTokenAction } = AuthActionCreators;

const ProtectedRoute = ({
  isVerified, li = undefined, targetPath, ...props
}) => (isVerified ? (
  <Route {...props} />
) : (
  <Redirect to={li ? '/' : `/login?return_url=${targetPath}`} />
));

const ProtectedRoutes = ({ isAuthed, location }) => {
  const { isMerchantDataReady, isFeatureEnabled } = useFeatureFlags();
  const [isGroupMessagingListsEnabled, setGroupMessagingListsEnabled] = useState(
    true,
  );
  const [scheduledGroupMessageEnabled, setScheduledGroupMessageEnabled] = useState(false);
  const [blueprintValueEnabled, setBlueprintValueEnabled] = useState(false);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      const groupMessagingListsFlag = await isFeatureEnabled('groupMessagingLists', true);
      setGroupMessagingListsEnabled(groupMessagingListsFlag);
      const scheduledGroupMessageIsActive = await isFeatureEnabled('groupmessagev2', false);
      setScheduledGroupMessageEnabled(scheduledGroupMessageIsActive);
      const blueprintValueIsActive = await isFeatureEnabled('blueprintValue', false);
      setBlueprintValueEnabled(blueprintValueIsActive);
    };

    fetchFeatureFlags();
  }, [isFeatureEnabled]);

  return (
    <ProtectedRoute isVerified={isAuthed} path="/" targetPath={location.pathname}>
      {!isMerchantDataReady ? <LoadingRoute /> : (
        <>
          <Header />
          <ToastProvider>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route
                exact
                path="/messaging"
                component={Messaging}
              />
              <Route
                exact
                path="/group-messaging/segments/:groupId?"
                component={GroupSegment}
              />
              {isGroupMessagingListsEnabled && (
                <Route
                  exact
                  path="/group-messaging/lists/:groupId?"
                  component={GroupList}
                />
              )}
              <Route
                exact
                path="/group-messaging/:groupId/analytics"
                component={GroupMessageAnalytics}
              />
              <Route
                exact
                path="/group-messaging/:groupId"
                component={GroupMessaging}
              />
              <Route
                exact
                path="/group-messaging"
                component={MessageGroupsList}
              />
              {scheduledGroupMessageEnabled && (
                <Route
                  exact
                  path="/scheduled-messages"
                  component={ScheduledList}
                />
              )}
              <Route
                exact
                path="/contacts"
                component={ContactsList}
              />
              <Route
                exact
                path="/customer-analytics"
                component={CustomerAnalyticsPage}
              />
              <Route
                exact
                path="/analytics"
                component={Analytics}
              />
              <Route
                exact
                path="/sms-analytics"
                component={SMSAnalytics}
              />
              {blueprintValueEnabled && (
                <Route
                  exact
                  path="/blueprint-snapshot"
                  component={BlueprintValueSnapshot}
                />
              )}
              <Route
                exact
                path="/attributed-orders"
                component={AttributedOrdersPage}
              />
              <Route
                path="/settings"
                render={(routeProps) => (
                  <Settings {...routeProps} />
                )}
              />
              {true && (
                <Route
                  exact
                  path="/abandoned-carts"
                  component={AbandonedCarts}
                />
              )}
              {true && (
                <Route
                  exact
                  path="/automated-messages"
                  component={AutomatedMessages}
                />
              )}
              <Route
                exact
                path="/upsells"
                component={UpSellsPage}
              />
              <Route
                exact
                path="/replies-analytics"
                component={RepliesAnalyticsPage}
              />
              <Route
                exact
                path="/replies"
                component={RepliesPage}
              />
              <Route
                exact
                path="/integrations"
                component={IntegrationsHubPage}
              />
              <Route
                exact
                path="/integrations/klaviyo"
                component={IntegrationsHubKlayvioPage}
              />
              <Route
                exact
                path="/integrations/privy"
                component={IntegrationsHubPrivyPage}
              />
              <Route
                exact
                path="/integrations/zendesk"
                component={IntegrationsHubZendeskPage}
              />
              <Route
                exact
                path="/integrations/gorgias"
                component={IntegrationsHubGorgiasPage}
              />
              <Route
                exact
                path="/integrations/smartrr"
                component={IntegrationsHubSmartrrPage}
              />
              <Route
                exact
                path="/integrations/recharge"
                component={IntegrationsHubRechargePage}
              />
              <Route
                exact
                path="/integrations/bold"
                component={IntegrationsHubBoldPage}
              />
              <Route
                exact
                path="/cross-sells"
                component={CrossSellsPage}
              />
              <Route
                exact
                path="/replenishments"
                component={ReplenishmentsPage}
              />
              <Route
                exact
                path="/cross-sells/edit/:id?"
                component={SingleCrossSell}
              />
              <Route
                exact
                path="/replenishments/edit/:id?"
                component={SingleCrossSell}
              />
              <Route
                exact
                path="/cross-sells/new"
                component={SingleCrossSell}
              />
              <Route
                exact
                path="/replenishments/new"
                component={SingleCrossSell}
              />
              <Route
                exact
                path="/integrations/customapi"
                component={IntegrationsHubCustomAPIPage}
              />
              <Route
                exact
                path="/cross-sells/product-report"
                component={CrossSells}
              />
              <Route
                exact
                path="/replenishments/product-report"
                component={Replenishments}
              />
              <Route
                path="/automations"
                component={CustomFlows}
              />
              <Route
                exact
                path="/smart-links"
                component={SmartLinksSummary}
              />
              <Route
                exact
                path="/smart-links/create"
                component={SmartLinksWizard}
              />
              <Route
                exact
                path="/smart-links/:id?"
                component={SmartLinksWizard}
              />
              <Route exact path="/logout" component={Logout} />
              <Redirect to="/" />
            </Switch>
          </ToastProvider>
        </>
      )}
    </ProtectedRoute>
  );
};

const LoadingRoute = () => (
  <Route path="/">
    <Header noCall />
    <PageBuilder noCall>
      <SEO title="Dashboard | Blueprint" />
      <div className="a-view dashboard">
        <div className="container">Loading...</div>
      </div>
    </PageBuilder>
  </Route>
);

const Routing = () => {
  const dispatch = useDispatch();
  const [isTokenCheckingComplete, setIsTokenCheckingComplete] = useState(false);
  const location = useLocation();

  const isAuthed = useSelector(
    (/** @type {RootState} */state) => state.AuthReducer.isAuthed,
    shallowEqual,
  );

  useEffect(() => {
    const checkToken = async () => {
      try {
        await dispatch(checkTokenAction());
        await setIsTokenCheckingComplete(true);
      } catch (err) {
        await setIsTokenCheckingComplete(true);
      }

      return null;
    };

    if (location.pathname !== '/login') {
      checkToken();
    }
  }, [dispatch, location]);

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route
        exact
        path="/merchants/:merchantId/billing/:stripePlanId"
        component={MerchantBilling}
      />
      <FeatureFlagProvider>
        {isTokenCheckingComplete ? (
          <ProtectedRoutes isAuthed={isAuthed} location={location} />
        ) : (
          <LoadingRoute />
        )}
      </FeatureFlagProvider>
      <CookieMessage />
    </Switch>
  );
};

export default Routing;
