import React from 'react';

export default ({ loading, usage }) => {
  if (loading || !usage) {
    return null;
  }

  const { quantity } = usage;

  return (
    <div className="segment-usage" title="SMS segments used this billing period">
      {quantity}
      {' '}
      segments used
    </div>
  );
};
