import React, { useEffect, useState } from 'react';

export default ({
  current, total, perPage, onChange,
}) => {
  const [maxPage, setMaxPage] = useState(0);

  useEffect(() => {
    setMaxPage(Math.ceil(total / perPage));
  }, [total, perPage]);

  const previousPage = () => {
    if (current > 1 && onChange) {
      onChange(current - 1);
    }
  };

  const nextPage = () => {
    if (current < maxPage && onChange) {
      onChange(current + 1);
    }
  };

  return maxPage <= 1 ? null : (
    <div className="pagination">
      <span className="totals">
        {(current - 1) * perPage + 1}
        {' '}
        -
        {Math.min(current * perPage, total)}
        {' '}
        of
        {' '}
        {total}
      </span>
      <span
        className={`nav previous ${current === 1 ? 'disabled' : ''}`}
        onClick={previousPage}
        role="link"
        tabIndex={0}
      >
        PREVIOUS
      </span>
      <span
        className={`nav next ${current === maxPage ? 'disabled' : ''}`}
        onClick={nextPage}
        role="link"
        tabIndex={0}
      >
        NEXT
      </span>
    </div>
  );
};
