import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import Picker from 'emoji-picker-react';

class EmojiPicker extends Component {
  handleClickOutside = () => {
    const { close, isOpen } = this.props;
    if (isOpen) {
      close();
    }
  };

  render() {
    const { onPick, isOpen } = this.props;
    return (
      <div className={`emoji-picker ${isOpen ? 'open' : ''}`}>
        <Picker onEmojiClick={onPick} />
      </div>
    );
  }
}

export default onClickOutside(EmojiPicker);
