import React, {
  memo,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { v4 as uuidv4 } from 'uuid';
import {
  useQueryClient,
} from 'react-query';

import { DemoPhone } from '../main/DemoPhone';
import { HelpBubble } from '../main/HelpBubble';
import { CustomFlowsActionCreators } from '../../redux/actions/customFlows';
import { isObjectEmpty } from '../../utils';
import EventFilter, { getDisabledFlagForEventFilter, getValueMapperForAutomationType } from '../main/CustomFlows/Filters/EventFilter';
import { BPToggle } from '../main/BPToggle';
import {
  TRIGGER_EVENT_TYPES,
  getMessagesFromNodes,
} from '../../utils/Flows';
import NodesEditor from '../main/CustomFlows/NodesEditor';
import ContactFilter, { getValueMapperForContactFilterType } from '../main/CustomFlows/Filters/ContactFilter';
import { useFeatureFlags } from '../../contexts';
import { MerchantActionCreators } from '../../redux/actions/merchant';

const { getMerchantData: getMerchantDataAction } = MerchantActionCreators;

/** @typedef {import('../../redux/reducers').RootState} CustomFlowsRootState */

/**
 * @typedef {Object} Filter
 * @property {String} id
 * @property {boolean} negate
 * @property {String} type
 * @property {String} comparator
 * @property {String} value
 */

const EXIT_EVENTS = [
  {
    value: 'ORDER',
    label: 'Exit on Order',
  },
  {
    value: 'INCOMING_SMS',
    label: 'Exit on Reply',
  },
];

const OPTIN_CLASSES = [
  {
    value: 'MARKETING',
    label: 'Marketing - Requires explict consent',
  },
  {
    value: 'TRANSACTIONAL',
    label: 'Transactional - Only for order confirmation purposes',
  },
];

const {
  getFlow: getFlowAction,
} = CustomFlowsActionCreators;

const ControlledEventFilter = ({ control }) => {
  const triggerEventType = useWatch({
    control,
    name: 'triggerEventType',
  });

  const eventFilterEnabled = useWatch({
    control,
    name: 'eventFilterEnabled',
  });

  return eventFilterEnabled ? (
    <Controller
      control={control}
      name="filters"
      render={({
        field: { onChange, value },
      }) => (
        <EventFilter
          automationType={triggerEventType}
          onChange={onChange}
          filters={value}
        />
      )}
    />
  ) : null;
};

const ControlledContactFilter = ({ control }) => {
  const contactFilterEnabled = useWatch({
    control,
    name: 'contactFilterEnabled',
  });

  return contactFilterEnabled ? (
    <Controller
      control={control}
      name="contactFilter.predicates"
      render={
        ({ field: { onChange, value } }) => (
          <ContactFilter
            onChange={onChange}
            filters={value}
          />
        )
      }
    />
  ) : null;
};

const ControlledExitEventSelector = ({ control }) => {
  const exitEventEnabled = useWatch({
    control,
    name: 'exitEventEnabled',
    defaultValue: false,
  });

  return exitEventEnabled && (
    <Controller
      control={control}
      name="exitEvent"
      render={({ field }) => (
        <select
          className="form-control"
          id="exitEvent"
          {...field}
        >
          <option value="0">Please select a exit event type</option>
          {EXIT_EVENTS.map((type) => (
            <option value={type.value} key={type.value}>
              {type.label}
            </option>
          ))}
        </select>
      )}
    />
  );
};

const CustomFlowForm = ({ id = null, onSubmit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { isFeatureEnabled } = useFeatureFlags();

  // I don't know. - DI 15/05/21 / DS 30/05/22
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const merchantData = useSelector(
    (/** @type {CustomFlowsRootState} */ state) => state.MerchantReducer.merchantData, shallowEqual,
  ) || {};

  const {
    isLoading,
    flow,
    flowCreated,
    flowUpdated,
  } = useSelector((/** @type {CustomFlowsRootState} */state) => state.CustomFlowsReducer);

  const [isAdvancedSettingsVisible, setAdvancedSettingsVisible] = useState(false);
  const [isChurnedSubscriberTriggerEnabled, setIsChurnedSubscriberTriggerEnabled] = useState(false);
  const [supportedTriggerEvents, setSupportedTriggerEvents] = useState(TRIGGER_EVENT_TYPES);

  const getMerchantInfo = useCallback(() => {
    const needsToGetMerchantData = Object.keys(merchantData).length === 0;

    if (needsToGetMerchantData) {
      dispatch(getMerchantDataAction());
    }
  }, [dispatch, merchantData]);

  useEffect(() => {
    getMerchantInfo();
  }, [getMerchantInfo]);

  useEffect(() => {
    const updateChurnedSubscriberTriggerFlagState = async () => {
      const isEnabled = await isFeatureEnabled(
        'churnedSubscriberTrigger',
        false,
      );

      setIsChurnedSubscriberTriggerEnabled(isEnabled);
    };

    updateChurnedSubscriberTriggerFlagState();
  }, [isFeatureEnabled]);

  useEffect(() => {
    if (merchantData.isV2Enabled) {
      setSupportedTriggerEvents(TRIGGER_EVENT_TYPES);
    } else {
      const triggerEventsWithoutV2Events = TRIGGER_EVENT_TYPES.filter((event) => event.value !== 'store.blueprint.prediction.CustomerPredictionSent');

      setSupportedTriggerEvents(triggerEventsWithoutV2Events);
    }
  }, [merchantData.isV2Enabled, setSupportedTriggerEvents]);

  const {
    control, handleSubmit, formState, reset, getValues, setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: flow,
  });

  const { isValid, dirtyFields, isSubmitting } = formState;

  /**
   * react-hook-form usually provides `isDirty` as a property of formState,
   * however we are running into an issue where `isDirty` is set to true
   * even when the form is not truly dirty. I believe this is related
   * to how we reset the form when we load a Flow to be edited, and how
   * the isDirty algorithm works comparing against the `defaultValues` that
   * are passed in the first time the form is rendered.
   *
   * For now, we can count the amount of fields in `dirtyFields` and if that
   * is zero we can safely assume the form is not truly dirty.
   */
  const isDirty = Object.keys(dirtyFields).length > 0;

  const triggerEventType = useWatch({
    control,
    name: 'triggerEventType',
  });

  const nodes = useWatch({
    control,
    name: 'nodes',
  });

  const handleFormSubmit = async (formData) => {
    const filters = getValues('filters');

    const type = supportedTriggerEvents.find(
      (eventType) => formData.triggerEventType === eventType.value,
    );

    let triggerEventFilter = {};

    if (type && formData.eventFilterEnabled) {
      const valueMapper = getValueMapperForAutomationType(formData.triggerEventType);

      triggerEventFilter = { [type.key]: valueMapper(filters) };
    }

    const contactFilter = {};

    if (formData.contactFilterEnabled) {
      contactFilter.predicates = formData.contactFilter.predicates.map((filter) => {
        const valueMapper = getValueMapperForContactFilterType(filter.type);

        return valueMapper(filter);
      });
    }

    await onSubmit({
      ...formData,
      triggerEventFilter,
      contactFilter,
    }, id);
  };

  const filterTriggerEventTypes = (type) => (
    (type.value === 'store.blueprint.subscription.LastSubscriptionCancelled' && isChurnedSubscriberTriggerEnabled)
    || (type.value !== 'store.blueprint.subscription.LastSubscriptionCancelled'));

  useEffect(() => {
    if (flowCreated === true) {
      addToast('Successfully created flow', {
        appearance: 'success',
        autoDismiss: true,
      });
      history.push('/automations');
    } else if (flowCreated === false) {
      addToast('Unable to create flow, please check the form and try again', {
        appearance: 'error',
        autoDismiss: true,
      });
    } else if (flowUpdated === true) {
      addToast('Successfully updated flow', {
        appearance: 'success',
        autoDismiss: true,
      });
    } else if (flowUpdated === false) {
      addToast('Unable to update flow, please check the form and try again', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [flowCreated, flowUpdated, history, addToast]);

  const getSingleFlow = useCallback((flowId) => {
    dispatch(getFlowAction(flowId, queryClient));
  }, [dispatch, queryClient]);

  useEffect(() => {
    if (id) {
      getSingleFlow(id);
    }
  }, [getSingleFlow, id]);

  useEffect(() => {
    if (id && !isObjectEmpty(flow)) {
      reset(flow);
    }
  }, [flow, id, reset]);

  const toggleAdvancedSettings = () => {
    setAdvancedSettingsVisible(!isAdvancedSettingsVisible);
  };

  if (isLoading || (id && isObjectEmpty(flow))) {
    return <div>Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="row single-custom-flow">
        <div className="col-md-9">
          <div className="form-group">
            <label htmlFor="name">Automation Title</label>
            <div className="field">
              <Controller
                name="name"
                render={({ field }) => (
                  <input
                    {...field}
                    className="form-control"
                    placeholder="Please enter an automation title"
                  />
                )}
                control={control}
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="name">
              Triggering Event
              <HelpBubble text="Please select the event that triggers this automation" />
            </label>
            <div className="field">
              <Controller
                name="triggerEventType"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange: handleChange, ...field } }) => (
                  <select
                    {...field}
                    className="form-control"
                    onChange={(e) => {
                      handleChange(e);
                      setValue('filters', [{ id: uuidv4() }], { shouldDirty: true });
                    }}
                  >
                    <option value="0">Please select a triggering event type</option>
                    {supportedTriggerEvents
                      .filter(filterTriggerEventTypes)
                      .map((type) => (
                        <option value={type.value} key={type.value}>
                          {type.label}
                        </option>
                      ))}
                  </select>
                )}
              />
            </div>
          </div>
          <div className="card mb-4">
            <div
              className="card-header"
              onClick={toggleAdvancedSettings}
              tabIndex={0}
              role="button"
            >
              <i className={`${isAdvancedSettingsVisible ? 'bi-caret-down-fill' : 'bi-caret-right-fill'} mr-2`} />
              Advanced Settings
              <i className="bi-gear-fill float-right" />
            </div>
            <div className={`card-body ${isAdvancedSettingsVisible ? '' : 'd-none'}`}>
              <div className="form-group">
                <label htmlFor="optin">
                  SMS Subscription Settings
                  <HelpBubble text="Allows you to set automations to be transactional and not require explicit optin for order confirmation purposes" />
                </label>
                <div className="field">
                  <Controller
                    name="optinClass"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <select
                        className="form-control"
                        {...field}
                      >
                        {OPTIN_CLASSES.map((type) => (
                          <option value={type.value} key={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="triggerFilter">
                  <span className="bp-toggle-label">Once Per Contact</span>
                  <HelpBubble text="If set, a contact can only ever enter this automation flow once." />
                  <Controller
                    control={control}
                    name="allowMultipleEntries"
                    render={({
                      field: { onChange, value },
                    }) => (
                      <BPToggle
                        handleClick={() => {
                          onChange(!value);
                        }}
                        active={value}
                      />
                    )}
                  />
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="triggerFilter">
                  <span className="bp-toggle-label">Triggering Event Filter</span>
                  <HelpBubble text="Allows you to filter so that only certain events trigger this automation" />
                  <Controller
                    control={control}
                    name="eventFilterEnabled"
                    render={({
                      field: { onChange, value },
                    }) => (
                      <BPToggle
                        handleClick={() => {
                          onChange(!value);
                        }}
                        active={value}
                        disabled={
                          getDisabledFlagForEventFilter(triggerEventType) || triggerEventType === undefined
                        }
                      />
                    )}
                  />
                </label>
                <div className="field">
                  <ControlledEventFilter control={control} />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="name">
                  <span className="bp-toggle-label">Contact Filter</span>
                  <HelpBubble text="Allows you to filter so that only certain contacts can enter this automation" />
                  <Controller
                    control={control}
                    name="contactFilterEnabled"
                    render={({
                      field: { onChange, value },
                    }) => (
                      <BPToggle
                        handleClick={() => {
                          onChange(!value);
                        }}
                        active={value}
                      />
                    )}
                  />
                </label>
                <div className="field">
                  <ControlledContactFilter control={control} />
                </div>
              </div>
              <div className="form-group mb-0">
                <label htmlFor="name">
                  <span className="bp-toggle-label">Exit Event</span>
                  <HelpBubble text="Allows you to choose an event that exits contacts from this automation" />
                  {' '}
                  <Controller
                    control={control}
                    name="exitEventEnabled"
                    render={({
                      field: { onChange, value },
                    }) => (
                      <BPToggle
                        handleClick={() => {
                          onChange(!value);
                        }}
                        active={value}
                      />
                    )}
                  />
                </label>
                <div className="field">
                  <ControlledExitEventSelector control={control} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row single-custom-flow">
        <div className="col-lg-9">
          <Controller
            control={control}
            name="nodes"
            render={({
              field: { onChange, value },
            }) => (
              <NodesEditor nodes={value} updateNodes={onChange} />
            )}
          />
        </div>
        <div className="col-lg-3 flex-column d-flex justify-content-between">
          <div className="cart-message-editor">
            <label htmlFor="demoPhone">
              <span>Preview</span>
            </label>
            <DemoPhone
              id="demoPhone"
              messages={getMessagesFromNodes(nodes)}
            />
          </div>
        </div>
      </div>

      <button
        type="submit"
        className="btn btn-primary mr-2"
        disabled={!isDirty || !isValid || isSubmitting}
      >
        {isSubmitting ? 'Saving' : 'Save' }
      </button>
    </form>
  );
};

export default memo(CustomFlowForm);
