import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { DEFAULT_SUPPORT_SESSION_DURATION } from '../../utils/settings';

const IntegrationGorgiasForm = ({
  gorgiasIntegrationData,
  onSubmit,
  onDelete,
  connectionTestEventCallback,
}) => {
  const [initialApiKeyData] = useState({
    apiUrl: gorgiasIntegrationData?.authData?.apiUrl,
    gorgiasEmail: gorgiasIntegrationData?.authData?.gorgiasEmail,
    apiKey: gorgiasIntegrationData?.authData?.apiKey,
    supportSessionDuration: gorgiasIntegrationData?.settingsData?.supportSessionDuration
      || DEFAULT_SUPPORT_SESSION_DURATION,
    performDeletionOnSubmit: false,
  });

  let canPerformConnectionTest = false;
  let canSubmitForm = false;
  let canDelete = false;
  let shouldFormElementsBeDisabled = false;

  const {
    integrationsLoading,
    gorgiasUpsertLoading,
    gorgiasTestLoading,
    gorgiasTestSuccess,
    gorgiasTestError,
    gorgiasDeletionLoading,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */state) => state.IntegrationsReducer,
  );

  const {
    register, handleSubmit, formState, getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialApiKeyData,
  });

  const handleFormSubmit = (formData) => onSubmit(formData);

  const handleConnectionTest = (event) => {
    event.preventDefault();
    const [gorgiasEmail, apiUrl, apiKey] = getValues(['gorgiasEmail', 'apiUrl', 'apiKey']);

    return connectionTestEventCallback({ gorgiasEmail, apiUrl, apiKey });
  };

  canPerformConnectionTest = formState.isValid
    && !gorgiasTestLoading
    && !gorgiasUpsertLoading
    && !gorgiasDeletionLoading;

  canSubmitForm = formState.isValid
    && !gorgiasUpsertLoading
    && !integrationsLoading
    && !gorgiasTestLoading
    && !gorgiasTestError
    && !gorgiasDeletionLoading
    && gorgiasTestSuccess;

  canDelete = !gorgiasUpsertLoading
  && !integrationsLoading
  && !gorgiasTestLoading
  && !gorgiasDeletionLoading
  && !gorgiasTestError;

  shouldFormElementsBeDisabled = !gorgiasUpsertLoading && !gorgiasTestLoading;

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="form-group">
        <label htmlFor="apiUrl">Base API URL</label>
        <div className="field">
          <input
            id="apiUrl"
            type="text"
            className="form-control"
            placeholder="API URL"
            disabled={!shouldFormElementsBeDisabled}
            {...register('apiUrl', { required: true })}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="gorgiasEmail">Username (Email)</label>
        <div className="field">
          <input
            id="gorgiasEmail"
            type="text"
            className="form-control"
            placeholder="Username (Email)"
            disabled={!shouldFormElementsBeDisabled}
            {...register('gorgiasEmail', { required: true })}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="apiKey">Password (API Key)</label>
        <div className="field">
          <input
            id="apiKey"
            type="text"
            className="form-control"
            placeholder="Password (API Key)"
            disabled={!shouldFormElementsBeDisabled}
            {...register('apiKey', { required: true })}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="supportSessionDuration">Support Session Duration (in hours)</label>
        <div className="field">
          <input
            id="supportSessionDuration"
            type="number"
            className="form-control"
            placeholder={`${DEFAULT_SUPPORT_SESSION_DURATION}`}
            disabled={!shouldFormElementsBeDisabled}
            {...register('supportSessionDuration', { required: false })}
          />
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-primary mr-2"
        disabled={!canSubmitForm}
      >
        {gorgiasIntegrationData?.active ? 'Update' : 'Activate'}
      </button>
      <button
        className="btn btn-info mr-2"
        onClick={handleConnectionTest}
        disabled={!canPerformConnectionTest}
        type="button"
      >
        Test Connection
      </button>
      {gorgiasIntegrationData?.active && (
        <button
          type="button"
          className="btn btn-danger mr-2"
          disabled={!canDelete}
          onClick={onDelete}
        >
          Delete
        </button>
      )}
      {gorgiasTestSuccess && (
      <span>
        <span className="mr-1">✅</span>
        Connection OK!
      </span>
      )}
      {gorgiasTestError && (
      <span>
        <span className="mr-1">❌</span>
        {gorgiasTestError.toString()}
      </span>
      )}
    </form>
  );
};

export default IntegrationGorgiasForm;
