import React, { useState, useCallback, useEffect } from 'react';

import { HelpBubble } from '../../HelpBubble';
import ProdVarSelect from '../../../global/ProdVarSelect';
import {
  UPSELL_PROD_SELECT_ALL_VARS,
  UPSELL_TRIGGER_HELP_TEXT,
  UPSELL_TEXT_ALL_VARS_SELECTED,
} from '../../../../utils';

/** @typedef {import('../../../global/ProdVarSelect').Item} Item */

const ProdVarAdder = ({
  upsellObj,
  allProducts,
  handleProductChange,
  productsLoading,
  type,
}) => {
  const [selectedProductId, setSelectedProductId] = useState(-1);
  const [selectedVariantId, setSelectedVariantId] = useState(-1);
  const [variantsFromChosenProd, setVariantsFromChosenProd] = useState(/** @type {Item[]} */ ([]));

  /**
   * Get full variant object from currently selected products' variants
   *
   * @param {Number} variantId
   */
  const getVariantById = useCallback((variantId) => {
    const index = variantsFromChosenProd.findIndex(
      (variant) => variant.id === variantId,
    );
    return variantsFromChosenProd[index];
  }, [variantsFromChosenProd]);

  /**
   * Get the variants associated with the given product.
   * This only returns the variants that are not currently selected
   *
   * @param {Number} productId
   */
  const getVariantsByProduct = (productId) => {
    const index = allProducts.findIndex((product) => product.id === productId);
    return allProducts[index].variants.filter(
      (variant) => upsellObj[type].findIndex(
        (product) => product.prodToGetVariantId === variant.id,
      ) < 0,
    );
  };

  /**
   * 1. Set the currently selected product
   * 2. Set the associated variants
   *
   * @param {Object} event Click event
   */
  const handleProductSelect = (event) => {
    const selectedId = parseInt(event.target.value, 10);
    if (!selectedId) {
      return;
    }
    setSelectedProductId(selectedId);
    setVariantsFromChosenProd(getVariantsByProduct(selectedId));
  };

  /**
   * All variants of the currently selected product will be sent into handler
   */
  const setAllVariants = useCallback(() => {
    const productDetail = allProducts.find(
      (product) => product.id === selectedProductId,
    );
    const newProductArr = [
      {
        upsellId: upsellObj.id,
        name: productDetail.name,
        variantName: UPSELL_TEXT_ALL_VARS_SELECTED,
        prodToGetProductId: selectedProductId,
      },
    ];
    handleProductChange([...upsellObj[type], ...newProductArr], type);
  }, [allProducts, handleProductChange, selectedProductId, type, upsellObj]);

  const setSpecificVariant = useCallback(
  /**
   * Only the currently selected variant will be sent into handler
   * (with the product id it belongs to)
   *
   * @param {Number} addedVarId selected variant id
   */
    (addedVarId) => {
      setSelectedVariantId(addedVarId);
      const addedVariant = getVariantById(addedVarId);
      const newProductArr = [
        {
          upsellId: upsellObj.id,
          name: addedVariant.name,
          variantName: addedVariant.variantName,
          prodToGetVariantId: addedVariant.id,
          prodToGetProductId: selectedProductId,
        },
      ];

      handleProductChange([...upsellObj[type], ...newProductArr], type);
    }, [getVariantById, handleProductChange, selectedProductId, type, upsellObj],
  );

  /**
   * It will add the selected varaint to the upsell products
   *
   * @param {Object} event Click event
   */
  const handleVariantSelect = (event) => {
    const addedVarId = event.target.value;
    if (!addedVarId) {
      return;
    } if (addedVarId === UPSELL_PROD_SELECT_ALL_VARS) {
      setAllVariants();
      return;
    }

    setSpecificVariant(parseInt(addedVarId, 10));
  };

  /**
   * Remove the clicked variant from upsell products
   *
   * @param {Object} event Click event
   */
  const handleVariantRemoval = (event) => {
    const variantId = parseInt(event.target.dataset.variant, 10);
    const index = upsellObj[type].findIndex(
      (variant) => variant.prodToGetVariantId === variantId,
    );

    const newProductsArr = [...upsellObj[type]];
    newProductsArr.splice(index, 1);

    handleProductChange(newProductsArr, type);
  };

  useEffect(() => {
    if (variantsFromChosenProd.length === 1) {
      setSpecificVariant(variantsFromChosenProd[0].id);
    }
  // Including setSpecificVariant as a dependency causes an infinite loop - DI 17/5/21
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantsFromChosenProd]);

  const shouldShowProdSelect = !productsLoading && allProducts && allProducts.length > 0;

  return (
    <>
      <label>
        <span className="section-head">
          Upsell
          {' '}
          {type}
        </span>
        <HelpBubble
          left
          small
          text={
            type === 'products'
              ? 'Upsell products will be offered to customers in the upsell message link after the set timeframe following the purchase of a trigger product'
              : UPSELL_TRIGGER_HELP_TEXT
          }
        />
        {type === 'triggers' && (
          <span className="note">(cannot be updated)</span>
        )}
      </label>
      <table className="product-chooser">
        <thead>
          <tr>
            <th>Name</th>
            <th>Variant (required)</th>
          </tr>
        </thead>
        <tbody>
          {shouldShowProdSelect && (
            <tr>
              <td>
                <ProdVarSelect
                  isProductSelect
                  selectedItemId={selectedProductId}
                  items={allProducts.filter(
                    (product) => product.subscription !== 1,
                  )}
                  handleChange={handleProductSelect}
                />
              </td>
              <td>
                <ProdVarSelect
                  isProductSelect={false}
                  selectedItemId={selectedVariantId}
                  items={variantsFromChosenProd}
                  handleChange={handleVariantSelect}
                  canChooseAllVars={type === 'triggers'}
                />
              </td>
            </tr>
          )}
          {upsellObj[type]
            .filter(
              (product) => product.prodToGetVariantId || product.variantName,
            )
            .map((product, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={index}>
                <td>{product.name}</td>
                <td>{product.variantName || 'n/a'}</td>
                <td className="variant-remover">
                  <span
                    data-variant={product.prodToGetVariantId}
                    onClick={handleVariantRemoval}
                    role="button"
                    tabIndex={0}
                  >
                    ✖
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default ProdVarAdder;
