import React from 'react';
import {
  formatAsMoney,
  formatValueAsPercentage,
} from '../../utils/numerical';

const CustomerAnalyticsBlock = (props) => {
  const {
    title,
    value,
    valuePercent,
    description,
    badLabel,
    badValue,
    badValuePercent,
    infoItems,
  } = props;
  return (
    <div className="analytics-info-card">
      <div>
        <h3 className="info-title">{title}</h3>
        <div className="info-description">
          {description}
        </div>
        <div className="info-value">
          {value}
          &nbsp;&nbsp;
          {valuePercent ? `(${valuePercent})` : ''}
        </div>
        <div className="info-subvalues">
          {infoItems.map((item) => (
            <div key={item.title}>
              {item.title}
              {' '}
              {item.value}
            </div>
          ))}
        </div>
        <div className="info-badsubvalues">
          {badLabel}
          {' '}
          {badValue}
          {' '}
          (
          {badValuePercent}
          )
        </div>
      </div>
    </div>
  );
};

const CustomerAnalyticsBlocks = (props) => {
  const {
    data,
    currency,
  } = props;

  const {
    smsOptedIn,
    smsOptedOut,
    smsSent,
    smsRevenueTotalRevenue,
    nCustomers,
    nOneTimeCustomers,
    nOneTimeCustomersChurned,
    totalOneTimeCustomerRevenue,
    nRepeatCustomers,
    nRepeatCustomersChurned,
    totalRepeatCustomerRevenue,
    avgOrderGap,
    nActiveSubscriberCustomers,
    nInactiveSubscriberCustomers,
    totalActiveSubscriberRevenue,
  } = data;

  const smsOptedOutPercent = formatValueAsPercentage(
    smsOptedOut / (smsOptedOut + smsOptedIn),
  );
  const smsRevenuePerMessage = formatAsMoney(
    smsRevenueTotalRevenue / smsSent,
    currency,
  );

  const percentOneTimeCustomers = formatValueAsPercentage(
    nOneTimeCustomers / nCustomers,
  );
  const percentOneTimeCustomersChurned = formatValueAsPercentage(
    nOneTimeCustomersChurned / nOneTimeCustomers,
  );
  const oneTimeCustomersAvgRevenue = formatAsMoney(
    totalOneTimeCustomerRevenue / nOneTimeCustomers,
    currency,
  );

  const percentRepeatCustomers = formatValueAsPercentage(
    nRepeatCustomers / nCustomers,
  );
  const percentRepeatCustomersChurned = formatValueAsPercentage(
    nRepeatCustomersChurned / nRepeatCustomers,
  );
  const repeatCustomersAvgRevenue = formatAsMoney(
    totalRepeatCustomerRevenue / nRepeatCustomers,
    currency,
  );

  const percentActiveSubscriberCustomers = formatValueAsPercentage(
    nActiveSubscriberCustomers / nCustomers,
  );
  const percentSubscribersChurned = formatValueAsPercentage(
    nInactiveSubscriberCustomers / (nInactiveSubscriberCustomers + nActiveSubscriberCustomers),
  );
  const activeSubscriberCustomersAvgRevenue = formatAsMoney(
    totalActiveSubscriberRevenue / nActiveSubscriberCustomers,
    currency,
  );

  return (
    <div className="row">
      <div className="col-md-3">
        <CustomerAnalyticsBlock
          title="SMS optins"
          description="Number of SMS subscribers"
          value={smsOptedIn}
          badLabel="Opted out"
          badValue={smsOptedOut}
          badValuePercent={smsOptedOutPercent}
          infoItems={[
            { title: 'Messages sent', value: smsSent },
            { title: 'Revenue per message', value: smsRevenuePerMessage },
          ]}
        />
      </div>
      <div className="col-md-3">
        <CustomerAnalyticsBlock
          title="One time customers"
          description="Customers who have ordered only once"
          value={nOneTimeCustomers}
          valuePercent={percentOneTimeCustomers}
          badLabel="Churned"
          badValue={nOneTimeCustomersChurned}
          badValuePercent={percentOneTimeCustomersChurned}
          infoItems={[
            { title: 'Avg. Revenue', value: oneTimeCustomersAvgRevenue },
          ]}
        />
      </div>
      <div className="col-md-3">
        <CustomerAnalyticsBlock
          title="Repeat customers"
          description="Customers who have ordered > 1 time"
          value={nRepeatCustomers}
          valuePercent={percentRepeatCustomers}
          badLabel="Churned"
          badValue={nRepeatCustomersChurned}
          badValuePercent={percentRepeatCustomersChurned}
          infoItems={[
            { title: 'Avg. Revenue', value: repeatCustomersAvgRevenue },
            { title: 'Avg. Order Gap', value: `${parseFloat(avgOrderGap).toFixed(1)} days` },
          ]}
        />
      </div>
      <div className="col-md-3">
        <CustomerAnalyticsBlock
          title="Active subscribers"
          description="Customers with an active subscription"
          value={nActiveSubscriberCustomers}
          valuePercent={percentActiveSubscriberCustomers}
          badLabel="Cancelled"
          badValue={nInactiveSubscriberCustomers}
          badValuePercent={percentSubscribersChurned}
          infoItems={[
            { title: 'Avg. Revenue', value: activeSubscriberCustomersAvgRevenue },
          ]}
        />
      </div>
    </div>
  );
};

export default CustomerAnalyticsBlocks;
