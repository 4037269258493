import { IntegrationsActionTypes } from '../../types';

const klaviyoUpsertInitialState = {
  klaviyoUpsertLoading: false,
  klaviyoUpsertSuccess: false,
  klaviyoUpsertError: null,
};

const klaviyoTestInitialState = {
  klaviyoTestLoading: false,
  klaviyoTestSuccess: false,
  klaviyoTestError: null,
};

const klaviyoGetListsInitialState = {
  klaviyoListsLoading: false,
  klaviyoListsLoaded: false,
  klaviyoLists: [],
  klaviyoListsError: null,
};

const privyUpsertInitialState = {
  privyUpsertLoading: false,
  privyUpsertSuccess: false,
  privyUpsertError: null,
};

const zendeskUpsertInitialState = {
  zendeskUpsertLoading: false,
  zendeskUpsertSuccess: false,
  zendeskUpsertError: null,
};

const zendeskDeleteInitialState = {
  zendeskDeleteLoading: false,
  zendeskDeleteSuccess: false,
  zendeskDeleteError: null,
};

const gorgiasUpsertInitialState = {
  gorgiasUpsertLoading: false,
  gorgiasUpsertSuccess: false,
  gorgiasUpsertError: null,
};

const gorgiasDeleteInitialState = {
  gorgiasDeletionLoading: false,
  gorgiasDeletionSuccess: false,
  gorgiasDeletionError: null,
};

const gorgiasTestInitialState = {
  gorgiasTestLoading: false,
  gorgiasTestSuccess: false,
  gorgiasTestError: null,
};

const rechargeTestInitialState = {
  rechargeTestLoading: false,
  rechargeTestSuccess: false,
  rechargeTestError: null,
};

const boldTestInitialState = {
  boldTestLoading: false,
  boldTestSuccess: false,
  boldTestError: null,
};

export const initialState = {
  integrationsLoading: false,
  integrationsErr: null,
  integrationsLoaded: false,
  integrationsData: {},
  ...klaviyoUpsertInitialState,
  ...klaviyoTestInitialState,
  ...klaviyoGetListsInitialState,
  ...privyUpsertInitialState,
  ...zendeskUpsertInitialState,
  ...zendeskDeleteInitialState,
  ...gorgiasUpsertInitialState,
  ...gorgiasTestInitialState,
  ...gorgiasDeleteInitialState,
  ...rechargeTestInitialState,
  ...boldTestInitialState,
};

export const IntegrationsReducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case IntegrationsActionTypes.GET_MERCHANT_INTEGRATIONS_REQ:
      return { ...state, integrationsLoading: true };
    case IntegrationsActionTypes.GET_MERCHANT_INTEGRATIONS_RES:
      if (error) {
        return {
          ...state,
          integrationsLoaded: false,
          integrationsErr: error,
        };
      }
      return {
        ...state,
        integrationsLoading: false,
        integrationsLoaded: true,
        integrationsData: payload.integrations,
      };

    case IntegrationsActionTypes.KLAVIYO_INTEGRATION_RESET:
      return {
        ...state,
        ...klaviyoUpsertInitialState,
        ...klaviyoTestInitialState,
      };

    case IntegrationsActionTypes.UPSERT_KLAVIYO_INTEGRATION_REQ:
      return {
        ...state,
        ...klaviyoUpsertInitialState,
        klaviyoUpsertLoading: true,
        ...klaviyoTestInitialState,
      };
    case IntegrationsActionTypes.UPSERT_KLAVIYO_INTEGRATION_RES:
      if (error) {
        return {
          ...state,
          ...klaviyoUpsertInitialState,
          klaviyoUpsertError: error,
        };
      }
      return {
        ...state,
        ...klaviyoUpsertInitialState,
        klaviyoUpsertSuccess: true,
      };

    case IntegrationsActionTypes.TEST_KLAVIYO_INTEGRATION_REQ:
      return {
        ...state,
        ...klaviyoTestInitialState,
        klaviyoTestLoading: true,
        ...klaviyoUpsertInitialState,
      };
    case IntegrationsActionTypes.TEST_KLAVIYO_INTEGRATION_RES:
      if (error) {
        return {
          ...state,
          ...klaviyoTestInitialState,
          klaviyoTestError: error,
        };
      }
      return {
        ...state,
        ...klaviyoTestInitialState,
        klaviyoTestSuccess: true,
      };

    case IntegrationsActionTypes.GET_KLAVIYO_LISTS_REQ:
      return {
        ...state,
        ...klaviyoGetListsInitialState,
        klaviyoListsLoading: true,
      };
    case IntegrationsActionTypes.GET_KLAVIYO_LISTS_RES:
      if (error) {
        return {
          ...state,
          ...klaviyoGetListsInitialState,
          klaviyoListsLoaded: true,
          klaviyoListsError: error,
        };
      }
      return {
        ...state,
        ...klaviyoGetListsInitialState,
        klaviyoListsLoaded: true,
        klaviyoLists: payload,
      };

    case IntegrationsActionTypes.UPSERT_PRIVY_INTEGRATION_RESET:
      return {
        ...state,
        ...privyUpsertInitialState,
      };
    case IntegrationsActionTypes.UPSERT_PRIVY_INTEGRATION_REQ:
      return {
        ...state,
        ...privyUpsertInitialState,
        privyUpsertLoading: true,
      };
    case IntegrationsActionTypes.UPSERT_PRIVY_INTEGRATION_RES:
      if (error) {
        return {
          ...state,
          ...privyUpsertInitialState,
          privyUpsertError: error,
        };
      }
      return {
        ...state,
        ...privyUpsertInitialState,
        privyUpsertSuccess: true,
      };

    case IntegrationsActionTypes.UPSERT_ZENDESK_INTEGRATION_RESET:
      return {
        ...state,
        ...zendeskUpsertInitialState,
      };
    case IntegrationsActionTypes.UPSERT_ZENDESK_INTEGRATION_REQ:
      return {
        ...state,
        ...zendeskUpsertInitialState,
        zendeskUpsertLoading: true,
      };
    case IntegrationsActionTypes.UPSERT_ZENDESK_INTEGRATION_RES:
      if (error) {
        return {
          ...state,
          ...zendeskUpsertInitialState,
          zendeskUpsertError: error,
        };
      }
      return {
        ...state,
        ...zendeskUpsertInitialState,
        zendeskUpsertSuccess: true,
      };
    case IntegrationsActionTypes.DELETE_ZENDESK_INTEGRATION_RESET:
      return {
        ...state,
        ...zendeskDeleteInitialState,
      };
    case IntegrationsActionTypes.DELETE_ZENDESK_INTEGRATION_REQ:
      return {
        ...state,
        zendeskDeleteLoading: true,
      };
    case IntegrationsActionTypes.DELETE_ZENDESK_INTEGRATION_RES:
      if (error) {
        return {
          ...state,
          zendeskDeleteLoading: false,
          zendeskDeleteError: error,
        };
      }
      return {
        ...state,
        zendeskDeleteLoading: false,
        zendeskDeleteSuccess: true,
      };
    case IntegrationsActionTypes.ZENDESK_INTEGRATION_RESET:
      return {
        ...state,
        ...zendeskUpsertInitialState,
        ...zendeskDeleteInitialState,
      };
    case IntegrationsActionTypes.UPSERT_GORGIAS_INTEGRATION_RESET:
      return {
        ...state,
        ...gorgiasUpsertInitialState,
        ...gorgiasTestInitialState,
        ...gorgiasDeleteInitialState,
      };
    case IntegrationsActionTypes.UPSERT_GORGIAS_INTEGRATION_REQ:
      return {
        ...state,
        ...gorgiasUpsertInitialState,
        gorgiasUpsertLoading: true,
      };
    case IntegrationsActionTypes.UPSERT_GORGIAS_INTEGRATION_RES:
      if (error) {
        return {
          ...state,
          ...gorgiasUpsertInitialState,
          gorgiasUpsertError: error,
        };
      }
      return {
        ...state,
        ...gorgiasUpsertInitialState,
        gorgiasUpsertSuccess: true,
      };
    case IntegrationsActionTypes.DELETE_GORGIAS_INTEGRATION_REQ:
      return {
        ...state,
        ...gorgiasDeleteInitialState,
        gorgiasDeletionLoading: true,
      };
    case IntegrationsActionTypes.DELETE_GORGIAS_INTEGRATION_RES:
      if (error) {
        return {
          ...state,
          ...gorgiasDeleteInitialState,
          gorgiasDeletionError: error,
        };
      }
      return {
        ...state,
        ...gorgiasDeleteInitialState,
        gorgiasDeletionSuccess: true,
      };
    case IntegrationsActionTypes.TEST_GORGIAS_INTEGRATION_REQ:
      return {
        ...state,
        ...gorgiasTestInitialState,
        gorgiasTestLoading: true,
        ...gorgiasUpsertInitialState,
      };
    case IntegrationsActionTypes.TEST_GORGIAS_INTEGRATION_RES:
      if (error) {
        return {
          ...state,
          ...gorgiasTestInitialState,
          gorgiasTestError: error,
        };
      }
      return {
        ...state,
        ...gorgiasTestInitialState,
        gorgiasTestSuccess: true,
      };

    case IntegrationsActionTypes.TEST_RECHARGE_INTEGRATION_REQ:
      return {
        ...state,
        ...rechargeTestInitialState,
        rechargeTestLoading: true,
      };
    case IntegrationsActionTypes.TEST_RECHARGE_INTEGRATION_RES:
      if (error) {
        return {
          ...state,
          ...rechargeTestInitialState,
          rechargeTestError: error,
        };
      }
      return {
        ...state,
        ...rechargeTestInitialState,
        rechargeTestSuccess: true,
      };
    case IntegrationsActionTypes.RECHARGE_INTEGRATION_RESET:
      return {
        ...state,
        ...boldTestInitialState,
      };

    case IntegrationsActionTypes.TEST_BOLD_INTEGRATION_REQ:
      return {
        ...state,
        ...boldTestInitialState,
        boldTestLoading: true,
      };
    case IntegrationsActionTypes.TEST_BOLD_INTEGRATION_RES:
      if (error) {
        return {
          ...state,
          ...boldTestInitialState,
          boldTestError: error,
        };
      }
      return {
        ...state,
        ...boldTestInitialState,
        boldTestSuccess: true,
      };
    case IntegrationsActionTypes.BOLD_INTEGRATION_RESET:
      return {
        ...state,
        ...boldTestInitialState,
      };

    default:
      return state;
  }
};
