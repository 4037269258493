import React, {
  memo,
} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Instruction from '../../components/main/Replenishments/Instruction';
import ReplenlishmentsProductTable from '../../components/main/Replenishments/Table';
import { types as CROSS_SELLS_TYPES, settings as CROSS_SELLS_SETTINGS } from '../../utils/crossSells';

/** @typedef {import('../../redux/reducers').RootState} RootState */

const MINUTES_IN_DAY = 24 * 60;

const Replenishments = () => {
  const {
    replenishmentsProductsSelected,
  } = useSelector((/** @type {RootState} */ state) => state.UpsellsReducer);

  const WrappedButton = () => {
    if (replenishmentsProductsSelected.length === 0) {
      return (
        <button
          className="btn btn-primary create-upsell-btn"
          onClick={() => {}}
          type="button"
          disabled
        >
          Create rule
        </button>
      );
    }

    const triggers = replenishmentsProductsSelected.map((product) => ({
      triggerProductId: product.initialProductId,
      triggerVariantId: product.initialProductVariantId,
      name: product.initialProductName,
      variantName: product.initialVariantName,
    }));

    let avgGapDays = 30;
    if (replenishmentsProductsSelected.length > 0) {
      avgGapDays = Math.round(replenishmentsProductsSelected[0].avgGapDays);
    }

    return (
      <Link
        to={{
          pathname: '/replenishments/new',
          state: {
            initialCrossSell: {
              messageContent: CROSS_SELLS_SETTINGS.replenishment.defaultMessage,
              messageInterval: avgGapDays * MINUTES_IN_DAY,
              triggers,
              products: [],
            },
            type: CROSS_SELLS_TYPES.REPLENISHMENTS,
            referrer: '/replenishments/product-report',
          },
        }}
        className="btn btn-primary create-upsell-btn"
      >
        Create rule
      </Link>
    );
  };

  return (
    <div>
      <Instruction />
      <div className="row">
        <div className="col-md-4">
          <h3>Product replenishment report</h3>
        </div>
        <div className="col-md-2 offset-md-6">
          <WrappedButton />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <ReplenlishmentsProductTable />
        </div>
      </div>
    </div>
  );
};

export default memo(Replenishments);
