import React, {
  memo, useState, useEffect, useCallback,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import {
  API,
  APIMethods,
  APIServiceConstants,
} from '../../redux/actions/BlueprintAPI';
import RepliesTable from './RepliesTable';
import { MerchantActionCreators } from '../../redux/actions/merchant';
import { getMessagesWithSentimentScore } from '../../utils/messageSentiment';
import MessageTypeSelect from '../RepliesAnalytics/MessageTypeSelect';

/** @typedef {import('../../redux/reducers').RootState} RootState */

const { getMerchantData: getMerchantDataAction } = MerchantActionCreators;

const RepliesPage = memo(() => {
  const [messages, setMessages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [previousMessageType, setPreviousMessageType] = useState('');
  const dispatch = useDispatch();
  // I don't know. - DI 15/05/21
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const merchantData = useSelector(
    (/** @type {RootState} */ state) => state.MerchantReducer.merchantData, shallowEqual,
  )
    || {};

  const fetchMessagesWithSentiments = useCallback(
    async (messageType = '', limit = 8, offset = 0) => {
      setLoading(true);
      const response = await API(
        APIMethods.GET,
        APIServiceConstants.SERVICE,
        `sms/sentiments?previousMessageType=${encodeURIComponent(
          messageType,
        )}&limit=${limit}&offset=${offset}`,
      );

      const mapping = getMessagesWithSentimentScore(response.data.messages);

      setMessages(mapping);
      setTotalCount(response.data.totalCount);
      setLoading(false);
    },
    [],
  );

  const handleMesageTypeChange = useCallback(
    async (newType) => {
      setPreviousMessageType(newType);

      await fetchMessagesWithSentiments(newType);
    },
    [fetchMessagesWithSentiments],
  );

  const getMerchantInfo = useCallback(() => {
    const needsToGetMerchantData = Object.keys(merchantData).length === 0;

    if (needsToGetMerchantData) {
      dispatch(getMerchantDataAction());
    }
  }, [dispatch, merchantData]);

  const handleFetchPageOfMessages = useCallback(
    (limit, offset) => fetchMessagesWithSentiments(previousMessageType, limit, offset),
    [previousMessageType, fetchMessagesWithSentiments],
  );

  useEffect(() => {
    getMerchantInfo();
  // I'm not sure what the pattern should be for exhaustive deps on "useEffect only once" empty deps arrays - DI 14/05/21
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageBuilder>
      <SEO title="Replies Analytics | Blueprint" />
      <div className="a-view sms-sentiments">
        <div className="container">
          <div className="top-bar">
            <h2 className="title">Replies Analytics</h2>

            <MessageTypeSelect
              onChange={handleMesageTypeChange}
              value={previousMessageType}
            />
          </div>

          <RepliesTable
            loading={loading}
            fetchMessages={handleFetchPageOfMessages}
            merchantCurrency={merchantData && merchantData.currencySymbol}
            messages={messages}
            totalCount={totalCount}
          />
        </div>
      </div>
    </PageBuilder>
  );
});

export default RepliesPage;
