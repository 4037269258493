import React, {
  memo, useState, useCallback, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MerchantActionCreators } from '../redux/actions/merchant';
import { SEO } from '../components/global/SEO';
import { PageBuilder } from '../components/global/PageBuilder';
import { BTitle } from '../components/global/BTitle';
import { formatDateFromSecs } from '../utils/dates';

const splitName = (name) => (!name ? null : name.split(' ')[0]);

const displayPlatform = (platform) => (platform === 'woo' ? 'WooCommerce' : 'Shopify');

const remainderOrZero = (used, total) => {
  const remainder = total - used;
  return remainder > 0 ? remainder : 0;
};

export default memo(() => {
  const dispatch = useDispatch();

  const {
    customerSubscriptionUsage,
    merchantData,
    merchantLoaded,
    userData,
    userLoaded,
  } = useSelector(
    (/** @type {import('../redux/reducers').RootState} */state) => state.MerchantReducer,
  );

  const [loaded, setLoaded] = useState(false);

  const getMerchantDataCallback = useCallback(() => {
    const { getMerchantData } = MerchantActionCreators;
    dispatch(getMerchantData());
  }, [dispatch]);

  const getUserDataCallback = useCallback(() => {
    const { getUserData } = MerchantActionCreators;
    dispatch(getUserData());
  }, [dispatch]);

  useEffect(() => {
    if (!merchantLoaded) {
      getMerchantDataCallback();
    }
  }, [merchantData, merchantLoaded, getMerchantDataCallback]);

  useEffect(() => {
    if (!userLoaded) {
      getUserDataCallback();
    }
  }, [userData, userLoaded, getUserDataCallback]);

  useEffect(() => {
    if (userLoaded && merchantLoaded) {
      setLoaded(true);
    }
  }, [userLoaded, merchantLoaded]);

  return (
    <PageBuilder>
      <SEO title="Dashboard | Blueprint" />
      <div className="a-view dashboard">
        <div className="container">
          {loaded ? (
            <div>
              <h2 className="title">
                Hey
                {' '}
                {splitName(userData.name) || 'there'}
                {' '}
                👋 Welcome to
                {' '}
                <BTitle text="blueprint" />
              </h2>
              <div className="row">
                <div className="col-md-5">
                  <div className="dash-card">
                    <BTitle text={merchantData.company} />
                    <div className="data">
                      <span>
                        E-Commerce Solution:
                        {' '}
                        {displayPlatform(merchantData.platform)}
                      </span>
                      <span>
                        Blueprint SMS Number: +
                        {merchantData.phone}
                      </span>
                      <span>
                        User:
                        {' '}
                        {userData.name}
                        {' '}
                        -
                        {' '}
                        {userData.role}
                      </span>
                      {customerSubscriptionUsage && (
                        <div className="data">
                          <span>
                            Next Bill Date (
                            {formatDateFromSecs(
                              customerSubscriptionUsage.end,
                              'dd.MM.yy',
                            )}
                            )
                          </span>
                          <span>
                            <b>
                              {remainderOrZero(
                                customerSubscriptionUsage.quantity,
                                customerSubscriptionUsage.total,
                              )}
                            </b>
                            {' '}
                            segments left on your plan.
                          </span>
                          <span>
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://help.blueprint.store/what-are-message-segments"
                            >
                              What happens if I go over?
                            </a>
                          </span>
                          <span>
                            <a href="mailto:support@blueprint.store?subject=I'd like to upgrade">
                              (Upgrade now)
                            </a>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="dash-card">
                    <BTitle text="Get Started" />
                    <div className="data">
                      <Link to="/automations">
                        1. Activate and setup your automations
                      </Link>
                      <Link to="/automations">
                        2. Manage your abandoned cart messaging
                      </Link>
                      <Link to="/messaging">
                        3. Message your Blueprint number to show a conversation
                        in the dashboard
                      </Link>
                      <Link to="/settings">4. Set your quiet hours</Link>
                      <br />
                      <p>
                        <span className="">Need support? Email us </span>
                        <a href="mailto:support@blueprint.store">
                          support@blueprint.store
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </PageBuilder>
  );
});
