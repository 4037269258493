import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { MarketingReducer } from './marketing';
import { AuthReducer } from './auth';
import { MerchantReducer } from './merchant';
import { CustomerReducer } from './customer';
import { ProductReducer } from './product';
import { UpsellsReducer } from './upSells';
import { ContactsReducer } from './contacts';
import { IntegrationsReducer } from './integrations';
import { APIKeysReducer } from './apiKeys';
import { CustomFlowsReducer } from './customFlows';
import { GroupMessagingReducer } from './groupMessaging';

const reducerMap = {
  form: formReducer,
  MarketingReducer,
  AuthReducer,
  MerchantReducer,
  CustomerReducer,
  ProductReducer,
  UpsellsReducer,
  ContactsReducer,
  IntegrationsReducer,
  APIKeysReducer,
  CustomFlowsReducer,
  GroupMessagingReducer,
};

/** @typedef {ReturnType<typeof rootReducer>} RootState */

const rootReducer = combineReducers(reducerMap);

export default rootReducer;
