import React, { useEffect, useState } from 'react';
import { MSG_TYPE_MMS, MSG_TYPE_SMS } from '../../../utils/messageType';
import { BRadio } from '../../global/BRadio';
import { MMS_MAX_IMG_SIZE_MB } from '../../../utils/settings';

const MMS_SUPPORTED_COUNTRIES = ['US', 'CA'];

export default ({
  url,
  addToMessage,
  msgType,
  onMsgTypeChange,
  merchant,
  file,
}) => {
  const [isFileSizeValid, setIsFileSizeValid] = useState(true);

  const handleMsgTypeChange = (type) => {
    onMsgTypeChange({
      msgType: type,
      mediaURL: type === MSG_TYPE_MMS ? url : null,
    });
  };

  const isMMSSupported = MMS_SUPPORTED_COUNTRIES.includes(merchant.country);

  useEffect(() => {
    setIsFileSizeValid(
      !(file && file.size / (1024 * 1024) > MMS_MAX_IMG_SIZE_MB),
    );
  }, [file]);

  return (
    <div className="upload-url">
      <div>
        <BRadio
          selected={msgType === MSG_TYPE_SMS}
          value={MSG_TYPE_SMS}
          text="Insert as link in SMS (works best when placed at the end of message)"
          onChange={handleMsgTypeChange}
        />
      </div>
      {isMMSSupported && isFileSizeValid && (
        <div>
          <BRadio
            selected={msgType === MSG_TYPE_MMS}
            value={MSG_TYPE_MMS}
            text="Attach to message as MMS (additional charges apply)"
            onChange={handleMsgTypeChange}
          />
        </div>
      )}
      {!isFileSizeValid && (
        <p className="note">
          Max allowed file size for MMS is
          {' '}
          {MMS_MAX_IMG_SIZE_MB.toFixed(2)}
          mb. The image you uploaded is
          {' '}
          {(file.size / (1024 * 1024)).toFixed(2)}
          mb. So you can only send this image as SMS.
        </p>
      )}

      <div>
        <button
          onClick={() => addToMessage(url)}
          className="btn btn-upload btn-secondary"
          type="button"
        >
          Add to message
        </button>
      </div>
    </div>
  );
};
