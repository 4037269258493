import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

const IntegrationPrivyForm = ({
  onSubmit,
}) => {
  let canSubmitForm = false;

  const {
    integrationsLoading,
    integrationsData,
    privyUpsertLoading,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */state) => state.IntegrationsReducer,
  );

  const {
    register, handleSubmit, setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      /** @type {String?} */
      webhook: null,
    },
  });

  const handleFormSubmit = (formData) => onSubmit(formData);

  useEffect(() => {
    if (!privyUpsertLoading && integrationsData?.privy) {
      setValue('webhook', `https://prod.blueprint-api.com/integrations/privy/submit/${integrationsData?.privy?.publicId}`);
    }
  }, [privyUpsertLoading, integrationsData?.privy, setValue]);

  canSubmitForm = !privyUpsertLoading && !integrationsLoading;

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="form-group">
        <label htmlFor="webhook">Webhook URL</label>
        <div className="field">
          <input
            id="webhook"
            type="text"
            className="form-control"
            placeholder="Webhook URL will display here once activated"
            disabled
            {...register('webhook')}
          />
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-primary mr-2"
        disabled={!canSubmitForm}
      >
        {integrationsData?.privy?.active ? 'Regenerate URL' : 'Activate'}
      </button>
    </form>
  );
};

export default IntegrationPrivyForm;
