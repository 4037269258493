import React, { memo, useCallback } from 'react';

/**
 * @enum {String}
 */
export const NEGATE_MODES = {
  yes: 'yes',
  no: 'no',
};

/**
 * @typedef {Object} NegateMode
 * @property {NEGATE_MODES} mode
 */

/**
 *
 * @param {Object} props
 * @param {NegateMode} props.value
 * @param {Function} props.onChange
 * @returns
 */
const Negate = ({ value, onChange }) => {
  const handleOnChange = useCallback(
    (event) => {
      const newMode = event.target.value;

      /** @type {NegateMode} */
      const obj = {
        mode: newMode,
      };

      onChange(obj);
    },
    [onChange],
  );

  return (
    <div className="subquery">
      <select
        className="form-control"
        value={value.mode}
        onChange={handleOnChange}
      >
        <option value={NEGATE_MODES.no}>Has</option>
        <option value={NEGATE_MODES.yes}>Has Not</option>
      </select>
    </div>
  );
};

export default memo(Negate);
