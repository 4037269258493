import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import cloneDeep from 'lodash.clonedeep';
import { DemoPhone } from '../DemoPhone';
import { MerchantActionCreators } from '../../../redux/actions/merchant';
import { getMerchantIntegrations } from '../../../redux/actions/AutomatedMessagingAPI';

import {
  getTemplatesByType,
  getDemoMessages,
  mapMessageTemplateToSave,
  filterForType,
  INTEGRATION_RECHARGE,
} from './helpers';
import MessageTemplateEditor from '../MessageTemplateEditor';
import {
  defaultMessageTemplates,
  subscriptions,
  AUTO_MESSAGE_TYPES,
} from './defaults';
import { isValidInterval } from '../../../utils/messageInteval';
import Subscriptions from '../AutoMessageSubscriptions';
import { useFeatureFlags } from '../../../contexts';

/** @typedef {import('../../../redux/reducers').RootState} RootState */

const {
  getMerchantData: getMerchantDataAction,
  updateAbandonedMessages: updateAbandonedMessagesAction,
} = MerchantActionCreators;

const AbandonedCartMessages = memo(() => {
  const [merchantIntegrations, setMerchantIntegrations] = useState({});
  const [hasFetchedIntegrations, setHasFetchedIntegrations] = useState(false);
  const [enableAbandonedRecharge, setEnableAbandonedRecharge] = useState(
    false,
  );
  const { isFeatureEnabled } = useFeatureFlags();

  const { addToast } = useToasts();
  const {
    merchantErr,
    merchantLoaded,
    merchantLoading,
    merchantData,
    abandonedCartsMessages: messageTemplates,
    loading: updateMessageTemplatesLoading,
  } = useSelector((/** @type {RootState} */state) => state.MerchantReducer);
  const dispatch = useDispatch();

  const handleRechargeFeatureFlag = useCallback(async () => {
    const flagEnabled = await isFeatureEnabled(
      'abandonedRecharge',
      false,
    );

    setEnableAbandonedRecharge(flagEnabled);
  }, [isFeatureEnabled]);

  useEffect(() => {
    if (merchantData.id) {
      handleRechargeFeatureFlag();
    }
  }, [handleRechargeFeatureFlag, merchantData]);

  const segmentsEnabled = useMemo(() => !!(
    merchantIntegrations[INTEGRATION_RECHARGE]
      && merchantIntegrations[INTEGRATION_RECHARGE].active
  ), [merchantIntegrations]);

  const [abandonedCartMessageTemplates, setAbandonedCartMessageTemplates] = useState([
    ...defaultMessageTemplates,
    ...subscriptions,
  ]);

  useEffect(() => {
    setAbandonedCartMessageTemplates(
      (currentTemplates) => currentTemplates.map((defaultMessageTemplate) => {
        const messageTemplate = messageTemplates.find(
          ({ messageType }) => messageType === defaultMessageTemplate.messageType,
        )
        || {
          messageContent: defaultMessageTemplate.messageContent,
          messageInterval: defaultMessageTemplate.messageInterval,
          active: defaultMessageTemplate.active,
        };

        const { messageContent, messageInterval, active } = messageTemplate;
        return {
          ...defaultMessageTemplate,
          ...messageTemplate,
          messageContent,
          messageInterval,
          active,
        };
      }),
    );
  }, [messageTemplates]);

  const getMerchantData = useCallback(() => {
    dispatch(getMerchantDataAction());
  }, [dispatch]);

  const updateMessageTemplates = useCallback(() => {
    if (!(updateMessageTemplatesLoading || merchantLoading)) {
      dispatch(
        updateAbandonedMessagesAction({
          messageTemplates: abandonedCartMessageTemplates.map(
            // @ts-ignore
            mapMessageTemplateToSave,
          ),
        }),
      );
    }
  }, [
    abandonedCartMessageTemplates,
    dispatch,
    merchantLoading,
    updateMessageTemplatesLoading,
  ]);

  useEffect(() => {
    if (!merchantLoaded) {
      getMerchantData();
    }
  }, [merchantLoaded, getMerchantData]);

  useEffect(() => {
    if (merchantErr) {
      addToast('Error getting messages', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, merchantErr]);

  const handleFetchMerchantIntegrations = useCallback(async () => {
    try {
      const integrations = await getMerchantIntegrations();

      setMerchantIntegrations(integrations);
    } catch (e) {
      addToast('The Merchants integrations could not be retrieved.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, setMerchantIntegrations]);

  useEffect(() => {
    if (merchantData.id && !hasFetchedIntegrations) {
      setHasFetchedIntegrations(true);
      handleFetchMerchantIntegrations();
    }
  }, [merchantData, hasFetchedIntegrations, handleFetchMerchantIntegrations]);

  const setMessageTemplate = useCallback(
    (editedMessageTemplate) => {
      const updatedMessageTemplates = cloneDeep(abandonedCartMessageTemplates);

      const existingMessageTemplate = abandonedCartMessageTemplates.find(
        (mt) => mt.messageType === editedMessageTemplate.messageType,
      );

      const editedMessageTemplateIndex = abandonedCartMessageTemplates.findIndex(
        (mt) => mt.messageType === editedMessageTemplate.messageType,
      );

      updatedMessageTemplates.splice(editedMessageTemplateIndex, 1);

      updatedMessageTemplates.splice(editedMessageTemplateIndex, 0, {
        ...existingMessageTemplate,
        ...editedMessageTemplate,
      });

      setAbandonedCartMessageTemplates(updatedMessageTemplates);
    },
    [abandonedCartMessageTemplates],
  );

  const disableSave = useMemo(() => {
    const currentTemplates = getTemplatesByType(abandonedCartMessageTemplates);
    const previousTemplates = getTemplatesByType(messageTemplates);

    const invalidTemplates = Object.keys(previousTemplates).find((key) => {
      if (typeof previousTemplates[key] === 'undefined') {
        return true;
      }

      return (
        !!previousTemplates[key].messageInterval
        && !isValidInterval(currentTemplates[key].messageInterval)
      );
    });

    if (invalidTemplates) {
      return true;
    }

    return Object.keys(previousTemplates)
      .map((key) => {
        if (typeof previousTemplates[key] === 'undefined') {
          return true;
        }
        return (
          previousTemplates[key].messageContent
            === currentTemplates[key].messageContent
          && previousTemplates[key].messageInterval
            === currentTemplates[key].messageInterval
          && previousTemplates[key].active === currentTemplates[key].active
        );
      })
      .every(Boolean);
  }, [abandonedCartMessageTemplates, messageTemplates]);

  const filteredDefaultMessageTemplates = abandonedCartMessageTemplates.filter(
    filterForType(AUTO_MESSAGE_TYPES.DEFAULT),
  );
  const filteredSubscriptionMessageTemplates = abandonedCartMessageTemplates.filter(
    filterForType(AUTO_MESSAGE_TYPES.SUBSCRIPTION),
  );

  return (
    <div className="row">
      <div className="col-md-8">
        {filteredDefaultMessageTemplates.map((abandonedCartMessage) => (
          <MessageTemplateEditor
            id="defaultMessageTemplateEditor"
            setMessageTemplate={setMessageTemplate}
            key={abandonedCartMessage.messageType}
            {...abandonedCartMessage}
          />
        ))}

        {enableAbandonedRecharge && (
          <Subscriptions enabled={segmentsEnabled}>
            {filteredSubscriptionMessageTemplates.map((abandonedCartMessage) => (
              <MessageTemplateEditor
                id="rechargeMessageTemplateEditor"
                setMessageTemplate={setMessageTemplate}
                enabled={segmentsEnabled}
                key={abandonedCartMessage.messageType}
                {...abandonedCartMessage}
              />
            ))}
          </Subscriptions>
        )}
        <div className="actions">
          <button
            className="btn btn-primary"
            disabled={disableSave || merchantLoading}
            onClick={updateMessageTemplates}
            type="button"
          >
            {updateMessageTemplatesLoading || merchantLoading
              ? 'Saving'
              : 'Save Changes'}
          </button>
        </div>
      </div>
      <div className="col-md-3 offset-md-1">
        <DemoPhone
          messages={getDemoMessages(filteredDefaultMessageTemplates)}
        />
        {enableAbandonedRecharge && (
          <DemoPhone
            messages={getDemoMessages(filteredSubscriptionMessageTemplates)}
          />
        )}

      </div>
    </div>
  );
});

export default AbandonedCartMessages;
