import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import { PageBuilder } from '../../components/global/PageBuilder';
import { SEO } from '../../components/global/SEO';
import SmartLinksTable from './Table';

const SmartLinksSummary = () => (
  <PageBuilder>
    <SEO title="Smart Links | Blueprint" />
    <div className="a-view smart-links">
      <div className="container">
        <div>
          <div className="row">
            <div className="col-md-12">
              <h2 className="title">
                Grow Subscribers
                {' '}
                {'>'}
                {' '}
                Smart Links
              </h2>
              <div className="instruction-container">
                <p>
                  Find more ways to build your audience
                  {' '}
                  <a href="https://help.blueprint.store/building-your-audience" target="_blank" rel="noreferrer">here</a>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Link
                to="/smart-links/create"
                className="btn btn-primary float-right"
              >
                Create New
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <SmartLinksTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageBuilder>
);

export default memo(SmartLinksSummary);
