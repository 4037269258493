import React from 'react';

import { formatAsMoney } from '../../../utils/numerical';
import { getReductedString, getSMSModuleNameForDisplay } from '../../../utils';

const AttributedOrdersTableRow = ({ item }) => (
  <tr>
    <td>
      {item.platformUrl ? (
        <a
          href={`${item.platformUrl}/orders/${item.orderIdExternal}`}
          target="_blank"
          rel="noreferrer"
        >
          {item.orderIdExternal}
        </a>
      ) : (
        item.orderIdExternal
      )}
    </td>
    <td>{item.orderCreatedAtFormatted}</td>
    <td>{formatAsMoney(item.totalPrice, item.currency)}</td>
    <td>{item.fullName}</td>
    <td>{getSMSModuleNameForDisplay(item.module)}</td>
    <td title={item.message} className="infotitle">
      {getReductedString(item.message, 70)}
    </td>
  </tr>
);

const AttributedOrdersTable = ({ items, platformUrl = '' }) => {
  if (!items) {
    return null;
  }
  const tableRows = items.map((item) => (
    <AttributedOrdersTableRow
      item={{ ...item, platformUrl }}
      key={item.orderIdExternal}
    />
  ));
  return (
    <table className="table table-bordered table-responsive-lg">
      <thead className="thead-dark">
        <tr>
          <th className="col-metric">Order ID</th>
          <th className="col-metric">
            Date Placed
            <br />
            DD-MM-YY
          </th>
          <th className="col-metric">Total</th>
          <th className="col-metric">Customer</th>
          <th className="col-metric">Message Type</th>
          <th className="col-metric">Message Text</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};

export default AttributedOrdersTable;
