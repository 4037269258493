export const MarketingActionTypes = {
  JOIN_WAITING_REQ: 'JOIN_WAITING_REQ',
  JOIN_WAITING_RES: 'JOIN_WAITING_RES',
  CALLBACK_REQ: 'CALLBACK_REQ',
  CALLBACK_RES: 'CALLBACK_RES',
};

export const AuthActionTypes = {
  AUTH_STATUS: 'AUTH_STATUS',
  LOGIN_REQ: 'LOGIN_REQ',
  LOGIN_RES: 'LOGIN_RES',
  REGISTER_REQ: 'REGISTER_REQ',
  REGISTER_RES: 'REGISTER_RES',
  UPDATE_PW_REQ: 'UPDATE_PW_REQ',
  UPDATE_PW_RES: 'UPDATE_PW_RES',
};

export const MerchantActionTypes = {
  GET_UDATA_REQ: 'GET_UDATA_REQ',
  GET_UDATA_RES: 'GET_UDATA_RES',
  GET_MDATA_REQ: 'GET_MDATA_REQ',
  GET_MDATA_RES: 'GET_MDATA_RES',
  UPDATE_MDATA_REQ: 'UPDATE_MDATA_REQ',
  UPDATE_MDATA_RES: 'UPDATE_MDATA_RES',
  UPDATE_ABCRTMES_REQ: 'UPDATE_ABCRTMES_REQ',
  UPDATE_ABCRTMES_RES: 'UPDATE_ABCRTMES_RES',
  UPDATE_AUTOMES_REQ: 'UPDATE_AUTOMES_REQ',
  UPDATE_AUTOMES_RES: 'UPDATE_AUTOMES_RES',
  UPLOAD_LOGO_REQ: 'UPLOAD_LOGO_REQ',
  UPLOAD_LOGO_RES: 'UPLOAD_LOGO_RES',
  TOGGLE_PO_REQ: 'TOGGLE_PO_REQ',
  TOGGLE_PO_RES: 'TOGGLE_PO_RES',
  VAL_WOO_REQ: 'VAL_WOO_REQ',
  VAL_WOO_RES: 'VAL_WOO_RES',
  TOGGLE_CHECKOUT_REQ: 'TOGGLE_CHECKOUT_REQ',
  TOGGLE_CHECKOUT_RES: 'TOGGLE_CHECKOUT_RES',
  CREATE_SUB_REQ: 'CREATE_SUB_REQ',
  CREATE_SUB_RES: 'CREATE_SUB_RES',
  GET_PLAN_RES: 'GET_PLAN_RES',
  GET_SUB_REQ: 'GET_SUB_REQ',
  GET_SUB_RES: 'GET_SUB_RES',
  GET_SUBS_ANALYTICS_REQ: 'GET_SUBS_ANALYTICS_REQ',
  GET_SUBS_ANALYTICS_RES: 'GET_SUBS_ANALYTICS_RES',
  UPDATE_M_SUBSCRIPTION_SETTINGS_REQ: 'UPDATE_M_SUBSCRIPTION_SETTINGS_REQ',
  UPDATE_M_SUBSCRIPTION_SETTINGS_RES: 'UPDATE_M_SUBSCRIPTION_SETTINGS_RES',
};

export const ProductActionTypes = {
  GET_PRODUCTS_REQ: 'GET_PRODUCTS_REQ',
  GET_PRODUCTS_RES: 'GET_PRODUCTS_RES',
  RESET_PRODUCTS: 'RESET_PRODUCTS',
};

export const CustomerActionTypes = {
  GET_CUS_REQ: 'GET_CUS_REQ',
  GET_CUS_RES: 'GET_CUS_RES',
  ADD_CUSNOTE_REQ: 'ADD_CUSNOTE_REQ',
  ADD_CUSNOTE_RES: 'ADD_CUSNOTE_RES',
};

export const GroupMessagingActionTypes = {
  GET_GROUPS_REQ: 'GET_GROUPS_REQ',
  GET_GROUPS_RES: 'GET_GROUPS_RES',
  ADD_CUSTOMER_TO_GROUP_REQ: 'ADD_CUSTOMER_TO_GROUP_REQ',
  ADD_CUSTOMER_TO_GROUP_RES: 'ADD_CUSTOMER_TO_GROUP_RES',
  DELETE_MESSAGE_GROUP_REQ: 'DELETE_MESSAGE_GROUP_REQ',
  DELETE_MESSAGE_GROUP_RES: 'DELETE_MESSAGE_GROUP_RES',
};

export const UpSellsActionTypes = {
  GET_UP_SELLS_REQ: 'GET_UP_SELLS_REQ',
  GET_UP_SELLS_RES: 'GET_UP_SELLS_RES',
  GET_UP_SELLS_ANALYTICS_REQ: 'GET_UP_SELLS_ANALYTICS_REQ',
  GET_UP_SELLS_ANALYTICS_RES: 'GET_UP_SELLS_ANALYTICS_RES',
  UPDATE_UPSELL_REQ: 'UPDATE_UPSELL_REQ',
  UPDATE_UPSELL_RES: 'UPDATE_UPSELL_RES',
  CREATE_UPSELL_REQ: 'CREATE_UPSELL_REQ',
  CREATE_UPSELL_RES: 'CREATE_UPSELL_RES',
  DELETE_UPSELL_REQ: 'DELETE_UPSELL_REQ',
  DELETE_UPSELL_RES: 'DELETE_UPSELL_RES',
  GET_UP_SELLS_CAMPAIGN_STATS_REQ: 'GET_UP_SELLS_CAMPAIGN_STATS_REQ',
  GET_UP_SELLS_CAMPAIGN_STATS_RES: 'GET_UP_SELLS_CAMPAIGN_STATS_RES',
  GET_SINGLE_CROSS_SELL_REQ: 'GET_SINGLE_CROSS_SELL_REQ',
  GET_SINGLE_CROSS_SELL_RES: 'GET_SINGLE_CROSS_SELL_RES',
  GET_ALL_CROSS_SELLS_REQ: 'GET_ALL_CROSS_SELLS_REQ',
  GET_ALL_CROSS_SELLS_RES: 'GET_ALL_CROSS_SELLS_RES',
  SET_CROSS_SELL_PRODUCTS: 'SET_CROSS_SELL_PRODUCTS',
  GET_CROSS_SELL_PRODUCTS: 'GET_CROSS_SELL_PRODUCTS',
  GET_ALL_REPLENISHMENTS_REQ: 'GET_ALL_REPLENISHMENTS_REQ',
  GET_ALL_REPLENISHMENTS_RES: 'GET_ALL_REPLENISHMENTS_RES',
  SET_REPLENISHMENT_PRODUCTS: 'SET_REPLENISHMENT_PRODUCTS',
  GET_REPLENISHMENT_PRODUCTS: 'GET_REPLENISHMENT_PRODUCTS',
  RESET_STATES_USED_IN_TOASTS: 'RESET_STATES_USED_IN_TOASTS',
};

export const ContactsActionTypes = {
  GET_CONTACTS_REQ: 'GET_CONTACTS_REQ',
  GET_CONTACTS_RES: 'GET_CONTACTS_RES',
};

export const IntegrationsActionTypes = {
  TEST_KLAVIYO_INTEGRATION_REQ: 'TEST_KLAVIYO_INTEGRATION_REQ',
  TEST_KLAVIYO_INTEGRATION_RES: 'TEST_KLAVIYO_INTEGRATION_RES',
  GET_KLAVIYO_LISTS_REQ: 'GET_KLAVIYO_LISTS_REQ',
  GET_KLAVIYO_LISTS_RES: 'GET_KLAVIYO_LISTS_RES',
  UPSERT_KLAVIYO_INTEGRATION_REQ: 'UPSERT_KLAVIYO_INTEGRATION_REQ',
  UPSERT_KLAVIYO_INTEGRATION_RES: 'UPSERT_KLAVIYO_INTEGRATION_RES',
  KLAVIYO_INTEGRATION_RESET: 'KLAVIYO_INTEGRATION_RESET',
  TEST_RECHARGE_INTEGRATION_REQ: 'TEST_RECHARGE_INTEGRATION_REQ',
  TEST_RECHARGE_INTEGRATION_RES: 'TEST_RECHARGE_INTEGRATION_RES',
  RECHARGE_INTEGRATION_RESET: 'RECHARGE_INTEGRATION_RESET',
  GET_MERCHANT_INTEGRATIONS_REQ: 'GET_MERCHANT_INTEGRATIONS_REQ',
  GET_MERCHANT_INTEGRATIONS_RES: 'GET_MERCHANT_INTEGRATIONS_RES',
  UPSERT_PRIVY_INTEGRATION_REQ: 'UPSERT_PRIVY_INTEGRATION_REQ',
  UPSERT_PRIVY_INTEGRATION_RES: 'UPSERT_PRIVY_INTEGRATION_RES',
  UPSERT_PRIVY_INTEGRATION_RESET: 'UPSERT_PRIVY_INTEGRATION_RESET',
  ZENDESK_INTEGRATION_RESET: 'ZENDESK_INTEGRATION_RESET',
  UPSERT_ZENDESK_INTEGRATION_REQ: 'UPSERT_ZENDESK_INTEGRATION_REQ',
  UPSERT_ZENDESK_INTEGRATION_RES: 'UPSERT_ZENDESK_INTEGRATION_RES',
  UPSERT_ZENDESK_INTEGRATION_RESET: 'UPSERT_ZENDESK_INTEGRATION_RESET',
  DELETE_ZENDESK_INTEGRATION_REQ: 'DELETE_ZENDESK_INTEGRATION_REQ',
  DELETE_ZENDESK_INTEGRATION_RES: 'DELETE_ZENDESK_INTEGRATION_RES',
  DELETE_ZENDESK_INTEGRATION_RESET: 'DELETE_ZENDESK_INTEGRATION_RESET',
  UPSERT_GORGIAS_INTEGRATION_REQ: 'UPSERT_GORGIAS_INTEGRATION_REQ',
  UPSERT_GORGIAS_INTEGRATION_RES: 'UPSERT_GORGIAS_INTEGRATION_RES',
  UPSERT_GORGIAS_INTEGRATION_RESET: 'UPSERT_GORGIAS_INTEGRATION_RESET',
  TEST_GORGIAS_INTEGRATION_REQ: 'TEST_GORGIAS_INTEGRATION_REQ',
  TEST_GORGIAS_INTEGRATION_RES: 'TEST_GORGIAS_INTEGRATION_RES',
  DELETE_GORGIAS_INTEGRATION_REQ: 'DELETE_GORGIAS_INTEGRATION_REQ',
  DELETE_GORGIAS_INTEGRATION_RES: 'DELETE_GORGIAS_INTEGRATION_RES',
  TEST_BOLD_INTEGRATION_REQ: 'TEST_BOLD_INTEGRATION_REQ',
  TEST_BOLD_INTEGRATION_RES: 'TEST_BOLD_INTEGRATION_RES',
  BOLD_INTEGRATION_RESET: 'BOLD_INTEGRATION_RESET',
};

export const APIKeysActionTypes = {
  GET_MERCHANT_APIKEYS_REQ: 'GET_MERCHANT_APIKEYS_REQ',
  GET_MERCHANT_APIKEYS_RES: 'GET_MERCHANT_APIKEYS_RES',
  INSERT_MERCHANT_APIKEYS_REQ: 'INSERT_MERCHANT_APIKEYS_REQ',
  INSERT_MERCHANT_APIKEYS_RES: 'INSERT_MERCHANT_APIKEYS_RES',
  DELETE_MERCHANT_APIKEYS_REQ: 'DELETE_MERCHANT_APIKEYS_REQ',
  DELETE_MERCHANT_APIKEYS_RES: 'DELETE_MERCHANT_APIKEYS_RES',
  UPDATE_MERCHANT_APIKEYS_REQ: 'UPDATE_MERCHANT_APIKEYS_REQ',
  UPDATE_MERCHANT_APIKEYS_RES: 'UPDATE_MERCHANT_APIKEYS_RES',
  RESET_MERCHANT_APIKEYS_MODIFY_STATE: 'RESET_MERCHANT_APIKEYS_MODIFY_STATE',
};

export const CustomFlowsActionTypes = {
  GET_FLOWS_REQ: 'GET_FLOWS_REQ',
  GET_FLOWS_RES: 'GET_FLOWS_RES',
  GET_FLOW_REQ: 'GET_FLOW_REQ',
  GET_FLOW_RES: 'GET_FLOW_RES',
  RESET_FLOW_FORM: 'RESET_FLOW_FORM',
  CREATE_FLOW_REQ: 'CREATE_FLOW_REQ',
  CREATE_FLOW_RES: 'CREATE_FLOW_RES',
  UPDATE_FLOW_REQ: 'UPDATE_FLOW_REQ',
  UPDATE_FLOW_RES: 'UPDATE_FLOW_RES',
  DELETED_FLOW: 'DELETED_FLOW',
  DELETING_FLOW: 'DELETING_FLOW',
  TOGGLE_FLOW_REQ: 'TOGGLE_FLOW_REQ',
  TOGGLE_FLOW_RES: 'TOGGLE_FLOW_RES',
};

export const GroupMessagingTypes = {
  GET_SEND_ESTIMATE_REQ: 'GET_SEND_ESTIMATE_REQ',
  GET_SEND_ESTIMATE_RES: 'GET_SEND_ESTIMATE_RES',
};
