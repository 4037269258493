import { ProductActionTypes } from '../../types';

export const initialState = {
  loading: false,
  loadingData: false,
  productErr: '',
  messageErr: null,
  allProducts: [],
  productsLoaded: false,
};

export const ProductReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case ProductActionTypes.GET_PRODUCTS_REQ: {
      return {
        ...state,
        loadingData: true,
        productsLoaded: false,
      };
    }
    case ProductActionTypes.GET_PRODUCTS_RES: {
      if (error) {
        return {
          ...state,
          loadingData: false,
          productErr: error,
          productsLoaded: false,
        };
      }
      return {
        ...state,
        loadingData: false,
        productErr: null,
        allProducts: payload,
        productsLoaded: true,
      };
    }
    case ProductActionTypes.RESET_PRODUCTS: {
      return { ...initialState };
    }
    case 'LOGOUT': {
      return initialState;
    }
    default:
      return state;
  }
};
