import React, {
  memo, useCallback, useEffect, useMemo,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useQuery } from 'react-query';

import { HelpBubble } from '../main/HelpBubble';
import { DemoPhone } from '../main/DemoPhone';
import { SegmentCharCounter } from '../main/SegmentCharCounter';
import { BPToggle } from '../main/BPToggle';
import { replacePlaceholderWithMockData } from '../../utils/sms';
import { getShortenedPermalink } from '../../queries/Merchant';

/**
 * @typedef {import('../../redux/reducers').RootState} RootState
 * @typedef {import('../main/DemoPhone').DemoMessage} DemoMessage
 */

const SmartLinkForm = ({ smartLink, merchantData, onSubmit }) => {
  const defaultValues = useMemo(() => ({
    shouldTriggerAlert: smartLink.shouldTriggerAlert || false,
    triggerText: smartLink.triggerText || '',
    messageText: smartLink.messageText || '',
    alternativeUrl: smartLink.alternativeUrl || '',
  }), [smartLink.shouldTriggerAlert, smartLink.triggerText, smartLink.messageText, smartLink.alternativeUrl]);

  const {
    control, formState, register, watch, reset, handleSubmit,
  } = useForm({
    mode: 'onSubmit',
    defaultValues,
  });

  const { isValid, isSubmitting } = formState;

  const { data: shortenedPermalink } = useQuery(
    'shortenedPermalink',
    getShortenedPermalink,
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    reset(defaultValues);
  }, [
    defaultValues,
    reset,
  ]);

  const generateMessage0 = useCallback(async () => {
    const content = await replacePlaceholderWithMockData({ company: merchantData.company }, watch('triggerText'), undefined, shortenedPermalink);
    return {
      content,
      date: '03/02 - 9:24:11 AM',
      integer: false,
    };
  }, [merchantData.company, watch, shortenedPermalink]);

  const generateMessage1 = useCallback(async () => {
    console.log('generateMessage1 is called');
    const content = await replacePlaceholderWithMockData({ company: merchantData.company }, watch('messageText'), undefined, shortenedPermalink);
    return {
      content,
      date: '03/02 - 9:24:12 AM',
      integer: false,
    };
  }, [merchantData.company, watch, shortenedPermalink]);

  const handleFormSubmit = (formData) => onSubmit({
    ...formData,
    isActive: smartLink.isActive || true,
  });

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="row">
        <div className="col-md-6">

          <div className="form-group">
            <div className="cart-message-editor mb-0">
              <label htmlFor="triggerText">
                <span>
                  Pre-typed Message
                </span>
              </label>
              <div>
                <textarea
                  className="message-content mt-0 mb-0"
                  id="triggerText"
                  placeholder="Please enter the pre-typed message content here"
                  rows={3}
                  maxLength={400}
                  {...register('triggerText')}
                />
                <SegmentCharCounter
                  text={watch('triggerText')}
                  helpBubbleSmall={false}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="cart-message-editor mb-0">
              <label htmlFor="messageText">
                <span>
                  Response
                </span>
              </label>
              <div>
                <textarea
                  className="message-content mt-0 mb-0"
                  id="messageText"
                  placeholder="Please enter the response message content here"
                  rows={3}
                  maxLength={400}
                  {...register('messageText')}
                />
                <SegmentCharCounter
                  text={watch('messageText')}
                  helpBubbleSmall={false}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="alternativeUrl">
              Alternative URL (optional)
            </label>
            <input
              type="url"
              id="alternativeUrl"
              className="form-control"
              {...register('alternativeUrl')}
            />
          </div>

          <div className="form-group">
            <div className="mb-2">
              <div className="d-inline-block mr-2 align-middle">
                <Controller
                  control={control}
                  name="shouldTriggerAlert"
                  render={({
                    field: { onChange, value },
                  }) => (
                    <BPToggle
                      handleClick={() => {
                        onChange(!value);
                      }}
                      active={value}
                    />
                  )}
                />
              </div>
              <label htmlFor="shouldTriggerAlert">
                <span className="info">Trigger alert</span>
                <HelpBubble
                  small
                  text="If activated, you will receive an alert when a customer sends you the pre-typed message"
                />
              </label>
            </div>
          </div>

          <div className="align-self-end">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={!isValid || isSubmitting}
            >
              {isSubmitting ? 'Saving' : 'Save'}
            </button>
          </div>
        </div>
        <div className="col-md-3 flex-column d-flex justify-content-between">
          <div className="cart-message-editor">
            <label htmlFor="demoPhone">
              <span>Preview</span>
            </label>
            <DemoPhone
              id="demoPhone"
              messages={
                [
                  generateMessage0(),
                  generateMessage1(),
                ]
              }
            />
          </div>

        </div>
      </div>
    </form>
  );
};

export default memo(SmartLinkForm);
