import React, { memo } from 'react';
import {
  Label,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import RepliesScatterChartTooltip from './RepliesScatterChartTooltip';

const RepliesScatterChart = ({ messages }) => (
  <div className="scatter-chart-container">
    <ResponsiveContainer width="100%" height="100%">
      <ScatterChart
        width={400}
        height={400}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <XAxis domain={[-1, 1]} type="number" dataKey="x" tick={false}>
          <Label value="Bad" position="insideBottomLeft" />
          <Label value="Good" position="insideBottomRight" />
          <Label value="Review Sentiment" position="center" />
        </XAxis>

        <YAxis
          type="number"
          dataKey="y"
          label={{ value: 'Low', position: 'insideBottomLeft' }}
          tick={false}
        >
          <Label value="Low" position="insideBottomLeft" />
          <Label value="High" position="insideTopLeft" />
          <Label
            value="Customer Lifetime Value"
            position="center"
            angle={-90}
          />
        </YAxis>

        <Tooltip content={<RepliesScatterChartTooltip />} />

        <Scatter name="SMS sentiments" data={messages} fill="#3AB8E8" />
      </ScatterChart>
    </ResponsiveContainer>
  </div>
);

export default memo(RepliesScatterChart);
