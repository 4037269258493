import React from 'react';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { AuthActionCreators } from '../redux/actions/auth';

const Logout = ({ actions: { logout } }) => {
  logout();
  // Ok this is horrid, but its best practice to reload the app on logout
  // ... to make sure all state renewed
  window.location.href = '/login?return_url=/';
  return <Redirect to="/login" />;
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(null, mapDispatchToProps)(Logout);
