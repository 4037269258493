import React, { memo, useMemo } from 'react';
import Modal from 'react-modal';

const RemoveCustomerFromMessageGroupModal = ({
  isOpen, onClose, customer, group, onDelete,
}) => {
  const displayName = useMemo(() => {
    if (!customer) {
      return '';
    }

    return customer.firstName && customer.lastName
      ? `${customer.firstName} ${customer.lastName}`
      : customer.phone;
  }, [customer]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      className="modal remove-customer text-center"
      overlayClassName="modal-overlay"
      contentLabel="Logo Modal"
    >
      <h3>Remove Customer</h3>

      <p>
        Are you sure you want to remove
        {' '}
        <i>{displayName}</i>
        {' '}
        from the
        {' '}
        <i>{group.name}</i>
        {' '}
        group?
      </p>

      <div className="buttons-container">
        <button className="btn btn-danger" onClick={onDelete} type="button">
          Remove
        </button>

        <button className="cancel-btn" onClick={onClose} type="button">
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default memo(RemoveCustomerFromMessageGroupModal);
