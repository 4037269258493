import {
  TYPE_ABANDONED_STAGE1,
  TYPE_ABANDONED_STAGE2,
  TYPE_ABANDONED_STAGE3,
  TYPE_RECHARGE_ABANDONED_STAGE1,
} from '../../../utils/messageTemplateTypes';

export const AUTO_MESSAGE_TYPES = {
  DEFAULT: 'default',
  SUBSCRIPTION: 'subscription',
};

export const defaultMessageTemplates = [
  {
    messageType: TYPE_ABANDONED_STAGE1,
    messageContent: '',
    messageInterval: 0,
    active: false,
    canSetActive: true,
    canSetInterval: true,
    title: 'MESSAGE # 1',
    help: '',
    type: AUTO_MESSAGE_TYPES.DEFAULT,
  },
  {
    messageType: TYPE_ABANDONED_STAGE2,
    messageContent: '',
    messageInterval: 0,
    active: false,
    canSetActive: true,
    canSetInterval: true,
    title: 'MESSAGE # 2',
    help: '',
    type: AUTO_MESSAGE_TYPES.DEFAULT,
  },
  {
    messageType: TYPE_ABANDONED_STAGE3,
    messageContent: '',
    messageInterval: 0,
    active: false,
    canSetActive: true,
    canSetInterval: true,
    title: 'MESSAGE # 3',
    help: '',
    type: AUTO_MESSAGE_TYPES.DEFAULT,
  },
];

export const subscriptions = [
  {
    messageType: TYPE_RECHARGE_ABANDONED_STAGE1,
    messageContent: '',
    messageInterval: 60,
    active: false,
    canSetActive: true,
    canSetInterval: true,
    title: 'MESSAGE # 1',
    help: '',
    type: AUTO_MESSAGE_TYPES.SUBSCRIPTION,
  },
];
