import React, { memo, useCallback } from 'react';

const COMMERCE_SUBSCRIBER_STATES = {
  active: 'active',
  inactive: 'inactive',
};

const CommerceSubscription = ({ value, onChange }) => {
  const handleOnChange = useCallback(
    (event) => {
      const newMode = event.target.value;
      const obj = {
        mode: newMode,
      };

      onChange(obj);
    },
    [onChange],
  );

  return (
    <div className="subquery">
      <select
        className="form-control"
        value={value.mode}
        onChange={handleOnChange}
      >
        <option value={COMMERCE_SUBSCRIBER_STATES.active}>
          Active Subscriber
        </option>
        <option value={COMMERCE_SUBSCRIBER_STATES.inactive}>
          Inactive Subscriber
        </option>
      </select>
    </div>
  );
};

export default memo(CommerceSubscription);
