import React, { memo } from 'react';
import Modal from 'react-modal';
import ListSelector from './ListSelector';

const ListSelectorModal = ({
  isOpen,
  onClose,
  onChange,
  value,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    className="modal products"
    overlayClassName="modal-overlay"
    contentLabel="Select List(s)"
    scrollable
  >
    <div className="product-selection-modal">
      <button className="close" onClick={onClose} type="button">
        Close
      </button>
      <span className="title text-left mb-3 ml-3">
        Select list(s)
      </span>
      <ListSelector
        onClose={onClose}
        onChange={onChange}
        value={value}
      />
    </div>
  </Modal>
);

export default memo(ListSelectorModal);
