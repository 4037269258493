import React, {
  useState,
  useEffect,
} from 'react';
import {
  Field, FieldArray, formValueSelector, reduxForm,
} from 'redux-form';
import { connect } from 'react-redux';
import { BPField } from './BPField';
import { BPTextArea } from './BPTextArea';
import { AwayHoursList } from './AwayHoursList';
import { brandDetailsValidate } from './validation';
import { BPToggle } from '../main/BPToggle';
import { TimezoneSelect } from '../main/TimezoneSelect';
import { TimeSelect } from '../main/TimeSelect';
import { SegmentCharCounter } from '../main/SegmentCharCounter';
import MessageIntervalSelect, {
  TIME_UNIT,
} from '../main/MessageIntervalSelect';

const positiveNumber = (value) => (value > 0 ? undefined : 'Please enter a positive number');

const makeChange = (props, type, operand) => {
  props.change(type, operand);
};

const BrandDetailsForm = (props) => {
  const {
    handleSubmit,
    valid,
    subErr,
    submitting,
    loading,
    initialValues: propInitialValues,
    awayHoursEnabled,
    quietHoursEnabled,
    rateLimitEnabled,
    rateLimitInterval,
    reset,
    pristine,
  } = props;

  const [initialValues, setInitialValues] = useState(propInitialValues);
  const [awayHoursContentValue, setAwayHoursContentValue] = useState('');

  useEffect(() => {
    if (initialValues.awayHoursContent) {
      setAwayHoursContentValue(initialValues.awayHoursContent);
    }
  // I'm not sure what the pattern should be for exhaustive deps on "useEffect only once" empty deps arrays - DI 14/05/21
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react/destructuring-assignment
    if (JSON.stringify(props.initialValues) !== JSON.stringify(initialValues)) {
      // eslint-disable-next-line react/destructuring-assignment
      setInitialValues(props.initialValues);
      // eslint-disable-next-line react/destructuring-assignment
      props.initialize(props.initialValues);
    }
  }, [initialValues, props]);

  const toggleQuietHours = () => {
    // Do these even need to be refetched here? - DI 15/5/21
    const { quietHoursEnabled: currentQuietHoursEnabled } = props;

    const newVal = currentQuietHoursEnabled === 1 ? 0 : 1;

    makeChange(props, 'quietHoursEnabled', newVal);
  };

  const toggleAwayHours = () => {
    const { awayHoursEnabled: currentAwayHoursEnabled } = props;

    const newVal = currentAwayHoursEnabled === 1 ? 0 : 1;

    makeChange(props, 'awayHoursEnabled', newVal);
  };

  const toggleRateLimit = () => {
    const { rateLimitEnabled: currentRateLimitEnabled } = props;

    const newVal = currentRateLimitEnabled === 1 ? 0 : 1;

    makeChange(props, 'rateLimitEnabled', newVal);
  };

  const onOfflineResChange = (event, newValue) => {
    setAwayHoursContentValue(newValue);
  };

  const setRateLimitInterval = (e) => {
    makeChange(props, 'rateLimitInterval', e.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className="brand-details-form settings-form">
      <>
        <div className="toggle-container section-title">
          <span className="toggle-label">Smart Sending</span>
          <BPToggle
            active={rateLimitEnabled === 1}
            handleClick={toggleRateLimit}
          />
        </div>
        <p>
          <small>
            When activated, Smart Sending will limit the number of marketing messages
            your subscribers can receive.
            Set the maximum amount of marketing messages and the timeframe below.
            Any message above this limit will be not be sent and marked as “skipped”.
          </small>
        </p>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="rateLimitCount">Maximum number of marketing messages</label>
            <Field
              id="rateLimitCount"
              className="form-control"
              component={BPField}
              disabled={rateLimitEnabled !== 1}
              name="rateLimitCount"
              placeholder="Number of messages"
              type="number"
              validate={positiveNumber}
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="rateLimitInterval">Within</label>
            <MessageIntervalSelect
              id="rateLimitInterval"
              customValueUnit={TIME_UNIT.hours}
              disabled={rateLimitEnabled !== 1}
              handleChange={setRateLimitInterval}
              name="rateLimitInterval"
              value={rateLimitInterval}
            />
          </div>
        </div>
      </>

      <p className="section-title">Timezone</p>

      <div className="form-group">
        <label htmlFor="timezone">
          Set your local timezone. This will be used for Analytics, Quiet Hours
          and Out of Hours.
        </label>
        <div className="row">
          <div className="col-md-6">
            <Field id="timezone" name="timezone" component={TimezoneSelect} />
          </div>
        </div>
      </div>

      <div className="toggle-container section-title">
        <span className="toggle-label">QUIET HOURS: </span>
        <BPToggle
          active={quietHoursEnabled === 1}
          handleClick={toggleQuietHours}
        />
      </div>
      <p>
        <small>
          When activated no automated message will be sent between the set time
          span.
        </small>
      </p>

      <div className="row">
        <div className={`col-md-4 ${quietHoursEnabled ? '' : 'disabled'}`}>
          <label htmlFor="quietStartHourInMerchantTimezone">From</label>
          <Field
            id="quietStartHourInMerchantTimezone"
            component={TimeSelect}
            name="quietStartHourInMerchantTimezone"
            disabled={quietHoursEnabled === 0}
            format={(value) => value || '00:00'}
          />
          <small className="font-italic">Recommended 21:00 (9 PM)</small>
        </div>

        <div className={`col-md-4 ${quietHoursEnabled ? '' : 'disabled'}`}>
          <label htmlFor="quietEndHourInMerchantTimezone">To</label>
          <Field
            id="quietEndHourInMerchantTimezone"
            component={TimeSelect}
            name="quietEndHourInMerchantTimezone"
            disabled={quietHoursEnabled === 0}
            format={(value) => value || '00:00'}
          />
          <small className="font-italic">Recommended 08:00 (8 AM)</small>
        </div>
      </div>

      {/* away hours */}
      <div className="toggle-container section-title">
        <span className="toggle-label">Out of Hours:</span>
        <BPToggle active={awayHoursEnabled} handleClick={toggleAwayHours} />
      </div>
      <p>
        <small>
          After setting your Out of Hours, customers will receive an automated
          response (set below) letting them know you&apos;re offline.
        </small>
      </p>
      <p>
        <small>
          You can still reply to customers when your Out of Hours message is on
          and automated messages will still be sent.
        </small>
      </p>

      {!!awayHoursEnabled && (
        <>
          <p>
            <small>
              <strong>Set when you are available:</strong>
            </small>
          </p>
          <FieldArray name="awayHours" component={AwayHoursList} />
          <p>
            <small>
              <strong>
                Customers will receive the following automated message when
                trying to contact you outside available hours:
              </strong>
            </small>
          </p>
          <p className="section-title">Offline Auto Response</p>
          <div className="segment-info-container">
            <Field
              name="awayHoursContent"
              component={BPTextArea}
              rows={3}
              onChange={onOfflineResChange}
              maxLength={400}
            />
            <SegmentCharCounter
              text={awayHoursContentValue}
              helpBubbleSmall={false}
              lazyLoadIcon={false}
            />
          </div>
        </>
      )}

      <div className="form-group">
        <p className="section-title">SUPPORT</p>
        <label htmlFor="supportEmailAddress">Support Email Address</label>
        <div className="field">
          <Field
            id="supportEmailAddress"
            name="supportEmailAddress"
            component={BPField}
            type="text"
            placeholder="Support Email Address"
            className="form-control"
          />
        </div>
      </div>
      <div className="form-group buttons">
        <button
          type="submit"
          disabled={!valid || submitting || pristine}
          className="btn btn-primary"
        >
          {loading ? 'Saving...' : 'Save Changes'}
        </button>
        {!pristine && (
          <button type="button" onClick={reset} className="btn btn-link">
            Cancel
          </button>
        )}
      </div>
      {subErr && <span className="form-error">{subErr}</span>}
    </form>
  );
};

const formName = 'brandDetailsForm';

const BrandDetailsFormRedux = reduxForm({
  form: formName,
  validate: brandDetailsValidate,
})(BrandDetailsForm);

const selector = formValueSelector(formName);

export default connect((state) => selector(
  state,
  'awayHoursEnabled',
  'awayHours',
  'quietHoursEnabled',
  'rateLimitEnabled',
  'rateLimitCount',
  'rateLimitInterval',
))(BrandDetailsFormRedux);
