import { UpSellsActionTypes } from '../../types';
import { APIMethods, APIServiceConstants, BlueprintAPI } from '../BlueprintAPI';

const { SERVICE } = APIServiceConstants;

export const CrossSellsActionCreators = {
  getAllCrossSells: () => async (dispatch) => {
    dispatch({
      type: UpSellsActionTypes.GET_ALL_CROSS_SELLS_REQ,
    });
    try {
      const response = await BlueprintAPI(SERVICE, 'getCrossSells', {}, null, true, APIMethods.GET);

      dispatch({
        type: UpSellsActionTypes.GET_ALL_CROSS_SELLS_RES,
        payload: response.data.crossSells || [],
      });
    } catch (e) {
      console.error('Error - getCrossSells:', e);
      dispatch({
        type: UpSellsActionTypes.GET_ALL_CROSS_SELLS_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
  setCrossSellSelectedProducts: (products = []) => async (dispatch) => {
    dispatch({
      type: UpSellsActionTypes.GET_CROSS_SELL_PRODUCTS,
      payload: products,
    });
  },
};

export default CrossSellsActionCreators;
