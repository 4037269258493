import React from 'react';
import { Field } from 'redux-form';
import AwayHoursRow from './AwayHoursRow';

export const AwayHoursList = ({ fields }) => fields.map((name, index) => (
  // eslint-disable-next-line react/no-array-index-key
  <Field name={name} component={AwayHoursRow} key={index} />
));

export default AwayHoursList;
