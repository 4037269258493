import React, {
  memo,
} from 'react';

import { COMPARATORS } from '../../../../global/Predicates/ComparatorSelector';
import Filter, { createFilterChangeHandler } from '../Filter';

import TextField from '../ValueComponents/TextField';
import ProductPicker from '../ValueComponents/ProductPicker';
import { NEGATE_MODES } from '../../../../global/Predicates/Negate';

/** @typedef {import('../../../../global/Predicates/Negate').NegateMode} NegateMode */
/** @typedef {import('../../../../global/Predicates/TypeSelector').Type} Type */
/** @typedef {import('../../../../global/Predicates/ComparatorSelector').Comparator} Comparator */
/** @typedef {import('../ValueComponents/ProductPicker').BasketValue} BasketValue */

/** @type {Type[]} */
const BasketTypes = [
  {
    title: 'Products',
    value: 'product',
    comparators: [COMPARATORS.containsAll, COMPARATORS.containsAny],
  },
  {
    title: 'Total value',
    value: 'totalPrice',
    comparators: [COMPARATORS.eq, COMPARATORS.gt, COMPARATORS.lt, COMPARATORS.gte, COMPARATORS.lte],
  },
];

export const valueMapper = (values) => {
  const getValue = (value, type) => {
    switch (type) {
      case 'totalPrice':
        return value;
      case 'product':
        return (value || []).map((basketValue) => ({
          id: basketValue.id,
          isVariant: basketValue.isVariant,
        }));
      case undefined:
        return null;
      default:
        throw new Error(`Tried to get Value for unexpected type ${type}`);
    }
  };

  return values.map(({
    id, type, value, ...rest
  }) => ({
    type,
    value: getValue(value, type),
    ...rest,
  }));
};

const getComponentForFilterType = (filterType) => {
  switch (filterType) {
    case 'product':
      return ProductPicker;
    case 'totalPrice':
      return TextField;
    default:
      throw new Error(`Tried to get Component for unexpected basket type: ${filterType}`);
  }
};

const getBasketType = (type) => {
  const result = BasketTypes.find((t) => t.value === type);

  if (!result) {
    throw new Error(`Failed to find expected basket type: ${type}`);
  }

  return result;
};

const getDefaultNegate = () => ({ mode: NEGATE_MODES.no });
const getDefaultType = () => BasketTypes[0].value;
const getDefaultValue = (filterType) => {
  switch (filterType) {
    case 'product':
      return /** @type {BasketValue[]} */([]);
    case 'totalPrice':
      return '';
    default:
      throw new Error(`Tried to get default value for unexpected basket type: ${filterType}`);
  }
};

const getDefaultComparator = (filterType) => {
  const basketType = getBasketType(filterType);

  return basketType.comparators[0].value;
};

/**
 *
 * @param {Object} props
 * @param {String} props.id
 * @param {Function} props.onChange
 * @param {String} props.value
 * @param {NegateMode} props.negate
 * @param {String} props.type
 * @param {String} props.comparator
 */
const BasketFilter = (props) => {
  const {
    type = getDefaultType(),
    negate = getDefaultNegate(),
    onChange,
    id,
  } = props;

  // Destructure these seperately because we need to know the `type` to get the defaults
  const {
    value = getDefaultValue(type),
    comparator = getDefaultComparator(type),
  } = props;

  const basketType = getBasketType(type);

  const onFilterChange = createFilterChangeHandler({
    id, type, value, negate, comparator, onChange,
  });

  const Component = getComponentForFilterType(type);

  return (
    <Filter
      negate={negate}
      comparator={comparator}
      type={type}
      onChange={onFilterChange}
      types={BasketTypes}
      comparators={basketType.comparators}
      value={(
        <Component
          // The Type of `value` is dynamic for each Component
          // @ts-ignore
          value={value}
          onChange={onFilterChange}
        />
      )}
    />
  );
};

export default memo(BasketFilter);
