import React, {
  memo, useEffect, useState, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { UpSellsActionCreators } from '../../../redux/actions/upSells';
import Instruction from './Instruction';
import UpSellsTable from './UpSellsTable';
import UpSellModal from './UpSellModal';
import UpSellDeletionModal from './UpSellDeletionModal';
import { getInvalidCheckMessage } from './validation';

const {
  getAllUpSells: getAllUpSellsAction,
  getUpSellAnalytics: getUpSellAnalyticsAction,
  updateUpSell: updateUpSellAction,
  createUpSell: createUpSellAction,
  deleteUpSell: deleteUpSellAction,
} = UpSellsActionCreators;

/**
 * @typedef {import('../../../redux/reducers').RootState} RootState
 */

/**
 * @typedef UpSell
 * @property {String} name
 * @property {Number} [id]
 */

const UpSells = () => {
  const { addToast } = useToasts();
  const {
    upsellsErr,
    upsellsLoaded,
    upsellsData,
    analyticsErr,
    analyticsLoaded,
    analyticsData,
    upsellUpdateErr,
    upsellUpdating,
    upsellsUpdated,
    upsellsUpdateResult,
    upsellCreateErr,
    upsellCreated,
    upsellCreateResult,
    upsellDeleteErr,
    upsellDeleting,
    upsellsDeleted,
    upsellsDeleteResult,
  } = useSelector((/** @type RootState} */state) => state.UpsellsReducer);

  const [editorModalOpen, setEditorModalOpen] = useState(false);

  /** @type {UpSell} */
  const initialUpsellInEditing = {
    name: '',
    id: undefined,
  };
  const [upsellInEditing, setUpsellInEditing] = useState(/** @type {UpSell?} */ (initialUpsellInEditing));

  const [deletionModalOpen, setDeletionModalOpen] = useState(false);

  /** @type {UpSell} */
  const initialUpsellInDeletion = {
    name: '',
    id: undefined,
  };
  const [upsellInDeletion, setUpsellInDeletion] = useState(initialUpsellInDeletion);

  const dispatch = useDispatch();

  /**
   *
   * @param {Number} id
   */
  const getUpsellById = (id) => {
    const index = upsellsData.findIndex((upsell) => upsell.id === id);
    return upsellsData[index];
  };

  const updateUpSell = useCallback(
    (data) => {
      const invalidInputCheckMsg = getInvalidCheckMessage(data);
      if (invalidInputCheckMsg) {
        addToast(invalidInputCheckMsg, {
          appearance: 'warning',
          autoDismiss: true,
        });
      } else {
        dispatch(updateUpSellAction(data));
      }
    },
    [addToast, dispatch],
  );

  const toggleEditorModal = useCallback(
    () => setEditorModalOpen(!editorModalOpen),
    [editorModalOpen],
  );

  const handleEditUpSellClick = (event) => {
    setUpsellInEditing(getUpsellById(parseInt(event.target.value, 10)));
    toggleEditorModal();
  };

  const handlePriorityChange = ({ upsellsByNewOrder, upsellId }) => {
    updateUpSell({
      upsellId,
      priorities: upsellsByNewOrder.map((upsell, index) => ({
        upsellId: upsell.id,
        priority: index + 1, // because index starts from 0 while priority starts from 1
      })),
    });
  };

  const createUpSell = useCallback(
    (data) => {
      const invalidInputCheckMsg = getInvalidCheckMessage(data);
      if (invalidInputCheckMsg) {
        addToast(invalidInputCheckMsg, {
          appearance: 'warning',
          autoDismiss: true,
        });
      } else {
        dispatch(createUpSellAction(data));
      }
    },
    [addToast, dispatch],
  );

  const deleteUpSell = useCallback(
    (data) => {
      dispatch(deleteUpSellAction(data));
    },
    [dispatch],
  );

  const handleNewUpSellClick = useCallback(() => {
    setUpsellInEditing(null);
    toggleEditorModal();
  }, [toggleEditorModal]);

  const getUpSellsCallback = useCallback(() => {
    dispatch(getAllUpSellsAction());
  }, [dispatch]);

  const getAnalyticsCallback = useCallback(() => {
    dispatch(getUpSellAnalyticsAction());
  }, [dispatch]);

  const getAnalyticsForUpSells = useCallback((analyticsDataToFilter) => {
    // TODO change - just for showing data on UI
    const upSellRelated = analyticsDataToFilter.filter(
      (data) => data.rowId === 'up-sells',
    );
    return upSellRelated.length > 0 ? upSellRelated[0] : {};
  }, []);

  const handleUpSellStateToggle = ({ upsellId, newState }) => {
    updateUpSell({ upsellId, isActive: newState });
  };

  const toggleDeletionModal = useCallback(
    () => setDeletionModalOpen(!deletionModalOpen),
    [deletionModalOpen],
  );

  const handleDeleteUpSell = (event) => {
    setUpsellInDeletion(getUpsellById(parseInt(event.target.value, 10)));
    toggleDeletionModal();
  };

  const handleConfirmedDelete = () => {
    deleteUpSell({ upsellId: upsellInDeletion.id });
  };

  useEffect(() => {
    if (!upsellsLoaded) {
      getUpSellsCallback();
    }
    // TODO - enable when the analytics part is done
    // if (!analyticsLoaded) {
    //   getAnalyticsCallback();
    // }
  }, [
    analyticsLoaded,
    getAnalyticsCallback,
    getUpSellsCallback,
    upsellsLoaded,
  ]);

  useEffect(() => {
    if (upsellsErr) {
      addToast('Could not get the upsells', {
        appearance: 'error',
        autoDismiss: true,
      });
    } else if (analyticsErr) {
      // Gonna uncomment after the analytics part is done TODO
      // addToast('Could not get the analytics for upsells', {
      //   appearance: 'warning',
      //   autoDismiss: true,
      // });
    }
  }, [addToast, analyticsErr, upsellsErr]);

  useEffect(() => {
    if (upsellUpdateErr) {
      addToast(`Could not update the upsell. Error: ${upsellUpdateErr}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, upsellUpdateErr]);

  useEffect(() => {
    if (upsellCreateErr) {
      addToast(`Could not create a new upsell. Error: ${upsellCreateErr}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, upsellCreateErr]);

  useEffect(() => {
    if (upsellsUpdated) {
      setEditorModalOpen(false);
      addToast(
        upsellsUpdateResult && upsellsUpdateResult.message
          ? upsellsUpdateResult.message
          : 'Updated successfully',
        {
          appearance: 'info',
          autoDismiss: true,
        },
      );
    }
  }, [addToast, upsellsUpdateResult, upsellsUpdated]);

  useEffect(() => {
    if (upsellCreated) {
      setEditorModalOpen(false);
      addToast(
        upsellCreateResult && upsellCreateResult.message
          ? upsellCreateResult.message
          : 'Created successfully',
        {
          appearance: 'info',
          autoDismiss: true,
        },
      );
    }
  }, [addToast, upsellCreateResult, upsellCreated]);

  useEffect(() => {
    if (upsellDeleteErr) {
      addToast(`Could not delete the upsell. Error: ${upsellDeleteErr}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, upsellDeleteErr]);

  useEffect(() => {
    if (upsellsDeleted) {
      setDeletionModalOpen(false);
      addToast(
        upsellsDeleteResult && upsellsDeleteResult.message
          ? upsellsDeleteResult.message
          : 'Deleted successfully',
        {
          appearance: 'info',
          autoDismiss: true,
        },
      );
    }
  }, [addToast, upsellsDeleteResult, upsellsDeleted]);

  return (
    <div>
      <Instruction />
      <div className="row">
        <div className="col-md-4">
          <h3>Upsells</h3>
        </div>
        <div className="col-md-2 offset-md-6">
          <button
            className="btn btn-primary create-upsell-btn"
            onClick={handleNewUpSellClick}
            type="button"
          >
            Create Upsell
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <UpSellsTable
            upsells={upsellsData}
            analytics={getAnalyticsForUpSells(analyticsData)}
            toggleUpSellState={handleUpSellStateToggle}
            handleEdit={handleEditUpSellClick}
            handleDelete={handleDeleteUpSell}
            handlePriorityChange={handlePriorityChange}
          />
        </div>
      </div>
      {editorModalOpen && (
        <UpSellModal
          isOpen={editorModalOpen}
          close={toggleEditorModal}
          upsell={upsellInEditing}
          upsellUpdating={upsellUpdating}
          handleUpdateSubmit={upsellInEditing ? updateUpSell : createUpSell}
        />
      )}
      {deletionModalOpen && (
        <UpSellDeletionModal
          isOpen={deletionModalOpen}
          close={toggleDeletionModal}
          upsellName={upsellInDeletion.name}
          upsellDeleting={upsellDeleting}
          handleDeleteSubmit={handleConfirmedDelete}
        />
      )}
    </div>
  );
};

export default memo(UpSells);
