export const messageTypes = [
  {
    module: 'messaging/prepareGroupMessage',
    label: 'Group Message',
  },
  {
    module: 'comms/automated/reorder-prompt',
    label: 'Reorder Prompt',
  },
  {
    module: 'comms/automated/post-order-checkin',
    label: 'Post Order Checkin',
  },
  {
    module: 'comms/automated/upsell',
    label: 'Upsell',
  },
  {
    module: 'checkout/orderCompleted',
    label: 'Order Completed',
  },
  {
    module: 'comms/orderFulfilled',
    label: 'Order Fulfilled',
  },
  {
    module: 'abandoned_cart_reminder_1',
    label: 'Abandoned Cart First Reminder',
  },
  {
    module: 'abandoned_cart_reminder_2',
    label: 'Abandoned Cart Second Reminder',
  },
  {
    module: 'abandoned_cart_reminder_3',
    label: 'Abandoned Cart Third Reminder',
  },
  {
    module: 'chatbot-sms-adapter/webbotMessage',
    label: 'Webbot',
  },
];

export const getMessageLabelFromType = (type) => {
  const msg = messageTypes.find((m) => m.module === type);

  return msg ? msg.label : '';
};

export const MSG_TYPE_MMS = 'MMS';
export const MSG_TYPE_SMS = 'SMS';
