import React, {
  memo,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { useToasts } from 'react-toast-notifications';
import Message from './Message';
import { EditorTextarea } from '../EditorTextarea';
import { sortByCreatedAtAndId } from '../../../utils';
import IndividualMessageDeliveryStatus from './IndividualMessageDeliveryStatus';
import { validateMessage } from './helpers';
import ImagePreview from '../ImagePreview';
import { MSG_TYPE_MMS, MSG_TYPE_SMS } from '../../../utils/messageType';
import { defaultCustomer } from '../CustomerBar';
import { CONVERSATION_TYPE } from '../../../constants';

const IndividualConversation = ({
  message,
  messages,
  customer = defaultCustomer,
  loaded,
  onClearAlert,
  onMessageChange,
  onSend,
  customerSelected,
  permalinkData,
  type,
  merchant,
}) => {
  /** @type {String|undefined} */
  const initialError = undefined;
  const [error, setError] = useState(/** @type {String|undefined} */ (initialError));
  const [msgType, setMsgType] = useState(MSG_TYPE_SMS);
  const [mmsMediaURL, setMmsMediaURL] = useState(null);
  const [showMMSSection, setShowMMSSection] = useState(false);
  /** @type {React.MutableRefObject<HTMLDivElement?>} */
  const messagesRef = useRef(null);
  const { customerPhone, fullName, supportFlagged } = customer;
  const customerPhoneId = customerPhone?.id;
  const customerPhoneNumber = customerPhone?.phone;
  const isUnsubscribed = (customerPhone?.optedIn === 0); // Only if they have explicitly opted out
  const { addToast } = useToasts();

  const handleOnChange = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;
      onMessageChange(value);
    },
    [onMessageChange],
  );

  const handleOnMsgTypeChange = ({ msgType: newMsgType, mediaURL = null }) => {
    setMsgType(newMsgType);
    setMmsMediaURL(mediaURL);
  };

  const addMMS = () => {
    if (mmsMediaURL) {
      setShowMMSSection(true);
    }
  };

  const handleMMSCancel = useCallback((showToast = true) => {
    setShowMMSSection(false);
    setMsgType(MSG_TYPE_SMS);
    setMmsMediaURL(null);

    if (showToast) {
      addToast('Switched to SMS', {
        appearance: 'info',
        autoDismiss: true,
      });
    }
  }, [addToast]);

  const sortedList = useMemo(() => {
    const filteredList = messages
      .filter((m) => messages.find((msg) => msg.id === m.id))
      .filter((m) => m.customerPhoneId === customerPhoneId);
    return [...filteredList].sort(sortByCreatedAtAndId);
  }, [customerPhoneId, messages]);

  useLayoutEffect(() => {
    const validType = ['init', 'message'].includes(type);
    if (!validType || sortedList.length === 0 || loaded === false) {
      return;
    }
    const behavior = type === 'init' ? 'auto' : 'smooth';

    if (messagesRef.current) {
      messagesRef.current.scrollIntoView({
        behavior,
      });
    }
  }, [loaded, sortedList.length, type]);

  const prependToMessage = (text) => {
    const newMessage = `${message} ${text}`;
    onMessageChange(newMessage.replace(/\n/g, '').trim());
  };

  const handleSend = useCallback(() => {
    const isMMS = !!(msgType === MSG_TYPE_MMS && mmsMediaURL);
    const errorMessage = validateMessage(message, permalinkData);

    if (!errorMessage) {
      onSend({
        method: 'sendCustomerMessage',
        channel: isMMS ? MSG_TYPE_MMS : MSG_TYPE_SMS,
        message: {
          content: message.trim(),
          permalinkData,
          mediaUrl: isMMS ? mmsMediaURL : null,
        },
        customerPhoneId,
      });
      onMessageChange('');
      handleMMSCancel(false);
      setError('');
    } else {
      setError(errorMessage);
    }
  }, [
    msgType,
    mmsMediaURL,
    message,
    permalinkData,
    onSend,
    customerPhoneId,
    onMessageChange,
    handleMMSCancel,
  ]);

  useEffect(() => {
    setError('');
  }, [permalinkData]);

  const header = fullName || customerPhoneNumber || '';
  const subTitle = header === customerPhoneNumber ? '' : customerPhoneNumber || '';

  const conversationStyle = showMMSSection
    ? {
      height: 'calc(100% - 240px)',
    }
    : {};

  return (
    <div className="conv-card">
      <div className="info-bar">
        <div>
          <div>
            <span className="contact-title">{header}</span>
            <span className="contact-sub-title">{subTitle}</span>
          </div>
          <div>
            {supportFlagged === 1 && (
            <button
              className="btn btn-outline-primary"
              onClick={onClearAlert}
              type="button"
            >
              Clear alert
            </button>
            )}
          </div>
        </div>
      </div>

      <div className="conversation-window" style={conversationStyle}>
        {customerSelected && (!customerPhoneNumber || !loaded) ? (
          <div className="loading">Loading...</div>
        ) : (
          <div>
            {sortedList.map((item) => (
              <Message
                key={item.id}
                {...item}
                fullName={fullName}
                type={CONVERSATION_TYPE.INDIVIDUAL}
                status={(
                  <IndividualMessageDeliveryStatus
                    channel={item.channel}
                    deliveryStatus={item.deliveryStatus}
                    direction={item.direction}
                    sendSkipped={item.sendSkipped}
                  />
                  )}
              />
            ))}
          </div>
        )}
        <div id="messages-ref" ref={messagesRef} />
      </div>
      {showMMSSection && (
      <ImagePreview
        url={mmsMediaURL}
        altText={message}
        onCancel={handleMMSCancel}
      />
      )}
      <div className="input-window">
        <EditorTextarea
          value={message}
          onChange={handleOnChange}
          onSend={handleSend}
          onMsgTypeChange={handleOnMsgTypeChange}
          prependToMessage={prependToMessage}
          addMMS={addMMS}
          disabled={!customerPhoneId || isUnsubscribed}
          msgType={msgType}
          merchant={merchant}
        />

        <div className="error">{error}</div>
      </div>
    </div>
  );
};

export default memo(IndividualConversation);
