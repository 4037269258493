import { v4 as uuid } from 'uuid';
import { createNode } from '../../../utils/Flows';

import { CustomFlowsActionTypes } from '../../types';

const INITIAL_MESSAGE_CONTENT = '{brand_name}: Hey {first_name} 👋 Enter your message content here...';
const INITIAL_OPTIN_CLASS = 'MARKETING';

const setFlowToggleProcessingState = (flows, flowId, state) => flows.map((flow) => {
  if (flow.id === flowId) {
    return {
      ...flow,
      isToggling: state,
    };
  }

  return flow;
});

export const initialState = {
  isLoading: false,
  flowsLoaded: false,
  flowCreated: null,
  flowUpdated: null,
  flowDeleting: null,
  flowDeleted: null,
  flows: [],
  flow: {
    name: '',
    optinClass: INITIAL_OPTIN_CLASS,
    // Define the default filter you see when you toggle the setting
    filters: [
      {
        id: uuid(),
      },
    ],
    contactFilter: {
      // Define the default filter you see when you toggle the setting
      predicates: [
        {
          id: uuid(),
          type: 'product',
        },
      ],
    },
    nodes: [createNode(0, INITIAL_MESSAGE_CONTENT)],
  },
};

export const CustomFlowsReducer = (state = initialState, action) => {
  const {
    type, payload,
  } = action;
  switch (type) {
    case CustomFlowsActionTypes.GET_FLOWS_RES: {
      return {
        ...state,
        flows: payload,
        flowsLoaded: true,
      };
    }
    case CustomFlowsActionTypes.GET_FLOW_REQ: {
      return {
        ...state,
        isLoading: true,
        flow: initialState.flow,
      };
    }
    case CustomFlowsActionTypes.GET_FLOW_RES: {
      return {
        ...state,
        isLoading: false,
        flow: payload,
      };
    }
    case CustomFlowsActionTypes.RESET_FLOW_FORM: {
      return {
        ...state,
        flow: initialState.flow,
        flowCreated: null,
        flowUpdated: null,
        flowDeleting: null,
        flowDeleted: null,
      };
    }
    case CustomFlowsActionTypes.CREATE_FLOW_REQ: {
      return {
        ...state,
        flowCreated: null,
      };
    }
    case CustomFlowsActionTypes.CREATE_FLOW_RES: {
      return {
        ...state,
        flowCreated: payload,
      };
    }
    case CustomFlowsActionTypes.UPDATE_FLOW_REQ: {
      return {
        ...state,
        flowUpdated: null,
      };
    }
    case CustomFlowsActionTypes.UPDATE_FLOW_RES: {
      return {
        ...state,
        flowUpdated: payload,
      };
    }
    case CustomFlowsActionTypes.DELETING_FLOW: {
      return {
        ...state,
        flowDeleting: true,
      };
    }
    case CustomFlowsActionTypes.DELETED_FLOW: {
      return {
        ...state,
        flow: null,
        flowDeleting: null,
        flowDeleted: payload,
      };
    }
    case CustomFlowsActionTypes.TOGGLE_FLOW_REQ: {
      return {
        ...state,
        flows: setFlowToggleProcessingState(state.flows, payload.flowId, true),
      };
    }
    case CustomFlowsActionTypes.TOGGLE_FLOW_RES: {
      return {
        ...state,
        flows: setFlowToggleProcessingState(state.flows, payload.flowId, false),
      };
    }
    default:
      return state;
  }
};
