import React, {
  memo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { DateTime } from 'luxon';

import { useDispatch, useSelector } from 'react-redux';
import { countSegmentsInMessage } from '../../../../utils/sms';

import LoadingMessage from '../Loading';
import { DemoPhone } from '../../DemoPhone';
import { HelpBubble } from '../../HelpBubble';
import { formatDate } from '../../../../utils/dates';

import { GROUP_TYPE } from '../../../../constants';
import { MMS_SEGMENT_RATIO_TO_SMS } from '../../../../utils/settings';
import { MSG_TYPE_MMS, MSG_TYPE_SMS } from '../../../../utils/messageType';

import { GroupMessagingActionCreators } from '../../../../redux/actions/groupMessaging';

const { getEstimatedTimeToSend: getEstimatedTimeToSendAction } = GroupMessagingActionCreators;

/** @enum {String} */
export const SendTypes = {
  NOW: 'now',
  SCHEDULED: 'scheduled',
};

const SendSchedule = ({
  scheduled,
  scheduledTime,
  setScheduled,
  setScheduledTime,
  setIsValid,
  timezone,
}) => {
  const toggleSendType = useCallback(({ target: { value } }) => {
    setScheduled(value);
  }, [setScheduled]);
  const handleScheduledTime = useCallback(({ target: { value } }) => {
    const scheduledTimeZone = DateTime.fromISO(value, { zone: timezone });
    setScheduledTime(scheduledTimeZone.toISO());
  }, [setScheduledTime, timezone]);

  useEffect(() => {
    if (scheduled === SendTypes.SCHEDULED) {
      setIsValid(DateTime.fromISO(scheduledTime).isValid);
    } else {
      setIsValid(true);
    }
  }, [scheduled, scheduledTime, setIsValid]);

  const currentTime = DateTime.fromObject({ zone: timezone }).toFormat('yyyy-MM-dd/hh:mm').replace('/', 'T');

  return (
    <div className="send-schedule">
      <div className="checkbox">
        <input id="sendNow" type="radio" name="sendType" checked={scheduled === SendTypes.NOW} value={SendTypes.NOW} onChange={toggleSendType} />
        <label htmlFor="sendNow">Send Now</label>
      </div>
      <div className="checkbox">
        <input id="sendScheduled" type="radio" name="sendType" checked={scheduled === SendTypes.SCHEDULED} value={SendTypes.SCHEDULED} onChange={toggleSendType} />
        <label htmlFor="sendScheduled">Scheduled Send</label>
        <input
          type="datetime-local"
          disabled={scheduled === SendTypes.NOW}
          onChange={handleScheduledTime}
          className="form-control datetime"
          min={currentTime}
        />
      </div>
      <p className="timezone">
        Your default timezone is set to:
        {' '}
        {timezone}
      </p>
    </div>
  );
};

const ModalContent = ({
  customerCount,
  group,
  saving,
  onClose,
  handleConfirm,
  refreshCount,
  messageExample,
  mmsMediaURL,
  setScheduled,
  setScheduledTime,
  scheduled,
  scheduledTime,
  loadingPlaceholder,
  timezone,
  scheduledGroupMessageIsActive,
}) => {
  const {
    estimateLoading,
    estimate,
  } = useSelector((
    /** @type {import('../../../../redux/reducers').RootState} */state,
  ) => state.GroupMessagingReducer);

  const dispatch = useDispatch();

  const { segmentCount } = countSegmentsInMessage(messageExample, mmsMediaURL ? MSG_TYPE_MMS : MSG_TYPE_SMS);

  const [isValid, setIsValid] = useState(true);

  const subscriberUpdatedAt = useCallback(
    () => (
      <div>
        <b>Last updated</b>
        :
        {formatDate(group.countUpdatedAt)}
      </div>
    ),
    [group],
  );

  useEffect(() => {
    if (scheduledGroupMessageIsActive && segmentCount && segmentCount > 0) {
      dispatch(getEstimatedTimeToSendAction(customerCount, segmentCount, !!mmsMediaURL));
    }
  }, [scheduledGroupMessageIsActive, dispatch, customerCount, segmentCount, mmsMediaURL]);

  if (refreshCount) {
    return <LoadingMessage loading={refreshCount} />;
  }

  let actionBtnLabel = saving ? 'Sending...' : 'Send';
  if (scheduled === SendTypes.SCHEDULED) {
    actionBtnLabel = saving ? 'Scheduling...' : 'Schedule';
  }

  return (
    <>
      <h3 className="text-center">
        <b>Send message?</b>
      </h3>

      <div className="row">
        <div className="col-md-6 info">
          <div>
            <b>Group name</b>
            :
            {' '}
            {group.name}
          </div>

          <div>
            <b>Subscriber count</b>
            :
            {' '}
            {customerCount}
          </div>

          {group.type === GROUP_TYPE.SEGMENT && subscriberUpdatedAt()}

          <div className="d-flex align-items-center">
            <div>
              <b>Estimated segments</b>
              :
              {' '}
              {customerCount
                  * segmentCount
                  * (mmsMediaURL ? MMS_SEGMENT_RATIO_TO_SMS : 1)}
            </div>

            <HelpBubble
              text="The actual total may vary depending on the content of any dynamic brackets."
              small
            />
          </div>

          {scheduledGroupMessageIsActive && (
            <div>
              <b>Estimated time to send</b>
              :
              {' '}
              {estimateLoading && (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              )}
              {!estimateLoading && (
              <>
                {estimate}
              </>
              )}
            </div>
          )}
        </div>

        <div className="col-md-6">
          <DemoPhone
            messages={[
              {
                content: messageExample,
                date: formatDate(new Date().toISOString()),
                mmsMediaURL,
              },
            ]}
          />
        </div>
      </div>

      <p className="text-center">
        Are you sure you want to send the message to
        {' '}
        {customerCount}
        {' '}
        contact
        {customerCount === 1 ? '' : 's'}
        ?
      </p>

      {scheduledGroupMessageIsActive && (
        <SendSchedule
          setScheduled={setScheduled}
          setScheduledTime={setScheduledTime}
          scheduled={scheduled}
          scheduledTime={scheduledTime}
          setIsValid={setIsValid}
          timezone={timezone}
        />
      )}

      <div className="buttons-container">
        <button
          className="btn btn-danger"
          disabled={saving || estimateLoading || !isValid || loadingPlaceholder}
          onClick={handleConfirm}
          type="button"
        >
          {actionBtnLabel}
        </button>

        <button className="cancel-btn" disabled={saving} onClick={onClose} type="button">
          Cancel
        </button>
      </div>
    </>
  );
};

export default memo(ModalContent);
