import React, { useEffect } from 'react';
import { useFeatureFlags } from '../../contexts/featureFlags';

export const BREAKDOWN_BY = {
  DATE: 'date',
  MESSAGE_TYPE_FULL: 'messageTypeFull',
  GROUP_MESSAGE: 'groupMessage',
  GROUP_MESSAGE_GROUP: 'groupMessageGroup',
  FLOW: 'flow',
};

let isFlowsEnabled = false;

export const BreakdownSelect = ({ value, onChange, id }) => {
  const { isFeatureEnabled } = useFeatureFlags();

  useEffect(() => {
    const updateIsFlowsEnabled = async () => {
      isFlowsEnabled = (await isFeatureEnabled(
        'customFlows',
        false,
      ));
    };
    updateIsFlowsEnabled();
  }, [isFeatureEnabled]);

  return (
    <select id={id} className="form-control" value={value} onChange={onChange}>
      <option value={BREAKDOWN_BY.MESSAGE_TYPE_FULL}>Message Type</option>
      <option value={BREAKDOWN_BY.DATE}>Date</option>
      <option value={BREAKDOWN_BY.GROUP_MESSAGE}>Group Message</option>
      <option value={BREAKDOWN_BY.GROUP_MESSAGE_GROUP}>Messaging Group</option>
      {isFlowsEnabled && <option value={BREAKDOWN_BY.FLOW}>Automation Campaign</option>}
    </select>
  );
};
