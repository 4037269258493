import React, {
  memo,
  useCallback,
} from 'react';
import { v4 as uuid } from 'uuid';

import Optin, { valueMapper as optinMapper } from './EventFilters/Optin';
import Message, { valueMapper as messageMapper } from './EventFilters/Message';
import Basket, { valueMapper as basketMapper } from './EventFilters/Basket';
import { NEGATE_MODES } from '../../../global/Predicates/Negate';

/** @typedef {import('react').FunctionComponentElement} FunctionComponentElement */

export const getDisabledFlagForEventFilter = (automationType) => {
  switch (automationType) {
    case 'store.blueprint.sms.Optin':
    case 'store.blueprint.sms.IncomingSMS':
    case 'store.blueprint.checkout.CheckoutAbandoned':
    case 'store.blueprint.subscription.NewCharge':
    case 'store.blueprint.order.OrderCreated':
    case 'store.blueprint.order.OrderFulfilled':
    case 'store.blueprint.subscription.SubscriptionCreated':
    case 'store.blueprint.subscription.UpcomingCharge':
      return false;
    case 'store.blueprint.subscription.ChargePaymentFailed':
    case 'store.blueprint.subscription.LastSubscriptionCancelled':
    default:
      return true;
  }
};

const getComponentForAutomationType = (automationType) => {
  switch (automationType) {
    case 'store.blueprint.sms.Optin':
      return Optin;
    case 'store.blueprint.sms.IncomingSMS':
      return Message;
    case 'store.blueprint.checkout.CheckoutAbandoned':
    case 'store.blueprint.subscription.NewCharge':
    case 'store.blueprint.order.OrderCreated':
    case 'store.blueprint.order.OrderFulfilled':
    case 'store.blueprint.subscription.SubscriptionCreated':
    case 'store.blueprint.subscription.UpcomingCharge':
      return Basket;
    case 'store.blueprint.subscription.ChargePaymentFailed':
    case 'store.blueprint.subscription.LastSubscriptionCancelled':
    default:
      return null;
  }
};

export const getValueMapperForAutomationType = (automationType) => {
  switch (automationType) {
    case 'store.blueprint.sms.Optin':
      return optinMapper;
    case 'store.blueprint.sms.IncomingSMS':
      return messageMapper;
    case 'store.blueprint.checkout.CheckoutAbandoned':
    case 'store.blueprint.subscription.NewCharge':
    case 'store.blueprint.order.OrderCreated':
    case 'store.blueprint.order.OrderFulfilled':
    case 'store.blueprint.subscription.SubscriptionCreated':
    case 'store.blueprint.subscription.UpcomingCharge':
    case 'store.blueprint.subscription.ChargePaymentFailed':
    case 'store.blueprint.subscription.LastSubscriptionCancelled':
      return basketMapper;
    default:
      throw new Error(`Tried to get value mapper for unknown type ${automationType}`);
  }
};

const EventFilter = ({ automationType, onChange, filters }) => {
  const Component = getComponentForAutomationType(automationType);

  const onFilterChange = useCallback((filter) => {
    let updatedFilters = [];

    const foundIndex = filters.findIndex((f) => f.id === filter.id);

    if (foundIndex < 0) {
      updatedFilters = [...filters, filter];
    }

    updatedFilters = filters.map((f) => (f.id === filter.id ? filter : f));

    onChange(updatedFilters);
  }, [filters, onChange]);

  const addFilter = useCallback(() => {
    onChange([...filters, { id: uuid() }]);
  }, [filters, onChange]);

  const removeFilter = useCallback((id) => {
    const updatedFilters = filters.filter((filter) => filter.id !== id);

    onChange(updatedFilters);
  }, [filters, onChange]);

  if (!Component) {
    return null;
  }

  return (
    <>
      {filters.map((filter, idx) => (
        <div key={filter.id} className="form-row mb-2">
          <Component
            onChange={onFilterChange}
            {...filter}
            comparator={filter.op}
            negate={{ mode: filter.negate ? NEGATE_MODES.yes : NEGATE_MODES.no }}
          />
          <div className="col-mb-1 filter-actions">
            {idx > 0 && (
              <button
                className="btn btn-link"
                type="button"
                onClick={() => {
                  removeFilter(filter.id);
                }}
              >
                🗑️
              </button>
            )}
          </div>
        </div>
      ))}
      {filters.length > 0 && <button className="minimal-blue-btn" onClick={addFilter} type="button">+ AND</button>}
    </>
  );
};

export default memo(EventFilter);
