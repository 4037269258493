import {
  APIServiceConstants,
  BlueprintAPI,
} from '../../redux/actions/BlueprintAPI';

const { SERVICE } = APIServiceConstants;

const deleteCustomDomain = async (customDomainId) => {
  try {
    await BlueprintAPI(SERVICE, `customDomains/${customDomainId}`, {}, null, false, 'DELETE');
  } catch (err) {
    throw new Error('Failed to delete custom domain. Please contact Support.');
  }
};

export default deleteCustomDomain;
