import React, { memo } from 'react';
import {
  useQuery,
} from 'react-query';

/** @typedef {import('packages/analytics/modules/contactStats').ContactStats} ContactStats */
/** @typedef {import('packages/dashboard-backend/types').MerchantData} MerchantData */

import { getContactStats } from '../../queries/BlueprintValue';
import { HelpBubble } from '../../components/main/HelpBubble';

/** @enum {String} */
const CONTACT_GROUP = {
  OPTEDIN: 'OPTEDIN',
  TRANSACTIONAL: 'TRANSACTIONAL',
  OTHER: 'OTHER',
};

const TableHeader = () => (
  <thead>
    <tr>
      <th>Status</th>
      <th>Number of Customers</th>
      <th>Avg. revenue per Customer</th>
      <th>Avg. orders per Customer</th>
      <th>Avg. re-order rate</th>
    </tr>
  </thead>
);

const LoadingTableRows = () => (
  <tr>
    <td colSpan={5}>Loading...</td>
  </tr>
);

const ErrorTableRows = () => (
  <tr>
    <td colSpan={5}>An error occurred. Please try again.</td>
  </tr>
);

const getStatusCellForContactGroup = (contactGroup) => {
  switch (contactGroup) {
    case CONTACT_GROUP.OPTEDIN:
      return (
        <td>
          <div>
            Marketing opted-in
            {' '}
            <HelpBubble text="Explicitly opted in for SMS marketing" />
          </div>
        </td>
      );
    case CONTACT_GROUP.TRANSACTIONAL:
      return (
        <td>
          <div>
            Transactional
            {' '}
            <HelpBubble text="Transactional messages only" />
          </div>
        </td>
      );
    case CONTACT_GROUP.OTHER:
      return (
        <td>
          <div>
            No contact
            {' '}
            <HelpBubble text="A customer that has had no SMS contact" />
          </div>
        </td>
      );
    default:
      return null;
  }
};

/**
 * @param {Object} props
 * @param {ContactStats} props.contactStats
 * @param {MerchantData} props.merchantData
 */
const SegmentedCustomerRows = ({ contactStats, merchantData }) => {
  const rows = contactStats.map((contactStatsRow) => {
    const {
      contactGroup,
      avgOrdersPerCustomer,
      avgRevenuePerCustomer,
      numCustomers,
      rateRepeatCustomers,
    } = contactStatsRow;

    return (
      <tr key={contactGroup}>
        {getStatusCellForContactGroup(contactGroup)}
        <td>{numCustomers}</td>
        <td>
          {merchantData.currencySymbol}
          {avgRevenuePerCustomer.toFixed(2)}
        </td>
        <td>{avgOrdersPerCustomer.toFixed(2)}</td>
        <td>
          {rateRepeatCustomers.toFixed(2)}
          %
        </td>
      </tr>
    );
  });

  return <>{rows}</>;
};

const SegmentedCustomerStats = ({ merchantData }) => {
  const {
    isLoading, isError, data,
  } = useQuery('contactStats', getContactStats);

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <p>
            Over the last
            {' '}
            <strong>90</strong>
            {' '}
            days
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <table
            className="table table-bordered table-hover table-responsive-lg bp-table bp-table-dark segmentedCustomerStats"
            role="table"
          >
            <TableHeader />
            <tbody>
              {isLoading && <LoadingTableRows />}
              {(!isLoading && isError) && <ErrorTableRows />}
              {(!isLoading && !isError) && (
                <SegmentedCustomerRows contactStats={data} merchantData={merchantData} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default memo(SegmentedCustomerStats);
