import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import Reducers from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initUrlApiBaseParamsAndSaveToLocalStorage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('baseApiURL')) {
    localStorage.setItem('baseApiURL', /** @type {String} */ (urlParams.get('baseApiURL')));
  }
  if (urlParams.has('baseWSApiURL')) {
    localStorage.setItem('baseWSApiURL', /** @type {String} */(urlParams.get('baseWSApiURL')));
  }
  if (urlParams.has('testEnv')) {
    const testEnv = urlParams.get('testEnv');
    localStorage.setItem('baseApiURL', `https://${testEnv}.blueprint-api-test.rocks`);
    localStorage.setItem('baseWSApiURL', `wss://ws-${testEnv}.blueprint-api-test.rocks`);
  }
};

const showInfoBox = (bottom, right, element, text) => {
  if (!element) {
    const infoBoxElement = document.createElement('div');
    infoBoxElement.style.position = 'fixed';
    infoBoxElement.style.backgroundColor = 'orange';
    infoBoxElement.style.padding = '3px 8px';
    infoBoxElement.style.border = '1px solid red';
    infoBoxElement.style.right = `${right}px`;
    infoBoxElement.style.bottom = `${bottom}px`;
    infoBoxElement.style.color = 'red';
    infoBoxElement.innerText = text;
    document.body.appendChild(infoBoxElement);
  }
};

// Allow REACT_APP_BLUEPRINT_API_URL and REACT_APP_WEBSOCKET_URL to be overridden by localStorage
let baseLabel;
let wsBaseLabel;

window.blueprintGetApiBaseURL = (
  defaultURL = process.env.REACT_APP_BLUEPRINT_API_URL,
  path = '',
) => {
  // This is a WIP, needs tidy
  // Only allow overrides if dev instance
  if (
    process.env.REACT_APP_BLUEPRINT_API_URL === 'https://dev.blueprint-api.com'
  ) {
    initUrlApiBaseParamsAndSaveToLocalStorage();

    const baseWSApiURL = localStorage.getItem('baseWSApiURL');

    if (baseWSApiURL) {
      showInfoBox(0, 0, wsBaseLabel, baseWSApiURL);
    }

    const baseApiURL = localStorage.getItem('baseApiURL');

    if (baseApiURL) {
      showInfoBox(33, 0, baseLabel, baseApiURL);

      return `${baseApiURL}${path}`;
    }
  }
  return defaultURL;
};

window.blueprintGetWSApiBaseURL = (
  defaultURL = process.env.REACT_APP_WEBSOCKET_URL,
) => {
  // This is a WIP, needs tidy
  // Only allow overrides if dev instance
  if (
    process.env.REACT_APP_WEBSOCKET_URL === 'wss://ws-dev.blueprint-api.com'
  ) {
    const baseWSApiURL = localStorage.getItem('baseWSApiURL');

    if (baseWSApiURL) {
      return baseWSApiURL;
    }
  }
  return defaultURL;
};

const store = createStore(
  Reducers,
  undefined,
  composeEnhancers(applyMiddleware(thunk)),
);

export default store;
