/**
 * @enum {String}
 */
export const PASSWORD_ERRORS = {
  TOO_SHORT: 'TOO_SHORT',
  MUST_CONTAIN_UPPERCASE: 'MUST_CONTAIN_UPPERCASE',
  MUST_CONTAIN_LOWERCASE: 'MUST_CONTAIN_LOWERCASE',
  MUST_CONTAIN_NUMBER: 'MUST_CONTAIN_NUMBER',
};

const PASSWORD_CHECKS = [
  {
    id: PASSWORD_ERRORS.TOO_SHORT,
    message: 'Password is too short. Must be 10 chars or more',
    regex: new RegExp('^.{10,}'),
  },
  {
    id: PASSWORD_ERRORS.MUST_CONTAIN_UPPERCASE,
    message: 'Password must contain at least one uppercase letter',
    regex: new RegExp('[A-Z]'),
  },
  {
    id: PASSWORD_ERRORS.MUST_CONTAIN_LOWERCASE,
    message: 'Password must contain at least one lowercase letter',
    regex: new RegExp('[a-z]'),
  },
  {
    id: PASSWORD_ERRORS.MUST_CONTAIN_NUMBER,
    message: 'Password must contain at least one number',
    regex: new RegExp('[0-9]'),
  },
];

/**
 * @param {String} plaintext
 * @returns {Object<String, String>}
 */
export const getPasswordStrengthErrors = (plaintext) => {
  const errors = {};

  for (const check of PASSWORD_CHECKS) {
    if (!check.regex.test(plaintext)) {
      errors[check.id] = check.message;
    }
  }

  return errors;
};
