import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BImage } from './BImage';
import { useFeatureFlags } from '../../contexts';

/**
 * @typedef SidebarItem
 * @property {String} link
 * @property {String} icon
 * @property {String} text
 * @property {Boolean} [external]
 * @property {Boolean} [isBeta]
 */

/**
 * @param {Function} isFeatureEnabled
 * @returns {Promise<SidebarItem[]>}
 */
const constructSidebarItems = async (isFeatureEnabled) => {
  const enableUpsells = await isFeatureEnabled(
    'upsells',
    false,
  );
  const enableSmartlinks = await isFeatureEnabled(
    'smartlinks',
    false,
  );

  const enableCrossSellReplenishment = await isFeatureEnabled(
    'crossSellReplenishment',
    false,
  );

  const enableContacts = await isFeatureEnabled(
    'contacts',
    false,
  );

  const enableReplies = await isFeatureEnabled(
    'replies',
    false,
  );

  const enableCustomFlows = await isFeatureEnabled(
    'customFlows',
    false,
  );

  const enableOldAutomations = await isFeatureEnabled(
    'oldAutomations',
    true,
  );

  const enableOldAbandonedCarts = await isFeatureEnabled(
    'oldAbandonedCarts',
    true,
  );

  const enableNewCustomerAnalytics = await isFeatureEnabled(
    'newCustomerAnalytics',
    false,
  );

  const enableDashboardBeta = await isFeatureEnabled(
    'dashboardBeta',
    false,
  );

  const enableBlueprintValue = await isFeatureEnabled(
    'blueprintValue',
    false,
  );

  return [
    { link: '/', icon: 'bp-home', text: 'Home' },
    { link: '/messaging', icon: 'bp-messaging', text: 'Messaging' },
    {
      link: '/group-messaging',
      icon: 'bp-groups',
      text: 'Group Messaging',
    },
    ...(enableContacts
      ? [{ link: '/contacts', icon: 'bp-contacts', text: 'Contacts' }]
      : []),
    ...(enableNewCustomerAnalytics
      ? [{ link: '/customer-analytics', icon: 'bp-analytics', text: 'Customer Analytics' }]
      : [{ link: '/analytics', icon: 'bp-analytics', text: 'Customer Analytics' }]
    ),
    {
      link: '/sms-analytics',
      icon: 'bp-analytics2',
      text: 'Messaging Analytics',
    },
    ...(enableBlueprintValue
      ? [
        {
          link: '/blueprint-snapshot',
          icon: 'bp-analytics2',
          text: 'Blueprint Snapshot',
        },
      ]
      : []),
    ...(enableReplies
      ? [
        {
          link: '/replies',
          icon: 'bp-reply',
          text: 'Replies',
        },
        {
          link: '/replies-analytics',
          icon: 'bp-reply-analytics',
          text: 'Replies Analytics',
        },
      ]
      : []),
    ...(enableOldAutomations
      ? [
        {
          link: '/automated-messages',
          icon: 'bp-automated-messages',
          text: 'Automated Messages',
        },
      ] : []),
    ...(enableCustomFlows ? [
      {
        link: '/automations',
        icon: 'bp-automated-messages',
        text: 'Automations',
      },
    ] : []),
    ...(enableOldAbandonedCarts ? [
      {
        link: '/abandoned-carts',
        icon: 'bp-abandon-cart',
        text: 'Abandoned Carts',
      },
    ] : []),
    ...(enableCrossSellReplenishment
      ? [
        {
          link: '/cross-sells',
          icon: 'arrow-up',
          text: 'Cross-sells',
        },
        {
          link: '/replenishments',
          icon: 'replenish',
          text: 'Replenishments',
        },
      ]
      : []),
    ...(enableUpsells
      ? [
        {
          link: '/upsells',
          icon: 'arrow-up',
          text: 'Upsells',
        },
      ]
      : []),

    ...(enableSmartlinks
      ? [
        { link: '/smart-links', icon: 'bp-build-audience', text: 'Grow Subscribers' },
      ]
      : []),
    { link: '/settings', icon: 'bp-settings', text: 'Settings' },
    {
      link: '/integrations',
      icon: 'bp-plug',
      text: 'Integrations',
    },
    ...(enableDashboardBeta
      ? [
        {
          link: '/beta/test',
          icon: 'arrow-up',
          text: 'Beta',
          isBeta: true,
        },
      ]
      : []),
    { link: '/logout', icon: 'bp-logout', text: 'Logout' },
  ];
};

export const Sidebar = ({ noCall = false }) => {
  const [loaded, setLoaded] = useState(false);
  const [sidebarItems, setSidebarItems] = useState(/** @type {SidebarItem[]} */ ([]));
  const { isFeatureEnabled } = useFeatureFlags();

  useEffect(() => {
    if (loaded) {
      return;
    }

    let fetchedSidebarItems = [];

    const fetchSidebarItems = async () => {
      if (noCall) {
        fetchedSidebarItems = await constructSidebarItems(() => false);
      } else {
        fetchedSidebarItems = await constructSidebarItems(isFeatureEnabled);
      }

      setSidebarItems(fetchedSidebarItems);
      setLoaded(true);
    };

    fetchSidebarItems();
  }, [isFeatureEnabled, loaded, noCall]);

  return (
    <div className="sidebar">
      {loaded
        ? sidebarItems.map((item) => {
          if (item.external) {
            return <SidebarItemExternal key={item.link} {...item} />;
          }
          if (item.isBeta) {
            return <SidebarItemBeta key={item.link} {...item} />;
          }
          return <SidebarItem key={item.link} {...item} />;
        })
        : null}
    </div>
  );
};

const SidebarItemContent = ({ icon, text }) => (
  <>
    <div>
      <BImage
        src={
            `https://static.blueprint.store/illustrations/dashboard/${
              icon
            }.svg`
          }
        alt={text}
      />
    </div>
    <span>{text}</span>
  </>
);

const SidebarItem = (item) => {
  const { link, disabled, text } = item;

  return (
    <NavLink
      exact
      to={link}
      className={`sidebar-link${disabled ? ' disabled' : ''}`}
      title={disabled ? '⏳ Coming Soon' : text}
    >
      <SidebarItemContent {...item} />
    </NavLink>
  );
};

const SidebarItemExternal = (item) => {
  const { link, disabled, text } = item;
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={`sidebar-link${disabled ? ' disabled' : ''}`}
      title={disabled ? '⏳ Coming Soon' : text}
    >
      <SidebarItemContent {...item} />
    </a>
  );
};

const SidebarItemBeta = (item) => {
  const { link, disabled, text } = item;

  return (
    <a
      href={link}
      className={`sidebar-link${disabled ? ' disabled' : ''}`}
      title={disabled ? '⏳ Coming Soon' : text}
    >
      <SidebarItemContent {...item} />
    </a>
  );
};

export default Sidebar;
