import React, { memo, useCallback } from 'react';
import { predicates, defaultNegateQuery } from './predicates';
import { cloneItem } from './helpers';
import Negate from '../../../global/Predicates/Negate';

const Predicate = ({
  index: predicateIndex, p, onChange, onDelete, products,
}) => {
  const {
    subQueries: { negate, ...subQueries },
    type,
  } = p;

  const handlePredicateChange = useCallback(
    (e) => {
      const newPredicateType = e.target.value;
      const newPredicate = predicates.find(
        (pred) => pred.type === newPredicateType,
      );

      onChange(cloneItem(newPredicate));
    },
    [onChange],
  );

  const handleSubQueryChange = useCallback(
    (name, value) => {
      onChange({
        ...p,
        subQueries: {
          ...subQueries,
          [name]: value,
        },
      });
    },
    [onChange, p, subQueries],
  );

  return (
    <div className="row">
      <div className="predicate col-xs-9 col-md-11">
        <Negate
          value={negate || defaultNegateQuery.negate}
          onChange={(value) => handleSubQueryChange('negate', { ...value, component: Negate })}
        />

        <select
          className="form-control"
          onChange={handlePredicateChange}
          value={type}
        >
          {predicates.map((pred, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <option value={pred.type} key={index}>
              {pred.title}
            </option>
          ))}
        </select>

        {Object.entries(subQueries).map(
          ([name, { component: SubQuery, ...value }], index) => (
            <SubQuery
              // eslint-disable-next-line react/no-array-index-key
              key={`${predicateIndex}-${index}`}
              onChange={(newValue) => handleSubQueryChange(name, { ...newValue, component: SubQuery })}
              products={products}
              value={value}
            />
          ),
        )}
      </div>

      <div className="col-xs-3 col-md-1 text-right predicate-actions">
        <div
          onClick={onDelete}
          role="button"
          tabIndex={0}
        >
          <img
            src="/images/trash.svg"
            className="trashIcon"
            alt="delete"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Predicate);
