import React, { memo } from 'react';

const CartItem = ({ cartItem, currencySymbol, removeItem }) => (
  <div className="row content" key={cartItem.id}>
    <div className="col-md-4">{cartItem.productName}</div>
    <div className="col-md-2">
      {cartItem.variantName !== 'Default Title' && cartItem.variantName}
    </div>
    <div className="col-md-2">
      x
      {cartItem.quantity}
    </div>
    <div className="col-md-3 price">
      {`${currencySymbol}${(cartItem.quantity * cartItem.price).toFixed(2)}`}
    </div>
    <div className="col-md-1 remove" onClick={() => removeItem(cartItem)} role="button" tabIndex={0}>
      🗑
    </div>
  </div>
);

export default memo(CartItem);
