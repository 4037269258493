import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import {
  getReductedString,
  UPSELL_TEXT_ALL_VARS_SELECTED,
} from '../../../utils';

const CollapsibleProductNames = ({ products, upsellId, type }) => {
  const identifier = `${upsellId}-${type}`;

  const [collapseObj, setCollapseObj] = useState({});

  const getFullProductName = (product) => `${product.name} [${product.variantName || UPSELL_TEXT_ALL_VARS_SELECTED}]`;

  const getProductNames = (productsToMap) => productsToMap.map((product) => getFullProductName(product)).join(', ');

  const getNameForInitialDisplay = (firstProdName, numberOfProds) => {
    const hiddenProducts = [...products];
    hiddenProducts.splice(0, 1);

    let initialDisplay = getReductedString(firstProdName);

    if (!collapseObj[identifier]) {
      initialDisplay += `[+ ${numberOfProds - 1} more]`;
    } else {
      initialDisplay += ',';
    }

    return initialDisplay;
  };

  const clickHandlerOnCollapsible = (clickedIdentifier) => {
    setCollapseObj({
      ...collapseObj,
      [clickedIdentifier]: collapseObj[clickedIdentifier] ? !collapseObj[clickedIdentifier] : true,
    });
  };

  if (!(products && products.length > 0 && Array.isArray(products))) {
    return <></>;
  }

  const numberOfProds = products.length;
  const firstProdName = products[0].name ? getFullProductName(products[0]) : '';

  if (numberOfProds < 2) {
    return <>{firstProdName}</>;
  }

  const initialDisplay = getNameForInitialDisplay(firstProdName, numberOfProds);

  return (
    <>
      <span
        role="button"
        tabIndex={0}
        className="collapsible-text"
        onClick={() => {
          clickHandlerOnCollapsible(identifier);
        }}
      >
        {collapseObj[identifier] ? '' : initialDisplay}

        <Collapse isOpened={collapseObj[identifier]}>
          {getProductNames(products)}
        </Collapse>
      </span>
    </>
  );
};

export default CollapsibleProductNames;
