import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import ProdVarAdder from './ProdVarAdder';
import Triggers from './Triggers';
import { ProductActionCreators } from '../../../../redux/actions/product';

/** @typedef {import('../../../../redux/reducers').RootState} RootState */

const { getAllProducts: getAllProductsAction } = ProductActionCreators;

const ProdVarAdderWrapper = ({
  upsellObj,
  handleProductChange,
  isForCreating,
}) => {
  const {
    loadingData,
    productErr: getAllProductsError,
    allProducts,
    productsLoaded,
  } = useSelector((/** @type {RootState} */ state) => state.ProductReducer);

  const areProductsLoaded = (allProducts && allProducts.length > 0) || productsLoaded;

  const dispatch = useDispatch();

  const { addToast } = useToasts();

  const getProducts = useCallback(() => {
    dispatch(getAllProductsAction());
  }, [dispatch]);

  useEffect(() => {
    if (!areProductsLoaded) {
      getProducts();
    }
    if (getAllProductsError) {
      addToast('Could not get the products', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, areProductsLoaded, getAllProductsError, getProducts]);

  return (
    <>
      <div className="col-md-4">
        {isForCreating ? (
          <ProdVarAdder
            upsellObj={upsellObj}
            allProducts={allProducts}
            handleProductChange={handleProductChange}
            productsLoading={loadingData}
            type="triggers"
          />
        ) : (
          <Triggers triggers={upsellObj.triggers} />
        )}
      </div>
      <div className="col-md-4">
        <ProdVarAdder
          upsellObj={upsellObj}
          allProducts={allProducts}
          handleProductChange={handleProductChange}
          productsLoading={loadingData}
          type="products"
        />
      </div>
    </>
  );
};

export default ProdVarAdderWrapper;
