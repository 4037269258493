import React from 'react';
import { MSG_TYPE_SMS } from '../../utils/messageType';
import { countSegmentsInMessage } from '../../utils/sms';
import { HelpBubble } from './HelpBubble';

export const SegmentCharCounter = ({
  text,
  helpBubbleSmall = true,
  helpBubbleLeft = false,
  lazyLoadIcon = false,
  msgType = MSG_TYPE_SMS,
}) => {
  const { segmentCount, charCountPerSegment, segmentSuffix } = countSegmentsInMessage(text, msgType);

  return (
    <span className="segment-info">
      {text.length}
      /
      {charCountPerSegment}
      <b className="segment-count">
        {' '}
        {segmentCount > 0 && `(${segmentCount} ${segmentSuffix})`}
      </b>
      <a
        href="https://help.blueprint.store/what-are-message-segments"
        target="_blank"
        rel="noreferrer" // Do we need page-analytics related stuff here?
      >
        <HelpBubble
          text="Estimated segment count based on message content, click to read more. Segment count may vary based on the content of dynamic brackets."
          small={helpBubbleSmall}
          left={helpBubbleLeft}
          lazyLoad={lazyLoadIcon}
        />
      </a>
    </span>
  );
};

export default SegmentCharCounter;
