import React, { memo } from 'react';

const Subscriptions = ({ enabled, children }) => (
  <div className="integration-recharge">
    <h2>Subscription</h2>
    {!enabled && (
    <p>
      (
      <a href="/integrations">Connect ReCharge</a>
      {' '}
      to activate)
    </p>
    )}
    {children}
  </div>
);

export default memo(Subscriptions);
