import { APIKeysActionTypes } from '../../types';

export const initialState = {
  apiKeysLoading: false,
  apiKeysError: null,
  apiKeysLoaded: false,
  apiKeysData: {},
  apiKeysInsertLoading: false,
  apiKeysInsertSuccess: false,
  apiKeysInsertError: null,
  apiKeysDeleteLoading: false,
  apiKeysDeleteSuccess: false,
  apiKeysDeleteError: null,
  apiKeysUpdateLoading: false,
  apiKeysUpdateSuccess: false,
  apiKeysUpdateError: null,
};

export const APIKeysReducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case APIKeysActionTypes.RESET_MERCHANT_APIKEYS_MODIFY_STATE:
      return {
        ...initialState,
        apiKeysLoading: state.apiKeysLoading,
        apiKeysLoaded: state.apiKeysLoaded,
        apiKeysError: state.apiKeysError,
        apiKeysData: state.apiKeysData,
      };
    case APIKeysActionTypes.GET_MERCHANT_APIKEYS_REQ:
      return {
        ...state,
        apiKeysLoading: true,
      };
    case APIKeysActionTypes.GET_MERCHANT_APIKEYS_RES:
      if (error) {
        return {
          ...state,
          apiKeysLoading: false,
          apiKeysLoaded: false,
          apiKeysError: error,
        };
      }
      return {
        ...state,
        apiKeysLoading: false,
        apiKeysLoaded: true,
        apiKeysData: payload.apiKeys,
      };
    case APIKeysActionTypes.INSERT_MERCHANT_APIKEYS_REQ:
      return {
        ...state,
        apiKeysInsertLoading: true,
        apiKeysInsertSuccess: false,
        apiKeysInsertError: null,
      };
    case APIKeysActionTypes.INSERT_MERCHANT_APIKEYS_RES:
      if (error) {
        return {
          ...state,
          apiKeysInsertLoading: false,
          apiKeysInsertSuccess: false,
          apiKeysInsertError: error,
        };
      }

      return {
        ...state,
        apiKeysInsertLoading: false,
        apiKeysInsertSuccess: true,
        apiKeysInsertError: null,
      };
    case APIKeysActionTypes.DELETE_MERCHANT_APIKEYS_REQ:
      return {
        ...state,
        apiKeysDeleteLoading: true,
        apiKeysDeleteSuccess: false,
        apiKeysDeleteError: null,
      };
    case APIKeysActionTypes.DELETE_MERCHANT_APIKEYS_RES:
      if (error) {
        return {
          ...state,
          apiKeysDeleteLoading: false,
          apiKeysDeleteSuccess: false,
          apiKeysDeleteError: error,
        };
      }
      return {
        ...state,
        apiKeysDeleteLoading: false,
        apiKeysDeleteSuccess: true,
        apiKeysDeleteError: null,
      };
    case APIKeysActionTypes.UPDATE_MERCHANT_APIKEYS_REQ:
      return {
        ...state,
        apiKeysUpdateLoading: true,
        apiKeysUpdateSuccess: false,
        apiKeysUpdateError: null,
      };
    case APIKeysActionTypes.UPDATE_MERCHANT_APIKEYS_RES:
      if (error) {
        return {
          ...state,
          apiKeysUpdateLoading: false,
          apiKeysUpdateSuccess: false,
          apiKeysUpdateError: error,
        };
      }

      return {
        ...state,
        apiKeysUpdateLoading: false,
        apiKeysUpdateSuccess: true,
        apiKeysUpdateError: null,
      };
    default:
      return state;
  }
};
