import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
import { useTable } from 'react-table';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { BPToggle } from '../../components/main/BPToggle';
import DeletionModal from './DeletionModal';
import { getSmartlinks } from '../../queries/SmartLinks';
import { upsertSmartLink, deleteSmartLink } from '../../mutations/SmartLinks';

/** @typedef {import('../../utils/types').SmartLink} SmartLink */

const Table = () => {
  const [smartLinkToDelete, setSmartLinkToDelete] = useState(/** @type {SmartLink | null} */ (null));

  const { addToast } = useToasts();
  const queryClient = useQueryClient();

  const {
    data: smartLinksResult,
  } = useQuery(
    'smartLinks',
    getSmartlinks,
    {
      initialData: {
        smartLinks: [],
        baseURL: '',
      },
    },
  );

  // Replaces the old smart link on cache with the new one
  const replaceSmartLinkOnCache = (updatedSmartLink) => (
    queryClient.setQueryData('smartLinks', (oldData) => (
      {
        ...oldData,
        smartLinks: oldData.smartLinks.map(
          (oldSmartLink) => (oldSmartLink.id === updatedSmartLink.id ? updatedSmartLink : oldSmartLink),
        ),
      }
    ))
  );

  const { mutateAsync } = useMutation(upsertSmartLink, {
    onError: (/** @type {Error} */ error) => {
      addToast(
        `Error occured while updating the Smart Link. ${error.message || ''}`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    },
    onSuccess: (data) => {
      addToast(
        'The Smart Link has been updated',
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );

      replaceSmartLinkOnCache(data);
    },
  });

  const deleteSmartLinkMutation = useMutation(deleteSmartLink, {
    onError: (/** @type {Error} */ error) => {
      addToast(
        `Error occured while deleting the Smart Link. ${error.message || ''}`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    },
    onSuccess: () => {
      addToast(
        'The Smart Link has been deleted',
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );

      queryClient.setQueryData('smartLinks', (oldData) => {
        const smartLinks = oldData.smartLinks.slice();

        smartLinks.splice(
          smartLinks.findIndex(
            (oldSmartLink) => (smartLinkToDelete && oldSmartLink.id === smartLinkToDelete.id),
          ),
          1,
        );

        return {
          ...oldData,
          smartLinks,
        };
      });

      setSmartLinkToDelete(null);
    },
  });

  const handleToggle = useCallback(
    async (cell) => {
      await mutateAsync({
        id: cell.row.values.id,
        isActive: !cell.value,
      });
    },
    [mutateAsync],
  );

  const tableData = useMemo(() => (
    smartLinksResult?.smartLinks.map((smartLink) => {
      const smartLinkURL = smartLinksResult?.baseURL ? smartLinksResult?.baseURL.concat('/', /** @type {String} */ (smartLink?.linkCode)) : '';

      return {
        ...smartLink,
        linkCode: smartLinkURL,
        linkCodeExtra: smartLinkURL,
      };
    })
  ), [smartLinksResult?.smartLinks, smartLinksResult?.baseURL]);

  const columns = useMemo(
    () => [
      {
        Header: 'Signup Link',
        accessor: 'linkCode',
        Cell: ({ cell }) => (
          <a href={cell.value}>{cell.value}</a>
        ),
      },
      {
        Header: '',
        accessor: 'linkCodeExtra',
        Cell: ({ cell }) => (
          <button
            className="minimal-grey-btn"
            value={cell.value}
            onClick={() => {
              navigator.clipboard.writeText(cell.value);
            }}
            type="button"
          >
            Copy
          </button>
        ),
      },
      /* Disabled due to layout issue. Left commented in case we want to add back.
       {
        Header: 'Desktop URL',
        accessor: 'alternativeUrl',
        Cell: ({ cell }) => (
          <a href={cell.value}>{cell.value}</a>
        ),
      }, */
      {
        Header: 'Pre-typed Message',
        accessor: 'triggerText',
      },
      {
        Header: 'Response',
        accessor: 'messageText',
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ cell }) => (
          <>
            <Link
              to={{ pathname: `/smart-links/${cell.value}`, state: { id: cell.value, smartLink: { ...cell.row.values } } }}
              className="minimal-black-btn"
            >
              Edit
            </Link>
            <button
              className="minimal-red-btn"
              value={cell.value}
              onClick={() => setSmartLinkToDelete({ ...cell.row.values })}
              type="button"
            >
              Delete
            </button>
          </>
        ),
      },
      {
        Header: 'Live',
        accessor: 'isActive',
        Cell: ({ cell }) => {
          const isThisToggleProcessing = cell?.row?.original?.isToggling;

          return (
            <>
              <div
                style={{ display: 'inline-block' }}
                className="mr-2 mt-1"
              >
                <BPToggle
                  active={cell.value}
                  disabled={isThisToggleProcessing}
                  handleClick={() => handleToggle(cell)}
                />
              </div>
              { isThisToggleProcessing && (
                <div
                  className="spinner-border"
                  role="status"
                  style={{ width: 23, height: 23 }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: 'Opt-ins',
        accessor: 'optedInCount',
      },
    ],
    [handleToggle],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: tableData,
    getRowId: (row) => row.id,
  });

  const handleConfirmedDelete = async (smartLinkId) => {
    await deleteSmartLinkMutation.mutateAsync(smartLinkId);
  };

  const getClassNamesStringFromColumn = (column) => {
    const { id: columnId } = column;

    switch (columnId) {
      case 'id':
      case 'triggerText':
        return 'always-wide-column';
      case 'messageText':
        return 'never-too-wide-column';
      case 'optedInCount':
        return 'always-wideish-column';
      case 'alternativeUrl':
        return 'link-container';
      default:
        return null;
    }
  };

  const getCellClassNameStringFromCell = (cell) => {
    const { column } = cell;

    return getClassNamesStringFromColumn(column);
  };

  return (
    <>
      <table {...getTableProps()} className="table table-hover">
        <thead className="thead-dark">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([{
                    className: getClassNamesStringFromColumn(column),
                  }])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps([{
                      className: getCellClassNameStringFromCell(cell),
                    }])}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        {smartLinkToDelete && (
          <DeletionModal
            smartLink={smartLinkToDelete}
            isOpen={!!smartLinkToDelete}
            isDeleting={deleteSmartLinkMutation.isLoading}
            close={setSmartLinkToDelete}
            handleDeleteSubmit={handleConfirmedDelete}
          />
        )}
      </table>
    </>
  );
};

export default Table;
