import React from 'react';
import {
  APIServiceConstants,
  BlueprintJSONAPI,
  APIMethods,
} from '../../redux/actions/BlueprintAPI';

const { SERVICE } = APIServiceConstants;

const switchMerchant = async (merchantId) => {
  await BlueprintJSONAPI(APIMethods.POST, SERVICE, 'switchActiveMerchantForUser', {
    merchantId,
  });
  // Reload the page to ensure all caches and state reset
  window.location.href = '/';
};

const ProfileDropdownStore = (props) => {
  const { merchant } = props;
  return (
    <li key={merchant.id} onClick={() => switchMerchant(merchant.id)} role="menuitem">
      <img src="https://static.blueprint.store/images/platforms/shopify.png" alt="Shopify" />
      <div>
        <h4>{ merchant.name }</h4>
        <small>{ merchant.domain }</small>
      </div>
    </li>
  );
};

export const ProfileDropdown = (props) => {
  const { userData = {} } = props;
  if (!userData?.id) return <></>;
  const merchantPermissions = userData?.merchantPermissions || [];
  return (
    <div className="profile">
      <div className="details">
        <div>
          <img
            src="https://static.blueprint.store/images/bp-default-user.png"
            alt="BP User"
            className="bp-user"
          />
        </div>
        <div className="info">
          <span className="name">{userData.name}</span>
          <span className="email">{userData.email}</span>
        </div>
      </div>
      <div className="profile-dropdown">
        <ul>
          {merchantPermissions.map((mp) => (
            <ProfileDropdownStore
              merchant={mp.merchant}
              key={mp.merchant.id}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProfileDropdown;
