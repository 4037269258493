import React, { useEffect } from 'react';
import LogRocket from 'logrocket';
import { useSelector } from 'react-redux';

/** @typedef {import('../../redux/reducers').RootState} RootState */

// This does not really need to be a component other than it needs to use the...
// Provider and useSelector magic
const LogRocketAnalytics = () => {
  const {
    userData,
    userLoaded,
    merchantData,
  } = useSelector((/** @type RootState} */ state) => state.MerchantReducer);

  const LOGROCKET_KEY = process.env.REACT_APP_LOGROCKET_PROJECT;

  useEffect(() => {
    if (LOGROCKET_KEY) {
      LogRocket.init(LOGROCKET_KEY);
    }
  },
  [LOGROCKET_KEY]);

  useEffect(() => {
    if (LOGROCKET_KEY && userLoaded) {
      LogRocket.identify(`${userData.id}`, {
        name: userData.name,
        email: userData.email,
        // Custom fields for BP merchant
        blueprintMerchantId: `${merchantData.id}`,
        blueprintMerchantName: merchantData.company,
      });
    }
  },
  [userLoaded, userData, merchantData, LOGROCKET_KEY]);

  return <></>;
};

export default LogRocketAnalytics;
