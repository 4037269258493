import React from 'react';

export default ({ completed, file, url }) => (
  <div className="upload-info">
    {completed && <img className="uploaded-image" src={url} alt="uploaded" />}

    <div>
      <p className="upload-name">
        {completed ? 'Uploaded' : 'Uploading'}
        {' '}
        :
        <span>{file.name}</span>
      </p>
    </div>
  </div>
);
