import React from 'react';
import {
  Route,
  Switch,
} from 'react-router-dom';

import { PageBuilder } from '../components/global/PageBuilder';
import { SEO } from '../components/global/SEO';

import { Summary, Wizard } from '../components/main/CustomFlows';

const CustomFlowsPage = () => (
  <PageBuilder>
    <SEO title="Automations | Blueprint" />
    <div className="a-view custom-flows">
      <div className="container">
        <Switch>
          <Route
            exact
            path="/automations"
            component={Summary}
          />
          <Route
            exact
            path="/automations/create"
            component={Wizard}
          />
          <Route
            exact
            path="/automations/:id?"
            component={Wizard}
          />
        </Switch>
      </div>
    </div>
  </PageBuilder>
);

export default CustomFlowsPage;
