import React, { memo, useCallback } from 'react';

const OCCURRENCE_MODE = {
  at_least_once: 'at_least_once',
  more_than_once: 'more_than_once',
  exactly_once: 'exactly_once',
  more_than: 'more_than',
  less_than: 'less_than',
  greater_than_or_equal: 'greater_than_or_equal',
  less_than_or_equal: 'less_than_or_equal',
};

const OCCURRENCE_ATTRIBUTES = {
  ntimes: 'ntimes',
};

const Occurrence = ({ value, onChange }) => {
  const handleOnChange = useCallback(
    (event) => {
      const newMode = event.target.value;
      const obj = {
        mode: newMode,
      };

      switch (newMode) {
        case OCCURRENCE_MODE.more_than:
        case OCCURRENCE_MODE.less_than:
        case OCCURRENCE_MODE.greater_than_or_equal:
        case OCCURRENCE_MODE.less_than_or_equal:
          obj.ntimes = 1;
          break;
        default:
          break;
      }

      onChange(obj);
    },
    [onChange],
  );

  const updateProperty = useCallback(
    (event) => {
      onChange({
        mode: value.mode,
        [event.target.name]: [event.target.value],
      });
    },
    [onChange, value.mode],
  );

  return (
    <div className="subquery">
      <select
        className="form-control"
        value={value.mode}
        onChange={handleOnChange}
      >
        <option value={OCCURRENCE_MODE.at_least_once}>At least once</option>
        <option value={OCCURRENCE_MODE.more_than_once}>More than once</option>
        <option value={OCCURRENCE_MODE.exactly_once}>Exactly once</option>
        <option value={OCCURRENCE_MODE.more_than}>More than N times</option>
        <option value={OCCURRENCE_MODE.greater_than_or_equal}>N or more times</option>
        <option value={OCCURRENCE_MODE.less_than}>Less than N times</option>
        <option value={OCCURRENCE_MODE.less_than_or_equal}>N or less times</option>
      </select>

      {[OCCURRENCE_MODE.more_than, OCCURRENCE_MODE.less_than,
        OCCURRENCE_MODE.greater_than_or_equal, OCCURRENCE_MODE.less_than_or_equal].includes(
        value.mode,
      ) && (
        <input
          className="form-control"
          placeholder="times"
          value={value[OCCURRENCE_ATTRIBUTES.ntimes]}
          name={OCCURRENCE_ATTRIBUTES.ntimes}
          onChange={updateProperty}
        />
      )}
    </div>
  );
};

export default memo(Occurrence);
