import React, { memo, useMemo } from 'react';
import { usePagination, useTable } from 'react-table';
import Pagination from '../../components/main/Pagination';
import { getMessageLabelFromType } from '../../utils/messageType';
import { getSentimentIcons } from '../../utils/messageSentiment';
import { formatDate } from '../../utils/dates';

const initialPageSize = 8;
const columns = [
  {
    Header: 'Received On',
    accessor: 'date',
  },
  {
    Header: 'Phone Number',
    accessor: 'phone',
  },
  {
    Header: 'Name',
    accessor: 'fullName',
  },
  {
    Header: 'Orders',
    accessor: 'ordersCount',
  },
  {
    Header: 'Lifetime Revenue',
    accessor: 'ltv',
  },
  {
    Header: 'Reply',
    accessor: 'message',
  },
  {
    Header: 'Sentiment',
    accessor: 'score',
  },
  {
    Header: 'Previous Message Type',
    accessor: 'previousMessageType',
  },
  {
    Header: 'Previous Message',
    accessor: 'previousMessage',
  },
];

function NoResults({ columnSize }) {
  return (
    <tr>
      <td colSpan={columnSize} className="noResults">
        No results found
      </td>
    </tr>
  );
}

const RepliesTable = ({
  fetchMessages, loading, merchantCurrency, messages, totalCount,
}) => {
  const data = useMemo(
    () => messages.map((m) => ({
      date: formatDate(m.createdAt),
      phone: m.phone,
      fullName: m.fullName,
      ordersCount: m.ordersCount,
      ltv: `${merchantCurrency}${Math.round(m.ltv)}`,
      message: m.message,
      score: getSentimentIcons(m.score),
      previousMessage: m.previousMessage,
      previousMessageType: getMessageLabelFromType(m.previousMessageType),
    })),
    [merchantCurrency, messages],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
      },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / initialPageSize),
    },
    usePagination,
  );

  const {
    columns: tableColumns,
    canPreviousPage,
    canNextPage,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const columnSize = useMemo(() => tableColumns.filter((column) => column.isVisible).length, [tableColumns]);

  React.useEffect(() => {
    fetchMessages(pageSize, pageIndex * pageSize);
  }, [pageIndex, pageSize, fetchMessages]);

  return (
    <div className="row">
      <div className="col-md-12">
        {/* eslint-disable react/jsx-key */}
        <table
          {...getTableProps()}
          className="table table-bordered table-hover table-responsive-lg bp-table bp-table-dark replies"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.length === 0 ? (
              <NoResults columnSize={columnSize} />
            ) : (
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        {/* eslint-enable react/jsx-key */}

        <Pagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          goToPreviousPage={previousPage}
          goToNextPage={nextPage}
          pageIndex={pageIndex}
          pageSize={pageSize}
          loading={loading}
          totalCount={totalCount}
        />
      </div>
    </div>
  );
};

export default memo(RepliesTable);
