import { DEFAULT_AWAY_OFFLINE } from '../../utils/settings';
import { getPasswordStrengthErrors } from './passwordValidation';

export const brandDetailsValidate = (values) => {
  const errors = {};

  if (values.quietHoursEnabled || values.awayHoursEnabled) {
    if (!values.timezone) {
      errors.timezone = 'Select your timezone';
    }
  }

  if (values.quietHoursEnabled) {
    if (!values.quietStartHourInMerchantTimezone) {
      errors.quietStartHourInMerchantTimezone = 'Select a time';
    }
    if (!values.quietEndHourInMerchantTimezone) {
      errors.quietEndHourInMerchantTimezone = 'Select a time';
    }
  }

  if (values.awayHoursEnabled) {
    if (!values.awayHoursContent) {
      errors.awayHoursContent = 'Enter content for your out-of-hours message';
    }
  }

  if (values.awayHours) {
    const awayErrors = [];

    values.awayHours.forEach(({ startsAt, endsAt }, index) => {
      if (
        startsAt
        && endsAt
        && startsAt !== DEFAULT_AWAY_OFFLINE
        && startsAt >= endsAt
      ) {
        awayErrors[index] = {
          startsAt: 'Start time must be before the end time',
        };
      }
    });

    if (awayErrors.length) {
      errors.awayHours = awayErrors;
    }
  }

  return errors;
};

export const updatePasswordValidate = (values) => {
  const errors = {};
  if (!values.currentPassword) {
    errors.currentPassword = 'Required';
  } else {
    const fieldErrors = getPasswordStrengthErrors(values.currentPassword);
    if (fieldErrors) [errors.currentPassword] = Object.values(fieldErrors);
  }

  if (!values.newPassword) {
    errors.newPassword = 'Required';
  } else {
    const fieldErrors = getPasswordStrengthErrors(values.newPassword);
    if (fieldErrors) [errors.newPassword] = Object.values(fieldErrors);
  }

  if (!values.confirmPassword) {
    errors.confirmPassword = 'Required';
  } else {
    const fieldErrors = getPasswordStrengthErrors(values.confirmPassword);
    if (fieldErrors) [errors.confirmPassword] = Object.values(fieldErrors);
  }

  if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords should match';
  }

  return errors;
};
