import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import { getReductedString } from '../../../utils';

const STR_LEN_FOR_FIELDS = {
  name: 18,
};

const CollapsibleText = ({ value, upsellId, type }) => {
  const [collapseObj, setCollapseObj] = useState({});

  const identifier = `${upsellId}-${type}`;

  const clickHandlerOnCollapsible = () => {
    setCollapseObj({
      ...collapseObj,
      [identifier]: collapseObj[identifier] ? !collapseObj[identifier] : true,
    });
  };

  const initialDisplay = getReductedString(value, STR_LEN_FOR_FIELDS[type]);

  return (
    <>
      <span
        className="collapsible-text"
        onClick={() => {
          clickHandlerOnCollapsible();
        }}
        role="button"
        tabIndex={0}
      >
        {collapseObj[identifier] ? '' : initialDisplay}

        <Collapse isOpened={collapseObj[identifier]}>{value}</Collapse>
      </span>
    </>
  );
};

export default CollapsibleText;
