import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

/** @typedef {import('../../components/main/DemoPhone').DemoMessage} DemoMessage */

/**
* @typedef FlowNode
* @property {String} id - Unique ID for each node
* @property {Number} entryDelay - Seconds to wait before processing this node when transitioning into it
* @property {'minutes'|'hours'|'days'} entryDelayUnit - The units used when selecting the entryDelay
* @property {'SendSMS'} type - Only SendSMS for now
* @property {String} messageContent - The text to send
* @property {String} [mediaUrl] - The mms media url
* @property {String | null} prevNodeId - NULL for the start of flow
* @property {String | null} nextNodeId - After this go to this node. NULL for end of flow
*/

// List of supported event types
export const TRIGGER_EVENT_TYPES = [
  {
    label: 'Checkout Abandoned',
    value: 'store.blueprint.checkout.CheckoutAbandoned',
    key: 'basketRules',
  },
  {
    label: 'Incoming SMS',
    value: 'store.blueprint.sms.IncomingSMS',
    key: 'incomingMessageRules',
  },
  {
    label: 'Order Placed',
    value: 'store.blueprint.order.OrderCreated',
    key: 'basketRules',
  },
  {
    label: 'Order Fulfilled',
    value: 'store.blueprint.order.OrderFulfilled',
    key: 'basketRules',
  },
  {
    label: 'New SMS subscriber',
    value: 'store.blueprint.sms.Optin',
    key: 'optinRules',
  },
  {
    label: 'Subscription: New Recurring Order',
    value: 'store.blueprint.subscription.NewCharge',
    key: 'basketRules',
  },
  {
    label: 'Subscription: New Subscriber Activated',
    value: 'store.blueprint.subscription.SubscriptionCreated',
    key: 'basketRules',
  },
  {
    label: 'Subscription: Upcoming Charge',
    value: 'store.blueprint.subscription.UpcomingCharge',
    key: 'basketRules',
  },
  {
    label: 'Subscription: Failed Payment Charge',
    value: 'store.blueprint.subscription.ChargePaymentFailed',
    key: 'basketRules',
  },
  {
    label: 'Subscription: Churned Subscriber',
    value: 'store.blueprint.subscription.LastSubscriptionCancelled',
    key: 'basketRules',
  },
  {
    label: 'Relo reorder prediction event',
    value: 'store.blueprint.prediction.CustomerPredictionSent',
    key: 'basketRules',
  },
];

/**
 * @param {Number} entryDelay
 * @param {String} messageContent
 * @param {String | null} prevNodeId
 * @param {String | null} nextNodeId
 * @returns {FlowNode}
 */
export const createNode = (entryDelay = 0, messageContent = '', prevNodeId = null, nextNodeId = null) => ({
  id: uuidv4(),
  entryDelay,
  entryDelayUnit: 'minutes',
  type: 'SendSMS',
  messageContent,
  prevNodeId,
  nextNodeId,
});

/**
 * Add a new node to the end of an existing nodeList and update
 * the final node in the existing nodeList to point to the new node.
 *
 * Return a new nodeList
 * @param {FlowNode[]} nodeList
 * @param {FlowNode} node
 * @returns {FlowNode[]}
 */
export const addNode = (nodeList, node) => {
  // Clone the last node because we are mutating it
  const lastNode = { ...nodeList[nodeList.length - 1] };

  // Clone the new node because we will mutate it
  const newNode = { ...node };

  // Assign a reference to the new node
  lastNode.nextNodeId = newNode.id;

  // Update the new node with a reference to the last node
  newNode.prevNodeId = lastNode.id;

  // Create a new nodeList, replacing only the lastNode with its clone
  const updatedNodes = nodeList.map((el) => (el.id === lastNode.id ? lastNode : el));

  // Add the new node to the new nodeList
  updatedNodes.push(newNode);

  return updatedNodes;
};

/**
 * Remove a node from a nodeList and update the surrounding nodes
 * to remove references to that node.
 *
 * Return a new nodeList
 * @param {FlowNode[]} nodeList
 * @param {FlowNode} node
 * @returns {FlowNode[]}
 */
export const removeNode = (nodeList, node) => {
  const nodeIdx = nodeList.findIndex((el) => el.id === node.id);

  const prevNode = nodeList[nodeIdx - 1];
  const nextNode = nodeList[nodeIdx + 1];

  let updatedNodes = nodeList.filter((el) => el.id !== node.id);

  /** @type {FlowNode} */
  let clonedPrevNode;
  /** @type {FlowNode} */
  let clonedNextNode;

  if (prevNode) {
    clonedPrevNode = { ...prevNode, nextNodeId: null };

    if (nextNode) {
      clonedNextNode = { ...nextNode };
      clonedPrevNode.nextNodeId = clonedNextNode.id;
      clonedNextNode.prevNodeId = clonedPrevNode.id;
    }
  } else if (nextNode) {
    clonedNextNode = { ...nextNode, prevNodeId: null };
  }

  updatedNodes = updatedNodes.map((el) => {
    if (clonedPrevNode && el.id === clonedPrevNode.id) {
      return clonedPrevNode;
    }
    if (clonedNextNode && el.id === clonedNextNode.id) {
      return clonedNextNode;
    }
    return el;
  });

  return updatedNodes;
};

/**
 * @param {FlowNode[]} nodeList
 * @returns {DemoMessage[]}
 */
export const getMessagesFromNodes = (nodeList) => nodeList.map((node) => {
  const entryDelay = Number.isNaN(node.entryDelay) ? 0 : node.entryDelay;
  const date = DateTime.now().plus({ seconds: entryDelay });

  /** @type {DemoMessage} */
  const demoMessage = {
    content: node.messageContent,
    integer: false,
    date: date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
  };

  return demoMessage;
});
