import React, { memo } from 'react';
import { UPSELL_PROD_SELECT_ALL_VARS } from '../../utils';

const shouldShowPlaceholder = (items, selectedItemId) => {
  if (!selectedItemId || !items) {
    return true;
  }

  return !items.find((item) => item.id === selectedItemId);
};

/**
 * Product and variant selector for the given list of products
 * (preferably from getAllProducts endpoint)
 *
 * @typedef Item
 * @property {Number} id Id of the item (productId or variantId)
 * @property {String} name Optional. Name of the product
 * @property {String} [variantName] Optional. Name of the variant
 */

/**
 * @param {Object}    params
 * @param {Boolean}   params.isProductSelect Whether the select is for product. False in case of variants
 * @param {Array<Item>} params.items Array of products or variants. All of them must have
 * @param {import('react').ChangeEventHandler}  params.handleChange Handler function for option change
 * @param {Number}    params.selectedItemId Currently selected item's id
 * @param {Boolean}    [params.canChooseAllVars] Whether all variants could be selected
 */
const ProdVarSelect = ({
  isProductSelect,
  items,
  handleChange,
  selectedItemId,
  canChooseAllVars = false,
}) => {
  const placeholder = isProductSelect ? 'Add Product' : 'Add Variant';

  return (
    <div className="custom-select-wrapper">
      <div className="icon-white" />
      <select
        className="custom-select"
        value={selectedItemId}
        disabled={items.length === 0}
        onChange={handleChange}
      >
        {shouldShowPlaceholder(items, selectedItemId) && (
          <option value={undefined}>{placeholder}</option>
        )}

        {canChooseAllVars && (
          <option value={UPSELL_PROD_SELECT_ALL_VARS}>All Variants</option>
        )}

        {items.map((item) => (
          <option key={item.id} value={item.id}>
            {isProductSelect ? item.name : item.variantName || 'n/a'}
          </option>
        ))}
      </select>
    </div>
  );
};

export default memo(ProdVarSelect);
