import React, {
  memo, useEffect, useState, useCallback,
} from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { SEO } from '../../global/SEO';
import { PageBuilder } from '../../global/PageBuilder';
import { wordings, settings } from '../../../utils/crossSells';
import CrossSellEditor from './CrossSellEditor';
import { UpSellsActionCreators } from '../../../redux/actions/upSells';

const { getSingleCrossSell: getSingleCrossSellAction } = UpSellsActionCreators;

const getTypeFromPath = (path) => Object.keys(settings).find(
  (type) => path.indexOf(settings[type].mainLink) > -1,
);

const THIRTY_DAYS_IN_MINUTES = 43200;

const getDefaultCrossSellInfo = (type) => ({
  name: '',
  products: [],
  triggers: [],
  messageContent: settings[type].defaultMessage,
  messageInterval: THIRTY_DAYS_IN_MINUTES,
  skipIfPurchasedBefore: false,
});

/**
 * @typedef {import('../../../redux/reducers').RootState} RootState
 */

const SingleCrossSell = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { state: stateFromLocation, pathname } = useLocation();
  const { addToast } = useToasts();

  const {
    singleCrossSellLoading,
    singleCrossSellErr,
    upsellsData,
  } = useSelector((/** @type RootState} */ state) => state.UpsellsReducer);

  const [type] = useState(stateFromLocation ? stateFromLocation.type : getTypeFromPath(pathname));
  const defaultCrossSellInfo = stateFromLocation && stateFromLocation.initialCrossSell
    ? stateFromLocation.initialCrossSell : getDefaultCrossSellInfo(type);

  const [initialCrossSell, setInitialCrossSell] = useState(
    id ? upsellsData.find((upsell) => upsell.id === parseInt(id, 10) && upsell.upsellType === type)
      : defaultCrossSellInfo,
  );

  useEffect(() => {
    if (singleCrossSellErr) {
      addToast('Could not get the cross-sell', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, singleCrossSellErr]);

  const getCrossSell = useCallback(async () => {
    const crossSell = await dispatch(getSingleCrossSellAction(id));
    if (crossSell) {
      setInitialCrossSell(crossSell);
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!initialCrossSell && id) {
      getCrossSell();
    }
  }, [id, getCrossSell, initialCrossSell]);

  const isCalledFromWizard = (stateFromLocation && stateFromLocation.referrer) && stateFromLocation.referrer.indexOf('product-report') > -1;

  const isNew = !id && (!initialCrossSell
    || JSON.stringify(initialCrossSell) === JSON.stringify(defaultCrossSellInfo)
    || pathname.indexOf('/new') > -1
    || isCalledFromWizard);

  const title = `${isNew ? 'Create' : 'Edit'} ${wordings[type].genericTerm} rule`;

  return (
    <PageBuilder>
      <SEO title="Cross-sell | Blueprint" />
      <div className="a-view single-cross-sell">
        <div className="container">
          <div className="top-bar">
            <h2 className="title">
              <Link to={{
                pathname: (stateFromLocation && stateFromLocation.referrer) || settings[type].mainLink,
              }}
              >
                {wordings[type].listName}
              </Link>
              {' '}
              {'>'}
              {' '}
              {title}
            </h2>
          </div>

          {singleCrossSellLoading && (
            <>Loading...</>
          )}

          {initialCrossSell && (
            <CrossSellEditor
              crossSell={initialCrossSell}
              isForCreating={isNew}
              type={type}
            />
          )}

          {(singleCrossSellErr || !initialCrossSell) && !singleCrossSellLoading && id && (
            <>
              <div className="alert alert-warning" role="alert">
                {`Could not get a ${wordings[type].genericTerm} by the given id.`}
                {' '}
                {singleCrossSellErr && `Error: ${singleCrossSellErr}`}
                {' '}
                <br />
                Maybe it doesn&apos;t exist. You can create a new one
                {' '}
                <Link
                  to={{ pathname: `${settings[type].mainLink}/new`, state: { type } }}
                >
                  here
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(SingleCrossSell);
