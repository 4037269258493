import React, {
  useEffect, useMemo, useCallback, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useFilters, usePagination, useSortBy, useTable,
} from 'react-table';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import currency from 'currency.js';
import Pagination from '../Pagination';

import { ContactsActionCreators } from '../../../redux/actions/contacts';
import { MerchantActionCreators } from '../../../redux/actions/merchant';

/** @typedef {import('../../../redux/reducers').RootState} RootState */

const { getContacts } = ContactsActionCreators;

/**
 * @enum {String}
 */
const CUSTOMER_STATUS = {
  CUSTOMER_STATUS_ACTIVE: 'Active',
  CUSTOMER_STATUS_CHURNED: 'Churned',
  CUSTOMER_STATUS_NO_RECORD: 'No customer record',
  CUSTOMER_STATUS_NOT_YET_PURCHASED: 'Not yet purchased',
};

/**
 * @enum {String}
 */
const MARKETING_OPTIN_STATUS = {
  MARKETING_OPTIN_STATUS_OPTED_IN: 'Opted in',
  MARKETING_OPTIN_STATUS_OPTED_OUT: 'Opted out',
  MARKETING_OPTIN_STATUS_NO_PREFERENCE: 'No preference',
};

/**
 * @enum {String}
 */
const COMMERCE_SUBSCRIPTION_STATUS = {
  COMMERCE_SUBSCRIPTION_STATUS_ACTIVE: 'Active subscriber',
  COMMERCE_SUBSCRIPTION_STATUS_INACTIVE: 'Inactive subscriber',
  COMMERCE_SUBSCRIPTION_STATUS_NOT_SUBSCRIBED: 'Not subscriber',
};

const columnIdSelectFilterMap = {
  optInStatus: {
    values: Object.values(MARKETING_OPTIN_STATUS),
    keys: Object.keys(MARKETING_OPTIN_STATUS),
  },
  customerStatus: {
    values: Object.values(CUSTOMER_STATUS),
    keys: Object.keys(CUSTOMER_STATUS),
  },
  commerceSubscriptionStatus: {
    values: Object.values(COMMERCE_SUBSCRIPTION_STATUS),
    keys: Object.keys(COMMERCE_SUBSCRIPTION_STATUS),
  },
};

function SelectColumnFilter({
  allValue,
  filterValue,
  setFilter,
  id,
  disabled,
}) {
  return (
    <div className="custom-select-wrapper">
      <div className="icon-white" />
      <select
        className="custom-select"
        value={filterValue}
        onChange={(e) => {
          setFilter(id, e.target.value || undefined);
        }}
        disabled={disabled}
      >
        <option value="">{allValue}</option>
        {columnIdSelectFilterMap[id].values.map((option, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <option key={i} value={columnIdSelectFilterMap[id].keys[i]}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

function SkeletonTableRows({ pageSize, columnSize }) {
  return (
    <>
      {new Array(pageSize).fill(null).map((_, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={idx}>
          {new Array(columnSize).fill(null).map((__, innerIdx) => (
            // eslint-disable-next-line react/no-array-index-key
            <td key={innerIdx}>-</td>
          ))}
        </tr>
      ))}
    </>
  );
}

function NoResults({ pageLength, columnSize }) {
  return pageLength <= 0 ? (
    <tr>
      <td colSpan={columnSize} className="noResults">
        No results found
      </td>
    </tr>
  ) : null;
}

function ColumnSorter({ column }) {
  if (!column.isSorted) {
    return <span />;
  }

  return (
    <span>
      {
        column.isSortedDesc
          ? ' 🔽'
          : ' 🔼'
      }
    </span>
  );
}

const Contacts = ({ optin }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    contactsLoading,
    contactsLoaded,
    contactsData,
    contactsTotalRowCount,
  } = useSelector((/** @type {RootState} */ state) => state.ContactsReducer);

  const { merchantData, merchantLoaded } = useSelector(
    (/** @type {RootState} */ state) => state.MerchantReducer,
  );

  const data = useMemo(() => contactsData, [contactsData]);

  const initialFilters = useMemo(() => {
    const filters = [];

    if (optin && optin !== 'all') {
      filters.push({
        id: 'optInStatus',
        value: 'MARKETING_OPTIN_STATUS_OPTED_IN',
      });
    }

    return filters;
  }, [optin]);

  const columns = useMemo(
    () => [
      {
        Header: 'Phone #',
        accessor: 'phone', // accessor is the "key" in the data
      },
      {
        Header: 'Name',
        accessor: 'fullName',
      },
      {
        Header: 'Marketing Optin Status',
        accessor: 'optInStatus',
        Cell: ({ cell }) => MARKETING_OPTIN_STATUS[cell.value],
      },
      {
        Header: 'Opt-in Date',
        accessor: 'optInDate',
        Cell: ({ cell }) => {
          if (!cell.value) {
            return null;
          }
          return DateTime.fromISO(cell.value).toLocaleString();
        },
      },
      {
        Header: 'Opt-out Date',
        accessor: 'optOutDate',
        Cell: ({ cell }) => {
          if (!cell.value) {
            return null;
          }
          return DateTime.fromISO(cell.value).toLocaleString();
        },
      },
      {
        Header: 'Customer Status',
        accessor: 'customerStatus',
        Cell: ({ cell }) => CUSTOMER_STATUS[cell.value],
      },
      {
        Header: 'Incoming SMS',
        accessor: 'incomingSMSCount',
      },
      {
        Header: 'Orders',
        accessor: 'orderCount',
      },
      {
        Header: 'Lifetime Revenue',
        accessor: 'totalRevenue',
        Cell: ({ cell }) => currency(cell.value, {
          symbol: merchantData.currencySymbol,
        }).format(),
      },
      {
        Header: 'Commerce Subscription Status',
        accessor: 'commerceSubscriptionStatus',
        Cell: ({ cell }) => COMMERCE_SUBSCRIPTION_STATUS[cell.value],
      },
    ],
    [merchantData],
  );

  const initialPageSize = 15;

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ['incomingSMSCount'],
        pageIndex: 0,
        pageSize: initialPageSize,
        filters: initialFilters,
      },
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      pageCount: Math.ceil(contactsTotalRowCount / initialPageSize),
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const {
    columns: tableColumns,
    canPreviousPage,
    canNextPage,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    previousPage,
    nextPage,
    setFilter,
    state: {
      filters, pageIndex, pageSize, sortBy,
    },
  } = tableInstance;

  const columnSize = useMemo(() => tableColumns.filter((column) => column.isVisible).length, [tableColumns]);

  useEffect(() => {
    if (!merchantLoaded) {
      return;
    }

    const [activeSort] = sortBy;

    dispatch(getContacts({
      filters, pageIndex, pageSize, sortBy: activeSort,
    }));
  }, [filters, pageIndex, pageSize, sortBy, merchantLoaded, dispatch]);

  useEffect(() => {
    if (!merchantLoaded) {
      const { getMerchantData } = MerchantActionCreators;
      dispatch(getMerchantData());
    }
  }, [dispatch, merchantData, merchantLoaded]);

  const getFilterValue = useCallback(
    (currentFilters, id) => (currentFilters.find((filter) => filter.id === id) || { value: '' })
      .value,
    [],
  );

  /** @type {React.MutableRefObject<HTMLInputElement?>} */
  const textInput = useRef(null);

  const handleRowClick = useCallback((row) => {
    history.push(`/messaging?id=${row.customerPhoneId}`);
  }, [history]);

  return (
    <>
      <form>
        <div className="d-flex mb-2">
          <div className="mr-2">
            <input
              className="form-control"
              placeholder="Name or number search"
              ref={textInput}
            />
            <input
              type="submit"
              value="Search"
              onClick={() => {
                setFilter('fullName', textInput?.current?.value);
              }}
              disabled={contactsLoading}
              className="btn btn-primary ml-2"
            />
          </div>
          <div className="mr-2">
            <SelectColumnFilter
              filterValue={getFilterValue(filters, 'optInStatus')}
              setFilter={setFilter}
              allValue="All optin statuses"
              id="optInStatus"
              disabled={contactsLoading}
            />
          </div>
          <div className="mr-2">
            <SelectColumnFilter
              filterValue={getFilterValue(filters, 'customerStatus')}
              setFilter={setFilter}
              allValue="All customer statuses"
              id="customerStatus"
              disabled={contactsLoading}
            />
          </div>
          <div className="mr-2">
            <SelectColumnFilter
              filterValue={getFilterValue(filters, 'commerceSubscriptionStatus')}
              setFilter={setFilter}
              allValue="Commerce subscription statuses"
              id="commerceSubscriptionStatus"
              disabled={contactsLoading}
            />
          </div>
        </div>
      </form>
      <div className="row">
        <div className="col-md-12">
          {
            // all of the props getters here include a key, but eslint can't know that.
            /* eslint-disable react/jsx-key */
          }
          <table
            {...getTableProps()}
            className="table table-bordered table-hover table-responsive-lg bp-table bp-table-dark"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <ColumnSorter column={column} />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {contactsLoaded && !contactsLoading
                ? (
                  <>
                    <NoResults pageLength={page.length} columnSize={columnSize} />
                    { page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                          onClick={(e) => {
                            e.preventDefault();
                            return handleRowClick(row.original);
                          }}
                        >
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </>
                ) : <SkeletonTableRows pageSize={pageSize} columnSize={columnSize} />}
            </tbody>
          </table>
        </div>
      </div>

      {/* eslint-enable react/jsx-key */}
      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        goToPreviousPage={previousPage}
        goToNextPage={nextPage}
        pageIndex={pageIndex}
        pageSize={pageSize}
        loading={contactsLoading}
        totalCount={contactsTotalRowCount}
      />
    </>
  );
};

export default Contacts;
