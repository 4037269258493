import {
  BlueprintJSONAPI, APIMethods, APIServiceConstants,
} from '../../redux/actions/BlueprintAPI';

/**
 * @param {Number} smartlinkId
 * @returns
 */
const deleteSmartLink = async (smartlinkId) => {
  const response = await BlueprintJSONAPI(
    APIMethods.DELETE,
    APIServiceConstants.SERVICE,
    `smartlinks/${smartlinkId}`,
  );

  return response?.data;
};

export default deleteSmartLink;
