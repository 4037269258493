export const CUSTOMER_MESSAGE_PLACEHOLDERS = {
  first_name: 'first_name',
  link_to_checkout: 'link_to_checkout',
  brand_name: 'brand_name',
  recommended_products: 'recommended_products',
  replenishment_products: 'replenishment_products',
  upsell_products: 'upsell_products',
  portal_link: 'portal_link',
  portal_link_add: 'portal_link_add',
};

export const GROUP_TYPE = {
  LIST: 0,
  SEGMENT: 1,
};

export const GROUP_MESSAGE_STATUS = {
  COMPLETED: 'COMPLETED',
  ERRORED: 'ERRORED',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
};

export const CONVERSATION_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  GROUP: 'GROUP',
};
