import {
  TYPE_ORDER_CONFIRMED,
  TYPE_FIRST_ORDER_CONFIRMED,
  TYPE_POST_ORDER_CHECKIN,
  TYPE_REORDER_PROMPT,
  TYPE_ORDER_FULFILLED,
  TYPE_RECHARGE_NEW_SUBSCRIPTION,
  TYPE_RECHARGE_NEW_SUBSCRIPTION_AFTER_X_DAYS,
  TYPE_RECHARGE_UPCOMING_CHARGE,
  TYPE_RECHARGE_RECURRING_CHARGE,
} from '../../../utils/messageTemplateTypes';

export const INTEGRATION_RECHARGE = 'recharge';
export const INTEGRATION_SMARTRR = 'smartrr';
export const INTEGRATION_BOLD = 'bold';

export const SKIPPABLE_INTEGRATIONS = [INTEGRATION_RECHARGE, INTEGRATION_SMARTRR, INTEGRATION_BOLD];

export const getTemplatesByType = (automatedMessageTemplates) => ({
  [TYPE_ORDER_CONFIRMED]: automatedMessageTemplates.find(
    (mt) => mt.messageType === TYPE_ORDER_CONFIRMED,
  ),
  [TYPE_FIRST_ORDER_CONFIRMED]: automatedMessageTemplates.find(
    (mt) => mt.messageType === TYPE_FIRST_ORDER_CONFIRMED,
  ),
  [TYPE_POST_ORDER_CHECKIN]: automatedMessageTemplates.find(
    (mt) => mt.messageType === TYPE_POST_ORDER_CHECKIN,
  ),
  [TYPE_REORDER_PROMPT]: automatedMessageTemplates.find(
    (mt) => mt.messageType === TYPE_REORDER_PROMPT,
  ),
  [TYPE_ORDER_FULFILLED]: automatedMessageTemplates.find(
    (mt) => mt.messageType === TYPE_ORDER_FULFILLED,
  ),
  [TYPE_RECHARGE_NEW_SUBSCRIPTION]: automatedMessageTemplates.find(
    (mt) => mt.messageType === TYPE_RECHARGE_NEW_SUBSCRIPTION,
  ),
  [TYPE_RECHARGE_NEW_SUBSCRIPTION_AFTER_X_DAYS]: automatedMessageTemplates.find(
    (mt) => mt.messageType === TYPE_RECHARGE_NEW_SUBSCRIPTION_AFTER_X_DAYS,
  ),
  [TYPE_RECHARGE_UPCOMING_CHARGE]: automatedMessageTemplates.find(
    (mt) => mt.messageType === TYPE_RECHARGE_UPCOMING_CHARGE,
  ),
  [TYPE_RECHARGE_RECURRING_CHARGE]: automatedMessageTemplates.find(
    (mt) => mt.messageType === TYPE_RECHARGE_RECURRING_CHARGE,
  ),
});

export const getDemoMessages = (automatedMessageTemplates) => {
  const templates = getTemplatesByType(automatedMessageTemplates);
  const messages = [];
  if (
    templates[TYPE_ORDER_CONFIRMED]
    && templates[TYPE_ORDER_CONFIRMED].active
  ) {
    messages.push({
      content: templates[TYPE_ORDER_CONFIRMED].messageContent,
      date: '03/02 - 9AM',
    });
  }

  if (
    templates[TYPE_FIRST_ORDER_CONFIRMED]
    && templates[TYPE_FIRST_ORDER_CONFIRMED].active
  ) {
    messages.push({
      content: templates[TYPE_FIRST_ORDER_CONFIRMED].messageContent,
      date: '03/02 - 9AM',
    });
  }

  if (
    templates[TYPE_ORDER_FULFILLED]
    && templates[TYPE_ORDER_FULFILLED].active
  ) {
    messages.push({
      content: templates[TYPE_ORDER_FULFILLED].messageContent,
      date: '03/02 - 9AM',
    });
  }

  if (
    templates[TYPE_POST_ORDER_CHECKIN]
    && templates[TYPE_POST_ORDER_CHECKIN].active
  ) {
    messages.push({
      content: templates[TYPE_POST_ORDER_CHECKIN].messageContent,
      date: '03/02 - 9AM',
    });
  }

  if (templates[TYPE_REORDER_PROMPT] && templates[TYPE_REORDER_PROMPT].active) {
    messages.push({
      content: templates[TYPE_REORDER_PROMPT].messageContent,
      date: '03/02 - 9AM',
    });
  }

  if (
    templates[TYPE_RECHARGE_NEW_SUBSCRIPTION]
    && templates[TYPE_RECHARGE_NEW_SUBSCRIPTION].active
  ) {
    messages.push({
      content: templates[TYPE_RECHARGE_NEW_SUBSCRIPTION].messageContent,
      date: '03/02 - 9AM',
    });
  }

  if (
    templates[TYPE_RECHARGE_RECURRING_CHARGE]
    && templates[TYPE_RECHARGE_RECURRING_CHARGE].active
  ) {
    messages.push({
      content: templates[TYPE_RECHARGE_RECURRING_CHARGE].messageContent,
      date: '03/02 - 9AM',
    });
  }

  if (
    templates[TYPE_RECHARGE_UPCOMING_CHARGE]
    && templates[TYPE_RECHARGE_UPCOMING_CHARGE].active
  ) {
    messages.push({
      content: templates[TYPE_RECHARGE_UPCOMING_CHARGE].messageContent,
      date: '03/02 - 9AM',
    });
  }

  if (
    templates[TYPE_RECHARGE_NEW_SUBSCRIPTION_AFTER_X_DAYS]
    && templates[TYPE_RECHARGE_NEW_SUBSCRIPTION_AFTER_X_DAYS].active
  ) {
    messages.push({
      content:
        templates[TYPE_RECHARGE_NEW_SUBSCRIPTION_AFTER_X_DAYS].messageContent,
      date: '03/02 - 9AM',
    });
  }
  return messages;
};

export const mapMessageTemplateToSave = ({
  id,
  messageContent,
  messageInterval,
  messageType,
  active,
}) => ({
  id,
  messageContent,
  messageInterval,
  messageType,
  active,
});

export const filterForType = (type) => (template) => template.type === type;
