import React from 'react';
import { Sidebar } from './Sidebar';

export const PageBuilder = ({ noCall = false, children }) => (
  <div className="page-builder">
    <Sidebar noCall={noCall} />
    <div className="content">{children}</div>
  </div>
);

export default PageBuilder;
