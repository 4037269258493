import React from 'react';

export default ({ loading, count }) => {
  if (loading || !count || !Number.isInteger(count)) {
    return null;
  }

  const subscribers = count > 1 ? 'subscribers' : 'subscriber';

  return (
    <div className="subscriber-count-wrapper">
      <div className="subscriber-count">
        <span className="icon-count">
          <img src="/images/user.png" alt="user" />
        </span>
        <span className="total-amount">
          {count}
          {' '}
          {subscribers}
        </span>
      </div>
    </div>
  );
};
