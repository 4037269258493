import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import {
  useHistory,
} from 'react-router-dom';
import {
  useQuery,
  useQueryClient,
  useMutation,
} from 'react-query';

import { getCustomDomain } from '../../../../queries/CustomDomain';
import { enableCloudfrontDistribution, disableCloudfrontDistribution, deleteCustomDomain } from '../../../../mutations/CustomDomain';

const defaultValues = {
  showDNSConfiguration: false,
  deleteCustomDomain: false,
  enableCustomDomain: false,
  disableCustomDomain: false,
};

const Status = ({
  formRootUrl,
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const deletionMutation = useMutation(deleteCustomDomain);

  const { isLoading: deletionMutationLoading } = deletionMutation;

  const { data } = useQuery(
    'customDomain',
    getCustomDomain,
    {
      enabled: !deletionMutationLoading,
      refetchOnMount: false,
      // Refetch data every 10 seconds to continue checking on certificate validation status
      refetchInterval: 10000,
    },
  );

  const enableMutation = useMutation(enableCloudfrontDistribution);

  const disableMutation = useMutation(disableCloudfrontDistribution);

  const {
    handleSubmit, formState, setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { isValid, isSubmitting } = formState;

  const handleFormSubmit = async (formData) => {
    if (formData.showDNSConfiguration) {
      return history.push(`${formRootUrl}/review`);
    }

    if (formData.deleteCustomDomain) {
      await deletionMutation.mutateAsync(data.Id);
      await queryClient.invalidateQueries('customDomain');
      return history.push(formRootUrl);
    }

    if (formData.enableCustomDomain) {
      await enableMutation.mutateAsync(data.Id);
      await queryClient.invalidateQueries('customDomain');
    }

    if (formData.disableCustomDomain) {
      await disableMutation.mutateAsync(data.Id);
      await queryClient.invalidateQueries('customDomain');
    }

    return null;
  };

  /**
   * Handle an edge case where the custom domain query returns
   * `null` after the deletionMutation has completed, even though
   * we invalidate the query, and push a history change during
   * the handleFormSubmit handler.
   *
   * I think there might be a race condition with the `enabled`
   * property of the custom domain query being based on the
   * `deletionMutationLoading` state of the mutation whereby
   * the mutation completes and then the query refetches before
   * being invalidated and then returns `null` before the history
   * push happens.
   */
  if (!data) {
    return history.push(formRootUrl);
  }

  const isValidationPending = data.ValidationStatus === 'PENDING_VALIDATION';
  const isValidationFailed = data.ValidationStatus === 'FAILED';
  const isValidationSuccess = data.ValidationStatus === 'SUCCESS';
  const isDistributionPending = data.DistributionStatus === 'InProgress';
  const isDistributionSuccess = data.DistributionStatus === 'Deployed';
  const isDistributionEnabled = data.DistributionEnabled;

  const isWaiting = isValidationPending || isDistributionPending;
  const isFailed = isValidationFailed;
  const isSuccess = isValidationSuccess && isDistributionSuccess && isDistributionEnabled;
  const isDeployedButDisabled = isValidationSuccess && isDistributionSuccess && !isDistributionEnabled;

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {isWaiting && (
        <>
          <p className="section-title">Pending changes</p>
          <small>
            This step may take some time. We&apos;ll keep checking on the status.
            <br />
            <br />
            Once complete your links will work immediately.
          </small>
          <div className="d-flex justify-content-center mt-4">
            <button
              type="submit"
              className="btn btn-primary mr-2"
              disabled={isSubmitting || !isValid}
              onClick={() => {
                setValue('showDNSConfiguration', true);
              }}
            >
              Show DNS Configuration
            </button>
          </div>
        </>
      )}
      {isSuccess && (
        <>
          <p className="section-title">Active</p>
          <small>We&apos;ve detected that your custom domain is configured correctly.</small>
          <div className="d-flex justify-content-center mt-4">
            <button
              type="submit"
              className="btn btn-primary mr-2"
              disabled={isSubmitting || !isValid}
              onClick={() => {
                setValue('disableCustomDomain', true);
              }}
            >
              Disable Custom Domain
            </button>
          </div>
        </>
      )}
      {isDeployedButDisabled && (
        <>
          <p className="section-title">Disabled</p>
          <small>
            Your custom domain is currently disabled.
            You can either re-enable it, or fully delete.

          </small>
          <div className="d-flex justify-content-center mt-4">
            <button
              type="submit"
              className="btn btn-primary mr-2"
              disabled={isSubmitting || !isValid}
              onClick={() => {
                setValue('enableCustomDomain', true);
              }}
            >
              Enable Custom Domain
            </button>
            <button
              type="submit"
              className="btn btn-primary mr-2"
              disabled={isSubmitting || !isValid}
              onClick={() => {
                setValue('deleteCustomDomain', true);
              }}
            >
              Delete Custom Domain
            </button>
          </div>
        </>
      )}
      {isFailed && (
        <>
          <p className="section-title">Failed</p>
          <small>
            There was a problem setting up your custom domain.
            You can try again, or contact Blueprint support.
            Error:
            {' '}
            {data.FailureReason}
          </small>
          <div className="d-flex justify-content-center mt-4">
            <button
              type="submit"
              className="btn btn-primary mr-2"
              disabled={isSubmitting || !isValid}
              onClick={() => {
                setValue('deleteCustomDomain', true);
              }}
            >
              Restart Setup Process
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default memo(Status);
