import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { MerchantActionCreators } from '../../redux/actions/merchant';

class RolesHOC extends Component {
  async componentDidMount() {
    const { userData, actions } = this.props;
    if (JSON.stringify(userData) === '{}') {
      await actions.getUserData();
    }
  }

  render() {
    const { children, allowedRoles, userData } = this.props;

    return allowedRoles.includes(userData.role) ? (
      children
    ) : (
      <div>Sorry you don&apos;t have the correct permissions to access this.</div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.MerchantReducer.userData,
  userErr: state.MerchantReducer.userErr,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(MerchantActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(RolesHOC),
);
