import React from 'react';
import { PageBuilder } from '../components/global/PageBuilder';
import { SEO } from '../components/global/SEO';
import UpSells from '../components/main/UpSells';

export const UpSellsPage = () => (
  <PageBuilder>
    <SEO title="Upsells | Blueprint" />
    <div className="a-view up-sells">
      <div className="container">
        <UpSells />
      </div>
    </div>
  </PageBuilder>
);

export default UpSellsPage;
