import React from 'react';

export default () => (
  <div className="instruction-container">
    <div className="row">
      <div className="col-md-3">
        <h2 className="title">Upsell</h2>
      </div>
      <div className="col-md-9">
        <p>
          Upsell messages are sent when a customer makes a purchase of a trigger
          product(s). Once a purchase of a trigger product is detected, an
          upsell message is scheduled to send with the message contents and
          upsell products in a one-click payment link.
        </p>
        <p>
          If a purchase matches multiple triggers, the one with the highest
          priority will be sent. Only one upsell message is sent for each
          trigger purchase per customer.
        </p>
      </div>
    </div>
  </div>
);
