import React from 'react';
import { BImage } from './BImage';

export const Logo = (props) => {
  const { small = false } = props;
  return (
    <BImage
      src={
        small ? '/images/logo.svg' : 'https://static.blueprint.store/logo.svg'
      }
      className="logo"
      alt="Blueprint"
    />
  );
};

export default Logo;
