import React, { useState, useEffect, useCallback } from 'react';
import { SEO } from '../../../components/global/SEO';
import { PageBuilder } from '../../../components/global/PageBuilder';
import {
  API,
  APIMethods,
  APIServiceConstants,
} from '../../../redux/actions/BlueprintAPI';
import { DateRangeSelect, DEFAULT_RANGES } from '../DateRangeSelect';
import Pagination from '../../../components/global/Pagination';
import AttributedOrdersTable from './AttributedOrdersTable';

const LOADINGSTATUS_LOADING = 'LOADING';
const LOADINGSTATUS_COMPLETE = 'COMPLETE';
const LOADINGSTATUS_ERROR = 'ERROR';

const ROWS_PER_PAGE = 15;
const DEFAULT_DATE_RANGE = {
  start: DEFAULT_RANGES['Last 28 Days'][0],
  end: DEFAULT_RANGES['Last 28 Days'][1],
};

// There is probably a better way to do this....
const LoadingInfo = ({ loadingStatus }) => {
  if (loadingStatus === LOADINGSTATUS_LOADING) {
    return <p>Loading...</p>;
  } if (loadingStatus === LOADINGSTATUS_ERROR) {
    return (
      <p>
        Error loading.&nbsp;
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#" onClick={window.location.reload}>
          Click to retry.
        </a>
      </p>
    );
  }
  return null;
};

const AttributedOrdersPage = () => {
  const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [viewData, setViewData] = useState([]);
  const [platformUrl, setPlatformUrl] = useState('');

  const [loadingStatus, setLoadingStatus] = useState(LOADINGSTATUS_LOADING);

  const setDateRangeChanged = useCallback(
    (newDateRange) => {
      setPageNumber(1);
      setTotalRows(0);
      setDateRange(newDateRange);
    },
    [setPageNumber, setTotalRows, setDateRange],
  );

  const pageChanged = useCallback(
    (newPageNumber) => {
      setPageNumber(newPageNumber);
    },
    [setPageNumber],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API(
          APIMethods.GET,
          APIServiceConstants.SERVICE,
          'getPlatformUrl',
        );
        setPlatformUrl(response.data.url);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setLoadingStatus(LOADINGSTATUS_LOADING);

    setViewData([]);

    const fetchData = async () => {
      try {
        const response = await API(
          APIMethods.POST,
          APIServiceConstants.ANALYTICS,
          'attributedOrders',
          {
            dateFrom: dateRange.start,
            dateTo: dateRange.end,
            datePeriod: 'custom',
            page: pageNumber,
            countPerPage: ROWS_PER_PAGE,
          },
        );

        setLoadingStatus(LOADINGSTATUS_COMPLETE);
        setViewData(response.data.rows);
        setTotalRows(response.data.total);
        return response;
      } catch (e) {
        setLoadingStatus(LOADINGSTATUS_ERROR);
        console.error(e);
      }

      return null;
    };

    fetchData();
  }, [dateRange, pageNumber]);

  return (
    <PageBuilder>
      <SEO title="Attributed Orders | Blueprint" />
      <div className="a-view sms-analytics">
        <div className="container">
          <div className="top-bar">
            <h2 className="title">
              <a href="/sms-analytics" className="title-breadcrumb">
                Messaging Analytics
              </a>
              {' '}
              &gt; Attributed Orders
            </h2>
            <div className="dates-container">
              <DateRangeSelect
                value={dateRange}
                onChange={setDateRangeChanged}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <AttributedOrdersTable
                items={viewData}
                platformUrl={platformUrl}
              />
              <LoadingInfo loadingStatus={loadingStatus} />
              {loadingStatus === LOADINGSTATUS_COMPLETE && totalRows === 0 && (
                <p>No data</p>
              )}
              <Pagination
                current={pageNumber}
                total={totalRows}
                perPage={ROWS_PER_PAGE}
                onChange={pageChanged}
              />
            </div>
          </div>
        </div>
      </div>
    </PageBuilder>
  );
};

export default AttributedOrdersPage;
