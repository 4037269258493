import React, { memo } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

const CreateGroupModal = ({ isOpen, close }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={close}
    className="modal message-group create-message-group"
    overlayClassName="modal-overlay"
    contentLabel="Choose group type"
  >
    <div className="create-new-message-group">
      <span className="close" onClick={close} role="button" tabIndex={0}>
        Close
      </span>

      <h3 className="title">Choose group type</h3>

      <br />

      <div className="row">
        <div className="col-md-8">
          <h4>Segment</h4>
          <p>Filter your Blueprint customers into a dynamic segment</p>
        </div>

        <div className="col-md-4 d-flex justify-content-end align-items-center">
          <Link
            to="/group-messaging/segments"
            className="btn btn-outline-secondary"
          >
            Select
          </Link>
        </div>
      </div>

      <hr className="divider" />

      <div className="row">
        <div className="col-md-8">
          <h4>List</h4>
          <p>A fixed list of subscribers manually selected</p>
        </div>

        <div className="col-md-4 d-flex justify-content-end align-items-center">
          <Link
            to="/group-messaging/lists"
            className="btn btn-outline-secondary"
          >
            Select
          </Link>
        </div>
      </div>
    </div>
  </Modal>
);

export default memo(CreateGroupModal);
