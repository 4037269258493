import React, {
  memo, useEffect, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { MerchantActionCreators } from '../redux/actions/merchant';
import { SEO } from '../components/global/SEO';
import { PageBuilder } from '../components/global/PageBuilder';
import { DashInfoCard } from '../components/main/DashInfoCard';

const {
  getMerchantData: getMerchantDataAction,
  getSubscriptionAnalytics: getSubscriptionAnalyticsAction,
} = MerchantActionCreators;

const Analytics = () => {
  const { addToast } = useToasts();
  const {
    merchantData,
    merchantLoaded,
    subscriptionAnalyticsLoaded,
    subscriptionAnalyticsError,
    customerCountBySubsType,
    revenueBySubsType,
  } = useSelector((/** @type {import('../redux/reducers').RootState} */state) => state.MerchantReducer);

  const dispatch = useDispatch();

  const getMerchantDataCallback = useCallback(() => {
    dispatch(getMerchantDataAction());
  }, [dispatch]);

  const getSubscriptionAnalyticsCallback = useCallback(() => {
    dispatch(getSubscriptionAnalyticsAction());
  }, [dispatch]);

  useEffect(() => {
    if (!merchantLoaded) {
      getMerchantDataCallback();
    }
  }, [
    getMerchantDataCallback,
    merchantLoaded,
  ]);

  useEffect(() => {
    if (!subscriptionAnalyticsLoaded) {
      getSubscriptionAnalyticsCallback();
    }
  }, [getSubscriptionAnalyticsCallback, subscriptionAnalyticsLoaded]);

  useEffect(() => {
    if (subscriptionAnalyticsError) {
      addToast(`Could not get the customer analytics data. ${subscriptionAnalyticsError}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, subscriptionAnalyticsError]);

  const getSubscriptionCustomerPercentage = ({ active, inactive, nonSubscription }) => {
    const sum = active + inactive + nonSubscription;
    if (sum === 0) {
      return 0;
    }

    return (active * 100) / sum;
  };

  const getSubscriptionRevenuePercentage = ({ subsRevenue = 0, nonSubsRevenue = 0 }) => {
    const sum = subsRevenue + nonSubsRevenue;
    if (sum === 0) {
      return 0;
    }

    return (subsRevenue * 100) / sum;
  };

  return (
    <PageBuilder>
      <SEO title="Analytics | Blueprint" />
      <div className="a-view analytics">
        <div className="container">
          <div className="top-bar">
            <h2 className="title">Analytics</h2>
          </div>
          {merchantLoaded ? (
            <div className="row">
              <div className="col-md-6">
                <DashInfoCard
                  title="Average Revenue Per Customer"
                  value={
                  (merchantData.currencySymbol || '£')
                  + (merchantData.ALR || '0.00')
                }
                  extraInfo="Average spend of customers before they churn"
                />
              </div>
              <div className="col-md-6">
                <DashInfoCard
                  title="Average Reorder Rate"
                  value={`${((merchantData.ARR || 0) * 100).toFixed(2)}%`}
                  extraInfo="Percentage of customers who ordered more than once"
                />
              </div>
              <div className="col-md-6">
                <DashInfoCard
                  title="Average Reorder Gap"
                  value={`${merchantData.AARFdays || '0'} days`}
                  extraInfo="Average number of days between repeat customer orders"
                />
              </div>
              <div className="col-md-6">
                <DashInfoCard
                  title="Churn Rate"
                  value={`${((merchantData.roryChurn || 0) * 100).toFixed(2)}%`}
                  extraInfo="% of customers who have churned"
                />
              </div>
            </div>
          ) : (<div><p>Loading</p></div>)}
          {subscriptionAnalyticsLoaded && (
            <div className="row">
              <div className="col-md-6">
                <DashInfoCard
                  title="Active subscription customers"
                  value={
                  `${customerCountBySubsType.active} (${getSubscriptionCustomerPercentage(customerCountBySubsType).toFixed(2)}%)`
                }
                  extraInfo={`${customerCountBySubsType.inactive} inactive subscription customers`}
                />
              </div>
              <div className="col-md-6">
                <DashInfoCard
                  title="Subscription Revenue"
                  value={`${merchantData.currencySymbol || '£'}${(revenueBySubsType.subsRevenue || 0).toFixed(2)} (${(getSubscriptionRevenuePercentage(revenueBySubsType)).toFixed(2)}%)`}
                  extraInfo="Revenue from subscription orders last 30 days"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(Analytics);
