import React from 'react';
import { PageBuilder } from '../components/global/PageBuilder';
import { SEO } from '../components/global/SEO';
import AutoMessages from '../components/main/AutoMessages';

export const AutomatedMessages = () => (
  <PageBuilder>
    <SEO title="Automated Messages | Blueprint" />
    <div className="a-view abandoned-carts">
      <div className="container">
        <h2 className="title">Automated Messages</h2>
        <AutoMessages />
      </div>
    </div>
  </PageBuilder>
);

export default AutomatedMessages;
