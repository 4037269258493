import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import IntegrationCustomAPIForm from '../../components/forms/IntegrationCustomAPIForm';
import { APIKeysActionCreators } from '../../redux/actions/apiKeys';

const { getMerchantAPIKeys, insertMerchantAPIKey, resetMerchantAPIKeysModifyState } = APIKeysActionCreators;

const IntegrationsHubCustomAPIPage = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const {
    apiKeysLoading,
    apiKeysLoaded,
    apiKeysError,
    apiKeysData,
    apiKeysInsertSuccess,
    apiKeysDeleteSuccess,
    apiKeysUpdateSuccess,
    apiKeysInsertError,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */ state) => state.APIKeysReducer,
  );

  useEffect(() => {
    if (!apiKeysLoaded && !apiKeysLoading && !apiKeysError) {
      dispatch(getMerchantAPIKeys());
    }
  }, [dispatch, apiKeysLoaded, apiKeysLoading, apiKeysError]);

  useEffect(() => {
    if (apiKeysInsertSuccess || apiKeysUpdateSuccess || apiKeysDeleteSuccess) {
      let messageAdjective;

      if (apiKeysInsertSuccess) {
        messageAdjective = 'created';
      }
      if (apiKeysUpdateSuccess) {
        messageAdjective = 'updated';
      }
      if (apiKeysDeleteSuccess) {
        messageAdjective = 'deleted';
      }

      addToast(
        `The API key has been ${messageAdjective}`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
    }

    return () => {
      dispatch(resetMerchantAPIKeysModifyState());
    };
  }, [addToast, dispatch, apiKeysInsertSuccess, apiKeysDeleteSuccess, apiKeysUpdateSuccess]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(insertMerchantAPIKey());
    dispatch(getMerchantAPIKeys());
  };

  return (
    <PageBuilder>
      <SEO title="Custom APIs | Integrations | Blueprint" />
      <div className="a-view integrations-hub">
        <div className="container">
          <div className="row back-btn-container">
            <div className="col-md-12">
              <Link to="/integrations">Back</Link>
            </div>
          </div>

          <div className="top-bar">
            <h2 className="title">
              Custom API
              {' '}
              {apiKeysData.length > 0 ? '✅' : ''}
            </h2>
          </div>

          {apiKeysInsertError && (
            <div className="row">
              <div className="col-lg-12">
                <div className="alert alert-danger">{apiKeysInsertError}</div>
              </div>
            </div>
          )}

          <p>
            The Blueprint Subscribe Phone Number API allows you to add and opt-in phone numbers
            to your Blueprint account for SMS transactional and marketing messages.
            {' '}
            <a href="https://help.blueprint.store/blueprint-subscribe-phone-number-api" target="_blank" rel="noreferrer">Read more.</a>
          </p>

          {apiKeysLoaded ? (
            <IntegrationCustomAPIForm
              onSubmit={handleSubmit}
            />
          ) : (
            <>Loading...</>
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(IntegrationsHubCustomAPIPage);
