import React, { memo, useCallback } from 'react';

import OPTIONS from './data/states.json';

const StateChoice = ({ value, onChange }) => {
  const handleOnChange = useCallback(
    (event) => {
      const obj = {
        mode: 'eq',
        value: event.target.value,
      };

      onChange(obj);
    },
    [onChange],
  );

  return (
    <div className="subquery">
      <select
        className="form-control"
        value={value.value}
        onChange={handleOnChange}
      >
        <option value="">Please select...</option>
        {Object.keys(OPTIONS).map((code) => <option value={code}>{OPTIONS[code]}</option>)}
      </select>
    </div>
  );
};

export default memo(StateChoice);
