import {
  BlueprintJSONAPI, APIMethods, APIServiceConstants,
} from '../../redux/actions/BlueprintAPI';

/** @typedef {import('../../utils/types').SmartLink} SmartLink */

/**
 * @param {SmartLink} body
 * @returns
 */
const upsertSmartLink = async (body) => {
  console.log('upsertSmartLink body', body);

  const response = await BlueprintJSONAPI(
    APIMethods.PUT,
    APIServiceConstants.SERVICE,
    'smartlinks',
    body,
  );

  return response?.data;
};

export default upsertSmartLink;
