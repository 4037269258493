import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import { BPToggle } from './BPToggle';
import MessageIntervalSelect, { TIME_UNIT } from './MessageIntervalSelect';
import { HelpBubble } from './HelpBubble';
import { SegmentCharCounter } from './SegmentCharCounter';
import ImageUpload from './ImageUpload';
import { BImage } from '../global/BImage';
import ImagePreview from './ImagePreview';
import { MSG_TYPE_MMS, MSG_TYPE_SMS } from '../../utils/messageType';

const MMS_MAX_CHAR_COUNT_PER_SEGMENT = 1600;
const SMS_MAX_CHAR_COUNT_PER_SEGMENT = 400;

const MessageTemplateEditor = ({
  id,
  messageType,
  messageContent,
  messageInterval,
  active,
  setMessageTemplate,
  canSetActive,
  canSetInterval,
  title = '',
  help = '',
  enabled = true,
  isToggleAvailable = true,
  allowImageUpload = false,
  onMessageTypeChange = () => {},
  merchant = {},
  mediaUrl = '',
}) => {
  const values = useMemo(
    () => ({
      id,
      messageType,
      messageContent,
      messageInterval,
      active,
      mediaUrl,
    }),
    [active, id, messageContent, messageInterval, messageType, mediaUrl],
  );

  const setContent = useCallback(
    (e) => setMessageTemplate({
      ...values,
      messageContent: e.target.value,
    }),
    [setMessageTemplate, values],
  );

  const setInterval = useCallback(
    (e) => setMessageTemplate({
      ...values,
      messageInterval: e.target.value,
    }),
    [setMessageTemplate, values],
  );

  const setActive = useCallback(
    () => setMessageTemplate({
      ...values,
      active: !active,
    }),
    [active, setMessageTemplate, values],
  );

  const [imageUploadOpen, setImageUploadOpen] = useState(false);

  const toggleImageUpload = () => setImageUploadOpen(!imageUploadOpen);

  const onRemoveMMS = () => {
    onMessageTypeChange();
  };

  const addUrlToMessageContentIfNotPresent = (url) => {
    if (values.messageContent.indexOf(url) === -1) {
      return `${values.messageContent} ${url}`;
    }
    return values.messageContent;
  };

  const removeUrlFromMessageContentIfPresent = (url) => {
    if (values.messageContent.indexOf(url) !== -1) {
      const regex = new RegExp(`\\s?${url}`, 'g');
      return values.messageContent.replace(regex, '');
    }
    return values.messageContent;
  };

  const imageUploadSelect = (url) => {
    let { messageContent: newMessageContent } = values;
    if (messageType === MSG_TYPE_SMS) {
      newMessageContent = addUrlToMessageContentIfNotPresent(url);
    }
    if (messageType === MSG_TYPE_MMS) {
      newMessageContent = removeUrlFromMessageContentIfPresent(url);
    }
    setMessageTemplate({
      ...values,
      messageContent: newMessageContent,
      mediaUrl: url,
    });
  };

  return (
    <div className="message-editor">
      <div className="top-bar">
        {isToggleAvailable && (
        <div>
          <BPToggle
            active={active && enabled}
            handleClick={setActive}
            disabled={!canSetActive || !enabled}
          />
          <span className="message-number">{title}</span>
          <HelpBubble text={help} />
        </div>
        )}
        {canSetInterval && enabled && (
        <div>
          <span className="send-after">Send After</span>
          <MessageIntervalSelect
            className="grey"
            name="sendAfter"
            customValueUnit={TIME_UNIT.days}
            handleChange={setInterval}
            value={messageInterval}
          />
        </div>
        )}
        { mediaUrl && (
        <ImagePreview
          url={mediaUrl}
          altText="Uploaded image for MMS"
          onCancel={onRemoveMMS}
        />
        )}
      </div>
      <div className="message-content-container">
        <textarea
          value={messageContent}
          onChange={setContent}
          className="message-content"
          rows={3}
          maxLength={
            messageType === MSG_TYPE_SMS ? SMS_MAX_CHAR_COUNT_PER_SEGMENT : MMS_MAX_CHAR_COUNT_PER_SEGMENT
}
          disabled={!enabled}
        />
        <div className="actions">
          <SegmentCharCounter text={messageContent} msgType={messageType} />
          { allowImageUpload && (
            <>
              <BImage
                src="/images/upload-icon.png"
                alt="Image Upload"
                className="attach-image"
                onClick={toggleImageUpload}
              />
              <ImageUpload
                isOpen={imageUploadOpen}
                close={toggleImageUpload}
                imageUploadSelect={imageUploadSelect}
                msgType={messageType}
                onMsgTypeChange={onMessageTypeChange}
                merchant={merchant}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(MessageTemplateEditor);
