/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { ProductActionCreators } from '../../redux/actions/product';

const { getAllProducts: getAllProductsAction } = ProductActionCreators;

/**
 * @typedef {import('../../redux/reducers').RootState} RootState
 */

/**
 * @typedef ProductSelectorVariant
 * @property {Number} variantId
 * @property {String} externalId
 * @property {String} variantName
 * @property {Boolean} selected
 *
 *
 * @typedef ProductSelectorProduct
 * @property {Number} productId
 * @property {String} externalId
 * @property {Boolean} selected
 * @property {Boolean} allVarsSelected
 * @property {String} productName
 * @property {ProductSelectorVariant[]} variants
 */

const ProductImage = ({ product }) => {
  if (product.deleted) {
    return (
      <div
        className="img-thumbnail mr-3"
        style={{
          display: 'inline-block',
          background: '#ccc',
          width: '40px',
          height: '40px',
        }}
      />
    );
  }

  return (<img src={product.imgSrc} alt={product.imgAlt} className="img-thumbnail mr-3" width="40" />);
};

const ProductSelector = ({
  handleProductSelection,
  currency,
  handleClose,
  preSelectedVariants,
  preSelectedProducts,
  omitSubscriptionProducts,
}) => {
  const {
    productErr: getAllProductsError,
    allProducts,
    productsLoaded,
  } = useSelector((/** @type RootState} */ state) => state.ProductReducer);

  const areProductsLoaded = (allProducts && allProducts.length > 0) || productsLoaded;

  const [productSelection, setProductSelection] = useState(/** @type {ProductSelectorProduct[]} */([]));

  const dispatch = useDispatch();

  const { addToast } = useToasts();

  useEffect(() => {
    if (productsLoaded && allProducts) {
      setProductSelection(allProducts.map((product) => {
        const isProductSelectedAsWhole = preSelectedProducts.some(
          (preSelectedProductId) => preSelectedProductId === product.id,
        );

        /** @type {ProductSelectorProduct} */
        return {
          productId: product.id,
          externalId: product.productIdExternal,
          selected: isProductSelectedAsWhole,
          allVarsSelected: isProductSelectedAsWhole,
          productName: product.name,
          variants: product.variants.map((variant) => ({
            variantId: variant.id,
            externalId: variant.variantId,
            variantName: variant.variantName,
            selected: isProductSelectedAsWhole || preSelectedVariants.some(
              (preSelectedVarId) => preSelectedVarId === variant.id,
            ),
          })),
        };
      }));
    }
  }, [productsLoaded, allProducts, preSelectedVariants, preSelectedProducts, omitSubscriptionProducts]);

  useEffect(() => {
    if (!areProductsLoaded) {
      dispatch(getAllProductsAction());
    }
    if (getAllProductsError) {
      addToast('Could not get the products', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, areProductsLoaded, getAllProductsError, dispatch]);

  const handleSave = (event) => {
    event.preventDefault();

    const productSelectionInfo = {
      products: productSelection.map((selection) => ({
        productId: selection.productId,
        externalId: selection.externalId,
        productName: selection.productName,
        variants: selection.variants.filter((variantSelection) => variantSelection.selected)
          .map((variantSelection) => ({
            variantId: variantSelection.variantId,
            externalId: variantSelection.externalId,
            variantName: variantSelection.variantName,
          })),
        allVarsSelected: selection.allVarsSelected,
      })).filter((mappedSelection) => mappedSelection.variants && mappedSelection.variants.length > 0),
    };

    handleProductSelection(productSelectionInfo);
    handleClose(event);
  };

  /**
   * Make the checkbox in front of the given product indeterminte (filling the checkbox with "-" instead of "✓")
   * when at least one of its variants are checked
   */
  const makeIndeterminateIfValid = (el) => {
    if (el) {
      const productId = parseInt(el.dataset.productId, 10);
      const currentProductSelection = productSelection.find(
        (selection) => selection.productId === productId,
      );

      if (!currentProductSelection) {
        return;
      }

      if (currentProductSelection.selected && currentProductSelection.allVarsSelected) {
        // This reassignment is necessary to make the checkbox indeterminate
        // Another option was to use "useRef()" - but that would've required some tedious refactorings
        // eslint-disable-next-line no-param-reassign
        el.indeterminate = false;
        return;
      }

      // eslint-disable-next-line no-param-reassign
      el.indeterminate = currentProductSelection.variants.some(
        (variantSelection) => variantSelection.selected,
      );
    }
  };

  const isChecked = (productId, variantId) => {
    const currentProductSelection = productSelection.find(
      (selection) => selection.productId === productId,
    );

    if (!currentProductSelection) {
      return false;
    }

    if (variantId) {
      return currentProductSelection.variants.some(
        (selection) => selection.selected && selection.variantId === variantId,
      );
    }

    return currentProductSelection.selected || currentProductSelection.allVarsSelected;
  };

  /**
   * It finds a product selection record by the given product id and flips its "selected" state
   * It will do the same for a variant if a variant id is provided
   */
  const onItemSelected = (event) => {
    const productSelectionClone = [...productSelection];
    const productId = parseInt(event.target.dataset.productId, 10);
    const variantId = parseInt(event.target.dataset.variantId, 10);

    const selectedProductSelection = productSelectionClone.find(
      (selection) => selection.productId === productId,
    );

    if (!selectedProductSelection) {
      return;
    }

    if (variantId) {
      const selectedVariantSelection = selectedProductSelection.variants.find(
        (variantSelection) => variantSelection.variantId === variantId,
      );

      if (!selectedVariantSelection) {
        return;
      }

      // Make the product unselected when at least one of its varaints is unselected
      if (selectedVariantSelection.selected) {
        selectedProductSelection.selected = false;
      }

      selectedVariantSelection.selected = !selectedVariantSelection.selected;
      selectedProductSelection.allVarsSelected = selectedProductSelection.selected;
    } else {
      selectedProductSelection.variants.forEach(
        (variantSelection, index) => {
          selectedProductSelection.variants[index].selected = !selectedProductSelection.selected;
        },
      );
      selectedProductSelection.selected = !selectedProductSelection.selected;
      selectedProductSelection.allVarsSelected = selectedProductSelection.selected;
    }

    setProductSelection(productSelectionClone);
  };

  // Every product on Shopify has an implicitly default variant with no name
  const hasOnlyDefaultVariant = (variants) => {
    if (!(variants && variants.length > 0)) {
      return true;
    }

    if (variants.length === 1 && !variants[0].variantName) {
      return true;
    }

    return false;
  };

  const priceAndQuantitySection = (variant) => (
    <div className="d-flex justify-content-end align-items-center">
      <span className="mr-3">
        {variant.stockQuantity > 0 ? `${variant.stockQuantity} available` : '' }
      </span>
      <span>
        {currency}
        {variant.price.toFixed(2)}
      </span>
    </div>
  );

  let productsArrToUse = allProducts;
  if (omitSubscriptionProducts && productsLoaded) {
    productsArrToUse = allProducts.filter(
      (product) => product.subscription !== 1,
    );
  }

  return (
    <>
      <div className="product-list-container mb-3">
        { productsLoaded
          ? (
            <div className="list-group">
              {productsArrToUse.map((product) => (
                <div key={product.productIdExternal}>
                  <label htmlFor={`product-checkbox-${product.id}`} className="list-group-item list-group-item-action">
                    <div className="d-flex w-100 justify-content-between">
                      <div className="d-flex justify-content-start align-items-center">
                        <input
                          id={`product-checkbox-${product.id}`}
                          type="checkbox"
                          className="check-box mr-3"
                          onChange={onItemSelected}
                          data-product-id={product.id}
                          checked={isChecked(product.id, null)}
                          ref={makeIndeterminateIfValid}
                        />
                        <ProductImage product={product} />
                        {product.name}
                        {!!product.deleted && (
                        <>
                          &nbsp;
                          <span className="badge badge-pill badge-danger">Deleted</span>
                        </>
                        )}
                      </div>

                      {!product.deleted && hasOnlyDefaultVariant(product.variants)
                        ? priceAndQuantitySection(product.variants[0])
                        : ''}
                    </div>
                  </label>
                  {!hasOnlyDefaultVariant(product.variants) && product.variants.map((variant) => (
                    <label key={variant.variantId} htmlFor={`variant-checkbox-${variant.id}`} className="list-group-item list-group-item-action">
                      <div className="d-flex w-100 justify-content-between">
                        <div className="d-flex justify-content-start align-items-center">
                          <input
                            id={`variant-checkbox-${variant.id}`}
                            type="checkbox"
                            className="check-box ml-4 mr-3"
                            onChange={onItemSelected}
                            data-product-id={product.id}
                            data-variant-id={variant.id}
                            checked={isChecked(product.id, variant.id)}
                          />
                          <span>{variant.variantName}</span>
                          {!!product.deleted && (
                          <>
                          &nbsp;
                            <span className="badge badge-pill badge-danger">Deleted</span>
                          </>
                          )}
                        </div>
                        {!product.deleted && priceAndQuantitySection(variant)}
                      </div>
                    </label>
                  ))}
                </div>
              ))}
            </div>
          )
          : <span className="mb-3 ml-3">Loading products...</span>}
      </div>
      <div className="row">
        <div className="col float-right mr-3">
          <button className="btn btn-primary btn-sm float-right ml-2" onClick={handleSave} type="button">
            Add
          </button>
          <button className="btn btn-outline-secondary btn-sm float-right" onClick={handleClose} type="button">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default ProductSelector;
