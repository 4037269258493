import {
  APIServiceConstants,
  BlueprintAPI,
} from '../../redux/actions/BlueprintAPI';

const { SERVICE } = APIServiceConstants;

const enableCloudfrontDistribution = async (customDomainId) => {
  try {
    await BlueprintAPI(SERVICE, `customDomains/${customDomainId}/enable`, {}, null, false, 'PUT');
  } catch (err) {
    throw new Error('Failed to enable Cloudfront Distribution. Please contact Support.');
  }
};

export default enableCloudfrontDistribution;
