import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { PageBuilder } from '../components/global/PageBuilder';
import { SEO } from '../components/global/SEO';
import { BPConvSearchbox } from '../components/main/BPConvSearchbox';
import AllContacts from '../components/main/AllContacts';
import IndividualConversation from '../components/main/Conversation/IndividualConversation';
import CustomerBar from '../components/main/CustomerBar';
import { useWebSocket } from '../hooks/useWebSocket';
import { CustomerActionCreators } from '../redux/actions/customer';
import { getSearchContacts } from '../components/main/AllContacts/helpers';
import { getSocketConversations } from '../components/main/Conversation/helpers';
import { CUSTOMER_MESSAGE_PLACEHOLDERS } from '../constants';
import { MerchantActionCreators } from '../redux/actions/merchant';
import { MAX_CHAR_LIMIT_PER_MSG } from '../utils/settings';

/** @typedef {import('../redux/reducers').RootState} RootState */

const PING_INTERVAL = 80000;

const { getMerchantData: getMerchantDataAction } = MerchantActionCreators;

const Messaging = ({ history }) => {
  const conversationsRef = useRef([]);
  const [query, setQuery] = useState('');
  const [message, setMessage] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [contactsLoaded, setContactsLoaded] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [read, setRead] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [conversationsLoaded, setConversationsLoaded] = useState(false);
  const [conversationsType, setConversationsType] = useState('');
  const [permalinkData, setPermalinkData] = useState(null);
  const [activeCustomerPhoneId, setActiveCustomerPhoneId] = useState();
  const activeCustomer = useSelector(
    (/** @type {RootState} */state) => state.CustomerReducer.customers[activeCustomerPhoneId],
  );
  // I don't know. - DI 15/05/21
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const merchantData = useSelector(
    (/** @type {RootState} */state) => state.MerchantReducer.merchantData, shallowEqual,
  ) || {};

  const dispatch = useDispatch();

  const getMerchantInfo = useCallback(() => {
    const needsToGetMerchantData = Object.keys(merchantData).length === 0;

    if (needsToGetMerchantData) {
      dispatch(getMerchantDataAction());
    }
  }, [dispatch, merchantData]);

  useEffect(() => {
    getMerchantInfo();
  // I'm not sure what the pattern should be for exhaustive deps on "useEffect only once" empty deps arrays - DI 14/05/21
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCustomer = useCallback(
    (customerPhoneId) => {
      if (!customerPhoneId) {
        return;
      }

      setActiveCustomerPhoneId(customerPhoneId);
      setConversationsLoaded(false);

      const { getCustomerData } = CustomerActionCreators;

      dispatch(getCustomerData(customerPhoneId));
    },
    [dispatch],
  );

  const handleConversations = useCallback((socketConversations) => {
    const { customer, type, message: msg } = socketConversations;
    const canUpdateConversations = ['init', 'update', 'message'].includes(type);
    const convs = canUpdateConversations
      ? getSocketConversations(
        socketConversations,
        conversationsRef.current || [],
      )
      : null;
    conversationsRef.current = convs;
    setConversationsType(type);

    switch (type) {
      case 'init':
        setConversations(convs);
        setConversationsLoaded(true);
        setRead(customer.customerPhoneId);
        break;
      case 'message':
        setConversations(convs);
        if (msg.agentRead === 0 && msg.direction === 'incoming') {
          setRead(customer.customerPhoneId);
        }
        break;
      default:
    }
  }, []);

  const onMessage = useCallback(
    (incomingMessage) => {
      if (!incomingMessage.method) {
        return;
      }
      switch (incomingMessage.method) {
        case 'contacts':
          setContactsLoaded(true);
          setContacts(incomingMessage.contacts);
          break;
        case 'conversations':
          handleConversations(incomingMessage);
          break;
        default:
      }
    },
    [handleConversations],
  );

  const onOpen = useCallback(() => {
    setIsOpened(true);
  }, [setIsOpened]);

  const onTokenError = useCallback(() => {
    history.push('/logout');
  }, [history]);

  const [sendMessage, websocketReadyState] = useWebSocket({
    onMessage,
    onOpen,
    onTokenError,
  });

  const handleSendMessage = useCallback(
    (outgoingMessage) => {
      sendMessage(outgoingMessage);
      setPermalinkData(null);
    },
    [sendMessage],
  );

  const handleClearAlert = useCallback(() => {
    sendMessage({
      method: 'clearAlert',
      customerPhoneId: activeCustomerPhoneId,
    });

    getCustomer(activeCustomerPhoneId);
  }, [activeCustomerPhoneId, getCustomer, sendMessage]);

  const handleUnsubscribe = useCallback(() => {
    getCustomer(activeCustomerPhoneId);

    setConversationsLoaded(true);
  }, [activeCustomerPhoneId, getCustomer]);

  useEffect(() => {
    const interval = setInterval(() => {
      sendMessage({ ping: 'ping' });
    }, PING_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [sendMessage]);

  useEffect(() => {
    if (isOpened) {
      sendMessage({
        method: 'getContacts',
      });
    }
  }, [isOpened, sendMessage]);

  const handleContactClick = useCallback(
    (customerPhoneId) => {
      if (activeCustomerPhoneId) {
        sendMessage({
          method: 'leaveConversation',
          customerPhoneId: activeCustomerPhoneId,
        });
      }

      sendMessage({ method: 'joinConversation', customerPhoneId });
      sendMessage({ method: 'getCustomerMessages', customerPhoneId });

      getCustomer(customerPhoneId);

      setPermalinkData(null);
    },
    [activeCustomerPhoneId, getCustomer, sendMessage],
  );

  const handleRead = useCallback(
    (customerPhoneId) => {
      if (customerPhoneId) {
        sendMessage({ method: 'updateReadReceipt', customerPhoneId });
      }
    },
    [sendMessage],
  );

  useEffect(() => {
    handleRead(read);
  }, [handleRead, read]);

  const updateMessage = useCallback(
    (newMessage, append = false) => {
      const newText = append && message ? `${message} ${newMessage}` : newMessage;

      if (newText.length <= MAX_CHAR_LIMIT_PER_MSG) {
        setMessage(newText);
      }
    },
    [message],
  );

  const updatePermalinkData = useCallback(
    (newPermalinkData) => {
      if (!newPermalinkData.products || newPermalinkData.products.length === 0) {
        setPermalinkData(null);

        return;
      }

      const hasPermalinkPlaceholder = message.includes(
        CUSTOMER_MESSAGE_PLACEHOLDERS.link_to_checkout,
      );

      if (!hasPermalinkPlaceholder) {
        const newText = `${message} {${CUSTOMER_MESSAGE_PLACEHOLDERS.link_to_checkout}}`;

        if (newText.length <= MAX_CHAR_LIMIT_PER_MSG) {
          setMessage(newText);

          setPermalinkData(newPermalinkData);
        }
      } else {
        setPermalinkData(newPermalinkData);
      }
    },
    [message],
  );

  const currentLocation = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(currentLocation.search);
    if (websocketReadyState === WebSocket.OPEN && queryParams.get('id')) {
      const customerPhoneId = parseInt(queryParams.get('id') || '0', 10);
      if (customerPhoneId > 0) {
        handleContactClick(customerPhoneId);
      }
    }
    // Cant include handleContactClick as that depends on the active customerPhoneId...
    // ...and it goes around in circles
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketReadyState, currentLocation]);

  // Double check if the active customer is flagged as "support"
  const isSupportFlagged = (customer, contactsInSupport) => customer
    && contactsInSupport
    && contactsInSupport.findIndex(
      (supportCust) => customer.customerPhone
        && supportCust.customerPhoneId === customer.customerPhone.id,
    ) > -1;

  const searchContacts = useMemo(() => getSearchContacts(contacts, query), [
    contacts,
    query,
  ]);

  const alerts = searchContacts.support.length;

  // It's beceause "contacts" has the latest data while "activeCustomer" is outdated
  if (isSupportFlagged(activeCustomer, searchContacts.support)) {
    activeCustomer.supportFlagged = 1;
  }

  return (
    <PageBuilder>
      <SEO title="Messaging | Blueprint" />
      <div className="a-view messaging">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="search-conv-card">
                <div className="intro">
                  <div>
                    <span className="intro-title">Conversations</span>
                  </div>
                  <BPConvSearchbox value={query} handleChange={setQuery} />
                  <div className="actions">
                    {alerts > 0 && (
                      <span className="msg-alerts">
                        (
                        {alerts}
                        ) Message Alerts
                      </span>
                    )}
                  </div>
                </div>
                <AllContacts
                  searchContacts={searchContacts}
                  loaded={contactsLoaded}
                  activeCustomerPhoneId={activeCustomerPhoneId}
                  handleClick={handleContactClick}
                />
              </div>
            </div>
            <div className="col-md-6">
              <IndividualConversation
                customer={activeCustomer}
                customerSelected={!!activeCustomerPhoneId}
                loaded={conversationsLoaded}
                messages={conversations}
                message={message}
                onClearAlert={handleClearAlert}
                onMessageChange={updateMessage}
                onSend={handleSendMessage}
                permalinkData={permalinkData}
                type={conversationsType}
                merchant={merchantData}
              />
            </div>
          </div>
          {activeCustomerPhoneId && (
            <CustomerBar
              customer={activeCustomer}
              merchant={merchantData}
              onUnsubscribe={handleUnsubscribe}
              permalinkData={permalinkData}
              updatePermalinkData={updatePermalinkData}
            />
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(Messaging);
