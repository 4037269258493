import React from 'react';
import { DashInfoCard } from '../DashInfoCard';

import {
  formatAsMoney,
} from '../../../utils/numerical';

import {
  wordings,
  settings,
} from '../../../utils/crossSells';

const AnalyticsKPIs = ({
  totals, currency, type,
}) => (
  <>
    <div className="col-lg-3">
      <DashInfoCard
        title={wordings[type].productSuggestionTitle}
        value={totals.numberOfProductOpportunities || 0}
        info={wordings[type].eligibleProducts}
        clickThroughHref={`${settings[type].mainLink}/product-report`}
        clickThroughText={`View recommended ${wordings[type].genericTerm}s`}
      />
    </div>
    <div className="col-lg-3">
      <DashInfoCard
        title="Total messages sent"
        value={totals.numMessagesSent || 0}
        info={`${totals.totalSegmentsSent || 0} SMS segments sent`}
        extraInfo={`Total number of ${wordings[type].genericTerm} messages and segments sent`}
      />
    </div>
    <div className="col-lg-3">
      <DashInfoCard
        title="Orders"
        value={totals.numOrders || 0}
        extraInfo={`Number of orders attributed to ${wordings[type].genericTerm} campaigns`}
      />
    </div>
    <div className="col-lg-3">
      <DashInfoCard
        title="Revenue generated"
        value={formatAsMoney(totals.totalRevenue || 0, currency)}
        info={
            `${formatAsMoney(totals.revenuePerMessage || 0, currency)
            } revenue per message`
          }
        extraInfo={`Total revenue from all orders attributed to ${wordings[type].genericTerm} campaigns`}
      />
    </div>
  </>
);

export default AnalyticsKPIs;
