import React, { memo } from 'react';

export function getPaginationBounds(totalRows, pageIndex, pageSize) {
  if (totalRows === 0) {
    return [0, 0];
  }

  const pageOffset = pageIndex * pageSize;
  const lowerBound = pageOffset + 1;
  const upperBound = Math.min(pageOffset + pageSize, totalRows);

  return [lowerBound, upperBound];
}

function PaginationCounter({ totalRowCount, pageIndex, pageSize }) {
  const paginationBounds = getPaginationBounds(
    totalRowCount,
    pageIndex,
    pageSize,
  );

  if (totalRowCount > 0) {
    return (
      <>
        {paginationBounds[0]}
        {' '}
        -
        {paginationBounds[1]}
        {' '}
        of
        {' '}
        {totalRowCount}
      </>
    );
  }

  return <>0 of 0</>;
}

function Pagination({
  canPreviousPage,
  canNextPage,
  goToPreviousPage,
  goToNextPage,
  pageIndex,
  pageSize,
  loading,
  totalCount,
}) {
  return (
    <div className="row">
      <div className="col-md-4 bp-pagination mt-4 mb-4">
        <span className="totals">
          <PaginationCounter
            totalRowCount={totalCount}
            pageSize={pageSize}
            pageIndex={pageIndex}
          />
        </span>

        <ul className="nav">
          <li className="nav-item">
            <button
              type="button"
              className={`btn btn-link nav-link ${
                !canPreviousPage || loading ? 'disabled' : ''
              }`}
              onClick={(e) => {
                if (canPreviousPage && !loading) {
                  goToPreviousPage(e);
                }
              }}
            >
              PREVIOUS
            </button>
          </li>

          <li className="nav-item">
            <button
              type="button"
              className={`btn btn-link nav-link  ${
                !canNextPage || loading ? 'disabled' : ''
              }`}
              onClick={(e) => {
                if (canNextPage && !loading) {
                  goToNextPage(e);
                }
              }}
            >
              NEXT
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default memo(Pagination);
