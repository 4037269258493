import React from 'react';
import { Product } from './Product';

export const ProductGrid = (props) => {
  const {
    allProducts, loading, currencySymbol, addToCart,
  } = props;
  return (
    <div className="product-grid">
      {loading && <div>Just looking around in the stock room...</div>}
      <div className="row">
        {allProducts.map((product) => (
          <Product
            key={product.id}
            currencySymbol={currencySymbol}
            product={product}
            addToCart={addToCart}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductGrid;
