import React, { memo, useCallback, useMemo } from 'react';
import { useTable } from 'react-table';
import { formatDate } from '../../../../utils/dates';
import { GROUP_TYPE } from '../../../../constants';
import LoadingMessage from '../Loading';

const columns = [
  {
    Header: 'Group Name',
    accessor: 'name',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Size',
    accessor: 'count',
  },
  {
    Header: 'Scheduled for',
    accessor: 'scheduledFrom',
  },
  {
    Header: 'Sending estimate',
    accessor: 'durationEstimate',
  },
  {
    Header: 'Message Content',
    accessor: 'message',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

function NoResults() {
  return (
    <tr>
      <td colSpan={columns.length + 1} className="noResults">
        No results found
      </td>
    </tr>
  );
}

const TableRow = ({ row, cancelCampaign }) => {
  const handleCancelCampaign = useCallback(() => {
    cancelCampaign(row.original.id);
  }, [cancelCampaign, row]);

  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => (
        <td {...cell.getCellProps()}>
          {cell.render('Cell')}
        </td>
      ))}
      <td>
        <button
          className="minimal-red-btn"
          type="button"
          onClick={handleCancelCampaign}
        >
          Cancel
        </button>
      </td>
    </tr>
  );
};

const ScheduleList = ({ campaigns, loading, cancelCampaign }) => {
  const data = useMemo(() => campaigns.map((campaign) => ({
    ...campaign,
    scheduledFrom: formatDate(campaign.scheduledFrom),
    type: campaign.type === GROUP_TYPE.LIST ? 'List' : 'Segment',
    count: campaign.type === GROUP_TYPE.LIST ? campaign.listQuantity : campaign.count,
  })), [campaigns]);

  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = tableInstance;

  if (loading) {
    return <LoadingMessage loading={loading} />;
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <table
          {...getTableProps()}
          className="table table-bordered table-hover table-responsive-lg bp-table bp-table-dark"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
                <th>
                  Actions
                </th>
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.length === 0 ? (
              <NoResults />
            ) : (
              rows.map((row) => {
                prepareRow(row);
                return <TableRow row={row} cancelCampaign={cancelCampaign} />;
              })
            )}
          </tbody>

        </table>
      </div>
    </div>
  );
};

export default memo(ScheduleList);
