import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import IntegrationGorgiasForm from '../../components/forms/IntegrationGorgiasForm';
import { IntegrationActionCreators } from '../../redux/actions/integrations';

const {
  getMerchantIntegrations,
  upsertGorgiasIntegration,
  deleteGorgiasIntegration,
  resetGorgiasIntegrationState,
  testGorgiasIntegration,
} = IntegrationActionCreators;

const IntegrationsHubGorgiasPage = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const [gorgiasIntegrationData, setGorgiasIntegrationData] = useState(null);

  const {
    integrationsLoading,
    integrationsLoaded,
    integrationsData,
    gorgiasUpsertSuccess,
    gorgiasUpsertError,
    gorgiasDeletionSuccess,
    gorgiasDeletionError,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */ state) => state.IntegrationsReducer,
  );

  useEffect(() => {
    if (!integrationsLoaded && !integrationsLoading) {
      dispatch(getMerchantIntegrations());
    }
  }, [dispatch, integrationsLoaded, integrationsLoading]);

  useEffect(() => {
    if (gorgiasUpsertSuccess) {
      const messageAdjective = integrationsData?.gorgias?.active
        ? 'updated'
        : 'activated';

      addToast(
        `Your integration with Gorgias has been ${messageAdjective}`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
    }

    return () => {
      dispatch(resetGorgiasIntegrationState());
    };
  }, [addToast, dispatch, integrationsData?.gorgias?.active, gorgiasUpsertSuccess]);

  useEffect(() => {
    if (gorgiasDeletionSuccess) {
      addToast(
        'Your integration with Gorgias has been deleted',
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
    }

    return () => {
      dispatch(resetGorgiasIntegrationState());
    };
  }, [addToast, dispatch, gorgiasDeletionSuccess]);

  useEffect(() => {
    if (integrationsLoaded) {
      if (integrationsData.gorgias) {
        setGorgiasIntegrationData(integrationsData.gorgias);
      }
    }

    if (gorgiasDeletionSuccess) {
      addToast(
        'Your integration with Gorgias has been deleted',
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );

      setGorgiasIntegrationData(null);
    }

    return () => {
      dispatch(resetGorgiasIntegrationState());
    };
  }, [addToast, dispatch, integrationsLoaded, integrationsData?.gorgias, gorgiasDeletionSuccess]);

  const handleSubmit = async (form) => {
    await dispatch(upsertGorgiasIntegration(form));

    return dispatch(getMerchantIntegrations());
  };

  const handleDeletion = async () => {
    await dispatch(deleteGorgiasIntegration());

    return dispatch(getMerchantIntegrations());
  };

  const connectionTestEventCallback = async (apiKeyData) => {
    const { apiUrl, gorgiasEmail, apiKey } = apiKeyData;
    return dispatch(testGorgiasIntegration(apiUrl, gorgiasEmail, apiKey));
  };

  return (
    <PageBuilder>
      <SEO title="Gorgias | Integrations | Blueprint" />
      <div className="a-view integrations-hub">
        <div className="container">
          <div className="row back-btn-container">
            <div className="col-md-12">
              <Link to="/integrations">Back</Link>
            </div>
          </div>

          <div className="top-bar">
            <h2 className="title">
              Gorgias
              {' '}
              {integrationsData?.gorgias?.active ? '✅' : ''}
            </h2>
          </div>

          {gorgiasUpsertError && (
            <div className="row">
              <div className="col-lg-12">
                <div className="alert alert-danger">{gorgiasUpsertError}</div>
              </div>
            </div>
          )}

          {gorgiasDeletionError && (
            <div className="row">
              <div className="col-lg-12">
                <div className="alert alert-danger">{gorgiasDeletionError}</div>
              </div>
            </div>
          )}

          {integrationsLoaded ? (
            <IntegrationGorgiasForm
              gorgiasIntegrationData={gorgiasIntegrationData}
              onSubmit={handleSubmit}
              onDelete={handleDeletion}
              connectionTestEventCallback={connectionTestEventCallback}
            />
          ) : (
            <>Loading...</>
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(IntegrationsHubGorgiasPage);
