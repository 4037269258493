import { APIServiceConstants, BlueprintAPI } from '../BlueprintAPI';
import { ContactsActionTypes } from '../../types';

const { SERVICE } = APIServiceConstants;

export const ContactsActionCreators = {
  getContacts: ({
    filters, pageIndex, pageSize, sortBy,
  }) => async (
    dispatch,
  ) => {
    dispatch({
      type: ContactsActionTypes.GET_CONTACTS_REQ,
    });

    try {
      const sessionToken = await localStorage.getItem('sToken');
      const data = {
        sessionToken, filters, pageIndex, pageSize, sortBy,
      };

      const response = await BlueprintAPI(
        SERVICE,
        'getContacts',
        data,
        null,
        true,
        'get',
      );

      dispatch({
        type: ContactsActionTypes.GET_CONTACTS_RES,
        payload: {
          contacts: response.data.contacts || [],
          totalRowCount: response.data.totalRowCount || 0,
        },
      });
    } catch (e) {
      console.error('Error - getContacts:', e);

      dispatch({
        type: ContactsActionTypes.GET_CONTACTS_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
};

export default ContactsActionCreators;
