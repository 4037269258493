import React from 'react';
import LazyLoad from 'react-lazyload';

export const BImage = (props) => {
  const {
    lazyLoad = false,
    offset = 200,
    src,
    alt,
    className = '',
    ...restProps
  } = props;

  if (!lazyLoad) {
    return (
      <img
        src={src}
        alt={alt}
        className={`bimage ${className}`}
        {...restProps}
      />
    );
  }
  return (
    <LazyLoad offset={offset}>
      <img
        src={src}
        alt={alt}
        className={`bimage ${className}`}
        {...restProps}
      />
    </LazyLoad>
  );
};

export default BImage;
