/**
 * Will return 'NaN' in cases of failure
 *
 * @param {String} total
 * @param {String} part
 * @returns {String}
 */
export const formatAsPercentage = (total, part) => ((parseFloat(part) / parseFloat(total)) * 100).toFixed(1);

export const formatAsEarned = (total, amount) => (total / amount).toFixed(2);

export const formatAsThousand = (total) => total.toLocaleString();

export const safeFormatAsThousand = (total) => (
  total && Number.isFinite(total) ? formatAsThousand(total) : total
);

export const safeFormatAsPercentage = (total, part) => ((formatAsPercentage(total, part) === 'NaN') ? '' : formatAsPercentage(total, part));

// E.g. 0.46 = 46.00%
export const formatValueAsPercentage = (value, decimalPlaces = 2) => {
  if (Number.isNaN(value) || value === null) {
    return 'N/A';
  }
  return `${Number(value * 100).toFixed(decimalPlaces)}%`;
};

// E.g 43.99 / GBP to £43.99
export const formatAsMoney = (value, currency = 'GBP', decimalPlaces = 2) => {
  const num = Number(value);
  return num.toLocaleString('en-UK', {
    style: 'currency',
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
    currency,
  });
};
