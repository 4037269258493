import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import {
  useHistory,
} from 'react-router-dom';
import {
  useQuery, useQueryClient,
} from 'react-query';

import Loading from '../Loading';
import { getCustomDomain } from '../../../../queries/CustomDomain';
import { getMerchant } from '../../../../queries/Merchant';
import ClipboardCopy from '../../../global/ClipboardCopy';

const slugify = (text) => (typeof text === 'string'
  ? text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
  : text);

const DNSConfigurationReview = ({
  formRootUrl,
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { data, isLoading: isCustomDomainLoading } = useQuery(
    'customDomain',
    getCustomDomain,
    {
      refetchOnMount: false,
    },
  );

  const { data: merchantData, isLoading: isMerchantDataLoading } = useQuery('merchant', getMerchant);

  const {
    handleSubmit, formState,
  } = useForm({
    mode: 'onChange',
  });

  const { isValid, isSubmitting } = formState;

  const handleFormSubmit = async () => {
    /**
     * The user is telling us they have completed some manual steps, so we need to retrigger
     * our lambda to check the status of the domain validation
     */
    await queryClient.invalidateQueries('customDomain');
    history.push(`${formRootUrl}/status`);
  };

  if (isMerchantDataLoading || isCustomDomainLoading) {
    return <Loading />;
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="container">
        <p className="section-title">DNS Configuration</p>
        <small>Here are the DNS Values you need to set:</small>
        <div className="row mt-4">
          <div className="col">
            <strong>DNS Record 1:</strong>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2"><strong>Type</strong></div>
              <div className="col-md-10">
                <ClipboardCopy />
                CNAME
              </div>
            </div>
            <div className="row">
              <div className="col-md-2"><strong>Name</strong></div>
              <div className="col-md-10 textSelect pointer">
                <ClipboardCopy />
                {data.Domain}
              </div>
            </div>
            <div className="row">
              <div className="col-md-2"><strong>Value</strong></div>
              <div className="col-md-10 textSelect pointer">
                <ClipboardCopy />
                {`${slugify(merchantData.company)}.blp.ai`}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <strong>DNS Record 2:</strong>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2"><strong>Type</strong></div>
              <div className="col-md-10">
                <ClipboardCopy />
                {data.DomainValidationRecord.Type}
              </div>
            </div>
            <div className="row">
              <div className="col-md-2"><strong>Name</strong></div>
              <div className="col-md-10 textSelect pointer">
                <ClipboardCopy />
                {data.DomainValidationRecord.Name}
              </div>
            </div>
            <div className="row">
              <div className="col-md-2"><strong>Value</strong></div>
              <div className="col-md-10 textSelect pointer">
                <ClipboardCopy />
                {data.DomainValidationRecord.Value}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <button
          type="submit"
          className="btn btn-primary mr-2"
          disabled={isSubmitting || !isValid}
        >
          {isSubmitting ? (
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              <span>&nbsp;Checking...</span>
            </>
          ) : (
            <span>I&apos;ve completed these steps</span>
          )}
        </button>
      </div>
    </form>
  );
};

export default memo(DNSConfigurationReview);
