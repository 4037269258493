import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { DEFAULT_SUPPORT_SESSION_DURATION } from '../../utils/settings';

const defaultValues = {
  publicId: null,
  acceptConsequences: false,
  performDeletionOnSubmit: false,
  performOnlyUpdate: false,
  supportSessionDuration: DEFAULT_SUPPORT_SESSION_DURATION,
};

const IntegrationZendeskForm = ({
  onSubmit,
}) => {
  let canSubmitForm = false;

  const {
    integrationsLoading,
    integrationsData,
    zendeskUpsertLoading,
    zendeskDeleteLoading,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */state) => state.IntegrationsReducer,
  );

  const {
    register, handleSubmit, setValue, reset, watch,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchSupportSessionDuration = watch('supportSessionDuration');
  const watchAcceptConsequences = watch('acceptConsequences');

  const handleFormSubmit = (formData) => onSubmit(formData);

  useEffect(() => {
    if (!zendeskUpsertLoading && integrationsData?.zendesk) {
      reset({
        ...defaultValues,
        publicId: integrationsData?.zendesk?.publicId,
        supportSessionDuration: integrationsData?.zendesk?.settingsData?.supportSessionDuration,
      }, { keepDefaultValues: false });
    }
  }, [zendeskUpsertLoading, integrationsData?.zendesk, reset]);

  useEffect(() => {
    if (!zendeskDeleteLoading && !integrationsData?.zendesk) {
      reset({
        ...defaultValues,
      }, { keepDefaultValues: false });
    }
  }, [integrationsData?.zendesk, reset, zendeskDeleteLoading]);

  canSubmitForm = !zendeskUpsertLoading && !integrationsLoading && !zendeskDeleteLoading;
  const isActive = integrationsData?.zendesk?.active;

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="form-group">
        <label htmlFor="publicId">Public ID</label>
        <div className="field">
          <input
            id="publicId"
            type="text"
            className="form-control"
            placeholder="Public ID will be displayed here once activated"
            readOnly
            {...register('publicId')}
          />
        </div>
      </div>
      { isActive && (
      <div className="form-group">
        <div className="form-check">
          <input
            id="acceptConsequences"
            type="checkbox"
            className="form-check-input mt-2"
            {...register('acceptConsequences')}
          />
          <label htmlFor="acceptConsequences" className="form-check-label">I understand re-generating/deleting the public id will break existing Zendesk integrations</label>
        </div>
      </div>
      )}
      { isActive && (
        <>
          <div className="form-group">
            <label htmlFor="supportSessionDuration">Support Session Duration (in hours)</label>
            <div className="field">
              <input
                id="supportSessionDuration"
                type="number"
                className="form-control"
                placeholder="72"
                {...register('supportSessionDuration')}
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary mr-2"
            disabled={!canSubmitForm || !watchSupportSessionDuration}
            onClick={() => {
              setValue('performOnlyUpdate', true);
            }}
          >
            Update
          </button>
        </>
      ) }
      <button
        type="submit"
        className="btn btn-primary mr-2"
        disabled={!canSubmitForm || (isActive && !watchAcceptConsequences)}
      >
        {isActive ? 'Regenerate' : 'Activate'}
      </button>
      {isActive && (
        <button
          type="submit"
          className="btn btn-primary mr-2"
          disabled={!canSubmitForm || !watchAcceptConsequences}
          onClick={() => {
            setValue('performDeletionOnSubmit', true);
          }}
        >
          Delete
        </button>
      )}
    </form>
  );
};

export default IntegrationZendeskForm;
