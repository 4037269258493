import React, {
  memo, useCallback, useState, useEffect,
} from 'react';
import Modal from 'react-modal';
import { useQuery } from 'react-query';

import { replacePlaceholderWithMockData } from '../../../../utils/sms';
import { getShortenedPermalink } from '../../../../queries/Merchant';

import ModalContent from './ModalContent';

const SendGroupMessageModal = ({
  merchantData,
  customerCount,
  isOpen,
  group,
  message,
  onClose,
  onConfirm,
  refreshCount,
  mmsMediaURL,
  setScheduled,
  setScheduledTime,
  scheduled,
  scheduledTime,
  scheduledGroupMessageIsActive,
}) => {
  const [saving, setSaving] = useState(false);
  const [loadingPlaceholder, setLoadingPlaceholder] = useState(false);
  const [messageExample, setMessageExample] = useState('');

  const { data: shortenedPermalink } = useQuery(
    'shortenedPermalink',
    getShortenedPermalink,
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    setSaving(false);
  }, [isOpen]);

  useEffect(() => {
    async function promiseHelper() {
      setLoadingPlaceholder(true);
      const mockMessage = await replacePlaceholderWithMockData(
        merchantData, message, undefined, shortenedPermalink,
      );
      setMessageExample(mockMessage);
      setLoadingPlaceholder(false);
    }
    promiseHelper();
  }, [merchantData, message, shortenedPermalink]);

  const handleConfirm = useCallback(async () => {
    setSaving(true);

    try {
      await onConfirm();
    } finally {
      setSaving(false);
    }
  }, [onConfirm]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      className="modal send-group-message"
      overlayClassName="modal-overlay"
      contentLabel="Send Group Message Modal"
    >
      <ModalContent
        group={group}
        saving={saving}
        customerCount={customerCount}
        onClose={onClose}
        handleConfirm={handleConfirm}
        refreshCount={refreshCount}
        messageExample={messageExample}
        mmsMediaURL={mmsMediaURL}
        setScheduled={setScheduled}
        setScheduledTime={setScheduledTime}
        scheduled={scheduled}
        scheduledTime={scheduledTime}
        loadingPlaceholder={loadingPlaceholder}
        timezone={merchantData?.timezone}
        scheduledGroupMessageIsActive={scheduledGroupMessageIsActive}
      />
    </Modal>
  );
};

export default memo(SendGroupMessageModal);
