import React, {
  memo, useState, useEffect, useCallback,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import {
  API,
  APIMethods,
  APIServiceConstants,
} from '../../redux/actions/BlueprintAPI';
import RepliesScatterChart from './RepliesScatterChart';
import MessageTypeSelect from './MessageTypeSelect';
import { getMessagesWithSentimentScore } from '../../utils/messageSentiment';
import { MerchantActionCreators } from '../../redux/actions/merchant';

/** @typedef {import('../../redux/reducers').RootState} RootState */

const { getMerchantData: getMerchantDataAction } = MerchantActionCreators;

const mapMessagesSentiments = (messages, merchantCurrency) => {
  const messagesWithSentiments = getMessagesWithSentimentScore(messages);

  return messagesWithSentiments.map((m) => ({
    x: m.score,
    y: m.ltv,
    message: m.message,
    customerName: m.fullName,
    lastOrderItems: m.lastOrderItems
      ? m.lastOrderItems
      : 'No previous purchases',
    currency: merchantCurrency,
  }));
};

const RepliesAnalyticsPage = () => {
  const [messages, setMessages] = useState([]);
  const [previousMessageType, setPreviousMessageType] = useState('');
  const dispatch = useDispatch();
  // I don't know. - DI 15/05/21
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const merchantData = useSelector(
    (/** @type {RootState} */state) => state.MerchantReducer.merchantData, shallowEqual,
  )
    || {};

  const getMerchantInfo = useCallback(() => {
    const needsToGetMerchantData = Object.keys(merchantData).length === 0;

    if (needsToGetMerchantData) {
      dispatch(getMerchantDataAction());
    }
  }, [dispatch, merchantData]);

  const fetchMessagesWithSentiments = useCallback(
    async (withPreviousMessageType = '') => {
      const response = await API(
        APIMethods.GET,
        APIServiceConstants.SERVICE,
        `sms/sentiments?previousMessageType=${encodeURIComponent(
          withPreviousMessageType,
        )}`,
      );

      const mapping = mapMessagesSentiments(
        response.data.messages,
        merchantData.currencySymbol,
      );

      setMessages(mapping);
    },
    [merchantData.currencySymbol],
  );

  useEffect(() => {
    getMerchantInfo();
  // I'm not sure what the pattern should be for exhaustive deps on "useEffect only once" empty deps arrays - DI 14/05/21
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (merchantData) {
      fetchMessagesWithSentiments();
    }
  }, [merchantData, fetchMessagesWithSentiments]);

  const handleMesageTypeChange = useCallback(
    async (newType) => {
      setPreviousMessageType(newType);

      await fetchMessagesWithSentiments(newType);
    },
    [fetchMessagesWithSentiments],
  );

  return (
    <PageBuilder>
      <SEO title="Replies Analytics | Blueprint" />
      <div className="a-view sms-sentiments">
        <div className="container">
          <div className="top-bar">
            <h2 className="title">Replies Analytics</h2>

            <MessageTypeSelect
              onChange={handleMesageTypeChange}
              value={previousMessageType}
            />
          </div>

          <RepliesScatterChart messages={messages} />
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(RepliesAnalyticsPage);
