import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import UpdatePasswordForm from '../../components/forms/UpdatePasswordForm';
import { AuthActionCreators } from '../../redux/actions/auth';

class Security extends Component {
  constructor(props) {
    super(props);

    this.state = { submitting: false, error: null };
  }

  handleSubmit = (data) => {
    const { actions, submitting, toastManager } = this.props;
    if (!submitting) {
      this.setState({ submitting: true, error: null });
      actions
        .updatePassword(data)
        .then(() => {
          const { pwErr } = this.props;
          if (pwErr) {
            this.setState({ error: pwErr, submitting: false });
            toastManager.add('Something went wrong', {
              appearance: 'error',
              autoDismiss: true,
            });
          } else {
            this.setState({ submitting: false });
            actions.resetForm('updatePasswordForm');
            toastManager.add('Password successfully changed', {
              appearance: 'success',
              autoDismiss: true,
            });
          }
          return null;
        })
        .catch((e) => {
          console.log('e', e);
          this.setState({ error: e.message, submitting: false });
          toastManager.add('Something went wrong', {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    }
  };

  render() {
    const { submitting, error } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="settings-card">
              <UpdatePasswordForm
                onSubmit={this.handleSubmit}
                loading={submitting}
                subErr={error}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pwErr: state.AuthReducer.pwErr,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(AuthActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(Security),
);
