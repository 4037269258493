import React, {
  memo, useEffect, useState, useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MerchantActionCreators } from '../../redux/actions/merchant';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import { BlueprintJSONAPI, APIMethods } from '../../redux/actions/BlueprintAPI';
import CustomerAnalyitcsBlocks from './CustomerAnalyticsBlocks';
import CustomerAnalyitcsTimeline from './CustomerAnalyticsTimeline';
import SmsOptinsAnalyticsTimeline from './SmsOptinsAnalyticsTimeline';

const LOADINGSTATUS_LOADING = 'LOADING';
const LOADINGSTATUS_COMPLETE = 'COMPLETE';
const LOADINGSTATUS_ERROR = 'ERROR';

const LoadingWrapper = ({ loadingStatus, children }) => {
  if (loadingStatus === LOADINGSTATUS_LOADING) {
    return <div>Loading...</div>;
  } if (loadingStatus === LOADINGSTATUS_ERROR) {
    return (
      <div>
        Error loading.&nbsp;
        <button type="button" onClick={window.location.reload}>
          Click to retry.
        </button>
      </div>
    );
  }
  return children;
};

// Custom hook to fetch merchantData
const useMerchantData = () => {
  const dispatch = useDispatch();
  // Urg. Redux.
  const { merchantData } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */ state) => state.MerchantReducer,
  );
  const getMerchantDataCallback = useCallback(() => {
    const { getMerchantData } = MerchantActionCreators;
    dispatch(getMerchantData());
  }, [dispatch]);
  useEffect(() => {
    if (!merchantData.id) {
      getMerchantDataCallback();
    }
  }, [getMerchantDataCallback, merchantData]);
  return merchantData;
};

const CustomerAnalyticsPage = () => {
  const [loadingStatus, setLoadingStatus] = useState(LOADINGSTATUS_LOADING);
  const [data, setData] = useState({ customerTimeline: [], smsOptinTimeline: [] });
  const merchantData = useMerchantData();

  useEffect(() => {
    setLoadingStatus(LOADINGSTATUS_LOADING);
    BlueprintJSONAPI(APIMethods.GET, 'analytics', 'getCustomerAnalytics')
      .then((response) => {
        setLoadingStatus(LOADINGSTATUS_COMPLETE);
        setData(response.data);
        return response;
      })
      .catch((err) => {
        setLoadingStatus(LOADINGSTATUS_ERROR);
        console.log(err);
      });
  }, []);

  const { currency } = merchantData;

  return (
    <PageBuilder>
      <SEO title="Customer Analytics | Blueprint" />
      <div className="a-view analytics">
        <div className="container">
          <div className="top-bar">
            <h2 className="title">Customer Analytics</h2>
          </div>
          <LoadingWrapper loadingStatus={loadingStatus}>
            <CustomerAnalyitcsBlocks data={data} currency={currency} />
            <SmsOptinsAnalyticsTimeline data={data.smsOptinTimeline} />
            <CustomerAnalyitcsTimeline data={data.customerTimeline} />
          </LoadingWrapper>
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(CustomerAnalyticsPage);
