import React from 'react';
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PageBuilder } from '../components/global/PageBuilder';
import PaymentForm from '../components/forms/PaymentForm';

const stripePromise = loadStripe(/** @type {String} */(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY));

export const MerchantBilling = () => {
  const { merchantId, stripePlanId } = useParams();
  return (
    <PageBuilder>
      <Elements stripe={stripePromise}>
        <div className="a-view dashboard">
          <div className="container">
            <h2 className="title">Subscribe to your plan here:</h2>
            <PaymentForm merchantId={merchantId} stripePlanId={stripePlanId} />
          </div>
        </div>
      </Elements>
    </PageBuilder>
  );
};

export default MerchantBilling;
