import React from 'react';
import { BImage } from '../global/BImage';

export const HelpBubble = ({
  text,
  left = false,
  small = false,
  lazyLoad = false,
}) => text && (
<div
  className={
        `help-bubble${left ? ' left' : ''}${small ? ' small' : ''}`
      }
>
  <BImage
    src="https://static.blueprint.store/illustrations/dashboard/bp-help.svg"
    alt="HELP"
    lazyLoad={lazyLoad}
  />
  <span className="text">{text}</span>
</div>
);

export default HelpBubble;
