import React, { memo, useCallback, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import ListSelectorModal from '../../../../global/ListSelectorModal';

/** @typedef {import('../../../../../redux/reducers').RootState} RootState */

/**
 *
 * @param {Object} props
 * @param {*} props.value
 * @param {Function} props.onChange
 * @returns
 */
const ListPicker = ({ value, onChange }) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  const [listSelectorOpen, setListSelectorOpen] = useState(false);

  const toggleListSelectorModal = useCallback(
    () => setListSelectorOpen(!listSelectorOpen),
    [listSelectorOpen],
  );

  const handleListSelection = useCallback((selectedLists) => {
    onChange('value', selectedLists);
  }, [onChange]);

  const buttonTitle = value.length > 0 ? `${value.length} list(s) selected` : 'Choose lists';
  const selectedListNames = value.map((list) => <p key={list.id}>{list.name}</p>);

  return (
    <div className="col-md-3">
      <button
        className="btn btn-primary btn-block"
        type="button"
        onClick={toggleListSelectorModal}
        ref={setTriggerRef}
      >
        {buttonTitle}
      </button>
      {visible && value.length > 0 && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {selectedListNames}
        </div>
      )}
      {listSelectorOpen && (
        <ListSelectorModal
          isOpen={listSelectorOpen}
          onClose={toggleListSelectorModal}
          onChange={handleListSelection}
          value={value.map((list) => list.id)}
        />
      )}
    </div>
  );
};

export default memo(ListPicker);
