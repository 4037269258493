import React from 'react';
import Group from './Group';

export default ({ groups }) => {
  if (!groups || !groups.length) {
    return <div className="no-groups">N/A</div>;
  }

  return (
    <ul>
      {groups.map((group, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Group key={i} group={group} />
      ))}
    </ul>
  );
};
