import React, { memo } from 'react';
import MessageGroup from './MessageGroup';

const MessageGroups = ({
  loading, groups = [], onDelete, groupMessagingListsEnabled = true,
}) => {
  if (loading) {
    return null;
  }

  return (
    <table className="table table-hover message-groups-table">
      <thead className="thead-dark">
        <tr className="row">
          <th className="col-md-2">Group Name</th>
          <th className="d-none d-md-table-cell col-md-1">Type</th>
          <th className="d-none d-md-table-cell col-md-1">Qty</th>
          <th className="d-none d-md-table-cell col-md-6">Notes</th>
          <th className="col-md-2">Actions</th>
        </tr>
      </thead>

      <tbody className="container">
        {groups.length === 0 ? (
          <tr>
            <td className="col-md-12">There are currently no message groups</td>
          </tr>
        ) : (
          groups.map((group) => (
            <MessageGroup
              key={group.id}
              group={group}
              onDelete={onDelete}
              groupMessagingListsEnabled={groupMessagingListsEnabled}
            />
          ))
        )}
      </tbody>
    </table>
  );
};

export default memo(MessageGroups);
