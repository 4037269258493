import {
  BlueprintJSONAPI, APIMethods, APIServiceConstants,
} from '../../../redux/actions/BlueprintAPI';

/**
 * @param {Object} body
 * @param {String} body.publicId
 * @param {String} body.accessToken
 * @returns
 */
const deleteIntegration = async (body) => {
  const response = await BlueprintJSONAPI(
    APIMethods.DELETE,
    APIServiceConstants.INTEGRATIONS,
    'smartrr',
    body,
  );

  return response?.data;
};

export default deleteIntegration;
