import React, { memo, useCallback } from 'react';
import { messageTypes } from '../../utils/messageType';

const MessageTypeSelect = ({ onChange, value }) => {
  const handleOnChange = useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <select
      className="form-control message-type-select"
      onChange={handleOnChange}
      value={value}
    >
      <option value="" key="-1">
        Select a previous message type
      </option>

      {messageTypes.map((pred, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <option value={pred.module} key={index}>
          {pred.label}
        </option>
      ))}
    </select>
  );
};

export default memo(MessageTypeSelect);
