import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React, { memo, useState } from 'react';

import MessageIntervalSelect, { TIME_UNIT } from '../../MessageIntervalSelect';
import { HelpBubble } from '../../HelpBubble';
import { DemoPhone } from '../../DemoPhone';
import ProdVarAdderWrapper from './ProdVarAdderWrapper';
import { isValidInterval } from '../../../../utils/messageInteval';
import { SegmentCharCounter } from '../../SegmentCharCounter';
import { BPToggle } from '../../BPToggle';

const THIRTY_DAYS_IN_MINUTES = 43200;

const DEFAULT_UPSELL_INFO = {
  name: '',
  products: [],
  triggers: [],
  messageContent: '{brand_name}: Hey {first_name} 👋 After your last purchase we thought you’d really like to try {recommended_products} 📦 1 click purchase link below 👇 {link_to_checkout}',
  messageInterval: THIRTY_DAYS_IN_MINUTES,
  skipIfPurchasedBefore: false,
};

const UpSellEditor = ({
  upsell, isForCreating, handleUpdateSubmit, upsellUpdating,
}) => {
  const [upsellObj, setUpsellObj] = useState(
    isForCreating ? DEFAULT_UPSELL_INFO : upsell,
  );
  const [isInvalid, setIsInvalid] = useState(false);

  const handleFieldEdit = (event) => {
    let fieldName = event.target.name;
    const fieldValue = event.target.value;

    if (!fieldName) {
      fieldName = 'messageInterval';

      if (!isValidInterval(fieldValue)) {
        setIsInvalid(true);
      } else {
        setIsInvalid(false);
      }
    }

    setUpsellObj({ ...upsellObj, [fieldName]: fieldValue });
  };

  const handleSkipToggle = () => {
    setUpsellObj({ ...upsellObj, skipIfPurchasedBefore: !upsellObj.skipIfPurchasedBefore });
  };

  const handleProductChange = (newProductsArr, type) => {
    setUpsellObj({
      ...upsellObj,
      [type]: newProductsArr,
    });
  };

  const getUpdatedUpsellObj = () => {
    const updatedObj = {
      upsellId: upsellObj.id,
    };

    if (upsell.name !== upsellObj.name) {
      updatedObj.name = upsellObj.name;
    }

    if (upsell.messageContent !== upsellObj.messageContent) {
      updatedObj.message = upsellObj.messageContent;
    }

    if (upsell.skipIfPurchasedBefore !== upsellObj.skipIfPurchasedBefore) {
      updatedObj.skipIfPurchasedBefore = upsellObj.skipIfPurchasedBefore;
    }

    if (upsell.messageInterval !== upsellObj.messageInterval) {
      updatedObj.sendAfterInterval = upsellObj.messageInterval;
    }

    if (
      JSON.stringify(upsell.products) !== JSON.stringify(upsellObj.products)
    ) {
      const filteredProducts = upsellObj.products.filter(
        (product) => product.prodToGetVariantId,
      );
      updatedObj.variantIds = filteredProducts.length > 0
        ? filteredProducts.map((product) => product.prodToGetVariantId)
        : null;
    }

    return updatedObj;
  };

  const getNewUpsellObj = () => {
    const filteredProducts = upsellObj.products.filter(
      (product) => product.prodToGetVariantId,
    );

    return {
      name: upsellObj.name,
      message: upsellObj.messageContent,
      sendAfterInterval: upsellObj.messageInterval,
      skipIfPurchasedBefore: upsellObj.skipIfPurchasedBefore,
      productVariantIds:
          filteredProducts.length > 0
            ? filteredProducts.map((product) => product.prodToGetVariantId)
            : null,
      triggerProdsOrVars: upsellObj.triggers.map((product) => ({
        variantId: product.prodToGetVariantId,
        productId: product.prodToGetProductId,
      })),
    };
  };

  const saveChanges = (event) => {
    event.preventDefault();

    if (isForCreating) {
      handleUpdateSubmit(getNewUpsellObj());
    } else {
      handleUpdateSubmit(getUpdatedUpsellObj());
    }
  };

  return (
    <form onSubmit={saveChanges}>
      <div className="row">
        <div className="col-md-4 name-container">
          <label htmlFor="upsellName">
            <span className="section-head">Upsell Name</span>
          </label>
          <input
            type="text"
            name="name"
            id="upsellName"
            value={upsellObj.name}
            onChange={handleFieldEdit}
            placeholder="Please enter an upsell name here"
            className="form-control"
          />
          <label className="send-after-wrapper">
            <span className="section-head">Send after</span>
            <HelpBubble
              small
              text="After this set timeframe the upsell will be sent to customers who purchased a trigger product/variant"
            />
            <MessageIntervalSelect
              className="grey"
              name="messageInterval"
              customValueUnit={TIME_UNIT.days}
              handleChange={handleFieldEdit}
              value={upsellObj.messageInterval}
            />
          </label>
        </div>
        <ProdVarAdderWrapper
          upsellObj={upsellObj}
          handleProductChange={handleProductChange}
          isForCreating={isForCreating}
        />
      </div>
      <div className="row upsell-second-row">
        <div className="col-md-8">
          <div className="cart-message-editor">
            <label htmlFor="upsellMessageContent">
              <span className="section-head">Upsell message</span>
            </label>
            <div>
              <textarea
                value={upsellObj.messageContent}
                onChange={handleFieldEdit}
                className="message-content"
                id="upsellMessageContent"
                name="messageContent"
                placeholder="Please enter an upsell message content here"
                rows={3}
                maxLength={400}
              />
              <SegmentCharCounter
                text={upsellObj.messageContent}
                helpBubbleSmall={false}
              />
            </div>
          </div>
          <div className="upsell-option-container">
            <label htmlFor="skip-upsell-toggle">
              <span className="section-head">Send if purchased before?</span>
              <HelpBubble
                small
                text="If activated, won't send upsell messages when a customer already purchased the product before."
              />
            </label>
            <div>
              <BPToggle
                id="skip-upsell-toggle"
                active={upsellObj.skipIfPurchasedBefore}
                handleClick={handleSkipToggle}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="cart-message-editor">
            <label htmlFor="demoPhone">
              <span className="section-head">Upsell Preview</span>
            </label>
            <DemoPhone
              id="demoPhone"
              messages={[
                {
                  content: upsellObj.messageContent,
                  date: '03/02 - 9AM',
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="row submit-container">
        <div className="col-md-12 d-flex justify-content-center">
          <button
            className="btn btn-primary"
            type="submit"
            disabled={upsellUpdating || isInvalid}
          >
            {upsellUpdating ? 'Saving' : 'Save Upsell'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default memo(UpSellEditor);
