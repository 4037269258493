import {
  APIServiceConstants,
  API,
  APIMethods,
} from '../../redux/actions/BlueprintAPI';

const getShortenedPermalink = async () => {
  const { data: { url } } = await API(
    APIMethods.GET,
    APIServiceConstants.SERVICE,
    'common/getShortenedPermalink',
  );

  return url;
};

export default getShortenedPermalink;
