import React, {
  useMemo,
  useEffect,
} from 'react';
import { useTable, useRowSelect } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { CrossSellsActionCreators } from '../../../redux/actions/crossSells';
import { formatValueAsPercentage } from '../../../utils/numerical';

import IndeterminateCheckbox from '../../global/IndeterminateCheckbox';

const {
  getAllCrossSells: getAllCrossSellsActions,
  setCrossSellSelectedProducts: setCrossSellSelectedProductsAction,
} = CrossSellsActionCreators;

const Table = () => {
  const dispatch = useDispatch();
  const columns = useMemo(
    () => [
      {
        Header: 'Product [Variant]',
        accessor: 'initialProductVariantTitle',
      },
      {
        Header: 'Purchases',
        accessor: 'nInitialPurchases',
      },
      {
        Header: 'Follow on Product',
        accessor: 'followingProductVariantTitle',
      },
      {
        Header: 'Follow on Purchases',
        accessor: 'nFollowingPurchases',
      },
      {
        Header: 'Rate',
        accessor: 'rate',
        Cell: ({ cell: { value } }) => formatValueAsPercentage(value, 0),
      },
      {
        Header: 'Avg. Order Gap',
        accessor: 'avgGapDays',
        Cell: ({ cell: { value } }) => (`${(Number(value).toFixed(1))} days`),
      },
    ],
    [],
  );

  const {
    crossSellsData,
  } = useSelector((/** @type {import('../../../redux/reducers').RootState} */state) => state.UpsellsReducer);

  useEffect(() => {
    dispatch(getAllCrossSellsActions());
  }, [dispatch]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
  } = useTable({ columns, data: crossSellsData },
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((cols, data) => [
        {
          id: 'selection',
          Cell: ({ row }) => {
            const selected = Object.keys(data.instance.state.selectedRowIds);
            const disabled = selected.length > 0 && selected.indexOf(row.id) === -1;

            return (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} disabled={disabled} />
              </div>
            );
          },
        },
        ...cols,
      ]);
    });

  useEffect(() => {
    const ids = Object.keys(selectedRowIds).map((id) => parseInt(id, 10));
    const products = crossSellsData.filter((crosssell, key) => ids.indexOf(key) > -1);
    dispatch(setCrossSellSelectedProductsAction(products));
  }, [selectedRowIds, crossSellsData, dispatch]);

  return (
    <table {...getTableProps()} className="table table-hover">
      <thead className="thead-dark">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
