import { CustomerActionTypes } from '../../types';
import { APIServiceConstants, BlueprintAPI } from '../BlueprintAPI';

const { SERVICE } = APIServiceConstants;

export const CustomerActionCreators = {
  getCustomerData: (customerPhoneId) => async (dispatch) => {
    dispatch({
      type: CustomerActionTypes.GET_CUS_REQ,
    });
    try {
      const sessionToken = localStorage.getItem('sToken');
      const data = { sessionToken, customerPhoneId };
      const response = await BlueprintAPI(SERVICE, 'getCustomerData', data);
      dispatch({
        type: CustomerActionTypes.GET_CUS_RES,
        payload: response.data.customer || {},
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: CustomerActionTypes.GET_CUS_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
  addCustomerNote: (customerPhoneId, note) => async (dispatch) => {
    dispatch({
      type: CustomerActionTypes.ADD_CUSNOTE_REQ,
    });
    try {
      const sessionToken = localStorage.getItem('sToken');
      const data = { sessionToken, customerPhoneId, note };
      const response = await BlueprintAPI(SERVICE, 'addCustomerNote', data);
      dispatch({
        type: CustomerActionTypes.ADD_CUSNOTE_RES,
        payload: response.data,
      });
      dispatch(CustomerActionCreators.getCustomerData(customerPhoneId));
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: CustomerActionTypes.ADD_CUSNOTE_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
};

export default CustomerActionCreators;
