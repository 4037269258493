import React from 'react';
import { Link } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';

import { BPToggle } from '../BPToggle';

import { getDaysAndHours } from '../../../utils/dates';
import { settings } from '../../../utils/crossSells';
import CrossSellInfo from './CrossSellInfo';

const CrossSellsTableRow = ({
  upsell,
  index,
  toggleUpSellState,
  handleDelete,
  type,
}) => {
  const isAvailable = upsell.unavailable_at === null;

  const trClassName = isAvailable ? 'available' : 'unavailable';

  return (
    <Draggable
      draggableId={String(upsell.id)}
      index={index}
      isDragDisabled={!isAvailable}
    >
      {(provided, snapshot) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`${trClassName} d-flex ${
            snapshot.isDragging ? 'dragging' : ''
          }`}
        >
          <td className="col-1">
            <img
              src="https://static.blueprint.store/illustrations/drag_drop.svg"
              alt="drag & drop icon"
              title="drag & drop icon"
              className="drag-drop-icon"
            />
            {index + 1}
          </td>
          <td className="col-6">
            {upsell.name}
            <span className="custom-tooltip-container selling-type ml-2">
              <span className="badge badge-pill badge-primary">
                {upsell.products.length}
                {' '}
                product
                {' '}
                { upsell.products.length > 1 ? 's' : ''}
              </span>
              <CrossSellInfo triggerProducts={upsell.triggers} targetProducts={upsell.products} type={type} />
            </span>

          </td>
          <td className="col-1">{getDaysAndHours(upsell.messageInterval)}</td>
          <td className="col-2 toggle-container">
            <BPToggle
              active={upsell.active}
              handleClick={() => toggleUpSellState({ id: upsell.id })}
              disabled={!isAvailable}
            />
          </td>
          <td className="col-2 action-btn-container">
            <Link
              to={{ pathname: `${settings[type].mainLink}/edit/${upsell.id}`, state: { type } }}
              className="minimal-black-btn"
            >
              Edit
            </Link>
            <button
              className="minimal-red-btn"
              value={upsell.id}
              onClick={handleDelete}
              type="button"
            >
              Delete
            </button>
          </td>
        </tr>
      )}
    </Draggable>
  );
};

export default CrossSellsTableRow;
