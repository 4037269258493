import React, { useState, useEffect, useCallback } from 'react';
import { MSG_TYPE_MMS } from '../../utils/messageType';
import { BImage } from '../global/BImage';
import EmojiPicker from './EmojiPicker';
import ImageUpload from './ImageUpload';
import { SegmentCharCounter } from './SegmentCharCounter';

export const EditorTextarea = (props) => {
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const {
    value,
    onChange,
    onSend,
    onMsgTypeChange,
    prependToMessage,
    addMMS,
    disabled = false,
    rows = 2,
    msgType,
    merchant,
    sendButtonLabel = 'Send',
  } = props;
  const textEl = React.createRef();

  useEffect(() => {
    textEl.current.scrollTop = textEl.current.scrollHeight;
  }, [textEl]);

  const emojiSelect = useCallback((_e, data) => prependToMessage(data.emoji), [
    prependToMessage,
  ]);

  const imageUploadSelect = useCallback(
    (url) => {
      if (msgType === MSG_TYPE_MMS) {
        addMMS();
      } else {
        prependToMessage(url);
      }
    },
    [msgType, addMMS, prependToMessage],
  );

  const toggleImageUpload = useCallback(
    () => setImageUploadOpen(!imageUploadOpen),
    [imageUploadOpen],
  );

  const toggleEmojiPicker = useCallback(() => setEmojiOpen(!emojiOpen), [
    emojiOpen,
  ]);

  const handleSend = useCallback(() => {
    if (value.length > 0) {
      onSend();
    }
  }, [onSend, value]);

  return (
    <div className={`editor ${disabled ? 'disabled' : ''}`}>
      <div className="editor-textarea">
        <textarea
          data-gramm_editor="false"
          className="form-control"
          value={value}
          onChange={onChange}
          disabled={disabled}
          rows={rows}
          ref={textEl}
        />
        <SegmentCharCounter
          text={value}
          helpBubbleSmall={false}
          helpBubbleLeft
          msgType={msgType}
        />
        <div className="toggles ignore-react-onclickoutside">
          <BImage
            src="/images/upload-icon.png"
            alt="Image Upload"
            onClick={toggleImageUpload}
          />
          <div>
            {!disabled && imageUploadOpen && (
              <ImageUpload
                isOpen={imageUploadOpen}
                close={toggleImageUpload}
                imageUploadSelect={imageUploadSelect}
                msgType={msgType}
                onMsgTypeChange={onMsgTypeChange}
                merchant={merchant}
              />
            )}
          </div>
          <BImage
            src="https://static.blueprint.store/illustrations/dashboard/bp-emoji-toggle.svg"
            alt="Emojis"
            onClick={toggleEmojiPicker}
          />
          <div className="pickers">
            {!disabled && (
              <EmojiPicker
                isOpen={emojiOpen}
                close={toggleEmojiPicker}
                onPick={emojiSelect}
              />
            )}
          </div>
        </div>
      </div>
      <button className="send-button" onClick={handleSend} disabled={disabled} type="button">
        {sendButtonLabel}
      </button>
    </div>
  );
};

export default EditorTextarea;
