import React from 'react';
import Modal from 'react-modal';

const DeletionModal = ({
  flow,
  isOpen,
  isDeleting,
  close,
  handleDeleteSubmit,
}) => {
  const setClose = () => close(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={setClose}
      className="modal small"
      overlayClassName="modal-overlay"
      contentLabel="Delete Automation"
    >
      <div className="upsell-modal">
        <span className="title">
          Delete
        </span>
        <p>
          Are you sure you want to delete &quot;
          {flow.name}
          &quot;
          ?
        </p>
        <button
          className="btn btn-red delete-upsell-btn"
          onClick={() => handleDeleteSubmit(flow.id)}
          type="button"
          disabled={isDeleting}
        >
          Delete
        </button>
        <button className="btn btn-link" onClick={setClose} type="button">cancel</button>
      </div>
    </Modal>
  );
};

export default DeletionModal;
