import React, {
  useState, useCallback, useEffect, memo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import PredicateSelection from './PredicateSelection';
import GroupDetails from '../GroupDetails';
import { cloneArray, cloneItem } from './helpers';
import { predicates } from './predicates';
import {
  createGroup,
  getSegmentCount,
  updateGroup,
} from '../../../../redux/actions/GroupMessagingAPI';
import { formatDate } from '../../../../utils/dates';

export const editMode = {
  create: {
    title: 'Create New',
    class: 'create',
    showDateAdded: false,
  },
  edit: {
    title: 'Save',
    class: 'edit',
    showDateAdded: true,
  },
};

const EditSegment = ({ group: originalGroup, products }) => {
  const [group, setGroup] = useState(originalGroup);
  const [dirty, setDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const history = useHistory();
  const mode = group.id ? editMode.edit : editMode.create;
  const { addToast } = useToasts();

  // If props have changed, we need to update state
  useEffect(() => {
    setGroup(originalGroup);
  }, [originalGroup]);

  useEffect(() => {
    if (group !== originalGroup || !group.id) {
      setDirty(true);
    }
  // Including all deps produces an infinite loop - DI 17/5/21
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group.predicates]);

  const handleNameChange = useCallback(
    ({ target: { value } }) => setGroup({ ...group, name: value }),
    [group],
  );

  const handleDescriptionChange = useCallback(
    ({ target: { value } }) => setGroup({ ...group, description: value }),
    [group],
  );

  const handleAddPredicateGroup = useCallback(() => {
    setGroup({
      ...group,
      predicates: {
        ...group.predicates,
        predicates: [
          ...group.predicates.predicates,
          {
            combinationMode: 'or',
            predicates: [cloneItem(predicates[0])],
          },
        ],
      },
    });
  }, [group]);

  const handlePredicateChange = useCallback(
    (groupIndex, predicateIndex, predicate) => {
      const newGroupsPredicates = cloneArray(group.predicates.predicates);
      const newSubQueryPredicates = cloneItem(newGroupsPredicates[groupIndex]);

      newSubQueryPredicates.predicates[predicateIndex] = predicate;

      newGroupsPredicates[groupIndex] = newSubQueryPredicates;

      setGroup({
        ...group,
        predicates: {
          ...group.predicates,
          predicates: newGroupsPredicates,
        },
      });
    },
    [group],
  );

  const handleAddPredicate = useCallback(
    (groupIndex) => {
      const newGroupsPredicates = cloneArray(group.predicates.predicates);
      const newSubQueryPredicates = cloneItem(newGroupsPredicates[groupIndex]);

      newSubQueryPredicates.predicates = [
        ...cloneArray(newSubQueryPredicates.predicates),
        cloneItem(predicates[0]),
      ];

      newGroupsPredicates[groupIndex] = newSubQueryPredicates;

      setGroup({
        ...group,
        predicates: {
          ...group.predicates,
          predicates: newGroupsPredicates,
        },
      });
    },
    [group],
  );

  const handleDeletePredicate = useCallback(
    (groupIndex, predicateIndex) => {
      const newGroupsPredicates = cloneArray(group.predicates.predicates);

      if (newGroupsPredicates[groupIndex].predicates.length === 1) {
        newGroupsPredicates.splice(groupIndex, 1);

        setGroup({
          ...group,
          predicates: {
            ...group.predicates,
            predicates: newGroupsPredicates,
          },
        });
      } else {
        const newSubQueryPredicates = cloneItem(
          newGroupsPredicates[groupIndex],
        );

        newSubQueryPredicates.predicates.splice(predicateIndex, 1);

        newGroupsPredicates[groupIndex] = newSubQueryPredicates;

        setGroup({
          ...group,
          predicates: {
            ...group.predicates,
            predicates: newGroupsPredicates,
          },
        });
      }
    },
    [group],
  );

  const handleSubmit = useCallback(async () => {
    if (!group.name || !group.description) {
      addToast('Please provide a name and a description.', {
        appearance: 'warning',
        autoDismiss: true,
      });

      return;
    }

    setSaving(true);

    try {
      const newGroup = {
        ...group,
        predicates: JSON.stringify(group.predicates),
      };

      if (group.id) {
        await updateGroup(newGroup);
      } else {
        await createGroup(newGroup);
      }

      addToast('The segment has been successfully saved.', {
        appearance: 'success',
        autoDismiss: true,
      });

      history.push('/group-messaging');
    } catch (e) {
      addToast('The segment could not be saved.', {
        appearance: 'error',
        autoDismiss: true,
      });

      setSaving(false);
    }
  }, [addToast, group, history]);

  const handleCalculateSize = useCallback(async () => {
    try {
      setCalculating(true);

      const newCount = await getSegmentCount(group.predicates);

      setGroup({
        ...group,
        count: newCount,
        countUpdatedAt: new Date().toISOString(),
      });

      setDirty(false);
    } catch (e) {
      addToast('The segment count could not be calculated.', {
        appearance: 'error',
        autoDismiss: true,
      });
    } finally {
      setCalculating(false);
    }
  }, [addToast, group]);

  return (
    <div className="edit-segment">
      <form>
        <div className="row">
          <div className="col-md-5">
            <GroupDetails
              description={group.description}
              name={group.name}
              setName={handleNameChange}
              setDescription={handleDescriptionChange}
              type="segment"
            />
          </div>
        </div>

        <label htmlFor="audiencePredicateSelection">Segment your Audience</label>

        {group.predicates.predicates.map((predicate, index) => (
          <PredicateSelection
            id="audiencePredicateSelection"
            // eslint-disable-next-line react/no-array-index-key
            key={`predicate-group-${index}`}
            onPredicateChange={(pIndex, p) => handlePredicateChange(index, pIndex, p)}
            onAddPredicate={() => handleAddPredicate(index)}
            onDeletePredicate={(pIndex) => handleDeletePredicate(index, pIndex)}
            selectedPredicates={predicate.predicates}
            predicateGroupIndex={index}
            products={products}
          />
        ))}

        <div className="row">
          <div className="col-md-5">
            <button
              className="minimal-grey-btn"
              type="button"
              onClick={handleAddPredicateGroup}
            >
              + AND
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 subscribers">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleCalculateSize}
            >
              {calculating ? 'Calculating...' : 'Calculate Size'}
            </button>

            <div className="d-flex flex-column">
              <span>
                {group.count || 0}
                {' '}
                Subscribers
              </span>
              <small>
                {group.countUpdatedAt && (
                  <>
                    {' '}
                    (last updated
                    {formatDate(group.countUpdatedAt)}
                    )
                  </>
                )}
              </small>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 save">
            <button
              className="btn btn-primary"
              disabled={dirty}
              type="button"
              onClick={handleSubmit}
            >
              {saving ? 'Saving...' : (
                <>
                  {mode.title}
                  {' '}
                  Segment
                </>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default memo(EditSegment);
