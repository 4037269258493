import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { BImage } from '../global/BImage';
import { displayDualDigits, isDateToday } from '../../assets/scripts/core';

export const Contact = ({
  customerPhoneId,
  name,
  number,
  channel,
  lstMsg,
  active,
  unreadMessages,
  handleClick,
  supportFlagged,
}) => {
  const onClick = useCallback(() => {
    handleClick(customerPhoneId);
  }, [customerPhoneId, handleClick]);
  const lastMessageTime = useMemo(() => {
    const lstMsgTime = new Date(lstMsg);

    if (isDateToday(lstMsgTime)) {
      return (
        `${lstMsgTime.getHours()}:${displayDualDigits(lstMsgTime.getMinutes())}`
      );
    }
    return (
      `${displayDualDigits(lstMsgTime.getDate())
      }/${
        displayDualDigits(lstMsgTime.getMonth() + 1)
      }/${
        lstMsgTime.getFullYear()}`
    );
  }, [lstMsg]);
  const channelIcon = useMemo(() => {
    switch (channel) {
      case 'whatsapp':
        return { src: 'bp-whatsapp-icon.svg', alt: 'WhatsApp' };
      case 'messenger':
        return { src: 'bp-messenger-icon.svg', alt: 'Messenger' };
      case 'webbot':
        return { src: 'bp-webbot.svg', alt: 'WebBot' };
      default:
        return { src: 'bp-sms-icon.svg', alt: 'SMS' };
    }
  }, [channel]);
  const title = name || number;
  const subTitle = name ? number : '';

  const contactClassNames = useMemo(() => classNames('contact', {
    active,
    support: supportFlagged === 1,
  }), [active, supportFlagged]);

  return (
    <div className={contactClassNames} onClick={onClick} role="button" tabIndex={0}>
      <div className="row">
        <div className="col-md-2 icon">
          <BImage
            src={
              `https://static.blueprint.store/illustrations/${channelIcon.src}`
            }
            alt={channelIcon.alt}
            lazyLoad={false}
            title={channelIcon.alt}
          />
        </div>
        <div className="col-md-10">
          <span className="contact-title">
            {title}
            {unreadMessages > 0 && (
              <span className="unread-counter">
                (
                {unreadMessages}
                )
              </span>
            )}
          </span>
          <div className="contact-info">
            <span className="contact-sub-title">{subTitle}</span>
            <span className="contact-lst-msg">{lastMessageTime}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
