import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import IntegrationKlaviyoForm from '../../components/forms/IntegrationKlaviyoForm';
import { IntegrationActionCreators } from '../../redux/actions/integrations';

const {
  getMerchantIntegrations,
  upsertKlaviyoIntegration,
  testKlaviyoIntegration,
  resetKlaviyoIntegrationState,
  getKlaviyoLists,
} = IntegrationActionCreators;

const IntegrationsHubKlaviyoPage = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  let klaviyoIntegrationData = null;

  const {
    integrationsLoading,
    integrationsLoaded,
    integrationsData,
    klaviyoUpsertSuccess,
    klaviyoUpsertError,
    klaviyoListsLoaded,
    klaviyoListsLoading,
    klaviyoLists,
    klaviyoListsError,
    klaviyoTestSuccess,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */state) => state.IntegrationsReducer,
  );

  const [currentApiKeyData, setCurrentApiKeyData] = useState(undefined);

  useEffect(() => {
    if (!integrationsLoaded && !integrationsLoading) {
      dispatch(getMerchantIntegrations());
    }
  }, [dispatch, integrationsLoaded, integrationsLoading]);

  useEffect(() => {
    if (!klaviyoListsLoaded && !klaviyoListsLoading && integrationsData.klaviyo) {
      const {
        private_api_key: privateKey,
        public_api_key: publicKey,
      } = integrationsData.klaviyo.authData;

      dispatch(getKlaviyoLists(publicKey, privateKey));
    }
  }, [dispatch, klaviyoListsLoaded, klaviyoListsLoading, integrationsData]);

  useEffect(() => {
    if (klaviyoTestSuccess && currentApiKeyData && !klaviyoListsLoaded && !klaviyoListsLoading) {
      const { publicKey, privateKey } = currentApiKeyData;
      dispatch(getKlaviyoLists(publicKey, privateKey));
    }
  }, [
    dispatch,
    klaviyoTestSuccess,
    currentApiKeyData,
    klaviyoListsLoaded,
    klaviyoListsLoading,
  ]);

  useEffect(() => {
    if (klaviyoListsError) {
      addToast(
        `Couldn't fetch the Klaviyo lists. ${klaviyoListsError}`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }
  }, [addToast, klaviyoListsError]);

  useEffect(() => {
    if (klaviyoUpsertSuccess) {
      const messageAdjective = klaviyoIntegrationData?.active
        ? 'updated'
        : 'activated';

      addToast(
        `Your integration with Klaviyo has been ${messageAdjective}`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
    }

    return () => {
      dispatch(resetKlaviyoIntegrationState());
    };
  }, [addToast, dispatch, klaviyoIntegrationData, klaviyoUpsertSuccess]);

  if (integrationsLoaded) {
    if (integrationsData.klaviyo) {
      klaviyoIntegrationData = integrationsData.klaviyo;
    }
  }

  const handleSubmit = async (apiKeyData) => {
    const {
      publicKey,
      privateKey,
      smsConsentEnabled,
      smsListsEnabled,
      subscriptionLists,
      outboundSMSEventsEnabled,
      outboundSubscriptionStatusEnabled,
    } = apiKeyData;

    let subscriptionListsToSend = [];
    if (Array.isArray(subscriptionLists) && subscriptionLists.length && !subscriptionLists[0].id) {
      subscriptionListsToSend = subscriptionLists.map((id) => ({ id }));
    }

    await dispatch(upsertKlaviyoIntegration({
      publicKey,
      privateKey,
      smsConsentEnabled,
      smsListsEnabled,
      subscriptionLists: subscriptionListsToSend,
      outboundSMSEventsEnabled,
      outboundSubscriptionStatusEnabled,
    }));
    return dispatch(getMerchantIntegrations());
  };

  const connectionTestEventCallback = async (apiKeyData) => {
    const { publicKey, privateKey } = apiKeyData;
    setCurrentApiKeyData(apiKeyData);
    return dispatch(testKlaviyoIntegration(publicKey, privateKey));
  };

  return (
    <PageBuilder>
      <SEO title="Klaviyo | Integrations | Blueprint" />
      <div className="a-view integrations-hub">
        <div className="container">
          <div className="row back-btn-container">
            <div className="col-md-12">
              <Link to="/integrations">Back</Link>
            </div>
          </div>

          <div className="top-bar">
            <h2 className="title">
              Klaviyo
              {' '}
              {klaviyoIntegrationData?.active ? '✅' : ''}
            </h2>
          </div>

          {klaviyoUpsertError && (
            <div className="row">
              <div className="col-lg-12">
                <div className="alert alert-danger">
                  <span className="mr-1">🚨</span>
                  {' '}
                  {klaviyoUpsertError}

                </div>
              </div>
            </div>
          )}

          {integrationsLoaded ? (
            <IntegrationKlaviyoForm
              klaviyoIntegrationData={klaviyoIntegrationData}
              onSubmit={handleSubmit}
              connectionTestEventCallback={connectionTestEventCallback}
              klaviyoLists={klaviyoLists}
              klaviyoListsLoading={klaviyoListsLoading}
            />
          ) : (
            <>Loading...</>
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(IntegrationsHubKlaviyoPage);
