import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MerchantActionCreators } from '../../redux/actions/merchant';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import { BlueprintAPI } from '../../redux/actions/BlueprintAPI';
import { BREAKDOWN_BY, BreakdownSelect } from './BreakdownSelect';
import { DEFAULT_RANGES, DateRangeSelect } from './DateRangeSelect';
import Pagination from '../../components/global/Pagination';
import SMSAnalyticsKPIs from './SMSAnalyticsKPIs';
import SMSAnalyticsCampaignTable from './SMSAnalyticsCampaignTable';

const LOADINGSTATUS_LOADING = 'LOADING';
const LOADINGSTATUS_COMPLETE = 'COMPLETE';
const LOADINGSTATUS_ERROR = 'ERROR';

const ROWS_PER_PAGE = 50;
const DEFAULT_DATE_RANGE = {
  start: DEFAULT_RANGES['Last 28 Days'][0],
  end: DEFAULT_RANGES['Last 28 Days'][1],
};

// There is probably a better way to do this....
const LoadingWrapper = ({ loadingStatus, children }) => {
  if (loadingStatus === LOADINGSTATUS_LOADING) {
    return <div>Loading...</div>;
  } if (loadingStatus === LOADINGSTATUS_ERROR) {
    return (
      <div>
        Error loading.&nbsp;
        <a href="/sms-analytics" onClick={window.location.reload}>
          Click to retry.
        </a>
      </div>
    );
  }
  return children;
};

// Custom hook to fetch merchantData
const useMerchantData = () => {
  const dispatch = useDispatch();
  // Urg. Redux.
  const { merchantData } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */ state) => state.MerchantReducer,
  );
  const getMerchantDataCallback = useCallback(() => {
    const { getMerchantData } = MerchantActionCreators;
    dispatch(getMerchantData());
  }, [dispatch]);
  useEffect(() => {
    if (!merchantData.id) {
      getMerchantDataCallback();
    }
  }, [getMerchantDataCallback, merchantData]);
  return merchantData;
};

const SMSAnalyticsPage = () => {
  const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);
  const [breakdownBy, setBreakdownBy] = useState(BREAKDOWN_BY.MESSAGE_TYPE_FULL);
  const [loadingStatus, setLoadingStatus] = useState(LOADINGSTATUS_LOADING);
  const [totals, setTotals] = useState({});
  const [campaignTypes, setCampaignTypes] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const viewRef = useRef(/** @type {HTMLDivElement?} */(null));
  const merchantData = useMerchantData();

  // Dont complete loading until both data and merchantData loaded
  const actualLoadingStatus = !merchantData.id
    ? LOADINGSTATUS_LOADING
    : loadingStatus;

  const { currency } = merchantData;

  const breakdownByChanged = useCallback(
    (newBreakdownBy) => {
      setBreakdownBy(newBreakdownBy);
    },
    [setBreakdownBy],
  );

  const pageChanged = useCallback(
    (newPageNumber) => {
      setCurrentPage(newPageNumber);
    },
    [setCurrentPage],
  );

  useEffect(() => {
    // scroll to top
    if (viewRef && viewRef.current && viewRef.current.parentNode) {
      /** @type {HTMLDivElement} */(viewRef.current.parentNode).scrollTop = 0;
    }

    // update data
    const sliceStart = (currentPage - 1) * ROWS_PER_PAGE;
    const sliceEnd = sliceStart + ROWS_PER_PAGE;
    setTableRows(campaignTypes.slice(sliceStart, sliceEnd));
  }, [currentPage, campaignTypes]);

  // LOADING DATA
  useEffect(() => {
    setLoadingStatus(LOADINGSTATUS_LOADING);
    setCampaignTypes([]);
    setCurrentPage(1);

    const sessionToken = localStorage.getItem('sToken');

    BlueprintAPI('analytics', 'smsAnalytics', {
      dateFrom: dateRange.start,
      dateTo: dateRange.end,
      datePeriod: 'custom',
      sessionToken,
      breakdownBy,
    })
      .then((response) => {
        setLoadingStatus(LOADINGSTATUS_COMPLETE);
        setTotals(response.data.totals);
        setCampaignTypes(response.data.breakdown);
        return response;
      })
      .catch((err) => {
        setLoadingStatus(LOADINGSTATUS_ERROR);
        console.log(err);
      });
  }, [dateRange, breakdownBy]);

  return (
    <PageBuilder>
      <SEO title="Marketing Analytics | Blueprint" />
      <div className="a-view sms-analytics" ref={viewRef}>
        <div className="container">
          <div className="top-bar">
            <h2 className="title">Messaging Analytics</h2>
            <div className="dates-container">
              <DateRangeSelect onChange={setDateRange} value={dateRange} />
            </div>
          </div>
          <LoadingWrapper loadingStatus={actualLoadingStatus}>
            <div className="row">
              <SMSAnalyticsKPIs totals={totals} currency={currency} />
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group form-inline breakdown">
                  <label htmlFor="breakdownSelector">Messaging performance by:</label>
                  <BreakdownSelect
                    id="breakdownSelector"
                    value={breakdownBy}
                    onChange={(event) => breakdownByChanged(event.target.value)}
                  />
                </div>
                <SMSAnalyticsCampaignTable
                  items={tableRows}
                  currency={currency}
                />
                <Pagination
                  current={currentPage}
                  total={campaignTypes.length}
                  perPage={ROWS_PER_PAGE}
                  onChange={pageChanged}
                />
              </div>
            </div>
          </LoadingWrapper>
        </div>
      </div>
    </PageBuilder>
  );
};

export default SMSAnalyticsPage;
