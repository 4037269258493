/* eslint-disable no-unused-vars */
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';

const UpSellDeletionModal = ({
  isOpen,
  close,
  upsellName,
  upsellDeleting,
  handleDeleteSubmit,
}) => {
  const [controller] = useState(new AbortController());
  const setClose = useCallback(() => {
    controller.abort();
    close(false);
  }, [close, controller]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={setClose}
      className="modal small"
      overlayClassName="modal-overlay"
      contentLabel="Delete Upsell"
    >
      <div className="upsell-modal">
        <span className="title">Delete Up-Sell</span>
        <p>
          Are you sure you want to delete the &quot;
          {upsellName}
          &quot; upsell?
        </p>
        <button
          className="btn btn-red delete-upsell-btn"
          onClick={handleDeleteSubmit}
          disabled={upsellDeleting}
          type="button"
        >
          {upsellDeleting ? 'Deleting...' : 'Delete'}
        </button>
        <button className="btn btn-link" onClick={setClose} type="button">cancel</button>
      </div>
    </Modal>
  );
};

export default UpSellDeletionModal;
