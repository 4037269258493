import React, { memo } from 'react';
import CartItem from './CartItem';

const Cart = ({ cart, currencySymbol, removeItem }) => (
  <div className="checkout-link-cart">
    <div className="row head">
      <div className="col-md-4">Product</div>
      <div className="col-md-2">Variant</div>
      <div className="col-md-3">Qty</div>
      <div className="col-md-1 price">Price</div>
    </div>
    {cart.length > 0 ? (
      <div>
        {cart.map((cartItem) => (
          <CartItem
            key={cartItem.id}
            cartItem={cartItem}
            currencySymbol={currencySymbol}
            removeItem={removeItem}
          />
        ))}
      </div>
    ) : (
      <div className="row">
        <div className="col-12">No Products</div>
      </div>
    )}
  </div>
);

export default memo(Cart);
