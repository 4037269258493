import { BlueprintAPI } from '../redux/actions/BlueprintAPI';

export const fetchToken = async () => {
  const sessionToken = localStorage.getItem('sToken');
  try {
    const response = await BlueprintAPI(
      null,
      null,
      { sessionToken },
      `${window.blueprintGetApiBaseURL(
        process.env.REACT_APP_WEBSOCKET_API_URL,
        '/websocket',
      )}/login`,
      false,
    );
    return response.data.token;
  } catch (err) {
    const message = 'Error fetching token';
    console.error(message, err);
    throw new Error(message);
  }
};

export default fetchToken;
