export const scrollToId = (id) => {
  const element = document.getElementById(id);

  if (element) {
    element.scrollIntoView();
  }
};

export const displayDualDigits = (date) => {
  if (date < 10) {
    return `0${date}`;
  }
  return date;
};

export function createDBDate(d) {
  if (!d) {
    return d;
  }
  return (
    `${[d.getFullYear(), d.getMonth() + 1, d.getDate()].join('-')
    } ${
      [d.getHours(), d.getMinutes(), d.getSeconds()].join(':')}`
  );
}

export function isDateToday(d) {
  const today = new Date();
  const todayString = `${today.getFullYear()}-${today.getMonth()}-${today.getDate()}`;
  const dateString = `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;
  return todayString === dateString;
}

export function isDateInPast(d) {
  const now = new Date().getTime();
  return d.getTime() < now;
}

export function roundTimeQuarterHour(time) {
  const timeToReturn = new Date(time);

  timeToReturn.setMilliseconds(
    Math.floor(time.getMilliseconds() / 1000) * 1000,
  );
  timeToReturn.setSeconds(Math.floor(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(Math.floor(timeToReturn.getMinutes() / 15) * 15);
  return timeToReturn;
}

export function minsToHours(mins, raw = false) {
  if (Number.isNaN(mins)) {
    return 'TBC';
  }
  const hours = mins / 60;
  const rHours = Math.floor(hours);
  const minutes = (hours - rHours) * 60;
  const rMinutes = Math.round(minutes);

  if (raw) {
    const string = `${rHours}.${Math.floor(rMinutes * 1.6666666667)}`;
    return parseFloat(string);
  }

  // Contruct the string
  let theString = '';
  if (rHours > 1) {
    theString = `${rHours} hrs `;
  } else if (rHours > 0) {
    theString = `${rHours} hr `;
  }

  if (rMinutes > 1) {
    theString += `${rMinutes} mins`;
  } else if (rMinutes > 0) {
    theString += `${rMinutes} min`;
  }

  if (rMinutes === 0 && rHours === 0) {
    return 'TBC';
  }
  return theString;
}

export function timezoneDate(date) {
  const theDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    0,
    0,
  );
  return theDate;
}

export const dateWithTimeZone = (timeZone, date) => {
  const theDate = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    ),
  );

  const utcDate = new Date(
    theDate.toLocaleString('en-GB', { timeZone: 'UTC' }),
  );
  const tzDate = new Date(
    theDate.toLocaleString('en-GB', { timeZone }),
  );
  const offset = utcDate.getTime() - tzDate.getTime();

  theDate.setTime(theDate.getTime() + offset);

  return theDate;
};

export function addYears(date, years) {
  const theDate = new Date(date);
  theDate.setFullYear(theDate.getFullYear() + years);
  return theDate;
}

export function addMonths(date, months) {
  const theDate = new Date(date);
  theDate.setMonth(theDate.getMonth() + months);
  return theDate;
}

export function addDays(date, days) {
  const theDate = new Date(date);
  theDate.setDate(theDate.getDate() + days);
  return theDate;
}

export function addMins(date, mins) {
  const theDate = new Date(date);
  theDate.setMinutes(theDate.getMinutes() + mins);
  return theDate;
}

export function monthDiff(d1, d2) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export const replaceURlMessages = (message, target = '_self') => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  if (!message) {
    return message;
  }
  return message.replace(
    urlRegex,
    `<a href="$1" target="${target}">$1</a>`,
  );
};
