import React, { memo, useCallback } from 'react';

const VALUE_MODE = {
  lt: 'lt',
  gt: 'gt',
  lte: 'lte',
  gte: 'gte',
  eq: 'eq',
};

const VALUE_ATTRIBUTES = {
  value: 'value',
};

const Value = ({ value, onChange }) => {
  const handleOnChange = useCallback(
    (event) => {
      onChange({
        mode: event.target.value,
        value: 1,
      });
    },
    [onChange],
  );

  const updateProperty = useCallback(
    (event) => {
      onChange({
        mode: value.mode,
        [event.target.name]: [event.target.value],
      });
    },
    [onChange, value.mode],
  );

  return (
    <div className="subquery">
      <select
        className="form-control"
        value={value.mode}
        onChange={handleOnChange}
      >
        <option value={VALUE_MODE.lt}>Less than</option>
        <option value={VALUE_MODE.gt}>Greater than</option>
        <option value={VALUE_MODE.lte}>Less or equal than</option>
        <option value={VALUE_MODE.gte}>Greater or equal than</option>
        <option value={VALUE_MODE.eq}>Equal to</option>
      </select>

      <input
        className="form-control"
        placeholder="amount"
        value={value[VALUE_ATTRIBUTES.value]}
        name={VALUE_ATTRIBUTES.value}
        onChange={updateProperty}
      />
    </div>
  );
};

export default memo(Value);
