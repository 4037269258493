import React, { memo, useMemo, useCallback } from 'react';

const PURCHASED_PRODUCT_ATTRIBUTES = {
  productId: 'productId',
  variantId: 'variantId',
};

/**
 *
 * @typedef Variant
 * @property { String } [productId]
 * @property { String } [variantId]
 * @property { String } [variantName]
 *
 * @typedef Product
 * @property { String } productIdExternal
 * @property { String } name
 * @property { Variant[] } variants
 *
 * @typedef PurchasedVariant
 * @property {String} [id]
 * @property {String} [name]
 *
 * @typedef PurchasedProduct
 * @property {String} id
 * @property {String} name
 * @property {PurchasedVariant[]} variants
 *
 * @param { Object } props - An object.
 * @param { Function } props.onChange - Property 1.
 * @param { Product[] } props.products - Property 2.
 * @param { Variant } props.value
 */
const PurchasedProduct = ({
  onChange,
  products = [],
  value = { productId: undefined, variantId: undefined, variantName: undefined },
}) => {
  /** @type {PurchasedProduct[]} */
  const mappedProducts = useMemo(
    () => products.map((p) => ({
      id: p.productIdExternal,
      name: p.name,
      variants: (p.variants || []).map((v) => ({
        id: v.variantId,
        name: v.variantName,
      })),
    })),
    [products],
  );

  const productVariants = useMemo(() => {
    const product = mappedProducts.find(
      (mappedProduct) => parseInt(mappedProduct.id, 10) === parseInt(value.productId || '', 10),
    );

    return (product?.variants?.length || 0) > 1 ? /** @type {PurchasedVariant[]} */ (product?.variants) : [];
  }, [mappedProducts, value]);

  const updateProduct = useCallback(
    (event) => {
      onChange({
        [PURCHASED_PRODUCT_ATTRIBUTES.productId]: event.target.value,
        [PURCHASED_PRODUCT_ATTRIBUTES.variantId]: '',
      });
    },
    [onChange],
  );

  const updateVariant = useCallback(
    (event) => {
      onChange({
        ...value,
        [PURCHASED_PRODUCT_ATTRIBUTES.variantId]: event.target.value,
      });
    },
    [onChange, value],
  );

  return (
    <div className="subquery">
      <select
        className="form-control"
        value={value.productId}
        disabled={mappedProducts.length === 0}
        onChange={updateProduct}
      >
        <option key="-1" value="">
          Select product
        </option>

        {mappedProducts.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>

      <select
        className="form-control"
        value={value.variantId}
        disabled={productVariants.length === 0}
        onChange={updateVariant}
      >
        <option key="-1" value="">
          Select variant
        </option>

        {productVariants.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default memo(PurchasedProduct);
