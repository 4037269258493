import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';
import { useDropzone } from 'react-dropzone';
import { useToasts } from 'react-toast-notifications';
import {
  APIServiceConstants,
  BlueprintAPI,
} from '../../../redux/actions/BlueprintAPI';
import UploadDropZone from './UploadDropZone';
import { compressImage, uploadSMSImage } from './helpers';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
};

const ImageUpload = ({
  isOpen,
  close,
  imageUploadSelect,
  msgType,
  onMsgTypeChange,
  merchant,
}) => {
  /** @type {String?} */
  const initialError = null;
  const { addToast } = useToasts();
  const [uploading, setUploading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState(/** @type {String?} */(initialError));
  const [file, setFile] = useState({});
  const [url, setUrl] = useState({});
  const [controller] = useState(new AbortController());
  const [signal] = useState(controller.signal);

  const setClose = useCallback(() => {
    controller.abort();
    close(false);
  }, [close, controller]);

  const addToMessage = useCallback(
    (newUrl) => {
      imageUploadSelect(newUrl);
      setClose();
    },
    [imageUploadSelect, setClose],
  );

  const generateShortUrl = useCallback(
    async (awsUrl) => {
      try {
        const originalUrl = awsUrl.split('?Content-Type')[0];

        const {
          data: { shortUrl },
        } = await BlueprintAPI(APIServiceConstants.UPLOADS, 'url-shortner', {
          shortBaseUrl: `${window.blueprintGetApiBaseURL(
            process.env.REACT_APP_BLUEPRINT_API_URL,
          )}/u/r`,
          originalUrl,
        });

        addToast('Successfully uploaded image', {
          appearance: 'success',
          autoDismiss: true,
        });

        setUrl(shortUrl);
        setCompleted(true);
      } catch (err) {
        console.error(err);
        setError(
          'An error occurred while generating a short URL, please try again later.',
        );
      }
    },
    [addToast],
  );

  const onDrop = useCallback(
    async (files) => {
      setUploading(true);
      setError(null);
      let droppedFile = files[0];
      setFile(droppedFile);
      try {
        const compressedFile = await compressImage(droppedFile);
        if (compressedFile) {
          setFile(compressedFile);
          droppedFile = compressedFile;
        }
        const response = await uploadSMSImage(droppedFile, signal);
        generateShortUrl(response.url);
      } catch (err) {
        if (err.data) {
          setError(err.data);
        } else if (
          err.message
          && !err.message.includes('The user aborted a request.')
        ) {
          setError(
            'An error occurred while uploading your image, please try again later.',
          );
        }
      }
    },
    [generateShortUrl, signal],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={setClose}
      className="modal upload"
      overlayClassName="modal-overlay"
      contentLabel="Image Upload Modal"
      style={customStyles}
    >
      <div className="image-upload">
        <button className="close" onClick={setClose} type="button">
          close
        </button>
        <p className="title">Upload an Image</p>
        <UploadDropZone
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragActive={isDragActive}
          addToMessage={addToMessage}
          file={file}
          url={url}
          error={error}
          uploading={uploading}
          completed={completed}
          msgType={msgType}
          onMsgTypeChange={onMsgTypeChange}
          merchant={merchant}
        />
      </div>
    </Modal>
  );
};

export default ImageUpload;
