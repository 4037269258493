import axios from 'axios';
import qs from 'qs';

/** @type { Object.<string,import('axios').Method> } */
export const APIMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
  LINK: 'link',
  UNLINK: 'unlink',
};

export const APIServiceConstants = {
  SERVICE: 'blueprint-store-backend',
  CHATBOT: 'chatbot-sms-adapter',
  BILLING: 'billing',
  MESSAGING: 'messaging',
  UPLOADS: 'u',
  ANALYTICS: 'analytics',
  INTEGRATIONS: 'integrations',
  FLOWS: 'flows',
  SMS: 'sms',
};

const findKeyForServiceName = (name) => {
  const [key] = Object.entries(APIServiceConstants).find(([, value]) => value === name)
    || [];
  return key;
};

export const getUrl = (service, endpoint) => {
  // support for multiple service domains (useful for offline use)
  // if no service-specific URL is found in the environment
  // we fall back to use REACT_APP_BLUEPRINT_API_URL
  const serviceKey = findKeyForServiceName(service);
  const envKey = `REACT_APP_BLUEPRINT_API_URL_${serviceKey}`;

  const apiURL = process.env[envKey] || window.blueprintGetApiBaseURL();

  return `${apiURL}/${service}/${endpoint}`;
};

/**
 *
 * @param {import('axios').Method} method
 * @param {String?} SERVICE
 * @param {String?} ENDPOINT
 * @param {Object} [inputData]
 * @param {String?} [fullSource]
 * @param {Boolean?} [jsonify]
 * @param {Object.<string, string>} [headers]
 * @returns
 */
const apiRequest = (
  method,
  SERVICE,
  ENDPOINT,
  inputData = {},
  fullSource = null,
  jsonify = null,
  headers = {},
) => new Promise((resolve, reject) => {
  let data = jsonify ? JSON.stringify({ ...inputData }) : { ...inputData };
  const URL = fullSource || getUrl(SERVICE, ENDPOINT);
  const sessionToken = localStorage.getItem('sToken');

  const { sessionToken: sToken, ...params } = inputData;

  const axiosRequest = {
    method,
    url: URL,
    headers: { Authorization: sessionToken, ...headers },
    ...(method !== APIMethods.GET && { data }),
    ...(method === APIMethods.GET && {
      params,
    }),
    paramsSerializer(qsParams) {
      return qs.stringify(qsParams, { arrayFormat: 'indices' });
    },
  };

  axios(axiosRequest)
    .then((response) => resolve(response))
    .catch(async (error) => {
      // No need to try refreshing the token if we were trying to fetch the token
      if (ENDPOINT === 'token') {
        return reject(error);
      }

      if (error.response && error.response.status === 401) {
        const latestSessionToken = localStorage.getItem('sToken');
        const refreshToken = localStorage.getItem('rToken');

        if (!latestSessionToken || !refreshToken) {
          return reject(new Error('No tokens in storage'));
        }
        // eslint-disable-next-line promise/no-nesting
        return axios
          .post(
            `${process.env.REACT_APP_BLUEPRINT_API_URL}/blueprint-store-backend/token`,
            JSON.stringify({
              operation: 'refresh',
              sessionToken: latestSessionToken,
              refreshToken,
            }),
          )
          .then(async (response) => {
            data = jsonify
              ? JSON.stringify({
                ...inputData,
                sessionToken: response.data.sessionToken,
              })
              : { ...inputData, sessionToken: response.data.sessionToken };
            localStorage.setItem('sToken', response.data.sessionToken);
            const updatedRequest = {
              ...axiosRequest,
              headers: {
                Authorization: response.data.sessionToken,
                'Content-type': 'application/json',
              },
              ...(method !== APIMethods.GET && { data }),
            };

            const resp = await axios(updatedRequest);
            return resolve(resp);
          })
          .catch((err) => {
            console.log(err.response);
            if (err?.response?.status === 401) {
              // Not logged in.
              window.location.href = '/login';
            }
            return reject(err.response || err);
          });
      }

      return reject(error.response || error);
    });
});

export const API = (
  method = APIMethods.GET,
  SERVICE,
  ENDPOINT,
  inputData,
  fullSource = null,
  jsonify = true,
) => apiRequest(method, SERVICE, ENDPOINT, inputData, fullSource, jsonify);

/**
 * @param {String?} SERVICE
 * @param {String?} ENDPOINT
 * @param {Object} [inputData]
 * @param {String?} [fullSource]
 * @param {Boolean?} [jsonify]
 * @param {import('axios').Method} method
 */
export const BlueprintAPI = (
  SERVICE,
  ENDPOINT,
  inputData,
  fullSource,
  jsonify = true,
  method = APIMethods.POST,
) => apiRequest(method, SERVICE, ENDPOINT, inputData, fullSource, jsonify);

const jsonHeader = {
  'Content-type': 'application/json',
};

/**
 * Adding a new request function, allowing you to set the request headers.
 * This is due to the mixture of json and x-www-form-urlencoded requests made
 * @param {import('axios').Method} method
 * @param {String} SERVICE
 * @param {String} ENDPOINT
 * @param {Object} [inputData]
 * @param {String|Null} fullSource
 * @param {Object.<string, string>} [headers]
 * @param {Boolean} jsonify
 * @returns {Promise}
 */
export const BlueprintJSONAPI = (
  method = APIMethods.GET,
  SERVICE,
  ENDPOINT,
  inputData,
  fullSource = null,
  headers = jsonHeader,
  jsonify = true,
) => apiRequest(
  method,
  SERVICE,
  ENDPOINT,
  inputData,
  fullSource,
  jsonify,
  headers,
);

export const extractErrorMessageFromError = (error) => {
  if (error.message) {
    return error.message;
  } if (error.data) {
    if (typeof error.data === 'string') {
      return error.data;
    }

    return error.data.message || error.data.error;
  }

  return 'Something went wrong';
};
