import React from 'react';

export default () => (
  <div className="instruction-container">
    <div className="row">
      <div className="col">
        <h2 className="title">Replenishment recommendations</h2>
        <p>
          Below shows your replenishment recommendations, calculated using your historic order data.
          Select a recommendation below and create a replenishment campaign in 2 clicks.
        </p>
      </div>
    </div>
  </div>
);
