import React, {
  memo, useCallback, useMemo,
} from 'react';

import { NEGATE_MODES } from '../../../../global/Predicates/Negate';
import Filter, { createFilterChangeHandler } from '../Filter';
import SelectField from '../ValueComponents/SelectField';

/** @typedef {import('../../../../global/Predicates/Negate').NegateMode} NegateMode */
/** @typedef {import('../../../../global/Predicates/TypeSelector').Type} Type */
/** @typedef {import('../../../../global/Predicates/ComparatorSelector').Comparator} Comparator */

/**
 * @typedef ABTestMembershipValue
 * @property {Number} group
 * @property {Number} nGroups
 */

const getDefaultNegate = () => ({ mode: NEGATE_MODES.no });
const getDefaultComparator = (comparators) => comparators[0].value;
const getDefaultValue = () => ({
  group: -1,
  nGroups: 2,
});

export const valueMapper = ({ id, op, ...values }) => ({ ...values });

const N_GROUPS_OPTIONS = [
  {
    value: '2',
    label: '2 Groups',
  },
  {
    value: '3',
    label: '3 Groups',
  },
  {
    value: '4',
    label: '4 Groups',
  },
  {
    value: '5',
    label: '5 Groups',
  },
];

/**
 * @param {Object} props
 * @param {String} props.id
 * @param {Function} props.onChange
 * @param {ABTestMembershipValue} props.value
 * @param {NegateMode} props.negate
 * @param {String} props.type
 * @param {Type[]} props.types
 * @param {String} props.comparator
 * @param {Comparator[]} props.comparators
 */
const ABTestMembership = (props) => {
  const {
    type,
    types,
    value = getDefaultValue(),
    negate = getDefaultNegate(),
    comparators,
    onChange,
    id,
  } = props;

  // Destructure these seperately because we need to know the `type` to get the defaults
  const {
    comparator = getDefaultComparator(comparators),
  } = props;

  const onFilterChange = createFilterChangeHandler({
    id, type, value, negate, comparator, onChange,
  });

  const onChangeGroupCount = useCallback((prop, newValue) => {
    onFilterChange(prop, {
      group: value.group,
      nGroups: parseInt(newValue, 10),
    });
  }, [onFilterChange, value]);

  const onChangeGroupSelection = useCallback((prop, newValue) => {
    onFilterChange(prop, {
      group: parseInt(newValue, 10),
      nGroups: value.nGroups,
    });
  }, [onFilterChange, value]);

  const GROUP_OPTIONS = useMemo(() => [{
    value: '-1',
    label: 'Please Select',
    selected: true,
    disabled: true,
  }, ...Array.from(Array(value.nGroups)).map((_, idx) => ({
    value: (idx + 1).toString(),
    label: `Group ${idx + 1}`,
  }))], [value]);

  return (
    <Filter
      negate={negate}
      comparator={comparator}
      type={type}
      onChange={onFilterChange}
      types={types}
      comparators={comparators}
      value={(
        <>
          <SelectField
            value={value.group.toString()}
            onChange={onChangeGroupSelection}
            options={GROUP_OPTIONS}
          />
          <SelectField
            value={value.nGroups.toString()}
            onChange={onChangeGroupCount}
            options={N_GROUPS_OPTIONS}
          />
        </>
      )}
    />
  );
};

export default memo(ABTestMembership);
