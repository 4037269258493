import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { BImage } from '../../global/BImage';
import { BPToggle } from '../BPToggle';
import CollapsibleProductNames from './CollapsibleProductNames';
import CollapsibleText from './CollapsibleText';

import { getDaysAndHours } from '../../../utils/dates';

const UpSellsTableRow = ({
  upsell,
  // eslint-disable-next-line no-unused-vars
  analytics,
  index,
  toggleUpSellState,
  handleEdit,
  handleDelete,
}) => {
  const isAvailable = upsell.unavailable_at === null;

  const trClassName = isAvailable ? 'available' : 'unavailable';

  return (
    <Draggable
      draggableId={String(upsell.id)}
      index={index}
      isDragDisabled={!isAvailable}
    >
      {(provided, snapshot) => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`${trClassName} d-flex ${
            snapshot.isDragging ? 'dragging' : ''
          }`}
        >
          <td className="col-1">
            <BImage
              src="https://static.blueprint.store/illustrations/drag_drop.svg"
              alt="drag & drop icon"
              title="drag & drop icon"
              className="drag-drop-icon"
            />
            {upsell.priority}
          </td>
          <td className="col-1">
            <CollapsibleText
              value={upsell.name}
              upsellId={upsell.id}
              type="name"
            />
          </td>
          <td className="col-2">
            <CollapsibleProductNames
              products={upsell.triggers}
              upsellId={upsell.id}
              type="triggers"
            />
          </td>
          <td className="col-2">
            <CollapsibleProductNames
              products={upsell.products}
              upsellId={upsell.id}
              type="products"
            />
          </td>
          <td className="col-1">{getDaysAndHours(upsell.messageInterval)}</td>
          <td colSpan={3} className="col-3">
            <span className="highlighted-text">
              {/* Gonna uncomment after the analytics part is done TODO */}
              {/* {analytics.numMessagesSent} */}
            </span>
            Coming Soon...
          </td>
          {/* Gonna uncomment after the analytics part is done TODO */}
          {/* <td>
            <span className="highlighted-text">
              {analytics.numOrdersAttributed}
            </span>
            &nbsp;
            {getPercentage(
              analytics.numMessagesSent,
              analytics.numOrdersAttributed,
            )}
            %
          </td> */}
          {/* Gonna uncomment after the analytics part is done TODO */}
          {/*
          <td>
            <span className="highlighted-text">
              {analytics.totalRevenueAttributed}
            </span>
            &nbsp; £
            {getPercentage(
              analytics.totalRevenueAttributed,
              analytics.numOrdersAttributed,
            )}
            &nbsp; EPM
          </td> */}
          <td colSpan={2} className="col-1 action-btn-container">
            <button
              className="minimal-black-btn"
              onClick={handleEdit}
              value={upsell.id}
              disabled={!isAvailable}
              type="button"
            >
              Edit
            </button>
            <button
              className="minimal-red-btn"
              value={upsell.id}
              onClick={handleDelete}
              type="button"
            >
              Delete
            </button>
          </td>
          <td className="col-1 toggle-container">
            <BPToggle
              active={upsell.active}
              handleClick={() => toggleUpSellState({ id: upsell.id })}
              disabled={!isAvailable}
            />
          </td>
        </tr>
      )}
    </Draggable>
  );
};

export default UpSellsTableRow;
