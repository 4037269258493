import React, {
  useMemo, memo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useTable,
  useSortBy,
  useRowState,
} from 'react-table';
import { APIKeysActionCreators } from '../../../redux/actions/apiKeys';

const { getMerchantAPIKeys, updateMerchantAPIKey, deleteMerchantAPIKey } = APIKeysActionCreators;

const EditableDescription = ({ cell, row }) => {
  const dispatch = useDispatch();
  if (cell.row.state.editing) {
    if (cell.state.value === undefined) {
      const value = cell.value || '';

      cell.setState({ value });
    }

    return (
      <div className="field">
        <input
          id="description"
          type="text"
          className="form-control"
          value={cell.state.value}
          onChange={(e) => { cell.setState({ value: e.target.value }); }}
          onKeyDown={async (e) => {
            // Trying to extract these handlers to external functions causes row.setState to not create a re-render
            if (e.key === 'Enter') {
              e.preventDefault();

              const isEnterACancel = row?.state?.cellState?.description?.value === row.original.description || row?.state?.cellState?.description?.value === '';

              row.setState((state) => ({ ...state, editing: false }));

              if (!isEnterACancel) {
                await dispatch(updateMerchantAPIKey(row.original.key, row.state.cellState.description.value));
                await dispatch(getMerchantAPIKeys());
              }
            }
          }}
        />
      </div>
    );
  }

  return <div className="description">{cell.value || <>&nbsp;</>}</div>;
};

const ActionButtons = ({ row }) => {
  const dispatch = useDispatch();
  const {
    apiKeysLoading,
    apiKeysDeleteLoading,
    apiKeysUpdateLoading,
  } = useSelector(
    (/** @type {import('../../../redux/reducers').RootState} */ state) => state.APIKeysReducer,
  );

  const isCancelButtonAvailable = row?.state?.cellState?.description?.value === row.original.description || row?.state?.cellState?.description?.value === '';
  const isAnyLoadingInProgress = apiKeysLoading || apiKeysUpdateLoading || apiKeysDeleteLoading;
  const isEditButtonDisabled = isAnyLoadingInProgress;
  const isSaveButtonDisabled = isAnyLoadingInProgress;
  const isDeleteButtonDisabled = isAnyLoadingInProgress;

  return (
    <>
      {row.state.editing && !isCancelButtonAvailable
      && (
      <button
        className="btn btn-link"
        type="button"
        onClick={
          // Trying to extract these handlers to external functions causes row.setState to not create a re-render
          async () => {
            await dispatch(updateMerchantAPIKey(row.original.key, row.state.cellState.description.value));
            await dispatch(getMerchantAPIKeys());
            row.setState((state) => ({ ...state, editing: false }));
          }
        }
        disabled={isSaveButtonDisabled}
      >
        💾
      </button>
      )}
      {row.state.editing && isCancelButtonAvailable
      && (
      <button
        className="btn btn-link"
        type="button"
        onClick={
          // Trying to extract these handlers to external functions causes row.setState to not create a re-render
          async () => {
            row.setState((state) => ({ ...state, editing: false }));
          }
        }
      >
        ❌
      </button>
      )}
      {!row.state.editing
      && (
      <button
        className="btn btn-link"
        type="button"
        onClick={
          // Trying to extract these handlers to external functions causes row.setState to not create a re-render
          () => {
            row.setState((state) => ({ ...state, editing: true }));
          }
        }
        disabled={isEditButtonDisabled}
      >
        📝
      </button>
      )}
      <button
        className="btn btn-link"
        type="button"
        onClick={
          // Trying to extract these handlers to external functions causes row.setState to not create a re-render
          async () => {
            await dispatch(deleteMerchantAPIKey(row.original.key));
            await dispatch(getMerchantAPIKeys());
          }
        }
        disabled={isDeleteButtonDisabled}
      >
        🗑️
      </button>
    </>
  );
};

const IntegrationsCustomAPITable = () => {
  const {
    apiKeysData,
  } = useSelector(
    (/** @type {import('../../../redux/reducers').RootState} */ state) => state.APIKeysReducer,
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Key',
        accessor: 'key',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: EditableDescription,
      },
      {
        Header: 'Actions',
        id: 'actions',
        className: 'actions',
        Cell: ActionButtons,
      },
      {
        accessor: 'createdAt',
      },
    ], [],
  );

  const sortBy = useMemo(() => [
    {
      id: 'createdAt',
      desc: true,
    },
  ], []);

  const tableInstance = useTable(
    {
      columns,
      data: apiKeysData,
      initialState: {
        hiddenColumns: ['createdAt'],
        sortBy,
      },
    },
    useSortBy,
    useRowState,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = tableInstance;

  return (
    <table
      {...getTableProps()}
      className="table table-bordered table-hover table-responsive-lg bp-table bp-table-dark"
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        { rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps({
                  className: cell.column.className,
                })}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default memo(IntegrationsCustomAPITable);
