import { GroupMessagingTypes } from '../../types';

export const initialState = {
  estimateLoading: false,
  estimate: null,
  estimateErr: null,
};

export const GroupMessagingReducer = (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case GroupMessagingTypes.GET_SEND_ESTIMATE_REQ: {
      return {
        ...state,
        estimateLoading: true,
        estimateErr: null,
      };
    }
    case GroupMessagingTypes.GET_SEND_ESTIMATE_RES: {
      if (error) {
        return {
          ...state,
          estimateLoading: false,
          estimateErr: error,
        };
      }

      const { durationEstimate } = payload;

      if (!durationEstimate) {
        return {
          ...state,
          estimateLoading: false,
          estimateErr: 'No duration estimate returned',
        };
      }

      return {
        ...state,
        estimateLoading: false,
        estimateErr: null,
        estimate: durationEstimate,
      };
    }
    default:
      return state;
  }
};
