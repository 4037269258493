import React from 'react';
import { PageBuilder } from '../components/global/PageBuilder';
import { SEO } from '../components/global/SEO';
import AbandonedCartMessages from '../components/main/AbandonedCartMessages';

export const AbandonedCarts = () => (
  <PageBuilder>
    <SEO title="Abandoned Carts | Blueprint" />
    <div className="a-view abandoned-carts">
      <div className="container">
        <h2 className="title">Abandoned Carts</h2>
        <AbandonedCartMessages />
      </div>
    </div>
  </PageBuilder>
);

export default AbandonedCarts;
