/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useQuery } from 'react-query';

import { wordings, types, settings } from '../../../utils/crossSells';
import MessageIntervalSelect, { TIME_UNIT } from '../MessageIntervalSelect';
import { HelpBubble } from '../HelpBubble';
import { DemoPhone } from '../DemoPhone';
import { isValidInterval } from '../../../utils/messageInteval';
import { SegmentCharCounter } from '../SegmentCharCounter';
import { BPToggle } from '../BPToggle';
import ProductsSection from './ProductsSection';
import { replacePlaceholderWithMockData } from '../../../utils/sms';
import { getShortenedPermalink } from '../../../queries/Merchant';

/**
 * @typedef {import('../../../redux/reducers').RootState} RootState
 */
import { UpSellsActionCreators } from '../../../redux/actions/upSells';
import { getInvalidCheckMessage } from './validation';

const {
  updateUpSellDirectly,
  createUpSellDirectly,
} = UpSellsActionCreators;

const PRODUCT_NAME_LIMIT_ON_DEMO = 3;

const CrossSellEditor = ({
  crossSell, isForCreating, type,
}) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();
  const [upsellObj, setUpsellObj] = useState(crossSell);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { merchantData } = useSelector(
    (/** @type RootState} */ state) => state.MerchantReducer,
  );

  const { data: shortenedPermalink } = useQuery(
    'shortenedPermalink',
    getShortenedPermalink,
    {
      staleTime: Infinity,
    },
  );

  const submit = async (data) => {
    const invalidInputCheckMsg = getInvalidCheckMessage(data);
    if (invalidInputCheckMsg) {
      addToast(invalidInputCheckMsg, {
        appearance: 'warning',
        autoDismiss: true,
      });

      setIsSubmitting(false);
      return;
    }

    const result = isForCreating
      ? await createUpSellDirectly(dispatch, data, type)
      : await updateUpSellDirectly(dispatch, data, type);

    if (result.error) {
      addToast(`Could not ${isForCreating ? 'create' : 'update'} a new ${wordings[type].genericTerm}. Error: ${result.error}`, {
        appearance: 'error',
        autoDismiss: true,
      });

      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(false);
    addToast(result || `${isForCreating ? 'Created' : 'Updated'} successfully`,
      {
        appearance: 'info',
        autoDismiss: true,
      });
    history.push(settings[type].mainLink);
  };

  const handleFieldEdit = (event) => {
    let fieldName = event.target.name;
    const fieldValue = event.target.value;

    if (!fieldName) {
      fieldName = 'messageInterval';

      if (!isValidInterval(fieldValue)) {
        setIsInvalid(true);
      } else {
        setIsInvalid(false);
      }
    }

    setUpsellObj({ ...upsellObj, [fieldName]: fieldValue });
  };

  const handleSkipToggle = () => {
    setUpsellObj({ ...upsellObj, skipIfPurchasedBefore: !upsellObj.skipIfPurchasedBefore });
  };

  const handleProductChange = (newProductsArr, productType) => {
    setUpsellObj({
      ...upsellObj,
      [productType]: newProductsArr,
    });
  };

  /**
   * Remove the clicked variant from upsell products
   *
   * @param {Object} event Click event
   */
  const handleVariantRemoval = (event) => {
    const { variantType } = event.target.dataset;
    const isTrigger = variantType === 'triggers';

    if (isTrigger && !isForCreating) {
      return;
    }

    const variantId = parseInt(event.target.dataset.variant, 10);

    const indexOfElToRemove = upsellObj[variantType].findIndex(
      (variant) => (isTrigger ? variant.triggerVariantId
        === variantId : variant.prodToGetVariantId === variantId),
    );

    const newProductsArr = [...upsellObj[variantType]];
    const productToRemove = newProductsArr[indexOfElToRemove];

    newProductsArr.forEach((prodVar, index) => {
      if (prodVar.triggerProductId === productToRemove.triggerProductId
        || prodVar.prodToGetProductId === productToRemove.prodToGetProductId) {
        newProductsArr[index].allVarsSelected = false;
      }
    });

    newProductsArr.splice(indexOfElToRemove, 1);

    handleProductChange(newProductsArr, variantType);
  };

  const handleProductSelection = (selectionData, isTrigger) => {
    const variantType = isTrigger ? 'triggers' : 'products';
    const newProductsArr = selectionData.products.flatMap(
      (productSelection) => (productSelection.variants.map((variantSelection) => ({
        name: productSelection.productName,
        variantName: variantSelection.variantName,
        [isTrigger ? 'triggerVariantId' : 'prodToGetVariantId']: variantSelection.variantId,
        [isTrigger ? 'triggerProductId' : 'prodToGetProductId']: productSelection.productId,
        allVarsSelected: productSelection.allVarsSelected,
      }))),
    );

    handleProductChange(newProductsArr, variantType);
  };

  const getUpdatedUpsellObj = () => {
    const updatedObj = {
      upsellId: upsellObj.id,
      upsellType: type,
    };

    const {
      products, name, messageContent, skipIfPurchasedBefore, messageInterval,
    } = upsellObj;

    if (crossSell.name !== name) {
      updatedObj.name = name;
    }

    if (crossSell.messageContent !== messageContent) {
      updatedObj.message = messageContent;
    }

    if (crossSell.skipIfPurchasedBefore !== skipIfPurchasedBefore) {
      updatedObj.skipIfPurchasedBefore = skipIfPurchasedBefore;
    }

    if (crossSell.messageInterval !== messageInterval) {
      updatedObj.sendAfterInterval = messageInterval;
    }

    if (
      JSON.stringify(crossSell.products) !== JSON.stringify(products)
    ) {
      updatedObj.variantIds = products.length > 0
        ? products.map((product) => product.prodToGetVariantId)
        : null;
    }

    return updatedObj;
  };

  const getNewUpsellObj = () => ({
    name: upsellObj.name,
    upsellType: type,
    message: upsellObj.messageContent,
    sendAfterInterval: upsellObj.messageInterval,
    skipIfPurchasedBefore: upsellObj.skipIfPurchasedBefore,
    productVariantIds:
        upsellObj.products.length > 0
          ? upsellObj.products.map((product) => product.prodToGetVariantId)
          : undefined,
    triggerProdsOrVars: upsellObj.triggers.map((product) => ({
      variantId: product.triggerVariantId,
      productId: product.triggerProductId,
    })),
  });

  const saveChanges = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (isForCreating) {
      submit(getNewUpsellObj());
    } else {
      submit(getUpdatedUpsellObj());
    }
  };

  const getProductNamesForDemo = () => {
    const itemsList = type === types.CROSS_SELLS ? upsellObj.products : upsellObj.triggers;

    if (!(itemsList && itemsList.length > 0)) {
      return '';
    }

    const productsCount = itemsList.length;

    const productNames = itemsList.slice(0, PRODUCT_NAME_LIMIT_ON_DEMO).reduce(
      (previous, current) => `${previous ? `${previous},` : ''} ${current.name} ${current.variantName || ''}`, '',
    );

    const suffix = productsCount > PRODUCT_NAME_LIMIT_ON_DEMO ? ` [+${productsCount - PRODUCT_NAME_LIMIT_ON_DEMO} more]` : '';

    return `${productNames}${suffix}`;
  };

  async function generateMessage() {
    const content = await replacePlaceholderWithMockData({ company: merchantData.company },
      upsellObj.messageContent, getProductNamesForDemo(), shortenedPermalink);
    return {
      content,
      date: '03/02 - 9AM',
      integer: false,
    };
  }

  return (
    <form onSubmit={saveChanges}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="upsellName">
              {wordings[type].genericTermSentenceCase}
              {' '}
              name
            </label>
            <input
              type="text"
              name="name"
              id="upsellName"
              value={upsellObj.name}
              onChange={handleFieldEdit}
              placeholder={`${wordings[type].genericTermSentenceCase} name`}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label>
              When someone buys any of
            </label>
            <ProductsSection
              products={upsellObj.triggers}
              isForCreating={isForCreating}
              handleVariantRemoval={handleVariantRemoval}
              handleProductSelection={handleProductSelection}
              isTrigger
            />
          </div>

          { type === types.CROSS_SELLS && (
          <div className="form-group">
            <label>
              Recommend all of these products
            </label>
            <ProductsSection
              products={upsellObj.products}
              isForCreating={isForCreating}
              handleVariantRemoval={handleVariantRemoval}
              handleProductSelection={handleProductSelection}
              isTrigger={false}
            />
          </div>
          )}

          <div className="form-group">
            <label>
              <span>
                Send
                {' '}
                {wordings[type].genericTerm}
                {' '}
                message after
              </span>
            </label>
            <MessageIntervalSelect
              className="grey"
              name="messageInterval"
              customValueUnit={TIME_UNIT.days}
              handleChange={handleFieldEdit}
              value={upsellObj.messageInterval}
            />
          </div>

          <div className="form-group">
            <div className="cart-message-editor mb-0">
              <label htmlFor="upsellMessageContent">
                <span>
                  {wordings[type].genericTermSentenceCase}
                  {' '}
                  message
                </span>
              </label>
              <div>
                { type === types.CROSS_SELLS
                && (
                <div className="mb-2">
                  <div className="d-inline-block mr-2 align-middle">
                    <BPToggle
                      id="skip-crossSell-toggle"
                      active={upsellObj.skipIfPurchasedBefore}
                      handleClick={handleSkipToggle}
                    />
                  </div>
                  <label htmlFor="skip-upsell-toggle">
                    <span className="info">Don&lsquo;t send if purchased before</span>
                    <HelpBubble
                      small
                      text="If activated, the cross-sell message won't be sent if a customer has previously purchased the recommended product."
                    />
                  </label>
                </div>
                )}
                <textarea
                  value={upsellObj.messageContent}
                  onChange={handleFieldEdit}
                  className="message-content mt-0 mb-0"
                  id="upsellMessageContent"
                  name="messageContent"
                  placeholder={`Please enter ${wordings[type].genericTerm} message content here`}
                  rows={3}
                  maxLength={400}
                />
                <SegmentCharCounter
                  text={upsellObj.messageContent}
                  helpBubbleSmall={false}
                />
              </div>
            </div>
          </div>
          <div className="align-self-end">
            <button
              className="btn btn-primary"
              type="submit"
              disabled={isSubmitting || isInvalid}
            >
              {isSubmitting ? 'Saving' : 'Save Rule'}
            </button>
          </div>
        </div>
        <div className="col-md-3 flex-column d-flex justify-content-between">
          <div className="cart-message-editor">
            <label htmlFor="demoPhone">
              <span>Preview</span>
            </label>
            <DemoPhone
              id="demoPhone"
              messages={[generateMessage()]}
            />
          </div>

        </div>
      </div>
    </form>
  );
};

export default memo(CrossSellEditor);
