import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { BPField } from './BPField';
import { updatePasswordValidate } from './validation';

const UpdatePasswordForm = ({
  handleSubmit,
  valid,
  subErr,
  submitting,
  loading,
  reset,
  pristine,
}) => (
  <form onSubmit={handleSubmit} className="settings-form">
    <div className="form-group small">
      <label htmlFor="currentPassword">Current Password</label>
      <div className="field">
        <Field
          id="currentPassword"
          name="currentPassword"
          component={BPField}
          type="password"
          placeholder="Current Password"
          className="form-control"
        />
      </div>
    </div>
    <div className="form-group small">
      <label htmlFor="newPassword">New Password</label>
      <div className="field">
        <Field
          id="newPassword"
          name="newPassword"
          component={BPField}
          type="password"
          placeholder="New Password"
          className="form-control"
        />
      </div>
    </div>
    <div className="form-group small">
      <label htmlFor="confirmPassword">Confirm Password</label>
      <div className="field">
        <Field
          id="confirmPassword"
          name="confirmPassword"
          component={BPField}
          type="password"
          placeholder="Confirm Password"
          className="form-control"
        />
      </div>
    </div>
    <div className="form-group buttons">
      <button
        type="submit"
        disabled={!valid || submitting || pristine}
        className="btn btn-primary"
      >
        {loading ? 'Updating...' : 'Update Password'}
      </button>
      {!pristine && (
      <button type="button" onClick={reset} className="btn btn-link">
        Cancel
      </button>
      )}
    </div>
    {subErr && <span className="form-error">{subErr}</span>}
  </form>
);

const UpdatePasswordFormRedux = reduxForm({
  form: 'updatePasswordForm',
  validate: updatePasswordValidate,
})(UpdatePasswordForm);

export default UpdatePasswordFormRedux;
