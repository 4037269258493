import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { GROUP_TYPE } from '../../../../constants';

export default ({ group, onDelete, groupMessagingListsEnabled = true }) => {
  const {
    id, name, type, listQuantity, count, description, readonly,
  } = group;
  const deleteGroup = useCallback(() => onDelete(group), [group, onDelete]);

  return (
    <tr className="row">
      <td className={`col-md-2 clickable ${readonly === 1 ? 'readOnly' : ''}`}>
        <Link to={`/group-messaging/${id}`}>{name}</Link>
      </td>
      <td className="d-none d-md-table-cell col-md-1">
        {type === GROUP_TYPE.LIST ? 'List' : 'Segment'}
      </td>
      <td className="d-none d-md-table-cell col-md-1">
        {type === GROUP_TYPE.LIST ? listQuantity : count}
      </td>
      <td className="d-none d-md-table-cell col-md-6 ellipsis">
        {description}
      </td>
      <td className="col-md-2 actions">
        {!readonly && type === GROUP_TYPE.SEGMENT && (
          <Link
            to={`/group-messaging/segments/${id}`}
            className="minimal-black-btn"
          >
            Edit
          </Link>
        )}
        {!readonly && type === GROUP_TYPE.LIST && groupMessagingListsEnabled && (
          <Link
            to={`/group-messaging/lists/${id}`}
            className="minimal-black-btn"
          >
            Edit
          </Link>
        )}
        {!readonly && (
          <button className="minimal-red-btn" onClick={deleteGroup} type="button">
            Delete
          </button>
        )}
      </td>
    </tr>
  );
};
