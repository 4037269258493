import { types } from '../../../utils/crossSells';

const PLACEHOLDER_UPSELL_PRODUCTS = 'recommended_products';
const PLACEHOLDER_UPSELL_LINK = 'link_to_checkout';
const invalidCombinationMsg = `You can't use "${PLACEHOLDER_UPSELL_PRODUCTS}", "${PLACEHOLDER_UPSELL_LINK}" placeholders without upsell products`;

const hasProdRelatedPlaceholder = (message) => message
  && (message.includes(PLACEHOLDER_UPSELL_PRODUCTS)
    || message.includes(PLACEHOLDER_UPSELL_LINK));

export const getInvalidCheckMessage = (upsell) => {
  if (upsell.message !== undefined) {
    if (!upsell.message) {
      return 'Please provide the message';
      // TODO - clarify about this length check
    } if (upsell.message.length < 20) {
      return 'The message is too short';
    }
  }

  if (upsell.name !== undefined) {
    if (!upsell.name) {
      return 'Please provide a name';
    } if (upsell.name.length >= 100) {
      return 'The name is too long';
    }
  }

  if (upsell.triggerProdsOrVars !== undefined) {
    if (!(upsell.triggerProdsOrVars && upsell.triggerProdsOrVars.length > 0)) {
      return 'Please choose at least one trigger product';
    }
  }

  if (upsell.productVariantIds !== undefined && upsell.upsellType !== types.REPLENISHMENTS) {
    if (
      !upsell.productVariantIds
      && hasProdRelatedPlaceholder(upsell.message)
    ) {
      return invalidCombinationMsg;
    }
  }

  if (upsell.variantIds !== undefined) {
    if (!upsell.variantIds && hasProdRelatedPlaceholder(upsell.message)) {
      return invalidCombinationMsg;
    }
  }

  return false;
};

export default getInvalidCheckMessage;
