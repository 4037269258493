import { ContactsActionTypes } from '../../types';

export const initialState = {
  contactsLoading: false,
  contactsErr: null,
  contactsLoaded: false,
  contactsData: [],
  contactsTotalRowCount: 0,
};

export const ContactsReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case ContactsActionTypes.GET_CONTACTS_REQ: {
      return {
        ...state,
        contactsLoading: true,
      };
    }
    case ContactsActionTypes.GET_CONTACTS_RES: {
      if (error) {
        return {
          ...state,
          contactsLoaded: false,
          contactsErr: error,
        };
      }
      return {
        ...state,
        contactsLoading: false,
        contactsLoaded: true,
        contactsData: payload.contacts,
        contactsTotalRowCount: payload.totalRowCount,
      };
    }
    default:
      return state;
  }
};
