import {
  APIServiceConstants,
  BlueprintAPI,
} from '../../redux/actions/BlueprintAPI';

const { SERVICE } = APIServiceConstants;

const getCustomDomain = async () => {
  try {
    const result = await BlueprintAPI(SERVICE, 'customDomains', {}, null, true, 'GET');

    return result.data;
  } catch (err) {
    if (err.status === 404) {
      return null;
    }

    throw err;
  }
};

export default getCustomDomain;
