import React, {
  memo, useCallback, useState, useEffect,
} from 'react';
import NoteModal from './NoteModal';

const NotesSection = ({ customerPhoneId, customerNotes = [] }) => {
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [notes, setNotes] = useState(customerNotes);

  useEffect(() => {
    setNotes(customerNotes);
  }, [customerNotes, customerPhoneId]);

  const noteSuccess = useCallback(
    (newNote) => {
      setNotesModalOpen(false);
      setNotes([...notes, newNote]);
    },
    [notes],
  );

  const toggleNotesModal = useCallback(() => {
    setNotesModalOpen(!notesModalOpen);
  }, [notesModalOpen]);

  return (
    <div className="note-section">
      {notes.map((note) => (
        <div className="note" key={note.id}>
          <div className="date">
            {new Date(note.createdAt).toLocaleString()}
            {' '}
            -
            {' '}
            {note.agentName.split(' ')[0]}
          </div>
          <p>{note.note}</p>
        </div>
      ))}

      <button className="btn btn-primary" onClick={toggleNotesModal} type="button">
        Add Note
      </button>

      {notesModalOpen && (
        <NoteModal
          customerPhoneId={customerPhoneId}
          isOpen={notesModalOpen}
          onSuccess={noteSuccess}
          onClose={toggleNotesModal}
        />
      )}
    </div>
  );
};

export default memo(NotesSection);
