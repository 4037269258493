import React, { memo, useCallback } from 'react';

/**
 * @typedef {Object} Type
 * @property {String} value - The internal value for this type
 * @property {String} title - The title to be displayed in the UI
 * @property {import('./ComparatorSelector').Comparator[]} comparators - The available comparators for this type
 */

/**
 *
 * @param {Object} props
 * @param {Function} props.onChange
 * @param {String} props.value
 * @param {Type[]} props.types
 */
const TypeSelector = ({ onChange, value, types }) => {
  const changeHandler = useCallback((e) => {
    const newValue = e.target.value;

    onChange(newValue);
  }, [onChange]);

  return (
    <select
      className="form-control"
      onChange={changeHandler}
      value={value}
    >
      {types.map((type) => (
        <option value={type.value} key={type.value}>
          {type.title}
        </option>
      ))}
    </select>
  );
};

export default memo(TypeSelector);
