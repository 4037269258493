import { UpSellsActionTypes } from '../../types';
import {
  APIMethods, APIServiceConstants, BlueprintAPI, BlueprintJSONAPI,
} from '../BlueprintAPI';
import { types } from '../../../utils/crossSells';

const { SERVICE, ANALYTICS } = APIServiceConstants;

/** @typedef {import('../../../utils/crossSells').types} UpsellType */

export const UpSellsActionCreators = {
  /**
   * @param {Boolean} includeDeleted
   * @param {UpsellType?} [type]
   */
  getAllUpSells: (includeDeleted = false, type = null) => async (dispatch) => {
    dispatch({
      type: UpSellsActionTypes.GET_UP_SELLS_REQ,
    });
    try {
      const data = { includeDeleted, type };

      const response = await BlueprintAPI(SERVICE, 'getAllUpSells', data, null, true, APIMethods.GET);

      dispatch({
        type: UpSellsActionTypes.GET_UP_SELLS_RES,
        payload: response.data.upsells || [],
        upsellType: type || types.CROSS_SELLS,
      });

      // Resetting states after updating/creating/deleting upsells to prevent repeated toast messages
      dispatch({
        type: UpSellsActionTypes.RESET_STATES_USED_IN_TOASTS,
        payload: null,
        upsellType: type || types.CROSS_SELLS,
      });
    } catch (e) {
      console.error('Error - getAllUpSells:', e);
      dispatch({
        type: UpSellsActionTypes.GET_UP_SELLS_RES,
        error: e.data || 'Something went wrong',
      });
    }
  },
  getUpSellAnalytics: (periodForAnalytics = 'thisYear') => async (dispatch) => {
    dispatch({
      type: UpSellsActionTypes.GET_UP_SELLS_ANALYTICS_REQ,
    });
    try {
      const response = await BlueprintAPI(ANALYTICS, 'smsAnalytics', {
        datePeriod: periodForAnalytics,
        breakdownBy: 'messageTypeFull',
      });

      dispatch({
        type: UpSellsActionTypes.GET_UP_SELLS_ANALYTICS_RES,
        payload: response.data.breakdown || [],
      });
    } catch (e) {
      console.error('Error - getUpSellAnalytics:', e);
      dispatch({
        type: UpSellsActionTypes.GET_UP_SELLS_ANALYTICS_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
  updateUpSell: (data, upsellType = types.CROSS_SELLS) => async (dispatch) => {
    dispatch({
      type: UpSellsActionTypes.UPDATE_UPSELL_REQ,
    });
    try {
      const response = await BlueprintAPI(SERVICE, 'updateUpSell', data);
      dispatch({
        type: UpSellsActionTypes.UPDATE_UPSELL_RES,
        payload: response.data.message || null,
      });
      dispatch(UpSellsActionCreators.getAllUpSells(false, upsellType));
    } catch (e) {
      console.error('Error - updateUpSell:', e);
      dispatch({
        type: UpSellsActionTypes.UPDATE_UPSELL_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
  updateUpSellDirectly: async (dispatch, data, upsellType = types.CROSS_SELLS) => {
    try {
      const response = await BlueprintAPI(SERVICE, 'updateUpSell', data);
      dispatch(UpSellsActionCreators.getAllUpSells(false, upsellType));

      return response.data.message || null;
    } catch (e) {
      console.error('Error - updateUpSellDirectly:', e);
      return {
        error: e.data ? e.data.message : 'Something went wrong',
      };
    }
  },
  createUpSell: (data, upsellType = types.CROSS_SELLS) => async (dispatch) => {
    console.log('data', data);
    dispatch({
      type: UpSellsActionTypes.CREATE_UPSELL_REQ,
    });
    try {
      const response = await BlueprintJSONAPI(APIMethods.POST, SERVICE, 'createUpSell', data);
      dispatch({
        type: UpSellsActionTypes.CREATE_UPSELL_RES,
        payload: response.data.message || null,
      });
      dispatch(UpSellsActionCreators.getAllUpSells(false, upsellType));
    } catch (e) {
      console.error('Error - createUpSell:', e);
      dispatch({
        type: UpSellsActionTypes.CREATE_UPSELL_RES,
        error: e.data || 'Something went wrong',
      });
    }
  },
  createUpSellDirectly: async (dispatch, data, upsellType = types.CROSS_SELLS) => {
    try {
      const response = await BlueprintJSONAPI(APIMethods.POST, SERVICE, 'createUpSell', data);
      dispatch(UpSellsActionCreators.getAllUpSells(false, upsellType));

      return response.data.message || null;
    } catch (e) {
      console.error('Error - createUpSellDirectly:', e);
      return {
        error: e.data || 'Something went wrong',
      };
    }
  },
  deleteUpSell: (data, upsellType = types.CROSS_SELLS) => async (dispatch) => {
    dispatch({
      type: UpSellsActionTypes.DELETE_UPSELL_REQ,
    });
    try {
      const response = await BlueprintAPI(SERVICE, 'deleteUpSell', data);
      dispatch({
        type: UpSellsActionTypes.DELETE_UPSELL_RES,
        payload: response.data.message || null,
      });
      dispatch(UpSellsActionCreators.getAllUpSells(false, upsellType));
    } catch (e) {
      console.error('Error - deleteUpSell:', e);
      dispatch({
        type: UpSellsActionTypes.DELETE_UPSELL_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
  getSingleCrossSell: (id) => async (dispatch) => {
    dispatch({
      type: UpSellsActionTypes.GET_SINGLE_CROSS_SELL_REQ,
    });
    try {
      const data = { id };

      const response = await BlueprintAPI(SERVICE, 'getSingleCrossSell', data, null, true, APIMethods.GET);

      dispatch({
        type: UpSellsActionTypes.GET_SINGLE_CROSS_SELL_RES,
        payload: response.data.crossSell || null,
      });

      return response.data.crossSell;
    } catch (e) {
      console.error('Error - getSingleCrossSell:', e);
      dispatch({
        type: UpSellsActionTypes.GET_SINGLE_CROSS_SELL_RES,
        error: e.data || 'Something went wrong',
      });
      return null;
    }
  },
  getUpsellCampaignStats: (type) => async (dispatch) => {
    dispatch({
      type: UpSellsActionTypes.GET_UP_SELLS_CAMPAIGN_STATS_REQ,
    });
    try {
      const data = { type };

      const response = await BlueprintAPI(SERVICE, 'getUpsellCampaignStatistics', data, null, true, APIMethods.GET);

      dispatch({
        type: UpSellsActionTypes.GET_UP_SELLS_CAMPAIGN_STATS_RES,
        payload: response.data.statistics || {},
      });
    } catch (e) {
      console.error('Error - getUpsellCampaignStatistics:', e);
      dispatch({
        type: UpSellsActionTypes.GET_UP_SELLS_CAMPAIGN_STATS_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
};

export default UpSellsActionCreators;
