import React, { memo } from 'react';

const Loading = () => (
  <div className="d-flex justify-content-center">
    <strong>Loading...</strong>
    <div className="spinner-border ml-2" role="status" aria-hidden="true" />
  </div>
);

export default memo(Loading);
