import React, { memo } from 'react';

const GroupDetails = ({
  name = '',
  description = '',
  disabled = false,
  type = '',
  setName,
  setDescription,
}) => (
  <div className="group-details">
    <div className="form-group">
      <label>
        {type}
        {' '}
        Name
      </label>
      <input
        className="form-control"
        disabled={disabled}
        type="text"
        value={name}
        onChange={setName}
        placeholder={`Please enter a ${type} name here`}
        required
      />
    </div>

    <div className="form-group">
      <label>
        {type}
        {' '}
        Description
      </label>
      <textarea
        className="form-control"
        disabled={disabled}
        value={description}
        onChange={setDescription}
        placeholder={`Please enter a ${type} description here`}
        required
      />
    </div>
  </div>
);

export default memo(GroupDetails);
