import { GroupMessagingTypes } from '../../types';
import { APIMethods, APIServiceConstants, BlueprintJSONAPI } from '../BlueprintAPI';

const { MESSAGING } = APIServiceConstants;

export const GroupMessagingActionCreators = {
  getEstimatedTimeToSend: (totalMessageCount, segmentCount, isMMS) => async (dispatch) => {
    dispatch({
      type: GroupMessagingTypes.GET_SEND_ESTIMATE_REQ,
    });
    try {
      const data = { totalMessageCount, segmentCount, isMMS };
      const response = await BlueprintJSONAPI(APIMethods.POST, MESSAGING, 'groups/send-time-estimate', data);
      dispatch({
        type: GroupMessagingTypes.GET_SEND_ESTIMATE_RES,
        payload: response.data || {},
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: GroupMessagingTypes.GET_SEND_ESTIMATE_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
};

export default GroupMessagingActionCreators;
