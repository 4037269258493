import React, { memo } from 'react';

const InfoNode = ({ delta, invertedColour = false }) => {
  const effectiveDelta = invertedColour ? delta * -1 : delta;

  let className = '';

  if (effectiveDelta > 0) {
    className = 'text-success';
  } else if (effectiveDelta < 0) {
    className = 'text-danger';
  }

  const amount = `${delta > 0 ? '+' : ''}${delta}%`;

  return (<span className={className}>{amount}</span>);
};

export default memo(InfoNode);
