import React from 'react';
import Customer from './Customer';

export default ({
  customers,
  subscribers,
  toggleSubscribers,
  handleDelete,
  showDateAdded,
  action,
}) => {
  if (!customers.length) {
    return (
      <div className="row body-row">
        <div className="col-md-12">There are currently no customers found</div>
      </div>
    );
  }

  return customers.map((customer) => (
    <Customer
      key={customer.customerPhoneId}
      customer={customer}
      subscribers={subscribers}
      toggleSubscribers={toggleSubscribers}
      handleDelete={handleDelete}
      showDateAdded={showDateAdded}
      action={action}
    />
  ));
};
