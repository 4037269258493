import React, { memo } from 'react';

import CustomDomainForm from '../../components/forms/Settings/CustomDomainForm';

const CustomDomain = () => (
  <div>
    <div className="row">
      <div className="col-md-12">
        <div className="settings-card custom-domains">
          <CustomDomainForm />
        </div>
      </div>
    </div>
  </div>
);

export default memo(CustomDomain);
