import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import CustomFlowsTable from './Table';

const CustomFlowsSummary = () => (
  <div>
    <div className="row">
      <div className="col-md-12">
        <h2 className="title">
          Automations
        </h2>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <Link
          to="/automations/create"
          className="btn btn-primary float-right"
        >
          Create New
        </Link>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <CustomFlowsTable />
      </div>
    </div>
  </div>
);

export default memo(CustomFlowsSummary);
