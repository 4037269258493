import React, {
  memo, useEffect, useState, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';
import { UpSellsActionCreators } from '../../../redux/actions/upSells';
import { MerchantActionCreators } from '../../../redux/actions/merchant';
import { wordings, settings } from '../../../utils/crossSells';
import AnalyticsKPIs from './AnalyticsKPIs';
import CrossSellsTable from './CrossSellsTable';
import CrossSellDeletionModal from './CrossSellDeletionModal';

const {
  getAllUpSells: getAllUpSellsAction,
  updateUpSell: updateUpSellAction,
  deleteUpSell: deleteUpSellAction,
  getUpsellCampaignStats,
} = UpSellsActionCreators;

/**
 * @typedef {import('../../../redux/reducers').RootState} RootState
 */

/**
 * @typedef UpSell
 * @property {String} name
 * @property {Number} [id]
 * @property {Boolean} active
 */

const CrossSells = ({ type }) => {
  const { addToast } = useToasts();
  const {
    upsellsErr,
    upsellsLoaded,
    upsellsData,
    upsellsDataType,
    upsellUpdateErr,
    upsellsUpdated,
    upsellsUpdateResult,
    upsellCreateErr,
    upsellCreated,
    upsellCreateResult,
    upsellDeleteErr,
    upsellDeleting,
    upsellsDeleted,
    upsellsDeleteResult,
    upsellsCampaignStatsErr,
    upsellsCampaignStatsLoaded,
    upsellsCampaignStatsData,
  } = useSelector((/** @type RootState} */ state) => state.UpsellsReducer);
  const { merchantData, merchantLoaded } = useSelector(
    (/** @type RootState} */ state) => state.MerchantReducer,
  );

  const [deletionModalOpen, setDeletionModalOpen] = useState(false);

  /** @type {UpSell} */
  const initialUpsellInDeletion = {
    name: '',
    id: undefined,
    active: false,
  };

  const [upsellInDeletion, setUpsellInDeletion] = useState(initialUpsellInDeletion);

  const dispatch = useDispatch();

  /**
   *
   * @param {Number} id
   */
  const getUpsellById = (id) => {
    const index = upsellsData.findIndex((upsell) => upsell.id === id);
    return upsellsData[index];
  };

  const handlePriorityChange = ({ upsellsByNewOrder, upsellId }) => {
    dispatch(updateUpSellAction({
      upsellId,
      priorities: upsellsByNewOrder.map((upsell, index) => ({
        upsellId: upsell.id,
        priority: index + 1, // because index starts from 0 while priority starts from 1
      })),
    }));
  };

  const handleUpSellStateToggle = ({ upsellId, newState }) => {
    dispatch(updateUpSellAction({ upsellId, isActive: newState }));
  };

  const toggleDeletionModal = useCallback(
    () => setDeletionModalOpen(!deletionModalOpen),
    [deletionModalOpen],
  );

  const handleDeleteUpSell = (event) => {
    setUpsellInDeletion(getUpsellById(parseInt(event.target.value, 10)));
    toggleDeletionModal();
  };

  const handleConfirmedDelete = () => {
    dispatch(deleteUpSellAction({ upsellId: upsellInDeletion.id }, type));
  };

  // when upsell is not loaded and the currently loaded upsell has other type
  const shouldReloadUpsells = !(upsellsLoaded && upsellsDataType === type);

  useEffect(() => {
    if (!merchantLoaded) {
      const { getMerchantData } = MerchantActionCreators;
      dispatch(getMerchantData());
    }
  }, [dispatch, merchantData, merchantLoaded]);

  useEffect(() => {
    if (shouldReloadUpsells) {
      dispatch(getAllUpSellsAction(false, type));
    }
    if (!upsellsCampaignStatsLoaded || shouldReloadUpsells) {
      dispatch(getUpsellCampaignStats(type));
    }
  }, [
    dispatch,
    upsellsCampaignStatsLoaded,
    type,
    shouldReloadUpsells,
  ]);

  useEffect(() => {
    if (upsellsCampaignStatsErr) {
      addToast('Could not get the analytics for upsells', {
        appearance: 'warning',
        autoDismiss: true,
      });
    }
  }, [addToast, upsellsCampaignStatsErr]);

  useEffect(() => {
    if (upsellsErr) {
      addToast('Could not get the upsells', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, upsellsErr]);

  useEffect(() => {
    if (upsellUpdateErr) {
      addToast(`Could not update the rule. ${upsellUpdateErr}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, upsellUpdateErr]);

  useEffect(() => {
    if (upsellCreateErr) {
      addToast(`Could not create a new rule. ${upsellCreateErr}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, upsellCreateErr]);

  useEffect(() => {
    if (upsellsUpdated) {
      addToast(
        upsellsUpdateResult && upsellsUpdateResult.message
          ? upsellsUpdateResult.message
          : 'Updated successfully',
        {
          appearance: 'info',
          autoDismiss: true,
        },
      );
    }
  }, [addToast, upsellsUpdateResult, upsellsUpdated]);

  useEffect(() => {
    if (upsellCreated) {
      addToast(
        upsellCreateResult && upsellCreateResult.message
          ? upsellCreateResult.message
          : 'Created successfully',
        {
          appearance: 'info',
          autoDismiss: true,
        },
      );
    }
  }, [addToast, upsellCreateResult, upsellCreated]);

  useEffect(() => {
    if (upsellDeleteErr) {
      addToast(`Could not delete the upsell. Error: ${upsellDeleteErr}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, upsellDeleteErr]);

  useEffect(() => {
    if (upsellsDeleted) {
      setDeletionModalOpen(false);
      addToast(
        upsellsDeleteResult && upsellsDeleteResult.message
          ? upsellsDeleteResult.message
          : 'Deleted successfully',
        {
          appearance: 'info',
          autoDismiss: true,
        },
      );
    }
  }, [addToast, upsellsDeleteResult, upsellsDeleted]);

  return (
    <div>
      <div className="top-bar">
        <h2 className="title">{wordings[type].mainTitle}</h2>
      </div>
      <div className="row">
        <AnalyticsKPIs
          totals={upsellsCampaignStatsData}
          currency={merchantLoaded ? merchantData.currency : ''}
          type={type}
        />
      </div>
      <div className="row">
        <div className="col float-right">
          <Link
            to={{ pathname: `${settings[type].mainLink}/new`, state: { type } }}
            className="btn btn-primary create-upsell-btn"
          >
            Add new
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <CrossSellsTable
            toggleUpSellState={handleUpSellStateToggle}
            handleDelete={handleDeleteUpSell}
            handlePriorityChange={handlePriorityChange}
            type={type}
            shouldReloadUpsells={shouldReloadUpsells}
          />
        </div>
      </div>
      {deletionModalOpen && (
        <CrossSellDeletionModal
          isOpen={deletionModalOpen}
          close={toggleDeletionModal}
          upsellName={upsellInDeletion.name}
          upsellDeleting={upsellDeleting}
          handleDeleteSubmit={handleConfirmedDelete}
          type={type}
        />
      )}
    </div>
  );
};

export default memo(CrossSells);
