import React from 'react';
import UploadInfo from './UploadInfo';
import UploadingInfo from './UploadingInfo';
import UploadURL from './UploadURL';
import ShowError from './ShowError';

export default ({
  getRootProps,
  getInputProps,
  isDragActive,
  error,
  uploading,
  completed,
  addToMessage,
  file,
  url,
  msgType,
  onMsgTypeChange,
  merchant,
}) => (
  <div className="upload-zone">
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <UploadInfo isDragActive={isDragActive} />
    </div>
    <p>
      For best results please follow the guide&nbsp;
      <a href="https://help.blueprint.store/uploading-an-image-best-practices" target="_blank" rel="noreferrer">here</a>
      .
    </p>
    {!error && uploading && (
    <UploadingInfo completed={completed} file={file} url={url} />
    )}
    {!error && completed && (
    <UploadURL
      url={url}
      addToMessage={addToMessage}
      msgType={msgType}
      onMsgTypeChange={onMsgTypeChange}
      merchant={merchant}
      file={file}
    />
    )}
    {error && <ShowError error={error} />}
  </div>
);
