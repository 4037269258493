import React, {
  useState, memo, useCallback, useEffect,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import Totals from '../../components/main/GroupMessaging/Analytics/Totals';
import MessageList from '../../components/main/GroupMessaging/Analytics/MessageList';
import LoadingMessage from '../../components/main/GroupMessaging/Loading';
import ErrorMessage from '../../components/main/GroupMessaging/Error';
import { getGroupDetails } from '../../redux/actions/GroupMessagingAPI';
import { initialGroup } from './GroupMessaging';

const GroupMessageAnalytics = () => {
  const [group, setGroup] = useState(initialGroup);
  const [error, setError] = useState(/** @type {String?} */(null));
  const { groupId } = useParams();
  const showComingSoon = true;

  const fetchGroupDetails = useCallback(async () => {
    try {
      const groupDetails = await getGroupDetails(groupId);

      setGroup({
        ...groupDetails,
      });
    } catch (e) {
      setError('An error has occurred while retrieving the group details');
    }
  }, [groupId]);

  useEffect(() => {
    fetchGroupDetails();
  }, [fetchGroupDetails, groupId]);

  return (
    <PageBuilder>
      <SEO title="Group Messaging | Blueprint" />
      <div className="a-view message-group-analytics">
        <div className="container">
          <div className="top-bar">
            <h2 className="title">
              <Link to="/group-messaging">Group Messaging</Link>
              {' '}
              {'>'}
              {' '}
              Analytics
            </h2>
          </div>

          {showComingSoon ? (
            <>
              <br />
              <br />
              <br />
              <h3 className="text-center">Coming soon...</h3>
            </>
          ) : (
            <>
              <LoadingMessage loading={!group.id} />

              <ErrorMessage error={error} />

              <Totals loading={!group.id} selectedGroup={group} />
              <MessageList loading={!group.id} messages={[]} />
            </>
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(GroupMessageAnalytics);
