import {
  APIServiceConstants,
  BlueprintAPI,
} from '../../redux/actions/BlueprintAPI';

const { SERVICE } = APIServiceConstants;

/** @typedef {import('../../utils/types').SmartLinksResult} SmartLinksResult */

/**
 * @returns {Promise<SmartLinksResult>}
 */
const getSmartlinks = async () => {
  try {
    const result = await BlueprintAPI(SERVICE, 'smartlinks', {}, null, true, 'GET');

    return {
      smartLinks: result?.data?.smartlinks || [],
      baseURL: result?.data?.baseURL || '',
    };
  } catch (error) {
    console.error(error);
  }

  return {
    smartLinks: [],
    baseURL: '',
  };
};

export default getSmartlinks;
