import React, {
  useMemo,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useTable } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { CustomFlowsActionCreators } from '../../../redux/actions/customFlows';
import { BPToggle } from '../BPToggle';
import DeletionModal from './DeletionModal';

/** @typedef {import('../../../redux/reducers').RootState} CustomFlowsRootState */

const {
  getAllFlows: getAllFlowsActions,
  toggleActiveState,
  deleteFlow: deleteFlowAction,
} = CustomFlowsActionCreators;

const Table = () => {
  const [flowToDelete, setFlowToDelete] = useState(false);
  const dispatch = useDispatch();
  const handleToggle = useCallback(
    (cell) => {
      dispatch(toggleActiveState(cell.value, cell.row.values.id));
    },
    [dispatch],
  );
  const { addToast } = useToasts();
  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'name',
      },
      {
        Header: 'Sends',
        accessor: 'numMessagesSent',
      },
      {
        Header: 'Revenue',
        accessor: 'revenue',
        Cell: ({ cell }) => (
          <>
            {(cell.value || 0).toFixed(2)}
          </>
        ),
      },
      {
        Header: 'Live',
        accessor: 'isActive',
        Cell: ({ cell }) => {
          const isThisToggleProcessing = cell?.row?.original?.isToggling;

          return (
            <>
              <div
                style={{ display: 'inline-block' }}
                className="mr-2 mt-1"
              >
                <BPToggle
                  active={cell.value}
                  disabled={isThisToggleProcessing}
                  handleClick={() => handleToggle(cell)}
                />
              </div>
              { isThisToggleProcessing && (
                <div
                  className="spinner-border"
                  role="status"
                  style={{ width: 23, height: 23 }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ cell }) => (
          <>
            <Link
              to={{ pathname: `/automations/${cell.value}`, state: { id: cell.value, flow: { ...cell.row.values } } }}
              className="minimal-black-btn"
            >
              Edit
            </Link>
            <button
              className="minimal-red-btn"
              value={cell.value}
              onClick={() => setFlowToDelete({ ...cell.row.values })}
              type="button"
            >
              Delete
            </button>
          </>
        ),
      },
    ],
    [handleToggle],
  );

  const {
    flows,
    flowDeleted,
    flowDeleting,
  } = useSelector((/** @type {CustomFlowsRootState} */state) => state.CustomFlowsReducer);

  useEffect(() => {
    dispatch(getAllFlowsActions());
  }, [dispatch]);

  useEffect(() => {
    if (flowDeleted === true) {
      addToast('Succesfully deleted flow', {
        appearance: 'success',
        autoDismiss: true,
      });
      setFlowToDelete(false);
    } else if (flowDeleted === false) {
      addToast('Unable to delete flow', {
        appearance: 'error',
        autoDismiss: true,
      });
      setFlowToDelete(false);
    }
  }, [flowDeleted, addToast]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: flows,
    getRowId: (row) => row.id,
  });

  const handleConfirmedDelete = (flowId) => {
    dispatch(deleteFlowAction(flowId));
  };

  const getClassNamesStringFromColumn = (column) => {
    const { id: columnId } = column;

    switch (columnId) {
      case 'id':
      case 'numMessagesSent':
      case 'revenue':
        return 'text-right';
      default:
        return null;
    }
  };

  const getCellClassNameStringFromCell = (cell) => {
    const { column } = cell;

    return getClassNamesStringFromColumn(column);
  };

  return (
    <>
      <table {...getTableProps()} className="table table-hover">
        <thead className="thead-dark">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([{
                    className: getClassNamesStringFromColumn(column),
                  }])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps([{
                      className: getCellClassNameStringFromCell(cell),
                    }])}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        {flowToDelete && (
          <DeletionModal
            flow={flowToDelete}
            isOpen={!!flowToDelete}
            isDeleting={flowDeleting}
            close={setFlowToDelete}
            handleDeleteSubmit={handleConfirmedDelete}
          />
        )}
      </table>
    </>
  );
};

export default Table;
