import {
  TYPE_ABANDONED_STAGE1,
  TYPE_ABANDONED_STAGE2,
  TYPE_ABANDONED_STAGE3,
  TYPE_RECHARGE_ABANDONED_STAGE1,
} from '../../../utils/messageTemplateTypes';

export const INTEGRATION_RECHARGE = 'recharge';

export const getTemplatesByType = (abandonedCartMessageTemplates) => ({
  [TYPE_ABANDONED_STAGE1]: abandonedCartMessageTemplates.find(
    (mt) => mt.messageType === TYPE_ABANDONED_STAGE1,
  ),
  [TYPE_ABANDONED_STAGE2]: abandonedCartMessageTemplates.find(
    (mt) => mt.messageType === TYPE_ABANDONED_STAGE2,
  ),
  [TYPE_ABANDONED_STAGE3]: abandonedCartMessageTemplates.find(
    (mt) => mt.messageType === TYPE_ABANDONED_STAGE3,
  ),
  [TYPE_RECHARGE_ABANDONED_STAGE1]: abandonedCartMessageTemplates.find(
    (mt) => mt.messageType === TYPE_RECHARGE_ABANDONED_STAGE1,
  ),
});

export const getDemoMessages = (automatedMessageTemplates) => {
  const templates = getTemplatesByType(automatedMessageTemplates);
  const messages = [];

  const abandonedStage1 = templates[TYPE_ABANDONED_STAGE1];
  const abandonedStage2 = templates[TYPE_ABANDONED_STAGE2];
  const abandonedStage3 = templates[TYPE_ABANDONED_STAGE3];
  const rechargeAbandonedStage1 = templates[TYPE_RECHARGE_ABANDONED_STAGE1];

  if (
    abandonedStage1
    && abandonedStage1.active
  ) {
    messages.push({
      content: abandonedStage1.messageContent,
      date: abandonedStage1.messageInterval,
      integer: true,
    });
  }

  if (
    abandonedStage2
    && abandonedStage2.active
  ) {
    messages.push({
      content: abandonedStage2.messageContent,
      date: abandonedStage2.messageInterval,
      integer: true,
    });
  }

  if (
    abandonedStage3
    && abandonedStage3.active
  ) {
    messages.push({
      content: abandonedStage3.messageContent,
      date: abandonedStage3.messageInterval,
      integer: true,
    });
  }

  if (
    rechargeAbandonedStage1
    && rechargeAbandonedStage1.active
  ) {
    messages.push({
      content: rechargeAbandonedStage1.messageContent,
      date: rechargeAbandonedStage1.messageInterval,
      integer: true,
    });
  }

  return messages;
};

export const mapMessageTemplateToSave = ({
  id,
  messageContent,
  messageInterval,
  messageType,
  active,
}) => ({
  id,
  messageContent,
  messageInterval,
  messageType,
  active,
});

export const filterForType = (type) => (template) => template.type === type;
