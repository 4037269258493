import React, { memo } from 'react';

import { NEGATE_MODES } from '../../../../global/Predicates/Negate';
import Filter, { createFilterChangeHandler } from '../Filter';
import SelectField from '../ValueComponents/SelectField';

/** @typedef {import('../../../../global/Predicates/Negate').NegateMode} NegateMode */
/** @typedef {import('../../../../global/Predicates/TypeSelector').Type} Type */
/** @typedef {import('../../../../global/Predicates/ComparatorSelector').Comparator} Comparator */

const getDefaultNegate = () => ({ mode: NEGATE_MODES.no });
const getDefaultComparator = (comparators) => comparators[0].value;
const getDefaultValue = () => 'ACTIVE';

export const valueMapper = ({ id, ...values }) => ({ ...values });

const SELECT_OPTIONS = [
  {
    value: 'ACTIVE',
    label: 'Active subscriber',
  },
  {
    value: 'INACTIVE',
    label: 'Inactive subscriber',
  },
  {
    value: 'NEVER_SUBSCRIBED',
    label: 'Never subscribed',
  },
];

/**
 * @param {Object} props
 * @param {String} props.id
 * @param {Function} props.onChange
 * @param {String} props.value
 * @param {NegateMode} props.negate
 * @param {String} props.type
 * @param {Type[]} props.types
 * @param {String} props.comparator
 * @param {Comparator[]} props.comparators
 */
const CommerceSubscriptionStatus = (props) => {
  const {
    type,
    types,
    value: initalValue,
    negate = getDefaultNegate(),
    comparators,
    onChange,
    id,
  } = props;

  const value = initalValue || getDefaultValue();

  // Destructure these seperately because we need to know the `type` to get the defaults
  const {
    comparator = getDefaultComparator(comparators),
  } = props;

  const onFilterChange = createFilterChangeHandler({
    id, type, value, negate, comparator, onChange,
  });

  // Need to call the onFilterChange so that the default value is sent to API...
  // if they click save before selecting a value
  if (!initalValue) {
    onFilterChange('value', value);
  }

  return (
    <Filter
      negate={negate}
      comparator={comparator}
      type={type}
      onChange={onFilterChange}
      types={types}
      comparators={comparators}
      value={(
        <SelectField
          value={value}
          onChange={onFilterChange}
          options={SELECT_OPTIONS}
        />
      )}
    />
  );
};

export default memo(CommerceSubscriptionStatus);
