import { CUSTOMER_MESSAGE_PLACEHOLDERS } from '../../../constants';

const handleUpdate = (messages, message) => {
  const existingItem = messages.find((s) => s.id === Number(message.id));
  const updatedItem = existingItem ? { ...existingItem, ...message } : null;
  return updatedItem
    ? [...messages.filter((s) => s.id !== updatedItem.id), updatedItem]
    : messages;
};

const handleMessage = (messages, message) => [...messages, message];

export const getSocketConversations = (
  socketMessage,
  existingConversations,
) => {
  const {
    type,
    messages: conversations,
    body: conversation,
    message,
  } = socketMessage;

  if (type === 'init') {
    return conversations;
  }
  if (type === 'update') {
    return handleUpdate(existingConversations, conversation);
  }
  if (type === 'message') {
    return handleMessage(existingConversations, message);
  }

  return [];
};

export const getDeliveryStatus = (deliveryStatus, sendSkipped, direction) => {
  if (direction === 'incoming') {
    return '';
  }

  if (sendSkipped === 1) {
    return 'Skipped';
  }

  switch (deliveryStatus) {
    case 'delivered':
      return 'Delivered';
    case 'accepted':
      return 'Sent';
    case 'failed':
      return 'Failed';
    case 'undelivered':
      return 'Failed';
    default:
      return 'Sent';
  }
};

const getInvalidPlaceholders = (message) => {
  const placeholders = (message.match(/\{\w+\}/g) || []).map((s) => s.slice(1, -1));

  return placeholders.filter(
    (p) => !Object.values(CUSTOMER_MESSAGE_PLACEHOLDERS).includes(p),
  );
};

export const validateMessage = (message, permalinkData) => {
  if (
    message.includes(CUSTOMER_MESSAGE_PLACEHOLDERS.link_to_checkout)
    && (!permalinkData || permalinkData.products.length === 0)
  ) {
    return 'The message contains a checkout link placeholder, but no products have been selected.';
  }

  const invalidPlaceholders = getInvalidPlaceholders(message);

  if (invalidPlaceholders.length > 0) {
    return `The message contains invalid placeholders: ${invalidPlaceholders.join(
      ', ',
    )}.`;
  }

  return null;
};
