import React, {
  memo, useCallback, useMemo, useState, useEffect,
} from 'react';
import { HelpBubble } from '../HelpBubble';
import { BImage } from '../../global/BImage';
import CheckoutLinkModal from '../CheckoutLinkModal';
import { numberOfPaidOrders, filterPaidOrders } from '../../../utils';
import CustomerMessageGroups from './CustomerMessageGroups';
import UnsubscribeBtn from './UnsubscribeBtn';
import NotesSection from './NotesSection';

const ACCORDION_TABS = {
  groups: 0,
  customerDetails: 1,
  orders: 2,
  notes: 3,
};

/**
 * @typedef CustomerPhone
 * @property { String } id
 * @property { * } optOutDate
 * @property { String } phone
 * @property { Number } optedIn
 */

/**
 * @typedef Customer
 * @property { String } [lastOrderId]
 * @property { *[] } orders
 * @property { String } [fullName]
 * @property { Number } [ALRorders]
 * @property { Number } [AOV]
 * @property { String } [email]
 * @property { Number } [AARFdays]
 * @property { * } lastOrderItems
 * @property { * } maxOrderDate
 * @property { CustomerPhone } [customerPhone]
 * @property { * } notes
 * @property { * } supportFlagged
 */

/**
 * @param { Object } props
 * @param { Customer } props.customer
 * @param { Object } props.merchant
 * @param { * } props.permalinkData
 * @param { Function } props.onUnsubscribe
 * @param { Function } props.updatePermalinkData
 */

/** @type { Customer } */
export const defaultCustomer = {
  AARFdays: undefined,
  ALRorders: undefined,
  AOV: undefined,
  customerPhone: undefined,
  email: undefined,
  fullName: undefined,
  lastOrderId: undefined,
  lastOrderItems: undefined,
  maxOrderDate: undefined,
  notes: undefined,
  orders: [],
  supportFlagged: undefined,
};

const CustomerBar = ({
  customer = defaultCustomer,
  merchant,
  permalinkData,
  onUnsubscribe,
  updatePermalinkData,
}) => {
  const [accordion, setAccordion] = useState(null);
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const {
    lastOrderId,
    orders,
    fullName,
    ALRorders,
    AOV,
    email,
    AARFdays,
    lastOrderItems,
    maxOrderDate,
    customerPhone,
    notes,
  } = customer;
  const numberOfSelectedProducts = useMemo(
    () => (permalinkData?.products || []).reduce((qty, p) => qty + p.quantity, 0),
    [permalinkData],
  );
  const customerPhoneId = customerPhone?.id;
  const optedOut = (customerPhone?.optedIn === 0); // Only if they have explicitly opted out
  const title = fullName || 'Summary';
  const numberOfOrders = useMemo(() => numberOfPaidOrders(orders), [orders]);

  useEffect(() => {
    setAccordion(null);
  }, [customer]);

  const toggleAccordion = useCallback(
    (id) => {
      const acdns = id !== accordion ? id : null;
      setAccordion(acdns);
    },
    [accordion],
  );

  const lastOrder = useMemo(
    () => (orders || []).find((o) => o.id === lastOrderId) || {},
    [lastOrderId, orders],
  );

  const toggleCheckoutModal = useCallback(() => {
    setCheckoutModalOpen(!checkoutModalOpen);
  }, [checkoutModalOpen]);

  return (
    <div className={`customer-bar ${customer ? 'active' : ''}`}>
      <div className="customer-info">
        <span className="title">{title}</span>
        {optedOut && <small className="opted-out">(unsubscribed)</small>}
      </div>

      <div className="insights">
        <span className="intro">Insights</span>
        <div>
          <span className="kpi">
            {merchant.currencySymbol || '£'}
            {ALRorders || '0.00'}
            {' '}
            <span className="small">
              (
              {numberOfOrders}
              {' '}
              orders)
            </span>
          </span>
          <span className="kpi-title">Lifetime Spend</span>
        </div>
        <div>
          <span className="kpi">
            {merchant.currencySymbol || '£'}
            {AOV || '0.00'}
          </span>
          <span className="kpi-title">AOV</span>
        </div>
        <div>
          <span className="kpi">
            {AARFdays || '0.00'}
            {' '}
            Days
          </span>
          <span className="kpi-title">Reorder frequency</span>
        </div>
      </div>

      {!optedOut && (
      <div className="bot-sect">
        <div className="checkout-sms">
          <div className="top">
            <span className="intro">Create checkout link</span>
            <HelpBubble
              text="Send customers a custom checkout link via sms. They can checkout using existing details or a custom link if they're a first time customer"
              left
              small
            />
          </div>

          <button className="btn btn-primary" onClick={toggleCheckoutModal} type="button">
            {numberOfSelectedProducts > 0
              ? 'Update Selection'
              : 'Add Product(s)'}
          </button>

          {numberOfSelectedProducts > 0 && (
          <div className="selected-products">
            <i>
              {numberOfSelectedProducts}
              {' '}
              product(s) selected
            </i>
          </div>
          )}
        </div>
      </div>
      )}

      <div className="customer-accordion">
        {!optedOut && (
        <div
          className={`accordion-item ${
            accordion === ACCORDION_TABS.groups ? 'active' : ''
          } accordion-groups-item`}
        >
          <div
            className="accordion-header"
            onClick={() => toggleAccordion(ACCORDION_TABS.groups)}
            role="button"
            tabIndex={0}
          >
            <span className="accordion-title">Groups</span>
            <BImage
              src="https://static.blueprint.store/illustrations/bp-accordion-arrow.svg"
              lazyLoad={false}
            />
          </div>
          <div className="accordion-body">
            {accordion === ACCORDION_TABS.groups && (
            <CustomerMessageGroups customerPhoneId={customerPhoneId} />
            )}
          </div>
        </div>
        )}

        <div
          className={
              `accordion-item ${
                accordion === ACCORDION_TABS.customerDetails ? 'active' : ''}`
            }
        >
          <div
            className="accordion-header"
            onClick={() => toggleAccordion(ACCORDION_TABS.customerDetails)}
            role="button"
            tabIndex={0}
          >
            <span className="accordion-title">Customer Details</span>
            <BImage
              src="https://static.blueprint.store/illustrations/bp-accordion-arrow.svg"
              lazyLoad={false}
            />
          </div>
          <div className="accordion-body">
            <div className="info-line">
              <span className="title">Email: </span>
              <span className="value">{email}</span>
            </div>
            <div className="info-line">
              <span className="title">Last Order Date: </span>
              {maxOrderDate && (
              <span className="value">
                {new Date(maxOrderDate).toLocaleDateString()}
              </span>
              )}
            </div>
            <div className="info-line">
              <span className="title">Last Order: </span>
              <div className="value">
                {lastOrderItems?.length > 0 ? (
                  lastOrderItems.map((item) => (
                    <div key={item.id} className="order-item">
                      <span className="text">
                        {item.productName}
                        {' '}
                        X
                        {item.quantity}
                      </span>
                    </div>
                  ))
                ) : (
                  <div>N/A</div>
                )}
              </div>
            </div>
            <div className="info-line">
              <span className="title">Last Order Address:</span>
              {lastOrder.shippingAddress1 && (
              <span className="value">
                {lastOrder.shippingAddress1}
                ,
                <br />
              </span>
              )}
              {lastOrder.shippingAddress2 && (
              <span className="value">
                {lastOrder.shippingAddress2}
                ,
                <br />
              </span>
              )}
              {lastOrder.shippingCity && (
              <span className="value">
                {lastOrder.shippingCity}
                ,
                <br />
              </span>
              )}
              {lastOrder.shippingPostCode && (
              <span className="value">
                {lastOrder.shippingPostCode}
                <br />
              </span>
              )}
            </div>
          </div>
        </div>
        <div
          className={
              `accordion-item ${
                accordion === ACCORDION_TABS.orders ? 'active' : ''}`
            }
        >
          <div
            className="accordion-header"
            onClick={() => toggleAccordion(ACCORDION_TABS.orders)}
            role="button"
            tabIndex={0}
          >
            <span className="accordion-title">
              Orders (
              {numberOfOrders}
              )
            </span>
            <BImage
              src="https://static.blueprint.store/illustrations/bp-accordion-arrow.svg"
              lazyLoad={false}
            />
          </div>
          <div className="accordion-body">
            {orders
                && filterPaidOrders(orders).map((order) => (
                  <div key={order.id} className="bp-order">
                    <span role="img" aria-label="package">
                      📦
                    </span>
                    {' '}
                    <span className="order-number">
                      #
                      {order.orderIdExternal}
                      {' '}
                      (
                      {new Date(order.orderCreatedAt).toLocaleDateString()}
                      )
                    </span>
                  </div>
                ))}
          </div>
        </div>

        <div
          className={
              `accordion-item ${
                accordion === ACCORDION_TABS.notes ? 'active' : ''}`
            }
        >
          <div
            className="accordion-header"
            onClick={() => toggleAccordion(ACCORDION_TABS.notes)}
            role="button"
            tabIndex={0}
          >
            <span className="accordion-title">
              Notes (
              {notes ? notes.length : 0}
              )
            </span>
            <BImage
              src="https://static.blueprint.store/illustrations/bp-accordion-arrow.svg"
              lazyLoad={false}
            />
          </div>
          <div className="accordion-body">
            {accordion === ACCORDION_TABS.notes && (
            <NotesSection
              customerPhoneId={customerPhoneId}
              customerNotes={notes}
            />
            )}
          </div>
        </div>
      </div>

      {!optedOut && (
      <div className="unsubscribe-sect">
        <UnsubscribeBtn customer={customer} onUnsubscribe={onUnsubscribe} />
      </div>
      )}

      {checkoutModalOpen && (
      <CheckoutLinkModal
        isOpen={checkoutModalOpen}
        onClose={toggleCheckoutModal}
        permalinkData={permalinkData}
        updatePermalinkData={updatePermalinkData}
      />
      )}
    </div>
  );
};

export default memo(CustomerBar);
