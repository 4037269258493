import React, {
  memo,
  useLayoutEffect,
  useMemo,
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import Message from './Message';
import { EditorTextarea } from '../EditorTextarea';
import { sortBySentAtAndId } from '../../../utils';
import GroupMessageDeliveryStatus from './GroupMessageDeliveryStatus';
import { validateMessage } from './helpers';
import ImagePreview from '../ImagePreview';
import { useFeatureFlags } from '../../../contexts';
import {
  MAX_NUMBER_OF_SUBSCRIBERS,
  MAX_NUMBER_OF_SUBSCRIBERS_V2,
} from '../../../utils/settings';
import ScheduledGroupMessageDeliveryStatus from './ScheduledGroupMessageDeliveryStatus';
import { CONVERSATION_TYPE } from '../../../constants';

/**
 * @typedef Group
 * @property { String } [name]
 */

/** @type { Group } */
const defaultGroup = {
  name: undefined,
};

const GroupConversation = ({
  group = defaultGroup,
  customerCount,
  message,
  updateMessage,
  messages = [],
  handleSend,
  onMessageChange,
  permalinkData,
  merchant,
  showMMSSection,
  mmsMediaURL,
  handleMMSCancel,
  handleOnMsgTypeChange,
  addMMS,
  msgType,
}) => {
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [scheduledGroupMessageIsActive, setScheduledGroupMessageIsActive] = useState(false);
  /** @type {React.MutableRefObject<HTMLDivElement?>} */
  const messagesRef = useRef(null);

  const sortedList = useMemo(() => [...messages].sort(sortBySentAtAndId), [messages]);
  const { isFeatureEnabled } = useFeatureFlags();

  useLayoutEffect(() => {
    if (sortedList.length === 0) {
      return;
    }

    const behavior = 'auto';

    if (messagesRef.current) {
      messagesRef.current.scrollIntoView({
        behavior,
      });
    }
  }, [sortedList]);

  useEffect(() => {
    setError('');
  }, [permalinkData]);

  const prependToMessage = useCallback(
    (text) => {
      const newMessage = `${message} ${text}`;
      updateMessage(newMessage.replace(/\n/g, '').trim());
    },
    [message, updateMessage],
  );

  useEffect(() => {
    const fn = async () => {
      const res = await isFeatureEnabled(
        'groupmessagev2',
        false,
      );
      setScheduledGroupMessageIsActive(res);
    };
    fn();
  }, [isFeatureEnabled]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const maxNumberOfSubscribers = scheduledGroupMessageIsActive
        ? MAX_NUMBER_OF_SUBSCRIBERS_V2
        : MAX_NUMBER_OF_SUBSCRIBERS;
      if (customerCount > maxNumberOfSubscribers) {
        setDisabled(true);
        setError(
          `The group messages exceed the maximum number of subscribers (${maxNumberOfSubscribers}) allowed.`,
        );
      } else {
        setDisabled(false);
        setError('');
      }
    } else {
      setDisabled(false);
      setError('');
    }
  }, [customerCount, scheduledGroupMessageIsActive]);

  const onSend = useCallback(() => {
    const errorMessage = validateMessage(message, permalinkData);

    if (!errorMessage) {
      handleSend({
        message,
        msgType,
        mmsMediaURL,
      });
      setError('');
    } else {
      setError(errorMessage);
    }
  }, [handleSend, message, mmsMediaURL, msgType, permalinkData]);

  const conversationStyle = showMMSSection
    ? {
      height: 'calc(100% - 240px)',
    }
    : {};

  return (
    <div className="conv-card">
      <div className="info-bar">
        <div className="contact-title">
          <span>
            Group:
            {group.name}
          </span>
          <span>
            [
            {customerCount}
            {' '}
            contacts]
          </span>
        </div>
      </div>

      <div className="conversation-window" style={conversationStyle}>
        <div>
          {sortedList.map((item) => {
            if (item.sendingVersion === 2) {
              return (
                <Message
                  key={item.id}
                  {...item}
                  name={item.userName}
                  type={CONVERSATION_TYPE.GROUP}
                  status={<ScheduledGroupMessageDeliveryStatus message={item} />}
                />
              );
            }
            return (
              <Message
                key={item.id}
                {...item}
                name={item.userName}
                type={CONVERSATION_TYPE.GROUP}
                status={<GroupMessageDeliveryStatus message={item} />}
              />
            );
          })}
        </div>

        <div id="messages-ref" ref={messagesRef} />
      </div>

      {showMMSSection && (
      <ImagePreview
        url={mmsMediaURL}
        altText={message}
        onCancel={handleMMSCancel}
      />
      )}

      <div className="input-window">
        <EditorTextarea
          value={message}
          onChange={onMessageChange}
          onSend={onSend}
          onMsgTypeChange={handleOnMsgTypeChange}
          addMMS={addMMS}
          prependToMessage={prependToMessage}
          disabled={disabled}
          msgType={msgType}
          merchant={merchant}
          sendButtonLabel="Preview"
        />

        <div className="error">{error}</div>
      </div>
    </div>
  );
};

export default memo(GroupConversation);
