import React, { memo, useMemo } from 'react';
import { CONVERSATION_TYPE } from '../../../constants';

const Message = ({
  id,
  sentAt,
  createdAt,
  direction,
  message,
  name,
  status,
  fullName,
  mediaURL,
  messageGroupName,
  type: messageType,
}) => {
  const suffix = useMemo(() => {
    if (direction === 'incoming') {
      return (fullName || ' ').split(' ')[0];
    }

    return `${name || 'bot'}${
      messageGroupName ? ` (${messageGroupName})` : ''
    }`;
  }, [direction, fullName, name, messageGroupName]);

  const dateLabel = useMemo(() => {
    const output = (messageType === CONVERSATION_TYPE.INDIVIDUAL) ? createdAt : sentAt;
    const date = new Date(output);
    return `${date.toLocaleString()} - ${suffix}`;
  }, [sentAt, createdAt, messageType, suffix]);

  const type = direction === 'incoming' ? 'cus' : 'agent';

  return (
    <div key={id} className={`message ${type}`}>
      <div className="msg-content">
        {mediaURL && (
        <a href={mediaURL} target="blank">
          <img src={mediaURL} alt="message image" />
        </a>
        )}
        <p>{message}</p>
      </div>

      <p className="date">{dateLabel}</p>

      {status}
    </div>
  );
};

export default memo(Message);
