import React from 'react';

import {
  formatAsMoney,
  formatValueAsPercentage,
} from '../../utils/numerical';

import { getReductedString } from '../../utils';

const formatCampaignType = (value) => {
  const lower = String(value).replace(/_/g, ' ').toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.slice(1);
};

const SMSAnalyticsCampaignTableRow = ({ item, currency }) => {
  const formatted = formatCampaignType(item.rowId);
  const cutOff = 70;
  const reducted = getReductedString(formatted, cutOff);
  const hasReducted = reducted !== formatted;
  return (
    <tr>
      <td
        title={hasReducted ? formatted : ''}
        className={hasReducted ? 'infotitle' : ''}
      >
        {reducted}
      </td>
      <td>{item.numMessagesSent}</td>
      <td>{item.numOrdersAttributed}</td>
      <td>{formatAsMoney(item.totalRevenueAttributed, currency)}</td>
      <td>{formatAsMoney(item.revenuePerMessage, currency)}</td>
      <td>{formatValueAsPercentage(item.conversionRate)}</td>
    </tr>
  );
};

const SMSAnalyticsCampaignTable = ({ items, currency }) => {
  if (!items) {
    return null;
  }
  const tableRows = items.map((item) => (
    <SMSAnalyticsCampaignTableRow
      item={item}
      currency={currency}
      key={item.rowId}
    />
  ));
  return (
    <table className="table table-bordered table-responsive-lg">
      <thead className="thead-dark">
        <tr>
          <th className="col-title" aria-label="title" />
          <th className="col-metric">Messages sent</th>
          <th className="col-metric">Orders</th>
          <th className="col-metric">Revenue</th>
          <th className="col-metric">Per message</th>
          <th className="col-metric">Conversion rate</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};

export default SMSAnalyticsCampaignTable;
