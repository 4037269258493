import { compareDateAsc } from './dates';

export const CHAR_COUNT_TO_DISPLAY_ON_UPSELLS = 30;

export const showCalc = (value) => (Number.isNaN(value) || !Number.isFinite(parseFloat(value)) ? '' : value);

export const percentCalc = (total, divide) => showCalc(((divide / total) * 100).toFixed(2));

export const filterPaidOrders = (orders) => orders.filter((order) => order.status === 'paid');

export const numberOfPaidOrders = (orders) => (orders && Array.isArray(orders)
  ? filterPaidOrders(orders).length : 0);

export const getReductedString = (
  value,
  maxLength = CHAR_COUNT_TO_DISPLAY_ON_UPSELLS,
) => {
  if (value.length > maxLength) {
    return `${value.substring(0, maxLength)}... `;
  }

  return value;
};

export const UPSELL_TRIGGER_HELP_TEXT = 'Trigger products are items that need to be included in a customer order for the upsell message to be send';

export const UPSELL_PROD_SELECT_ALL_VARS = 'all';
export const UPSELL_TEXT_ALL_VARS_SELECTED = 'All Variants';

export const hasNoRecommendedProds = (products) => products.every((product) => !product.prodToGetVariantId);

const sortById = (o1, o2) => {
  if (o1.id < o2.id) {
    return -1;
  }
  if (o1.id > o2.id) {
    return 1;
  }
  return 0;
};

export const sortByCreatedAtAndId = (o1, o2) => {
  const valueByCreatedAt = compareDateAsc(o1.createdAt, o2.createdAt);
  const hasIds = 'id' in o1 && 'id' in o2;
  if (valueByCreatedAt === 0 && hasIds) {
    return sortById(o1, o2);
  }
  return valueByCreatedAt;
};

export const sortBySentAtAndId = (o1, o2) => {
  const valueBySent = compareDateAsc(o1.sentAt, o2.sentAt);
  const hasIds = 'id' in o1 && 'id' in o2;
  if (valueBySent === 0 && hasIds) {
    return sortById(o1, o2);
  }
  return valueBySent;
};

export const getSMSModuleNameForDisplay = (moduleName) => {
  const map = {
    abandoned_cart_reminder_1: 'Abandoned Cart 1',
    abandoned_cart_reminder_2: 'Abandoned Cart 2',
    abandoned_cart_reminder_3: 'Abandoned Cart 3',
    'chatbot-sms-adapter/autoresponder': 'Autoresponse',
    'chatbot-sms-adapter/away-hours': 'Away Hours',
    'chatbot-sms-adapter/error': '',
    'chatbot-sms-adapter/incomingMessage': '',
    'chatbot-sms-adapter/invokeCheckout': 'Checkout Link',
    'chatbot-sms-adapter/operations': '',
    'chatbot-sms-adapter/optin': 'Optin',
    checkout_verification_sent: 'Verification',
    'checkout/orderCompleted': 'Order Complete',
    'comms/automated/post-order-checkin': 'Post Order Checkin',
    'comms/automated/reorder-prompt': 'Reorder Prompt',
    'comms/automated/upsell': 'Upsell',
    'comms/orderFulfilled': 'Order Fulfilled',
    'comms/postOrderCheckIn': 'Post Order Checkin',
    'comms/postOrderReorderPrompt': 'Reorder Prompt',
    'messaging/prepareGroupMessage': 'Group Message',
    'websocket/sendCustomerMessage': 'Direct Message',
  };

  return map[moduleName] || '';
};

export function isObjectEmpty(obj) {
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const i in obj) { return false; }

  return true;
}
