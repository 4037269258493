import React, {
  memo, useState, useCallback, useEffect,
} from 'react';
import { BPConvSearchbox } from '../../BPConvSearchbox';

const CustomerSearchBar = ({ customers, setFilteredCustomers }) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = useCallback(
    (newValue) => {
      setSearchValue(newValue);

      const newValueLower = newValue.toLowerCase();

      setFilteredCustomers(
        customers.filter(
          (customer) => (customer.firstName || '').toLowerCase().includes(newValueLower)
            || (customer.lastName || '').toLowerCase().includes(newValueLower)
            || (customer.phone || '').toLowerCase().includes(newValueLower),
        ),
      );
    },
    [customers, setFilteredCustomers],
  );

  useEffect(() => {
    setFilteredCustomers(customers);
    setSearchValue('');
  }, [customers, setFilteredCustomers]);

  return <BPConvSearchbox value={searchValue} handleChange={handleSearch} />;
};

export default memo(CustomerSearchBar);
