import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  UPSELL_TEXT_ALL_VARS_SELECTED,
} from '../../../utils';

import ProductSelectorModal from '../../global/ProductSelectorModal';

/**
 * @typedef {import('../../../redux/reducers').RootState} RootState
 */

const ProductsSection = ({
  products, isForCreating, handleVariantRemoval, handleProductSelection, isTrigger,
}) => {
  const { merchantData } = useSelector(
    (/** @type RootState} */ state) => state.MerchantReducer,
  );
  const [productSelectorOpen, setProductSelectorOpen] = useState(false);

  const toggleProductSelectorModal = useCallback(
    () => setProductSelectorOpen(!productSelectorOpen),
    [productSelectorOpen],
  );

  const passToProductSelectionHandler = (selectionData) => {
    handleProductSelection(selectionData, isTrigger);
  };

  const variantIds = products.map(
    (selectedProduct) => (isTrigger ? selectedProduct.triggerVariantId : selectedProduct.prodToGetVariantId),
  );

  const productIds = products.filter((selectedProduct) => selectedProduct.allVarsSelected).map(
    (selectedProduct) => (isTrigger ? selectedProduct.triggerProductId : selectedProduct.prodToGetProductId),
  );

  return (
    <div className="border rounded d-flex flex-column products-container">
      {products.map((product, index) => {
        const productVariantId = isTrigger ? product.triggerVariantId : product.prodToGetVariantId;

        if (!productVariantId && !product.name) {
          return '';
        }

        let variantName = product.variantName || UPSELL_TEXT_ALL_VARS_SELECTED;
        if (product.allVarsSelected && product.variantName) {
          variantName = `${UPSELL_TEXT_ALL_VARS_SELECTED}|${product.variantName}`;
        }
        const productName = `${product.name} [${variantName}]`;

        return (
          <div key={productVariantId || index}>
            <span className="product-name">
              {productName}
            </span>
            <span
              className={`variant-remover ${isTrigger && !isForCreating ? 'disabled' : ''}`}
              data-variant-type={isTrigger ? 'triggers' : 'products'}
              data-variant={productVariantId}
              onClick={handleVariantRemoval}
              role="button"
              tabIndex={0}
            >
              {' ['}
              ✖
              {'] '}
            </span>
          </div>
        );
      })}
      <button
        className="btn btn-primary align-self-end mt-1"
        type="button"
        onClick={toggleProductSelectorModal}
        disabled={isTrigger && !isForCreating}
      >
        Add
      </button>

      {productSelectorOpen && (
        <ProductSelectorModal
          isOpen={productSelectorOpen}
          close={toggleProductSelectorModal}
          handleProductSelection={passToProductSelectionHandler}
          currency={merchantData.currencySymbol}
          preSelectedVariants={variantIds}
          preSelectedProducts={productIds}
          omitSubscriptionProducts
        />
      )}
    </div>
  );
};

export default ProductsSection;
