import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { MerchantActionCreators } from '../../redux/actions/merchant';
import BrandDetailsForm from '../../components/forms/BrandDetailsForm';
import RolesHOC from '../../components/main/RolesHOC';

import { DEFAULT_QUIET_START, DEFAULT_QUIET_END } from '../../utils/settings';

/** @typedef {import('packages/dashboard-backend/types').AwayHourInfo} AwayHourInfo */
/** @typedef {import('packages/dashboard-backend/types').MerchantData} MerchantData */

/**
 * @typedef GeneralState
 * @property {Boolean} loaded
 * @property {MerchantData?} merchantData
 * @property {AwayHourInfo?} awayHourInfo
 * @property {Boolean} submitting
 */

class General extends Component {
  constructor(props) {
    super(props);

    /** @type {GeneralState} */
    this.state = {
      loaded: false,
      merchantData: null,
      awayHourInfo: null,
      submitting: false,
    };
  }

  componentDidMount() {
    const { awayHourInfo, merchantData } = this.props;
    if (JSON.stringify(merchantData) === '{}') {
      this.loadData();
    } else {
      this.setState({ loaded: true, merchantData, awayHourInfo });
    }
  }

  loadData = () => {
    const { actions } = this.props;
    actions
      .getMerchantData()
      .then(() => {
        const { awayHourInfo, merchantData } = this.props;
        this.setState({
          awayHourInfo,
          merchantData,
          loaded: true,
          submitting: false,
        });
        return null;
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  handleSubmit = (data) => {
    const { actions, submitting, toastManager } = this.props;
    if (!submitting) {
      this.setState({ submitting: true });
      actions
        .updateMerchantData({ ...data })
        .then(() => {
          const { merchantErr } = this.props;
          if (merchantErr) {
            console.log('merchantErr', merchantErr);
            this.setState({ submitting: false });
            toastManager.add(merchantErr, {
              appearance: 'error',
              autoDismiss: true,
            });
          } else {
            this.loadData();
            toastManager.add('Settings saved successfully', {
              appearance: 'success',
              autoDismiss: true,
            });
          }
          return null;
        })
        .catch((e) => {
          this.setState({ submitting: false });
          console.log('e', e);
          toastManager.add('Something went wrong', {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    }
  };

  getInitials = () => {
    const { awayHourInfo, merchantData } = this.state;

    const data = {};

    if (awayHourInfo) {
      data.awayHoursEnabled = awayHourInfo.enabled;
      data.awayHoursContent = awayHourInfo.content;
      data.awayHours = awayHourInfo.hours;
    }

    if (merchantData) {
      data.quietHoursEnabled = merchantData.quietHoursEnabled;
      data.quietStartHourInMerchantTimezone = merchantData.quietStartHourInMerchantTimezone
      || DEFAULT_QUIET_START;
      data.quietEndHourInMerchantTimezone = merchantData.quietEndHourInMerchantTimezone || DEFAULT_QUIET_END;
      data.timezone = merchantData.timezone;
      data.supportEmailAddress = merchantData.supportEmailAddress;
      data.rateLimitEnabled = merchantData.rateLimitEnabled;
      data.rateLimitInterval = merchantData.rateLimitInterval;
      data.rateLimitCount = merchantData.rateLimitCount;
    }

    return data;
  };

  render() {
    const { loaded, submitting, merchantData } = this.state;
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="settings-card">
              <RolesHOC allowedRoles={['admin']}>
                {loaded ? (
                  <BrandDetailsForm
                    // Maybe redux-form is doing some magic? I'm not sure. DI 14/05/21
                    // @ts-ignore
                    onSubmit={this.handleSubmit}
                    loading={submitting}
                    initialValues={this.getInitials()}
                    merchantId={merchantData?.id}
                    company={merchantData?.company}
                  />
                ) : (
                  <div>Loading...</div>
                )}
              </RolesHOC>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  merchantData: state.MerchantReducer.merchantData,
  awayHourInfo: state.MerchantReducer.awayHourInfo,
  merchantErr: state.MerchantReducer.merchantErr,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(MerchantActionCreators, dispatch),
});

export default withToastManager(
  connect(mapStateToProps, mapDispatchToProps)(General),
);
