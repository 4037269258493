import React from 'react';
import Modal from 'react-modal';

const InfoModal = ({
  isOpen,
  close,
  title,
  text,
}) => {
  const setClose = () => close(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={setClose}
      className="modal small"
      overlayClassName="modal-overlay"
      contentLabel={title}
    >
      <div className="info-modal pl-3 pr-3 pt-3">
        <span className="title">
          {title}
        </span>
        <p className="text-center mt-4">
          {text}
        </p>
      </div>
    </Modal>
  );
};

export default InfoModal;
