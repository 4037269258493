import React from 'react';

export const BPTextArea = ({
  input,
  readOnly = false,
  disabled = false,
  rows = 3,
  maxLength = 400,
  meta: { error },
}) => (
  <div className="cart-message-editor">
    <textarea
      {...input}
      data-gramm_editor="false"
      className="message-content"
      rows={rows}
      readOnly={readOnly}
      disabled={disabled}
      maxLength={maxLength}
    />
    {error && <span className="field-error">{error}</span>}
  </div>
);

export default BPTextArea;
