import {
  APIMethods,
  APIServiceConstants,
  BlueprintJSONAPI,
  extractErrorMessageFromError,
} from '../BlueprintAPI';
import { APIKeysActionTypes } from '../../types';

export const APIKeysActionCreators = {
  resetMerchantAPIKeysModifyState: () => (dispatch) => {
    dispatch({
      type: APIKeysActionTypes.RESET_MERCHANT_APIKEYS_MODIFY_STATE,
    });
  },
  getMerchantAPIKeys: () => async (dispatch) => {
    dispatch({
      type: APIKeysActionTypes.GET_MERCHANT_APIKEYS_REQ,
    });

    try {
      const response = await BlueprintJSONAPI(
        APIMethods.GET,
        APIServiceConstants.INTEGRATIONS,
        'merchants/apiKeys',
      );

      dispatch({
        type: APIKeysActionTypes.GET_MERCHANT_APIKEYS_RES,
        payload: {
          apiKeys: response?.data?.apiKeys || [],
        },
      });
    } catch (error) {
      console.error('Error - getMerchantAPIKeys:', error);

      dispatch({
        type: APIKeysActionTypes.GET_MERCHANT_APIKEYS_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },
  insertMerchantAPIKey: () => async (dispatch) => {
    dispatch({
      type: APIKeysActionTypes.INSERT_MERCHANT_APIKEYS_REQ,
    });

    try {
      await BlueprintJSONAPI(
        APIMethods.POST,
        APIServiceConstants.INTEGRATIONS,
        'merchants/apiKeys',
      );

      dispatch({
        type: APIKeysActionTypes.INSERT_MERCHANT_APIKEYS_RES,
        payload: {},
      });
    } catch (error) {
      console.error('Error - insertMerchantAPIKey:', error);

      dispatch({
        type: APIKeysActionTypes.INSERT_MERCHANT_APIKEYS_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },
  updateMerchantAPIKey: (key, description) => async (dispatch) => {
    dispatch({
      type: APIKeysActionTypes.UPDATE_MERCHANT_APIKEYS_REQ,
    });

    const data = {
      key,
      description,
    };

    try {
      await BlueprintJSONAPI(
        APIMethods.PUT,
        APIServiceConstants.INTEGRATIONS,
        'merchants/apiKeys',
        data,
      );

      dispatch({
        type: APIKeysActionTypes.UPDATE_MERCHANT_APIKEYS_RES,
        payload: {},
      });
    } catch (error) {
      console.error('Error - updateMerchantAPIKey:', error);

      dispatch({
        type: APIKeysActionTypes.UPDATE_MERCHANT_APIKEYS_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },
  deleteMerchantAPIKey: (key) => async (dispatch) => {
    dispatch({
      type: APIKeysActionTypes.DELETE_MERCHANT_APIKEYS_REQ,
    });

    const data = {
      key,
    };

    try {
      await BlueprintJSONAPI(
        APIMethods.DELETE,
        APIServiceConstants.INTEGRATIONS,
        'merchants/apiKeys',
        data,
      );

      dispatch({
        type: APIKeysActionTypes.DELETE_MERCHANT_APIKEYS_RES,
        payload: {},
      });
    } catch (error) {
      console.error('Error - deleteMerchantAPIKey:', error);

      dispatch({
        type: APIKeysActionTypes.DELETE_MERCHANT_APIKEYS_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },
};

export default APIKeysActionCreators;
