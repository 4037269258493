import React, { memo } from 'react';
import { Contact } from '../Contact';

const AllContacts = ({
  activeCustomerPhoneId,
  handleClick,
  loaded,
  searchContacts: { support, normal },
}) => (!loaded ? (
  <div className="loading">Loading...</div>
) : (
  <div className="all-contacts">
    <div>
      {[...support, ...normal].map((contact) => (
        <Contact
          // key={contact.customerPhoneId} -- do NOT provide key as it breaks scrolling behavour and jumps the list
          {...contact}
          active={contact.customerPhoneId === activeCustomerPhoneId}
          handleClick={handleClick}
        />
      ))}
    </div>
  </div>
));

export default memo(AllContacts);
