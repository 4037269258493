import React from 'react';
import Modal from 'react-modal';

import { wordings } from '../../../utils/crossSells';

const CrossSellDeletionModal = ({
  isOpen,
  close,
  upsellName,
  upsellDeleting,
  handleDeleteSubmit,
  type,
}) => {
  const setClose = () => close(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={setClose}
      className="modal small"
      overlayClassName="modal-overlay"
      contentLabel="Delete Upsell"
    >
      <div className="upsell-modal">
        <span className="title">
          Delete
          {' '}
          {wordings[type].genericTerm}
        </span>
        <p>
          Are you sure you want to delete the &quot;
          {upsellName}
          &quot;
          {' '}
          {wordings[type].genericTerm}
          ?
        </p>
        <button
          className="btn btn-red delete-upsell-btn"
          onClick={handleDeleteSubmit}
          disabled={upsellDeleting}
          type="button"
        >
          {upsellDeleting ? 'Deleting...' : 'Delete'}
        </button>
        <button className="btn btn-link" onClick={setClose} type="button">cancel</button>
      </div>
    </Modal>
  );
};

export default CrossSellDeletionModal;
