import { API, APIMethods, APIServiceConstants } from './BlueprintAPI';

export const unsubscribeCustomer = async (customerPhoneId) => {
  await API(
    APIMethods.POST,
    APIServiceConstants.SERVICE,
    `customer/${customerPhoneId}/unsubscribe`,
  );
};

export const addNode = async (customerPhoneId, note) => {
  const response = await API(
    APIMethods.POST,
    APIServiceConstants.SERVICE,
    `customer/${customerPhoneId}/note`,
    {
      note,
    },
  );

  return response?.data?.note || {};
};
