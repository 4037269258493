import React, { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import ProductSelectorModal from '../../../../global/ProductSelectorModal';

/** @typedef {import('../../../../../redux/reducers').RootState} RootState */
/** @typedef {import('../../../../global/ProductSelector').ProductSelectorProduct} ProductSelectorProduct */
/** @typedef {import('../../../../global/ProductSelector').ProductSelectorVariant} ProductSelectorVariant */

/**
 * @typedef {Object} BasketValue
 * @property {String} id
 * @property {Boolean} isVariant
 * @property {Number} internalId
 * @property {String} name
 */

/**
 *
 * @param {Object} props
 * @param {BasketValue[]} props.value
 * @param {Function} props.onChange
 * @returns
 */
const ProductPicker = ({ value, onChange }) => {
  const { merchantData } = useSelector(
    (/** @type RootState} */ state) => state.MerchantReducer,
  );

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  const [productSelectorOpen, setProductSelectorOpen] = useState(false);

  const preSelectedProducts = value.filter(
    (productSelection) => !productSelection.isVariant,
  ).map((productSelection) => productSelection.internalId);

  const preSelectedVariants = value.filter(
    (productSelection) => productSelection.isVariant,
  ).map((productSelection) => productSelection.internalId);

  const selectedProductNames = value.map((productSelection) => <p>{productSelection.name}</p>);

  const toggleProductSelectorModal = useCallback(
    () => setProductSelectorOpen(!productSelectorOpen),
    [productSelectorOpen],
  );

  /**
   *
   * @param {Object} options
   * @param {ProductSelectorProduct[]} options.products
   */
  const handleProductSelection = ({ products }) => {
    const productSelection = products.flatMap((product) => {
      if (product.allVarsSelected) {
        /** @type {BasketValue} */
        const basketValue = {
          id: product.externalId,
          isVariant: false,
          internalId: product.productId,
          name: product.productName,
        };

        return [basketValue];
      }

      return product.variants.map((variant) => {
        /** @type {BasketValue} */
        const basketValue = {
          id: variant.externalId,
          isVariant: true,
          internalId: variant.variantId,
          name: `${product.productName} [${variant.variantName}]`,
        };

        return basketValue;
      });
    });

    onChange('value', productSelection);
  };

  const buttonTitle = value.length > 0 ? `${value.length} product(s) selected` : 'Choose products';

  return (
    <div className="col-md-3">
      <button
        className="btn btn-primary btn-block"
        type="button"
        onClick={toggleProductSelectorModal}
        ref={setTriggerRef}
      >
        {buttonTitle}
      </button>
      {visible && value.length > 0 && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {selectedProductNames}
        </div>
      )}
      {productSelectorOpen && (
        <ProductSelectorModal
          isOpen={productSelectorOpen}
          close={toggleProductSelectorModal}
          handleProductSelection={handleProductSelection}
          currency={merchantData.currencySymbol}
          preSelectedVariants={preSelectedVariants}
          preSelectedProducts={preSelectedProducts}
          omitSubscriptionProducts
        />
      )}
    </div>
  );
};

export default memo(ProductPicker);
