import { MerchantActionTypes } from '../../types';

export const initialState = {
  loading: false,
  userErr: null,
  userLoaded: false,
  userData: {},
  merchantErr: null,
  merchantLoading: false,
  merchantLoaded: false,
  merchantData: {},
  awayHourInfo: {},
  merchantAnalytics: {},
  updateAutomatedMessagesLoading: false,
  abandonedCartsMessages: [],
  automatedMessages: [],
  uploadLogoErr: null,
  validateWooErr: null,
  customerSubscriptionLoading: false,
  customerSubscriptionError: null,
  customerSubscriptionSuccess: false,
  stripePlan: {},
  stripeProduct: {},
  customerSubscriptionUsageLoading: false,
  customerSubscriptionUsageLoaded: false,
  customerSubscriptionUsageError: null,
  customerSubscriptionUsage: null,
  totalSubscribers: null,
  subscriptionAnalyticsLoading: false,
  subscriptionAnalyticsLoaded: false,
  subscriptionAnalyticsError: null,
  customerCountBySubsType: {},
  revenueBySubsType: {},
  chatbotSettings: {},
};

export const MerchantReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case MerchantActionTypes.GET_UDATA_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case MerchantActionTypes.GET_UDATA_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          userErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        userLoaded: true,
        userData: payload,
      };
    }
    case MerchantActionTypes.GET_MDATA_REQ: {
      return {
        ...state,
        merchantLoading: true,
      };
    }
    case MerchantActionTypes.GET_MDATA_RES: {
      if (error) {
        return {
          ...state,
          merchantLoading: false,
          merchantErr: error,
        };
      }

      return {
        ...state,
        merchantErr: null,
        merchantLoading: false,
        merchantLoaded: true,
        merchantData: payload.merchantData,
        awayHourInfo: {
          ...payload.awayHourInfo,
          hours: payload.awayHourInfo.hours.map((el) => ({
            ...el,
            startsAt: el.startsAt ? el.startsAt.substring(0, 5) : el.startsAt, // convert to HH:MM from HH:MM:SS
            endsAt: el.endsAt ? el.endsAt.substring(0, 5) : el.endsAt,
          })),
        },
        abandonedCartsMessages: payload.abandonedCartsMessages,
        automatedMessages: payload.automatedMessages,
        totalSubscribers: payload.totalSubscribers,
        chatbotSettings: payload.chatbotSettings || {},
      };
    }
    case MerchantActionTypes.UPDATE_MDATA_REQ: {
      return {
        ...state,
        merchantLoading: true,
      };
    }
    case MerchantActionTypes.UPDATE_MDATA_RES: {
      if (error) {
        return {
          ...state,
          merchantLoading: false,
          merchantErr: error,
        };
      }
      return {
        ...state,
        merchantLoading: false,
        merchantErr: null,
      };
    }
    case MerchantActionTypes.UPDATE_ABCRTMES_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case MerchantActionTypes.UPDATE_ABCRTMES_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          merchantErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        merchantErr: null,
      };
    }
    case MerchantActionTypes.UPDATE_AUTOMES_REQ: {
      return {
        ...state,
        updateAutomatedMessagesLoading: true,
      };
    }
    case MerchantActionTypes.UPDATE_AUTOMES_RES: {
      if (error) {
        return {
          ...state,
          updateAutomatedMessagesLoading: false,
          merchantErr: error,
        };
      }
      return {
        ...state,
        updateAutomatedMessagesLoading: false,
        merchantErr: null,
      };
    }
    case MerchantActionTypes.UPLOAD_LOGO_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case MerchantActionTypes.UPLOAD_LOGO_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          uploadLogoErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        uploadLogoErr: null,
      };
    }
    case MerchantActionTypes.VAL_WOO_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case MerchantActionTypes.VAL_WOO_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          validateWooErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        validateWooErr: null,
      };
    }
    case MerchantActionTypes.TOGGLE_PO_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case MerchantActionTypes.TOGGLE_PO_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          updatePOErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        updatePOErr: null,
      };
    }
    case MerchantActionTypes.TOGGLE_CHECKOUT_REQ: {
      return {
        ...state,
        loading: true,
      };
    }
    case MerchantActionTypes.TOGGLE_CHECKOUT_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          updatePOErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        updatePOErr: null,
      };
    }
    case MerchantActionTypes.CREATE_SUB_REQ: {
      return {
        ...state,
        customerSubscriptionLoading: true,
      };
    }
    case MerchantActionTypes.CREATE_SUB_RES: {
      if (error) {
        return {
          ...state,
          customerSubscriptionLoading: false,
          customerSubscriptionError: error,
        };
      }
      return {
        ...state,
        customerSubscriptionError: null,
        customerSubscriptionLoading: false,
        customerSubscriptionSuccess: true,
      };
    }
    case MerchantActionTypes.GET_PLAN_RES: {
      if (error) {
        return {
          ...state,
          stripePlan: {},
          stripeProduct: {},
        };
      }
      return {
        ...state,
        stripePlan: payload.plan,
        stripeProduct: payload.product,
      };
    }
    case MerchantActionTypes.GET_SUB_REQ: {
      return {
        ...state,
        customerSubscriptionUsageLoading: true,
      };
    }
    case MerchantActionTypes.GET_SUB_RES: {
      if (error) {
        return {
          ...state,
          customerSubscriptionUsageLoading: false,
          customerSubscriptionUsageLoaded: true,
          customerSubscriptionUsageError: error,
        };
      }
      return {
        ...state,
        customerSubscriptionUsageError: null,
        customerSubscriptionUsageLoading: false,
        customerSubscriptionUsageLoaded: true,
        customerSubscriptionUsage: payload.usage || false,
      };
    }
    case MerchantActionTypes.GET_SUBS_ANALYTICS_REQ: {
      return {
        ...state,
        subscriptionAnalyticsLoading: true,
      };
    }
    case MerchantActionTypes.GET_SUBS_ANALYTICS_RES: {
      if (error) {
        return {
          ...state,
          subscriptionAnalyticsLoading: false,
          subscriptionAnalyticsLoaded: true,
          customerSubscriptionUsageError: error,
        };
      }
      return {
        ...state,
        subscriptionAnalyticsError: null,
        subscriptionAnalyticsLoading: false,
        subscriptionAnalyticsLoaded: true,
        customerCountBySubsType: payload.customerCountBySubsType,
        revenueBySubsType: payload.revenueBySubsType,
      };
    }
    case 'LOGOUT': {
      return initialState;
    }
    default:
      return state;
  }
};
