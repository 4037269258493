import React from 'react';
import Modal from 'react-modal';
import ProductSelector from './ProductSelector';

const ProductSelectorModal = ({
  isOpen,
  close,
  handleProductSelection,
  currency,
  preSelectedVariants = [],
  preSelectedProducts = [],
  omitSubscriptionProducts = false,
}) => {
  const setClose = () => close(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={setClose}
      className="modal products"
      overlayClassName="modal-overlay"
      contentLabel="Select Products"
      scrollable
    >
      <div className="product-selection-modal">
        <button className="close" onClick={setClose} type="button">
          Close
        </button>
        <span className="title text-left mb-3 ml-3">
          Select products
        </span>
        <ProductSelector
          handleProductSelection={handleProductSelection}
          currency={currency}
          handleClose={setClose}
          preSelectedVariants={preSelectedVariants}
          preSelectedProducts={preSelectedProducts}
          omitSubscriptionProducts={omitSubscriptionProducts}
        />
      </div>
    </Modal>
  );
};

export default ProductSelectorModal;
