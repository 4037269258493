import {
  APIServiceConstants,
  BlueprintAPI,
} from '../../redux/actions/BlueprintAPI';

const { SERVICE } = APIServiceConstants;

const createCustomDomain = async (postData) => {
  try {
    await BlueprintAPI(SERVICE, 'customDomains/certificate', {
      domain: postData.customDomain,
    }, null, false);
  } catch (err) {
    throw new Error('Failed to create Custom Domain. Please contact Support.');
  }
};

export default createCustomDomain;
