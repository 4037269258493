import React, { memo, useMemo } from 'react';
import { GROUP_MESSAGE_STATUS } from '../../../constants';

function ScheduledGroupMessageDeliveryStatus({ message }) {
  const {
    totalMessageCount,
    sqsProgressPercentage,
    totalMessagesSentToSQS,
    status,
    sentCount,
    skippedCount,
  } = message;

  const statusString = useMemo(() => {
    if (status === GROUP_MESSAGE_STATUS.COMPLETED) {
      return (
        <>
          <span className="sent">SENT</span>
          {' '}
          <span>
            to
            {' '}
            { sentCount }
            {' '}
            contacts
          </span>
          {sentCount !== totalMessageCount && (
            <>
              {' '}
              <span>
                (
                {totalMessageCount - skippedCount - sentCount}
                {' '}
                failed
                {skippedCount > 0 && (
                <>
                  ,
                  {skippedCount}
                  {' '}
                  skipped
                </>
                )}
                )
              </span>
            </>
          )}
        </>
      );
    }

    if (status === GROUP_MESSAGE_STATUS.ERRORED) {
      return (
        <>
          <span className="failed">FAILED</span>
          {' '}
          <span>
            sent to
            {' '}
            {sentCount}
            {' '}
            /
            {' '}
            {totalMessageCount}
            {' '}
            contacts
          </span>
        </>
      );
    }

    if (status === GROUP_MESSAGE_STATUS.PENDING) {
      return (
        <>
          <span className="pending">SCHEDULED</span>
          {' '}
          <span>
            to send to
            {' '}
            {totalMessageCount}
            {' '}
            contacts
          </span>
        </>
      );
    }

    if (status === GROUP_MESSAGE_STATUS.CANCELLED) {
      return (
        <>
          <span className="cancelled">CANCELLED</span>
        </>
      );
    }

    return (
      <>
        <span className="sending">SENDING...</span>
        {' '}
        <span>
          {sqsProgressPercentage}
          % complete (
          {totalMessagesSentToSQS}
          /
          {totalMessageCount}
          {' '}
          contacts
          )
        </span>
      </>
    );
  }, [
    status,
    totalMessagesSentToSQS,
    totalMessageCount,
    sqsProgressPercentage,
    sentCount,
    skippedCount,
  ]);

  return <div className="group-message-delivery-status">{statusString}</div>;
}

export default memo(ScheduledGroupMessageDeliveryStatus);
