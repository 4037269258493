import React, { memo, useCallback, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import Modal from 'react-modal';

/** @typedef {import('../../../../../redux/reducers').RootState} RootState */

/**
 * @typedef List
 * @property {String} id
 * @property {String} name
 */

/**
 * @param {Object} props
 * @param {Function} props.onClose
 * @param {Function} props.onChange
 * @param {String[]} props.value
 * @param {List[]} props.lists
 */
const ListSelector = ({
  onClose, onChange, lists, value = [],
}) => {
  const [selectedLists, setSelectedLists] = useState(value);

  const selectList = useCallback((listId) => {
    setSelectedLists((currentSelectedListIds) => [...currentSelectedListIds, listId]);
  }, []);

  const deselectList = useCallback((listId) => {
    setSelectedLists((currentSelectedListIds) => currentSelectedListIds.filter(
      (selectedListId) => selectedListId !== listId,
    ));
  }, []);

  const handleSave = useCallback(() => {
    onChange(lists.filter((list) => selectedLists.includes(list.id)));
    onClose();
  }, [onChange, lists, onClose, selectedLists]);

  return (
    <>
      <div className="product-list-container mb-3">
        <div className="list-group">
          {lists.map((list) => {
            const checked = selectedLists.some((listId) => listId === list.id);
            return (
              <div key={list.id}>
                <label className="list-group-item list-group-item-action" htmlFor={`list-item-${list.id}`}>
                  <div className="d-flex w-100 justify-content-between">
                    <div className="d-flex justify-content-start align-items-center">
                      <input
                        id={`list-item-${list.id}`}
                        type="checkbox"
                        className="check-box mr-3"
                        checked={checked}
                        onChange={() => {
                          if (checked) {
                            deselectList(list.id);
                          } else {
                            selectList(list.id);
                          }
                        }}
                      />
                      {list.name}
                    </div>
                  </div>
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <div className="row">
        <div className="col float-right mr-3">
          <button className="btn btn-primary btn-sm float-right ml-2" onClick={handleSave} type="button">
            Select
          </button>
          <button className="btn btn-outline-secondary btn-sm float-right" onClick={() => { onClose(); }} type="button">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

const ListSelectorModal = ({
  label = 'list',
  isOpen,
  onClose,
  onChange,
  value,
  lists,
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    className="modal products"
    overlayClassName="modal-overlay"
    contentLabel={`Select ${label}(s)"`}
    scrollable
  >
    <div className="product-selection-modal">
      <button className="close" onClick={onClose} type="button">
        Close
      </button>
      <span className="title text-left mb-3 ml-3">
        Select
        {' '}
        {label}
        (s)
      </span>
      <ListSelector
        onClose={onClose}
        onChange={onChange}
        value={value}
        lists={lists}
      />
    </div>
  </Modal>
);

/**
 * @param {Object} props
 * @param {String} props.name
 * @param {*} props.value
 * @param {Function} props.onChange
 * @param {List[]} props.options
 * @param {String} props.label
 * @returns
 */
const SimpleListPicker = ({
  label = 'list', name = 'option', value, onChange, options,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  const [listSelectorOpen, setListSelectorOpen] = useState(false);

  const toggleListSelectorModal = useCallback(
    () => setListSelectorOpen(!listSelectorOpen),
    [listSelectorOpen],
  );

  const handleListSelection = useCallback((selectedLists) => {
    onChange('value', selectedLists);
  }, [onChange]);

  const buttonTitle = value.length > 0 ? `${value.length} ${name}(s) selected` : `Choose ${name}(s)`;
  const selectedListNames = value.map((list) => <p key={list.id}>{list.name}</p>);

  return (
    <div className="col-md-3">
      <button
        className="btn btn-primary btn-block"
        type="button"
        onClick={toggleListSelectorModal}
        ref={setTriggerRef}
      >
        {buttonTitle}
      </button>
      {visible && value.length > 0 && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {selectedListNames}
        </div>
      )}
      {listSelectorOpen && (
        <ListSelectorModal
          label={label}
          isOpen={listSelectorOpen}
          onClose={toggleListSelectorModal}
          onChange={handleListSelection}
          value={value.map((list) => list.id)}
          lists={options}
        />
      )}
    </div>
  );
};

export default memo(SimpleListPicker);
