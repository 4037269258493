import React from 'react';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import LogRocketAnalytics from './components/global/LogRocketAnalytics';
import Routing from './components/Routing';
import './assets/sass/index.scss';
import 'react-popper-tooltip/dist/styles.css';
import store from './redux';

Modal.setAppElement('#root');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = (location) => {
  window.previousLocation = location;

  return (
    <Provider store={store}>
      <LogRocketAnalytics />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
