import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

const IntegrationSmartrrForm = ({
  onSubmit,
  isSubmitting,
  isTesting,
  isDeleting,
  isDeleted,
  smartrrIntegrationData,
}) => {
  const accessToken = smartrrIntegrationData?.authData?.accessToken;

  const {
    register, handleSubmit, reset, setValue, formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      accessToken,
      publicId: smartrrIntegrationData?.publicId,
      isTesting: false,
      isDeleting: false,
    },
  });

  useEffect(() => {
    if (smartrrIntegrationData?.publicId || isDeleted) {
      reset({
        accessToken,
        publicId: smartrrIntegrationData?.publicId,
      });
    }
  }, [smartrrIntegrationData?.publicId, accessToken, isDeleted, reset]);

  const handleFormSubmit = (formData) => onSubmit(formData);

  // Just use the word "update" when the integration is already active
  // Use "activate" when no integration is found
  // eslint-disable-next-line no-nested-ternary
  const submitBtnLabel = smartrrIntegrationData?.active
    ? (isSubmitting ? 'Updating...' : 'Update')
    : (isSubmitting ? 'Activating' : 'Activate');

  const eventInProgress = isSubmitting || isTesting || isDeleting;

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="form-group">
        <label htmlFor="accessToken">Smartrr Access Token</label>
        <div className="field">
          <input
            id="accessToken"
            type="text"
            className="form-control"
            placeholder="Smartrr Access Token"
            {...register('accessToken', {
              required: true,
              minLength: {
                value: 10,
                message: 'The access token is too short',
              },
            })}
          />
        </div>
        <span>
          {errors.accessToken?.message}
        </span>
      </div>
      { smartrrIntegrationData?.publicId && (
      <div className="form-group">
        <label htmlFor="publicId">Public id</label>
        <div className="field">
          <input
            id="publicId"
            type="text"
            className="form-control"
            readOnly
            {...register('publicId')}
          />
        </div>
      </div>
      ) }
      <button
        type="submit"
        className="btn btn-primary mr-2"
        disabled={eventInProgress}
        onClick={() => {
          setValue('isTesting', false);
          setValue('isDeleting', false);
        }}
      >
        {submitBtnLabel}
      </button>
      <button
        type="submit"
        className="btn btn-primary mr-2"
        disabled={eventInProgress}
        onClick={() => {
          setValue('isTesting', true);
          setValue('isDeleting', false);
        }}
      >
        {isTesting ? 'Testing...' : 'Test connection'}
      </button>
      <button
        type="submit"
        className="btn btn-danger mr-2"
        disabled={eventInProgress || !smartrrIntegrationData?.active}
        onClick={() => {
          setValue('isDeleting', true);
          setValue('isTesting', false);
        }}
      >
        {isDeleting ? 'Deleting...' : 'Delete integration'}
      </button>
    </form>
  );
};

export default IntegrationSmartrrForm;
