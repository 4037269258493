import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';

import ScheduleList from '../../components/main/GroupMessaging/ScheduleList';
import {
  getScheduledCampaigns,
  cancelScheduledCampaign,
} from '../../redux/actions/GroupMessagingAPI';

const ScheduledList = () => {
  const { addToast } = useToasts();
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchScheduledCampaigns = useCallback(async () => {
    try {
      setLoading(true);
      const scheduledCampaigns = await getScheduledCampaigns();
      setCampaigns(scheduledCampaigns);
      setLoading(false);
    } catch (e) {
      addToast('The list of scheduled campaigns could not be retrieved.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast]);

  useEffect(() => {
    fetchScheduledCampaigns();
  }, [fetchScheduledCampaigns]);

  const handleCancelCampaign = useCallback(async (id) => {
    try {
      setLoading(true);
      await cancelScheduledCampaign(id);
      addToast('The scheduled campaign was successfully cancelled.', {
        appearance: 'success',
        autoDismiss: true,
      });
      await fetchScheduledCampaigns();
    } catch (e) {
      addToast('The Scheduled Campaign could not be cancelled', {
        appearance: 'error',
        autoDismiss: true,
      });
      setLoading(false);
    }
  }, [addToast, fetchScheduledCampaigns]);

  return (
    <PageBuilder>
      <SEO title="Scheduled Messages | Blueprint" />
      <div className="a-view group-segment">
        <div className="container">
          <div className="top-bar">
            <h2 className="title">
              <Link to="/group-messaging">Group Messaging</Link>
              {' '}
              {'>'}
              {' '}
              Scheduled Messages
            </h2>
          </div>

          {campaigns && (
            <ScheduleList
              loading={loading}
              campaigns={campaigns}
              cancelCampaign={handleCancelCampaign}
            />
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(ScheduledList);
