import React from 'react';

export const BPField = ({
  input,
  readOnly = false,
  disabled = false,
  placeholder,
  className,
  groupContent,
  type,
  overrideErr = false,
  meta: { touched, error },
}) => (
  <div className={groupContent && 'input-group'}>
    <input
      {...input}
      readOnly={readOnly}
      disabled={disabled}
      placeholder={placeholder}
      type={type}
      className={
        className + ((overrideErr || touched) && error ? ' error' : '')
      }
      autoComplete="something-unsupported"
      autofill="off"
    />
    {groupContent}
    {touched && error && <span className="field-error">{error}</span>}
  </div>
);

export default BPField;
