import React, { memo, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import IntervalSelect, { getSecondsForUnitValue } from '../IntervalSelect';
import {
  createNode, addNode, removeNode,
} from '../../../utils/Flows';
import MessageTemplateEditor from '../MessageTemplateEditor';
import { MSG_TYPE_SMS, MSG_TYPE_MMS } from '../../../utils/messageType';

/**
 * @typedef {import('../../../utils/Flows').FlowNode} FlowNode
 */

/** @typedef {import('../../../redux/reducers').RootState} RootState */

const MAX_NUMBER_OF_MESSAGES = 10;

const NodeDelay = ({
  idx, delay, unit, onChange,
}) => {
  const onChangeDelay = (newDelay) => {
    onChange(idx, newDelay, unit);
  };

  const onChangeUnit = (newUnit, currentValue) => {
    const newDelay = getSecondsForUnitValue(newUnit, currentValue);

    onChange(idx, newDelay, newUnit.value);
  };

  const HeadingText = () => {
    let title = 'Then wait...';

    if (idx === 0) {
      title = 'Send first message after...';
    }

    return (
      <label>{title}</label>
    );
  };

  return (
    <>
      <HeadingText />
      <IntervalSelect onChangeValue={onChangeDelay} onChangeUnit={onChangeUnit} unit={unit} value={delay} />
    </>
  );
};

/**
 *
 * @param {Object} props
 * @param {FlowNode} props.node
 * @param {Number} props.idx
 * @param {Function} props.updateContent
 * @param {Function} props.updateMediaUrl
 * @param {Function} props.removeExistingNode
 * @param {Function} props.updateDelay
 * @returns
 */
const Node = ({
  node, idx, updateContent, removeExistingNode, updateDelay, updateMediaUrl,
}) => {
  const [messageType, setMessageType] = useState(MSG_TYPE_SMS);
  const setMessageTemplate = ({ id, messageContent }) => {
    updateContent(id, messageContent);
  };

  const merchantData = useSelector(
    (/** @type {RootState} */state) => state.MerchantReducer.merchantData, shallowEqual,
  ) || {};

  const onMessageTypeChange = (entry = {}) => {
    setMessageType(entry.msgType);
    updateMediaUrl(idx, entry.mediaURL);
  };

  useEffect(() => {
    const newMessageType = node.mediaUrl ? MSG_TYPE_MMS : MSG_TYPE_SMS;
    setMessageType(newMessageType);
  }, [node]);

  return (
    <div className="row">
      <div className="col-md-12">
        <NodeDelay
          idx={idx}
          delay={node.entryDelay}
          unit={node.entryDelayUnit}
          onChange={updateDelay}
        />
        <div className="row">
          <div className="col-md-12 mt-4">
            <label>
              <button
                className="trash mr-2"
                type="button"
                title="Remove Message"
                onClick={() => {
                  removeExistingNode(node);
                }}
              >
                <i className="bi bi-trash" />
              </button>
              Message
              {' '}
              {idx + 1}
              {' '}
              Content
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <MessageTemplateEditor
              messageType={messageType}
              id={idx}
              messageInterval={0}
              canSetActive={false}
              canSetInterval={false}
              setMessageTemplate={setMessageTemplate}
              active
              messageContent={node.messageContent}
              isToggleAvailable={false}
              allowImageUpload
              onMessageTypeChange={onMessageTypeChange}
              merchant={merchantData}
              mediaUrl={node.mediaUrl}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param {Object} props
 * @param {FlowNode[]} props.nodes
 * @param {Function} props.updateNodes
 */
const NodesEditor = ({ nodes, updateNodes }) => {
  const updateNodeMessageContent = (idx, newContent) => {
    const node = { ...nodes[idx] };

    node.messageContent = newContent;

    const updatedNodes = nodes.map((el) => (el.id === node.id ? node : el));

    updateNodes(updatedNodes);
  };

  const updateMediaUrl = (idx, mediaUrl) => {
    const node = { ...nodes[idx] };

    node.mediaUrl = mediaUrl;

    const updatedNodes = nodes.map((el) => (el.id === node.id ? node : el));

    updateNodes(updatedNodes);
  };

  const updateNodeDelay = (idx, newDelay, newUnit) => {
    const node = { ...nodes[idx] };

    node.entryDelay = newDelay;
    node.entryDelayUnit = newUnit;

    const updatedNodes = nodes.map((el) => (el.id === node.id ? node : el));

    updateNodes(updatedNodes);
  };

  const addNewNode = () => {
    updateNodes(addNode(nodes, createNode(3600, '')));
  };

  const removeExistingNode = (node) => {
    updateNodes(removeNode(nodes, node));
  };

  return (
    <div className="form-group">
      {
        nodes.map(
          (node, idx) => (
            <Node
              node={node}
              idx={idx}
              key={node.id}
              updateContent={updateNodeMessageContent}
              updateMediaUrl={updateMediaUrl}
              removeExistingNode={removeExistingNode}
              updateDelay={updateNodeDelay}
            />
          ),
        )
      }
      <button className="btn btn-quad btn-sm btn-block" type="button" tabIndex={0} onClick={addNewNode} disabled={nodes.length >= MAX_NUMBER_OF_MESSAGES}>+ Add Message</button>
    </div>
  );
};

export default memo(NodesEditor);
