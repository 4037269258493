import React from 'react';

export const DashInfoCard = (props) => {
  const {
    title,
    value,
    info,
    extraInfo,
    clickThroughHref = '',
    clickThroughText = '',
    change,
  } = props;
  return (
    <div className="dash-info-card">
      <div>
        <span className="info-title">{title}</span>
        <h2 className="info-value">{value}</h2>
        <p className="info">{info}</p>
        <p className="extra-info">
          {extraInfo}
          {clickThroughHref && (
            <>
              {' '}
              <a href={clickThroughHref} className="extra-link">
                {clickThroughText || clickThroughHref}
              </a>
            </>
          )}
        </p>
      </div>
      <span className="change ">{change}</span>
    </div>
  );
};

export default DashInfoCard;
