import React from 'react';

export default ({ isDragActive }) => {
  if (isDragActive) {
    return <p>Drop the files here ...</p>;
  }
  return (
    <div>
      <button className="btn btn-upload btn-secondary" type="button">Upload</button>
      <p>Drag and drop some files here, or click to select files</p>
      <p>We accept the following image types: jpeg, png, gif.</p>
    </div>
  );
};
