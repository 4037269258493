import React from 'react';

export default ({ url, altText, onCancel }) => (
  <div className="image-preview">
    <img
      height="60px"
      alt={`An image attached with the message: ${altText}`}
      src={url}
    />
    <span className="close-btn" onClick={onCancel} role="button" tabIndex={0}>
      ✖
    </span>
  </div>
);
