import React, { useState } from 'react';
import CustomerSearchBar from '../CustomerSearchBar';
import SubscriberList from '../SubscriberList';

export default ({
  customers,
  subscribers = [],
  toggleSubscribers,
  handleDelete,
  showDateAdded,
  action,
  actionTitle,
}) => {
  const [filteredCustomers, setFilteredCustomers] = useState(customers);

  return (
    <div>
      <CustomerSearchBar
        customers={customers}
        setFilteredCustomers={setFilteredCustomers}
      />
      <SubscriberList
        customers={filteredCustomers}
        subscribers={subscribers}
        toggleSubscribers={toggleSubscribers}
        handleDelete={handleDelete}
        showDateAdded={showDateAdded}
        action={action}
        actionTitle={actionTitle}
      />
    </div>
  );
};
