import React, { memo, useCallback } from 'react';

const VALUE_MODE = {
  stringContains: 'stringContains',
  stringEquals: 'stringEquals',
};

const VALUE_ATTRIBUTES = {
  value: 'value',
};

const Discount = ({ value, onChange }) => {
  const handleOnChange = useCallback(
    (event) => {
      onChange({
        ...value,
        mode: event.target.value,
      });
    },
    [onChange, value],
  );

  const updateProperty = useCallback(
    (event) => {
      onChange({
        mode: value.mode,
        [event.target.name]: event.target.value,
      });
    },
    [onChange, value.mode],
  );

  return (
    <div className="subquery">
      <select
        className="form-control"
        value={value.mode}
        onChange={handleOnChange}
      >
        <option value={VALUE_MODE.stringContains}>Contains text</option>
        <option value={VALUE_MODE.stringEquals}>Text is exactly</option>
      </select>

      <input
        className="form-control"
        placeholder="Text"
        value={value[VALUE_ATTRIBUTES.value]}
        name={VALUE_ATTRIBUTES.value}
        onChange={updateProperty}
      />
    </div>
  );
};

export default memo(Discount);
