import {
  APIMethods,
  APIServiceConstants,
  BlueprintJSONAPI,
  extractErrorMessageFromError,
} from '../BlueprintAPI';
import { IntegrationsActionTypes } from '../../types';

const { INTEGRATIONS } = APIServiceConstants;

export const IntegrationActionCreators = {
  getMerchantIntegrations: () => async (dispatch) => {
    dispatch({
      type: IntegrationsActionTypes.GET_MERCHANT_INTEGRATIONS_REQ,
    });

    try {
      const response = await BlueprintJSONAPI(
        APIMethods.GET,
        APIServiceConstants.INTEGRATIONS,
        'merchants/integrations',
        {},
      );

      dispatch({
        type: IntegrationsActionTypes.GET_MERCHANT_INTEGRATIONS_RES,
        payload: {
          integrations: response?.data?.integrations || [],
        },
      });
    } catch (error) {
      console.error('Error - getMerchantIntegrations:', error);

      dispatch({
        type: IntegrationsActionTypes.GET_MERCHANT_INTEGRATIONS_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },

  resetKlaviyoIntegrationState: () => async (dispatch) => dispatch({
    type: IntegrationsActionTypes.KLAVIYO_INTEGRATION_RESET,
  }),

  upsertKlaviyoIntegration: (data) => async (dispatch) => {
    dispatch({
      type: IntegrationsActionTypes.UPSERT_KLAVIYO_INTEGRATION_REQ,
    });

    try {
      await BlueprintJSONAPI(
        APIMethods.POST,
        INTEGRATIONS,
        'klaviyo/auth-data',
        data,
      );

      dispatch({
        type: IntegrationsActionTypes.UPSERT_KLAVIYO_INTEGRATION_RES,
        payload: {},
      });
    } catch (error) {
      console.error('Error - upsertKlaviyoIntegration:', error);

      dispatch({
        type: IntegrationsActionTypes.UPSERT_KLAVIYO_INTEGRATION_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },

  testKlaviyoIntegration: (publicKey, privateKey) => async (dispatch) => {
    dispatch({
      type: IntegrationsActionTypes.TEST_KLAVIYO_INTEGRATION_REQ,
    });

    try {
      const sessionToken = await localStorage.getItem('sToken');
      const data = { sessionToken, publicKey, privateKey };

      const integrationTestResult = await BlueprintJSONAPI(
        APIMethods.POST,
        INTEGRATIONS,
        'klaviyo/test',
        data,
      );

      if (integrationTestResult.data?.connectionIsValid === true) {
        return dispatch({ type: IntegrationsActionTypes.TEST_KLAVIYO_INTEGRATION_RES });
      }

      throw new Error(integrationTestResult.data?.message);
    } catch (error) {
      console.error('Error - testKlaviyoIntegration:', error);

      return dispatch({
        type: IntegrationsActionTypes.TEST_KLAVIYO_INTEGRATION_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },

  getKlaviyoLists: (publicKey, privateKey) => async (dispatch) => {
    dispatch({
      type: IntegrationsActionTypes.GET_KLAVIYO_LISTS_REQ,
    });

    try {
      const data = { publicKey, privateKey };

      const integrationTestResult = await BlueprintJSONAPI(
        APIMethods.GET,
        INTEGRATIONS,
        'klaviyo/lists',
        data,
      );

      return dispatch({
        type: IntegrationsActionTypes.GET_KLAVIYO_LISTS_RES,
        payload: integrationTestResult.data.lists,
      });
    } catch (error) {
      console.error('Error - testKlaviyoIntegration:', error);

      return dispatch({
        type: IntegrationsActionTypes.GET_KLAVIYO_LISTS_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },

  resetPrivyIntegrationState: () => async (dispatch) => dispatch({
    type: IntegrationsActionTypes.UPSERT_PRIVY_INTEGRATION_RESET,
  }),

  upsertPrivyIntegration: () => async (dispatch) => {
    dispatch({
      type: IntegrationsActionTypes.UPSERT_PRIVY_INTEGRATION_REQ,
    });

    try {
      await BlueprintJSONAPI(
        APIMethods.POST,
        INTEGRATIONS,
        'privy/generate',
        {},
      );

      dispatch({
        type: IntegrationsActionTypes.UPSERT_PRIVY_INTEGRATION_RES,
        payload: {},
      });
    } catch (error) {
      console.error('Error - upsertPrivyIntegration:', error);

      dispatch({
        type: IntegrationsActionTypes.UPSERT_PRIVY_INTEGRATION_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },

  resetZendeskIntegrationState: () => async (dispatch) => dispatch({
    type: IntegrationsActionTypes.ZENDESK_INTEGRATION_RESET,
  }),

  resetGorgiasIntegrationState: () => async (dispatch) => dispatch({
    type: IntegrationsActionTypes.UPSERT_GORGIAS_INTEGRATION_RESET,
  }),

  upsertZendeskIntegration: (form) => async (dispatch) => {
    dispatch({
      type: IntegrationsActionTypes.UPSERT_ZENDESK_INTEGRATION_REQ,
    });

    try {
      await BlueprintJSONAPI(
        APIMethods.PUT,
        INTEGRATIONS,
        'zendesk/update',
        form,
      );

      dispatch({
        type: IntegrationsActionTypes.UPSERT_ZENDESK_INTEGRATION_RES,
        payload: {},
      });
    } catch (error) {
      console.error('Error - upsertZendeskIntegration:', error);

      dispatch({
        type: IntegrationsActionTypes.UPSERT_ZENDESK_INTEGRATION_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },

  generateZendeskIntegration: () => async (dispatch) => {
    dispatch({
      type: IntegrationsActionTypes.UPSERT_ZENDESK_INTEGRATION_REQ,
    });

    try {
      await BlueprintJSONAPI(
        APIMethods.POST,
        INTEGRATIONS,
        'zendesk/generate',
        {},
      );

      dispatch({
        type: IntegrationsActionTypes.UPSERT_ZENDESK_INTEGRATION_RES,
        payload: {},
      });
    } catch (error) {
      console.error('Error - generateZendeskIntegration:', error);

      dispatch({
        type: IntegrationsActionTypes.UPSERT_ZENDESK_INTEGRATION_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },

  deleteZendeskIntegration: (publicId) => async (dispatch) => {
    dispatch({
      type: IntegrationsActionTypes.DELETE_ZENDESK_INTEGRATION_REQ,
    });

    try {
      const data = {
        publicId,
      };

      await BlueprintJSONAPI(
        APIMethods.DELETE,
        INTEGRATIONS,
        'zendesk',
        data,
      );

      dispatch({
        type: IntegrationsActionTypes.DELETE_ZENDESK_INTEGRATION_RES,
        payload: {},
      });
    } catch (error) {
      console.error('Error - deleteZendeskIntegration:', error);

      dispatch({
        type: IntegrationsActionTypes.DELETE_ZENDESK_INTEGRATION_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },

  upsertGorgiasIntegration: (form) => async (dispatch) => {
    dispatch({
      type: IntegrationsActionTypes.UPSERT_GORGIAS_INTEGRATION_REQ,
    });

    try {
      await BlueprintJSONAPI(
        APIMethods.POST,
        INTEGRATIONS,
        'gorgias/generate',
        form,
      );

      dispatch({
        type: IntegrationsActionTypes.UPSERT_GORGIAS_INTEGRATION_RES,
        payload: {},
      });
    } catch (error) {
      console.error('Error - upsertGorgiasIntegration:', error);

      dispatch({
        type: IntegrationsActionTypes.UPSERT_GORGIAS_INTEGRATION_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },

  deleteGorgiasIntegration: () => async (dispatch) => {
    dispatch({
      type: IntegrationsActionTypes.DELETE_GORGIAS_INTEGRATION_REQ,
    });

    try {
      await BlueprintJSONAPI(
        APIMethods.DELETE,
        INTEGRATIONS,
        'gorgias/delete',
      );

      dispatch({
        type: IntegrationsActionTypes.DELETE_GORGIAS_INTEGRATION_RES,
        payload: {},
      });
    } catch (error) {
      console.error('Error - deleteGorgiasIntegration:', error);

      dispatch({
        type: IntegrationsActionTypes.DELETE_GORGIAS_INTEGRATION_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },

  testGorgiasIntegration: (apiUrl, gorgiasEmail, apiKey) => async (dispatch) => {
    dispatch({
      type: IntegrationsActionTypes.TEST_GORGIAS_INTEGRATION_REQ,
    });

    try {
      const sessionToken = await localStorage.getItem('sToken');
      const data = {
        sessionToken, apiUrl, gorgiasEmail, apiKey,
      };

      const integrationTestResult = await BlueprintJSONAPI(
        APIMethods.POST,
        INTEGRATIONS,
        'gorgias/test',
        data,
      );

      if (integrationTestResult.data?.connectionIsValid === true) {
        return dispatch({ type: IntegrationsActionTypes.TEST_GORGIAS_INTEGRATION_RES });
      }

      throw new Error(integrationTestResult.data?.message);
    } catch (error) {
      console.error('Error - testGorgiasIntegration:', error);

      return dispatch({
        type: IntegrationsActionTypes.TEST_GORGIAS_INTEGRATION_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },

  testRechargeIntegration: () => async (dispatch) => {
    dispatch({
      type: IntegrationsActionTypes.TEST_RECHARGE_INTEGRATION_REQ,
    });

    try {
      const sessionToken = await localStorage.getItem('sToken');
      const data = { sessionToken };

      const integrationTestResult = await BlueprintJSONAPI(
        APIMethods.POST,
        INTEGRATIONS,
        'recharge/test',
        data,
      );

      if (integrationTestResult.data?.connectionIsValid === true) {
        return dispatch({ type: IntegrationsActionTypes.TEST_RECHARGE_INTEGRATION_RES });
      }

      throw new Error(integrationTestResult.data?.message);
    } catch (error) {
      console.error('Error - testRechargeIntegration:', error);

      return dispatch({
        type: IntegrationsActionTypes.TEST_RECHARGE_INTEGRATION_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },

  resetRechargeIntegrationState: () => async (dispatch) => dispatch({
    type: IntegrationsActionTypes.RECHARGE_INTEGRATION_RESET,
  }),

  testBoldIntegration: () => async (dispatch) => {
    dispatch({
      type: IntegrationsActionTypes.TEST_BOLD_INTEGRATION_REQ,
    });

    try {
      const sessionToken = await localStorage.getItem('sToken');
      const data = { sessionToken };

      const integrationTestResult = await BlueprintJSONAPI(
        APIMethods.POST,
        INTEGRATIONS,
        'bold/test',
        data,
      );

      if (integrationTestResult.data?.connectionIsValid === true) {
        return dispatch({ type: IntegrationsActionTypes.TEST_BOLD_INTEGRATION_RES });
      }

      throw new Error(integrationTestResult.data?.message);
    } catch (error) {
      console.error('Error - testBoldIntegration:', error);

      return dispatch({
        type: IntegrationsActionTypes.TEST_BOLD_INTEGRATION_RES,
        error: extractErrorMessageFromError(error),
      });
    }
  },

  resetBoldIntegrationState: () => async (dispatch) => dispatch({
    type: IntegrationsActionTypes.BOLD_INTEGRATION_RESET,
  }),
};

export default IntegrationsActionTypes;
