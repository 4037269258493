import React from 'react';

export const BPConvSearchbox = (props) => {
  const { value, handleChange } = props;
  return (
    <div className="bp-conv-searchbox">
      <input
        type="text"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        placeholder="Search..."
      />
    </div>
  );
};

export default BPConvSearchbox;
