import {
  BlueprintJSONAPI,
  APIMethods,
  APIServiceConstants,
} from './BlueprintAPI';

export const getMerchantIntegrations = async () => {
  const response = await BlueprintJSONAPI(
    APIMethods.GET,
    APIServiceConstants.INTEGRATIONS,
    'merchants/integrations',
  );

  return response?.data?.integrations || [];
};

export default getMerchantIntegrations;
