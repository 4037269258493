import React, { useEffect, useState } from 'react';

import { options } from './MessageIntervalSelect';

/**
 * @typedef DemoMessage
 * @property {String} content
 * @property {String} date
 * @property {Boolean} [integer] - Indicates if `date` property is a preset time interval in minutes
 * @property {String} [mmsMediaURL]
 */

const returnDefaultTitle = () => options[0].title;

const getIntervalByTime = (mins) => options.find(({ val }) => val === parseInt(mins, 10));

const minutesToTimeIntervals = (mins) => {
  const interval = getIntervalByTime(mins);
  const title = interval ? interval.title : returnDefaultTitle();
  return `+ ${title}`;
};

// eslint-disable-next-line react/no-array-index-key
const Messages = ({ messages }) => messages.map((message, k) => <Message key={k} {...message} />);

const Message = ({
  content, date, integer = false, mmsMediaURL,
}) => (
  <div className="message">
    <div className="content">
      {mmsMediaURL && (
        <a href={mmsMediaURL} target="blank">
          <img src={mmsMediaURL} className="mms-media" alt="mms" />
        </a>
      )}
      <p>{content}</p>
    </div>

    <div className="timestamp">
      {integer ? minutesToTimeIntervals(date) : date}
    </div>
  </div>
);

/**
 *
 * @param {Object} props
 * @param {String} [props.id]
 * @param {Promise<DemoMessage>[] | DemoMessage[]} props.messages
 */
export const DemoPhone = ({ id = undefined, messages }) => {
  const initMessagesValues = /** @type {messagesValues[]} */ [];
  const [loading, setLoading] = useState(true);
  const [messagesValues, setMessageValues] = useState(initMessagesValues);

  useEffect(() => {
    setLoading(true);
    async function messagesSetter() {
      try {
        const messagesValuesIn = await Promise.all(messages);
        setMessageValues(messagesValuesIn);
        setLoading(false);
      } catch (error) {
        console.error('Error loading messages', error);
      }
    }
    messagesSetter();
  }, [messages]);

  return (
    <div id={id} className="demo-phone">
      <div className="ear-piece" />
      <div className="message-container">
        {loading && <span className="label label-default">Loading</span>}
        {messagesValues && <Messages messages={messagesValues} />}
      </div>
    </div>
  );
};

export default DemoPhone;
