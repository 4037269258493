import { UpSellsActionTypes } from '../../types';
import { types } from '../../../utils/crossSells';

export const initialState = {
  upsellsLoading: false,
  upsellsErr: null,
  upsellsLoaded: false,
  upsellsData: [],
  upsellsDataType: types.CROSS_SELLS,
  upsellsCampaignStatsLoading: false,
  upsellsCampaignStatsErr: null,
  upsellsCampaignStatsLoaded: false,
  upsellsCampaignStatsData: [],
  analyticsLoading: false,
  analyticsErr: null,
  analyticsLoaded: false,
  analyticsData: [],
  upsellUpdating: false,
  upsellUpdateErr: null,
  upsellsUpdated: false,
  upsellsUpdateResult: '',
  upsellCreateErr: null,
  upsellCreated: false,
  upsellCreateResult: '',
  upsellDeleting: false,
  upsellDeleteErr: null,
  upsellsDeleted: false,
  upsellsDeleteResult: '',
  singleCrossSellLoading: false,
  singleCrossSellErr: null,
  singleCrossSellLoaded: false,
  singleCrossSellData: null,
  crossSellsData: [],
  crossSellsProductsSelected: [],
  replenishmentsData: [],
  replenishmentsProductsSelected: [],
};

export const UpsellsReducer = (state = initialState, action) => {
  const {
    type, payload, upsellType, error,
  } = action;
  switch (type) {
    case UpSellsActionTypes.RESET_STATES_USED_IN_TOASTS: {
      return {
        ...state,
        upsellsErr: null,
        upsellsCampaignStatsErr: null,
        analyticsErr: null,
        upsellUpdateErr: null,
        upsellsUpdated: false,
        upsellCreateErr: null,
        upsellCreated: false,
        upsellDeleteErr: null,
        upsellsDeleted: false,
        singleCrossSellErr: null,
      };
    }
    case UpSellsActionTypes.GET_UP_SELLS_REQ: {
      return {
        ...state,
        upsellsLoading: true,
      };
    }
    case UpSellsActionTypes.GET_UP_SELLS_RES: {
      if (error) {
        return {
          ...state,
          upsellsLoading: false,
          upsellsErr: error,
        };
      }
      return {
        ...state,
        upsellsLoading: false,
        upsellsLoaded: true,
        upsellsData: payload,
        upsellsDataType: upsellType,
      };
    }
    case UpSellsActionTypes.UPDATE_UPSELL_REQ: {
      return {
        ...state,
        upsellUpdating: true,
        upsellsUpdated: false,
        upsellUpdateErr: null,
      };
    }
    case UpSellsActionTypes.UPDATE_UPSELL_RES: {
      if (error) {
        return {
          ...state,
          upsellUpdating: false,
          upsellUpdateErr: error,
        };
      }
      return {
        ...state,
        upsellUpdating: false,
        upsellsUpdated: true,
        upsellsUpdateResult: payload,
      };
    }
    // Using "upsellUpdating" here because they use same modal, form, submit button, etc
    case UpSellsActionTypes.CREATE_UPSELL_REQ: {
      return {
        ...state,
        upsellUpdating: true,
        upsellCreated: false,
        upsellCreateErr: null,
      };
    }
    case UpSellsActionTypes.CREATE_UPSELL_RES: {
      if (error) {
        return {
          ...state,
          upsellUpdating: false,
          upsellCreated: false,
          upsellCreateErr: error,
        };
      }
      return {
        ...state,
        upsellUpdating: false,
        upsellCreated: true,
        upsellCreateResult: payload,
      };
    }
    case UpSellsActionTypes.DELETE_UPSELL_REQ: {
      return {
        ...state,
        upsellDeleting: true,
        upsellsDeleted: false,
        upsellDeleteErr: null,
      };
    }
    case UpSellsActionTypes.DELETE_UPSELL_RES: {
      if (error) {
        return {
          ...state,
          upsellDeleting: false,
          upsellDeleteErr: error,
        };
      }
      return {
        ...state,
        upsellDeleting: false,
        upsellsDeleted: true,
        upsellsDeleteResult: payload,
      };
    }
    case UpSellsActionTypes.GET_UP_SELLS_ANALYTICS_REQ: {
      return {
        ...state,
        analyticsLoading: true,
      };
    }
    case UpSellsActionTypes.GET_UP_SELLS_ANALYTICS_RES: {
      if (error) {
        return {
          ...state,
          analyticsLoading: false,
          analyticsErr: error,
        };
      }
      return {
        ...state,
        analyticsLoading: false,
        analyticsLoaded: true,
        analyticsData: payload,
      };
    }
    case UpSellsActionTypes.GET_SINGLE_CROSS_SELL_REQ: {
      return {
        ...state,
        singleCrossSellLoading: true,
      };
    }
    case UpSellsActionTypes.GET_SINGLE_CROSS_SELL_RES: {
      if (error) {
        return {
          ...state,
          singleCrossSellLoading: false,
          singleCrossSellErr: error,
        };
      }
      return {
        ...state,
        singleCrossSellLoading: false,
        singleCrossSellLoaded: true,
        singleCrossSellData: payload,
      };
    }
    case UpSellsActionTypes.GET_ALL_CROSS_SELLS_RES: {
      return {
        ...state,
        crossSellsData: payload,
      };
    }
    case UpSellsActionTypes.GET_CROSS_SELL_PRODUCTS: {
      return {
        ...state,
        crossSellsProductsSelected: payload,
      };
    }
    case UpSellsActionTypes.GET_UP_SELLS_CAMPAIGN_STATS_REQ: {
      return {
        ...state,
        upsellsCampaignStatsLoading: true,
      };
    }
    case UpSellsActionTypes.GET_UP_SELLS_CAMPAIGN_STATS_RES: {
      if (error) {
        return {
          ...state,
          upsellsCampaignStatsLoading: false,
          upsellsCampaignStatsErr: error,
        };
      }
      return {
        ...state,
        upsellsCampaignStatsLoading: false,
        upsellsCampaignStatsLoaded: true,
        upsellsCampaignStatsData: payload,
      };
    }
    case UpSellsActionTypes.GET_ALL_REPLENISHMENTS_RES: {
      return {
        ...state,
        replenishmentsData: payload,
      };
    }
    case UpSellsActionTypes.GET_REPLENISHMENT_PRODUCTS: {
      return {
        ...state,
        replenishmentsProductsSelected: payload,
      };
    }
    default:
      return state;
  }
};
