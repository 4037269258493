import {
  BlueprintJSONAPI, APIMethods, APIServiceConstants,
} from '../../../redux/actions/BlueprintAPI';

const upsertIntegration = async (accessToken) => {
  const response = await BlueprintJSONAPI(
    APIMethods.POST,
    APIServiceConstants.INTEGRATIONS,
    'smartrr',
    {
      accessToken,
    },
  );

  if (response.status !== 200) {
    throw new Error(response.data);
  }

  return response?.data;
};

export default upsertIntegration;
