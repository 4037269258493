import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import { getUrl, APIServiceConstants } from '../../redux/actions/BlueprintAPI';
import { IntegrationActionCreators } from '../../redux/actions/integrations';
import { MerchantActionCreators } from '../../redux/actions/merchant';

const TEN_SECONDS_IN_MILLISECONDS = 10000;

const { getMerchantData } = MerchantActionCreators;
const {
  getMerchantIntegrations,
  testRechargeIntegration,
  resetRechargeIntegrationState,
} = IntegrationActionCreators;

const IntegrationsHubRechargePage = () => {
  const dispatch = useDispatch();
  const { INTEGRATIONS } = APIServiceConstants;

  let installUrl;
  const [hasRechargeInstallSubmitted, setHasRechargeInstallSubmitted] = useState(false);
  const [isIntegrationActive, setIsIntegrationActive] = useState(false);

  const {
    integrationsLoading,
    integrationsLoaded,
    integrationsData,
    rechargeTestLoading,
    rechargeTestSuccess,
    rechargeTestError,
    merchantData,
    merchantLoaded,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */state) => (
      { ...state.IntegrationsReducer, ...state.MerchantReducer }
    ),
  );

  const allDependenciesLoaded = merchantLoaded && integrationsLoaded;

  useEffect(() => {
    if (!integrationsLoaded && !integrationsLoading) {
      dispatch(getMerchantIntegrations());
    }
  }, [dispatch, integrationsLoaded, integrationsLoading]);

  useEffect(() => {
    if (!merchantLoaded) {
      dispatch(getMerchantData());
    }
  }, [merchantLoaded, dispatch]);

  useEffect(() => () => {
    dispatch(resetRechargeIntegrationState());
  }, [dispatch]);

  useEffect(() => {
    let activationCheckInterval;

    if (integrationsLoaded) {
      if (hasRechargeInstallSubmitted && !isIntegrationActive) {
        activationCheckInterval = setInterval(() => {
          if (!integrationsLoading) {
            dispatch(getMerchantIntegrations());
          }
        }, TEN_SECONDS_IN_MILLISECONDS);
      } if (hasRechargeInstallSubmitted && isIntegrationActive && activationCheckInterval) {
        clearInterval(activationCheckInterval);
      }
    }

    return () => clearInterval(activationCheckInterval);
  }, [
    dispatch,
    integrationsLoaded,
    integrationsLoading,
    hasRechargeInstallSubmitted,
    isIntegrationActive,
  ]);

  useEffect(() => {
    if (integrationsLoaded) {
      if (integrationsData.recharge) {
        setIsIntegrationActive(integrationsData.recharge.active);
      }
    }
  }, [integrationsData, integrationsLoaded]);

  const handleActivateClick = () => {
    setHasRechargeInstallSubmitted(true);
    const cacheBusterSecondsSinceEpoch = (new Date()).getTime();
    installUrl = getUrl(INTEGRATIONS, `recharge/install?merchantId=${merchantData.id}&cb=${cacheBusterSecondsSinceEpoch}`);
    return window.open(installUrl, '_blank');
  };

  const handleTestConnectionClick = () => dispatch(testRechargeIntegration());

  return (
    <PageBuilder>
      <SEO title="Recharge | Integrations | Blueprint" />
      <div className="a-view integrations-hub">
        <div className="container">
          <div className="row back-btn-container">
            <div className="col-md-12">
              <Link to="/integrations">Back</Link>
            </div>
          </div>

          <div className="top-bar">
            <h2 className="title">
              Recharge
              {' '}
              {isIntegrationActive ? '✅' : ''}
            </h2>
          </div>

          {allDependenciesLoaded && isIntegrationActive && (
            <button className="btn btn-primary mr-2" onClick={handleTestConnectionClick} disabled={rechargeTestLoading} type="button">Test Connection</button>
          )}

          {allDependenciesLoaded && !isIntegrationActive && (
            <button className="btn btn-primary" onClick={handleActivateClick} type="button">Activate Connection</button>
          )}

          {rechargeTestSuccess && (
            <span>
              <span className="mr-1">✅</span>
              Connection OK!
            </span>
          )}

          {rechargeTestError && (
            <span>
              <span className="mr-1">🚨</span>
              {rechargeTestError}
            </span>
          )}

          {!allDependenciesLoaded && (
            <>Loading...</>
          )}

        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(IntegrationsHubRechargePage);
