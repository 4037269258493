import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';

import CrossSellsTableRow from './CrossSellsTableRow';

/** @typedef {import('../CrossSells').UpSell} UpSell */

/**
 * Reordering the given list by removing the element on "sourceIndex" and re-adding it after the "destinationIndex"
 *
 * @param {Array} list
 * @param {Number} sourceIndex index of the element to move
 * @param {Number} destinationIndex the element will be moved after this index
 * @returns Array
 */
const reorder = (list, sourceIndex, destinationIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};

/**
 * @typedef {import('../../../redux/reducers').RootState} RootState
 */

const CrossSellsTable = ({
  toggleUpSellState,
  handleDelete,
  handlePriorityChange,
  type,
  shouldReloadUpsells,
}) => {
  const [upsellsLocal, setUpsellsLocal] = useState(/** @type {UpSell[]} */ ([]));

  const {
    upsellsData: upsells,
  } = useSelector((/** @type RootState} */ state) => state.UpsellsReducer);

  useEffect(() => {
    setUpsellsLocal(upsells);
  }, [upsells]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { draggableId, destination, source } = result;

    if (destination.index === source.index) {
      return;
    }

    const reorderResult = reorder(
      upsellsLocal,
      source.index,
      destination.index,
    );

    setUpsellsLocal(reorderResult);
    handlePriorityChange({
      upsellsByNewOrder: reorderResult,
      upsellId: parseInt(draggableId, 10), // because draggableId will always be String
    });
  };

  const toggleUpSellStateLocally = ({ id }) => {
    const indexToChange = upsellsLocal.findIndex((upsell) => upsell.id === id);
    const updatedUpSellsData = [...upsellsLocal];
    const currentState = updatedUpSellsData[indexToChange].active;

    updatedUpSellsData[indexToChange].active = !currentState;

    setUpsellsLocal(updatedUpSellsData);
    toggleUpSellState({ upsellId: id, newState: !currentState });
  };

  if (!upsellsLocal) {
    return null;
  }

  const tableRows = upsellsLocal.map((upsell, index) => (
    <CrossSellsTableRow
      upsell={upsell}
      key={upsell.id}
      index={index}
      toggleUpSellState={toggleUpSellStateLocally}
      handleDelete={handleDelete}
      type={type}
    />
  ));

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <table className="table table-hover">
              <thead className="thead-dark">
                <tr className="d-flex">
                  <th className="col-1">Priority</th>
                  <th className="col-6">Name</th>
                  <th className="col-1">Send After</th>
                  <th className="col-2 centered">Status</th>
                  <th className="col-2">Actions</th>
                </tr>
              </thead>
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {shouldReloadUpsells ? <tr className="text-center mt-3 mb-3"><td colSpan={8}>Loading...</td></tr> : tableRows}
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default CrossSellsTable;
