import React, {
  memo, useState, useCallback, useEffect,
} from 'react';
import { useParams, Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import EditSegment from '../../components/main/GroupMessaging/EditSegment/EditSegment';
import { getGroupDetails } from '../../redux/actions/GroupMessagingAPI';
import LoadingMessage from '../../components/main/GroupMessaging/Loading';
import { cloneItem } from '../../components/main/GroupMessaging/EditSegment/helpers';
import {
  predicates,
  addComponentNames,
} from '../../components/main/GroupMessaging/EditSegment/predicates';
import { ProductActionCreators } from '../../redux/actions/product';
import { initialGroup } from './GroupMessaging';

/** @typedef {import('../../redux/reducers').RootState} RootState */

const { getAllProducts: getAllProductsAction } = ProductActionCreators;

const GroupSegment = () => {
  const [group, setGroup] = useState(initialGroup);
  const { groupId } = useParams();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { allProducts = [] } = useSelector((/** @type {RootState} */ state) => state.ProductReducer);
  const getProducts = useCallback(() => {
    dispatch(getAllProductsAction());
  }, [dispatch]);

  const fetchGroupDetails = useCallback(async () => {
    try {
      const groupDetails = await getGroupDetails(groupId);

      const predicateGroup = JSON.parse(groupDetails.predicates);

      setGroup({
        ...groupDetails,
        predicates: {
          ...predicateGroup,
          predicates: addComponentNames(predicateGroup.predicates),
        },
      });
    } catch (e) {
      addToast('The segment details could not be retrieved.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, groupId]);

  useEffect(() => {
    if (groupId) {
      fetchGroupDetails();
    } else {
      setGroup({
        id: undefined,
        name: '',
        description: '',
        type: 1,
        readOnly: 0,
        count: 0,
        customers: [],
        predicates: {
          combinationMode: 'and',
          predicates: [
            {
              combinationMode: 'or',
              predicates: [cloneItem(predicates[0])],
            },
          ],
        },
        messages: [],
      });
    }

    getProducts();
  }, [fetchGroupDetails, getProducts, groupId]);

  return (
    <PageBuilder>
      <SEO title="Segmentation Test | Blueprint" />
      <div className="a-view group-segment">
        <div className="container">
          <div className="top-bar">
            <h2 className="title">
              <Link to="/group-messaging">Group Messaging</Link>
              {' '}
              {'>'}
              {' '}
              {groupId ? 'Edit' : 'Create'}
              {' '}
              Segment
            </h2>
          </div>

          <LoadingMessage loading={!group} />

          {group && <EditSegment group={group} products={allProducts} />}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(GroupSegment);
