import { UpSellsActionTypes } from '../../types';
import { APIMethods, APIServiceConstants, BlueprintAPI } from '../BlueprintAPI';

const { SERVICE } = APIServiceConstants;

export const ReplenishmentsActionCreators = {
  getAllReplenishments: () => async (dispatch) => {
    dispatch({
      type: UpSellsActionTypes.GET_ALL_REPLENISHMENTS_REQ,
    });
    try {
      const response = await BlueprintAPI(SERVICE, 'getReplenishments', {}, null, true, APIMethods.GET);

      dispatch({
        type: UpSellsActionTypes.GET_ALL_REPLENISHMENTS_RES,
        payload: response.data.replenishments || [],
      });
    } catch (e) {
      console.error('Error - getReplenishments:', e);
      dispatch({
        type: UpSellsActionTypes.GET_ALL_REPLENISHMENTS_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
  setReplenishmentelectedProducts: (products = []) => async (dispatch) => {
    dispatch({
      type: UpSellsActionTypes.GET_REPLENISHMENT_PRODUCTS,
      payload: products,
    });
  },
};

export default ReplenishmentsActionCreators;
