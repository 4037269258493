import React, {
  memo,
  useCallback,
} from 'react';
import { v4 as uuid } from 'uuid';

import { NEGATE_MODES } from '../../../global/Predicates/Negate';
import { COMPARATORS } from '../../../global/Predicates/ComparatorSelector';

import AOV, { valueMapper as aovMapper } from './ContactFilters/AOV';
import LTV, { valueMapper as ltvMapper } from './ContactFilters/LTV';
import NumberOfOrders, { valueMapper as numberOfOrdersMapper } from './ContactFilters/NumberOfOrders';
import PurchaseHistory, { valueMapper as productMapper } from './ContactFilters/PurchaseHistory';
import OptedIn, { valueMapper as optedInMapper } from './ContactFilters/OptedIn';
import ListMembership, { valueMapper as listsMapper } from './ContactFilters/ListMembership';
import PhoneCountryCode, { valueMapper as phoneCountryCodeMapper } from './ContactFilters/PhoneCountryCode';
import ABTestMembership, { valueMapper as abMapper } from './ContactFilters/ABTestMembership';
import AddressStateCode, { valueMapper as addressStateCodeMapper } from './ContactFilters/AddressStateCode';
import AddressCountryCode, { valueMapper as addressCountryCodeMapper } from './ContactFilters/AddressCountryCode';
import ActiveCampaigns, { valueMapper as activeCampaignsMapper } from './ContactFilters/ActiveCampaigns';
import CommerceSubscriptionStatus, { valueMapper as commerceSubscriptionStatusMapper } from './ContactFilters/CommerceSubscriptionStatus';

/** @typedef {import('react').FunctionComponentElement} FunctionComponentElement */
/** @typedef {import('../../../global/Predicates/TypeSelector').Type} Type */
/** @typedef {import('../../../global/Predicates/ComparatorSelector').Comparator} Comparator */

/** @type {Type[]} */
const ContactFilterTypes = [
  {
    title: 'Purchased Products',
    value: 'product',
    comparators: [
      COMPARATORS.containsAll, COMPARATORS.containsAny,
    ],
  },
  {
    title: 'Average Order Value (AOV)',
    value: 'aov',
    comparators: [
      COMPARATORS.eq, COMPARATORS.gt, COMPARATORS.lt, COMPARATORS.gte, COMPARATORS.lte,
    ],
  },
  {
    title: 'Lifetime Value (LTV)',
    value: 'ltv',
    comparators: [
      COMPARATORS.eq, COMPARATORS.gt, COMPARATORS.lt, COMPARATORS.gte, COMPARATORS.lte,
    ],
  },
  {
    title: 'Number of Orders',
    value: 'numberOfOrders',
    comparators: [
      COMPARATORS.eq, COMPARATORS.gt, COMPARATORS.lt, COMPARATORS.gte, COMPARATORS.lte,
    ],
  },
  {
    title: 'Opted In',
    value: 'optedIn',
    comparators: [
      COMPARATORS.eq,
    ],
  },
  {
    title: 'List membership',
    value: 'lists',
    comparators: [
      COMPARATORS.containsAny, COMPARATORS.containsAll,
    ],
  },
  {
    title: 'Phone Country Code',
    value: 'phoneCountryCode',
    comparators: [
      COMPARATORS.equalsOneOf,
    ],
  },
  {
    title: 'A/B test membership',
    value: 'abTestGroup',
    comparators: [
      COMPARATORS.none,
    ],
  },
  {
    title: 'Address State Code',
    value: 'defaultAddressStateCode',
    comparators: [
      COMPARATORS.equalsOneOf,
    ],
  },
  {
    title: 'Address Country Code',
    value: 'defaultAddressCountryCode',
    comparators: [
      COMPARATORS.equalsOneOf,
    ],
  },
  {
    title: 'Automation Membership',
    value: 'activeCampaigns',
    comparators: [
      COMPARATORS.isCurrentlyActive, COMPARATORS.wasEverActive,
    ],
  },
  {
    title: 'Commerce Subscription Status',
    value: 'commerceSubscriptionStatus',
    comparators: [
      COMPARATORS.eq,
    ],
  },
];

export const getValueMapperForContactFilterType = (filterType) => {
  switch (filterType) {
    case 'product':
      return productMapper;
    case 'aov':
      return aovMapper;
    case 'ltv':
      return ltvMapper;
    case 'numberOfOrders':
      return numberOfOrdersMapper;
    case 'optedIn':
      return optedInMapper;
    case 'lists':
      return listsMapper;
    case 'phoneCountryCode':
      return phoneCountryCodeMapper;
    case 'abTestGroup':
      return abMapper;
    case 'defaultAddressStateCode':
      return addressStateCodeMapper;
    case 'defaultAddressCountryCode':
      return addressCountryCodeMapper;
    case 'activeCampaigns':
      return activeCampaignsMapper;
    case 'commerceSubscriptionStatus':
      return commerceSubscriptionStatusMapper;
    default:
      throw new Error(`Tried to get value mapper for unknown type ${filterType}`);
  }
};

const getComponentForFilterType = (filterType) => {
  switch (filterType) {
    case 'product':
      return PurchaseHistory;
    case 'aov':
      return AOV;
    case 'ltv':
      return LTV;
    case 'numberOfOrders':
      return NumberOfOrders;
    case 'optedIn':
      return OptedIn;
    case 'lists':
      return ListMembership;
    case 'phoneCountryCode':
      return PhoneCountryCode;
    case 'abTestGroup':
      return ABTestMembership;
    case 'defaultAddressStateCode':
      return AddressStateCode;
    case 'defaultAddressCountryCode':
      return AddressCountryCode;
    case 'activeCampaigns':
      return ActiveCampaigns;
    case 'commerceSubscriptionStatus':
      return CommerceSubscriptionStatus;
    default:
      throw new Error(`Tried to get Component for unexpected basket type: ${filterType}`);
  }
};

const getContactFilterType = (type) => {
  const result = ContactFilterTypes.find((t) => t.value === type);

  if (!result) {
    throw new Error(`Failed to find expected contact filter type: ${type}`);
  }

  return result;
};

const ContactFilter = ({ onChange, filters }) => {
  const onFilterChange = useCallback((filter) => {
    let updatedFilters = [];

    const foundIndex = filters.findIndex((f) => f.id === filter.id);

    if (foundIndex < 0) {
      updatedFilters = [...filters, filter];
    }

    updatedFilters = filters.map((f) => (f.id === filter.id ? filter : f));

    onChange(updatedFilters);
  }, [filters, onChange]);

  const removeFilter = useCallback((id) => {
    const updatedFilters = filters.filter((filter) => filter.id !== id);

    onChange(updatedFilters);
  }, [filters, onChange]);

  const addFilter = useCallback(() => {
    onChange([...filters, { id: uuid(), type: 'product' }]);
  }, [filters, onChange]);

  return (
    <>
      {filters.map((filter, idx) => {
        const type = getContactFilterType(filter.type);
        const Component = getComponentForFilterType(filter.type);

        if (!Component) {
          return null;
        }

        return (
          <div key={filter.id} className="form-row mb-2">
            <Component
              onChange={onFilterChange}
              {...filter}
              comparator={filter.op}
              negate={{ mode: filter.negate ? NEGATE_MODES.yes : NEGATE_MODES.no }}
              types={ContactFilterTypes}
              comparators={type.comparators}
            />
            <div className="col-mb-1 filter-actions">
              {idx > 0 && (
              <button
                className="btn btn-link"
                type="button"
                onClick={() => {
                  removeFilter(filter.id);
                }}
              >
                🗑️
              </button>
              )}
            </div>
          </div>
        );
      })}
      {filters.length > 0 && <button className="minimal-blue-btn" onClick={addFilter} type="button">+ AND</button>}
    </>
  );
};

export default memo(ContactFilter);
