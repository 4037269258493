import React, {
  memo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { getMerchantIntegrations } from '../../redux/actions/AutomatedMessagingAPI';

import SubscriptionsForm from '../../components/forms/Settings/SubscriptionsForm';

/** @typedef {import('../../redux/reducers').RootState} RootState */

const Subscriptions = () => {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(true);
  const [hasFetchedIntegrations, setHasFetchedIntegrations] = useState(false);
  const [merchantIntegrations, setMerchantIntegrations] = useState({});

  const {
    merchantLoaded,
    merchantData,
    chatbotSettings,
  } = useSelector((/** @type {RootState} */ state) => state.MerchantReducer);

  const handleFetchMerchantIntegrations = useCallback(async () => {
    try {
      const integrations = await getMerchantIntegrations();

      setMerchantIntegrations(integrations);
      setHasFetchedIntegrations(true);
    } catch (e) {
      addToast('The Merchants integrations could not be retrieved.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast]);

  useEffect(() => {
    if (merchantLoaded && hasFetchedIntegrations) {
      setLoading(false);
    }
  }, [merchantLoaded, hasFetchedIntegrations]);

  useEffect(() => {
    if (merchantData.id && !hasFetchedIntegrations) {
      handleFetchMerchantIntegrations();
    }
  }, [merchantData, hasFetchedIntegrations, handleFetchMerchantIntegrations]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="settings-card subscriptions">
            <SubscriptionsForm
              isLoading={loading}
              chatbotSettings={chatbotSettings}
              merchantIntegrations={merchantIntegrations}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Subscriptions);
