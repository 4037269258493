import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const PROVIDER_META_DATA = {
  klaviyo: {
    image: '/images/integrations/klaviyo.svg',
    title: 'Klaviyo',
    text: 'Email Marketing for Ecommerce',
    path: '/integrations/klaviyo',
  },
  recharge: {
    image: '/images/integrations/recharge.svg',
    title: 'ReCharge',
    text: 'Subscriptions & Recurring Payments for Ecommerce',
    path: '/integrations/recharge',
  },
  privy: {
    image: '/images/integrations/privy.svg',
    title: 'Privy',
    text: 'Email & More For Small Ecommerce Businesses',
    path: '/integrations/privy',
  },
  zendesk: {
    image: '/images/integrations/zendesk.svg',
    title: 'Zendesk',
    text: 'Customer Service & Sales CRM',
    path: '/integrations/zendesk',
  },
  gorgias: {
    image: '/images/integrations/gorgias.svg',
    title: 'Gorgias',
    text: 'Multichannel Helpdesk For Ecommerce Stores',
    path: '/integrations/gorgias',
  },
  bold: {
    image: '/images/integrations/bold.svg',
    title: 'Bold Subscriptions',
    text: 'Subscriptions & Recurring Payments for Ecommerce',
    path: '/integrations/bold',
  },
  smartrr: {
    image: '/images/integrations/smartrr.svg',
    title: 'Smartrr Subscriptions',
    text: 'The smartrr recurring revenue engine',
    path: '/integrations/smartrr',
  },
  'custom-api': {
    image: '/images/integrations/custom-api.svg',
    title: 'Custom API',
    text: 'Add subscribers using the Blueprint API',
    path: '/integrations/customapi',
  },
};

const IntegrationStatusCard = ({ type, active = false }) => {
  const cardMetaData = PROVIDER_META_DATA[type];
  const cardClassNames = useMemo(() => classNames('card', {
    active,
  }), [active]);

  return (
    <div className={cardClassNames}>
      <img src={cardMetaData.image} alt={cardMetaData.title} className="mt-3" />
      <div className="card-body">
        <h5 className="card-title">
          {cardMetaData.title}
          {' '}
          {active ? '✅' : ''}
        </h5>
        <p className="card-text text-sm">{cardMetaData.text}</p>
        <Link to={cardMetaData.path || '/integrations'} className="stretched-link" />
      </div>
    </div>
  );
};

export default memo(IntegrationStatusCard);
