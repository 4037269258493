import React, { useCallback, useMemo } from 'react';
import { formatDate } from '../../../../utils/dates';

/**
 * @typedef Customer
 * @property {Number} customerPhoneId
 */

const getDisplayName = (customer) => (customer.firstName && customer.lastName
  ? `${customer.firstName} ${customer.lastName}`
  : customer.phone);

const findCustomerInSubscribers = (customerPhoneId, list) => list.find(
  ({ customerPhoneId: cPId }) => customerPhoneId === cPId,
);

export default ({
  customer,
  subscribers = [],
  toggleSubscribers,
  showDateAdded,
  handleDelete,
  action = 'toggle',
}) => {
  const subscribedCustomer = useMemo(
    () => findCustomerInSubscribers(customer.customerPhoneId, subscribers),
    [customer, subscribers],
  );

  const { createdAt, customerPhoneId } = subscribedCustomer || customer;

  const clickToAdd = useCallback(() => toggleSubscribers(customer), [
    toggleSubscribers,
    customer,
  ]);

  const clickToDelete = useCallback(() => handleDelete(customerPhoneId), [
    handleDelete,
    customerPhoneId,
  ]);

  const width = showDateAdded ? 'col-md-4' : 'col-md-9';

  const toggleSubscriber = () => (
    <div className="check-box-wrapper">
      <label>
        <span className={`styled-check-box ${createdAt ? 'subscribed' : ''}`} />
        <input
          type="checkbox"
          className="check-box"
          onChange={clickToAdd}
          defaultChecked={!!createdAt}
          value={customerPhoneId}
        />
      </label>
    </div>
  );

  const removeSubscriber = () => (
    <div className="text-right" onClick={clickToDelete} role="button" tabIndex={0}>
      <span className="customer-delete">X</span>
    </div>
  );

  return (
    <div className="row body-row customer">
      <div className={width}>{getDisplayName(customer)}</div>
      {showDateAdded && (
        <div className="col-md-5">{createdAt && formatDate(createdAt)}</div>
      )}
      <div className="col-md-3">
        {action === 'toggle' && toggleSubscriber()}
        {action === 'delete' && removeSubscriber()}
      </div>
    </div>
  );
};
