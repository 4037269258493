import React, { useState } from 'react';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';

export const CookieMessage = () => {
  const cookiesAccepted = document.cookie.replace(
    /(?:(?:^|.*;\s*)cookiesAccepted\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
  const [accepted, setAccepted] = useState(cookiesAccepted === 'true');
  const acceptCookies = () => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 90); // expires in 90 days
    document.cookie = `cookiesAccepted=true; expires=${expires.toUTCString()}`;
    setAccepted(true);
  };

  if (accepted) {
    return null;
  }
  return (
    <Fade bottom delay={500}>
      <div className="cookie-message">
        <div className="container">
          <div className="row">
            <div className="col-md-2 icon">
              <span role="img" aria-label="cookie">
                🍪
              </span>
            </div>
            <div className="col-md-8">
              <p>
                We use
                {' '}
                <Link to="/privacy-policy">cookies to collect information</Link>
                {' '}
                about how you use blueprint. We use this information to make the
                website work as well as possible and improve our services.
              </p>
            </div>
            <div className="col-md-2">
              <button className="btn btn-green" onClick={acceptCookies} type="button">
                Accept cookies
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default CookieMessage;
