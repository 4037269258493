import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import CustomFlowForm from '../../forms/CustomFlowForm';
import { CustomFlowsActionCreators } from '../../../redux/actions/customFlows';

const {
  createFlow: createFlowAction,
  updateFlow: updateFlowAction,
} = CustomFlowsActionCreators;

const CustomFlowsWizard = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();

  const onSubmitHandler = (formData, id) => {
    if (id) {
      return dispatch(updateFlowAction(state.id, formData));
    }

    return dispatch(createFlowAction(formData));
  };

  return (
    <div className="a-view single-custom-flow">
      <div className="container">
        <div className="top-bar">
          <h2 className="title">
            <Link to={{
              pathname: (state && state.referrer) || '/automations',
            }}
            >
              Automations
            </Link>
            {' '}
            {'>'}
            {' '}
            {state && state.flow ? 'Update' : 'Create a new'}
            {' '}
            Automation
          </h2>
        </div>
        <CustomFlowForm id={state && state.id ? state.id : null} onSubmit={onSubmitHandler} />
      </div>
    </div>
  );
};

export default memo(CustomFlowsWizard);
