import React, { memo } from 'react';

/**
 * @typedef SelectOptions
 * @property {String} value
 * @property {String} label
 * @property {Boolean} [selected]
 * @property {Boolean} [disabled]
 */

/**
 * @param {Object} props
 * @param {String} props.value
 * @param {SelectOptions[]} props.options
 * @param {Function} props.onChange
 * @returns
 */
const SelectField = ({ onChange, value, options = [] }) => (
  <div className="col-md-3">
    <select
      value={value}
      className="form-control"
      onChange={(e) => {
        const newValue = e.target.value;

        onChange('value', newValue);
      }}
    >
      {options.map((option) => (
        <option
          value={option.value}
          key={option.value}
          disabled={option.disabled}
          selected={option.selected}
        >
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default memo(SelectField);
