import React, { memo, useCallback } from 'react';

/**
 * @typedef {Object} Comparator
 * @property {String} value - The internal value for this comparator
 * @property {String} title - The title to be displayed in the UI
 */

/**
 * @enum {Comparator}
 */
export const COMPARATORS = {
  stringContains: {
    title: 'Contains',
    value: 'stringContains',
  },
  containsAll: {
    title: 'Contains all',
    value: 'containsAll',
  },
  containsAny: {
    title: 'Contains any',
    value: 'containsAny',
  },
  equalsOneOf: {
    title: 'Equals one of',
    value: 'equalsOneOf',
  },
  isCurrentlyActive: {
    title: 'Is currently active in Automation',
    value: 'isCurrentlyActive',
  },
  wasEverActive: {
    title: 'Was ever active in Automation',
    value: 'wasEverActive',
  },
  eq: {
    title: 'Equal to',
    value: 'eq',
  },
  gt: {
    title: 'Greater than',
    value: 'gt',
  },
  lt: {
    title: 'Less than',
    value: 'lt',
  },
  gte: {
    title: 'Greater than or equal to',
    value: 'gte',
  },
  lte: {
    title: 'Less than or equal to',
    value: 'lte',
  },
  none: {
    title: '',
    value: '',
  },
};

/**
 *
 * @param {Object} props
 * @param {Function} props.onChange
 * @param {String} props.value
 * @param {Comparator[]} props.comparators
 */
const ComparatorSelector = ({ onChange, value, comparators }) => {
  const changeHandler = useCallback((e) => {
    const newValue = e.target.value;

    onChange(newValue);
  }, [onChange]);

  // If our comparator is a `none` type, don't return any components
  if (value === '') {
    return null;
  }

  return (
    <div className="col-md-3">
      <select
        className="form-control"
        onChange={changeHandler}
        value={value}
      >
        {comparators.map((type) => (
          <option value={type.value} key={type.value}>
            {type.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default memo(ComparatorSelector);
