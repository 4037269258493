import React, { memo } from 'react';

import { NEGATE_MODES } from '../../../../global/Predicates/Negate';
import Filter, { createFilterChangeHandler } from '../Filter';
import ProductPicker from '../ValueComponents/ProductPicker';

/** @typedef {import('../../../../global/Predicates/Negate').NegateMode} NegateMode */
/** @typedef {import('../../../../global/Predicates/TypeSelector').Type} Type */
/** @typedef {import('../../../../global/Predicates/ComparatorSelector').Comparator} Comparator */
/** @typedef {import('../ValueComponents/ProductPicker').BasketValue} BasketValue */

const getDefaultNegate = () => ({ mode: NEGATE_MODES.no });
const getDefaultComparator = (comparators) => comparators[0].value;

/**
 *
 * @returns {BasketValue[]}
 */
const getDefaultValue = () => [];

export const valueMapper = ({
  id, value, ...rest
}) => ({
  ...(value && {
    value: value.map((basketValue) => ({
      id: basketValue.id,
      isVariant: basketValue.isVariant,
    })),
  }),
  ...rest,
});

/**
 *
 * @param {Object} props
 * @param {String} props.id
 * @param {Function} props.onChange
 * @param {BasketValue[]} props.value
 * @param {NegateMode} props.negate
 * @param {String} props.type
 * @param {Type[]} props.types
 * @param {String} props.comparator
 * @param {Comparator[]} props.comparators
 */
const PurchaseHistoryFilter = (props) => {
  const {
    type,
    types,
    value = getDefaultValue(),
    negate = getDefaultNegate(),
    comparators,
    onChange,
    id,
  } = props;

  // Destructure these seperately because we need to know the `type` to get the defaults
  const {
    comparator = getDefaultComparator(comparators),
  } = props;

  const onFilterChange = createFilterChangeHandler({
    id, type, value, negate, comparator, onChange,
  });

  return (
    <Filter
      negate={negate}
      comparator={comparator}
      type={type}
      onChange={onFilterChange}
      types={types}
      comparators={comparators}
      value={(
        <ProductPicker value={value} onChange={onFilterChange} />
      )}
    />
  );
};

export default memo(PurchaseHistoryFilter);
