import React, {
  memo, useState, useCallback, useEffect, useMemo,
} from 'react';
import { useToasts } from 'react-toast-notifications';

export const options = [
  { val: 60, title: '1 Hour' },
  { val: 120, title: '2 Hours' },
  { val: 180, title: '3 Hours' },
  { val: 240, title: '4 Hours' },
  { val: 300, title: '5 Hours' },
  { val: 600, title: '10 Hours' },
  { val: 960, title: '16 Hours' },
  { val: 1440, title: '24 Hours' },
  { val: 2880, title: '2 Days' },
  { val: 4320, title: '3 Days' },
  { val: 5760, title: '4 Days' },
  { val: 7200, title: '5 Days' },
  { val: 8640, title: '6 Days' },
  { val: 10080, title: '7 Days' },
  { val: 20160, title: '14 Days' },
  { val: 30240, title: '21 Days' },
  { val: 40320, title: '28 Days' },
  { val: 43200, title: '30 Days' },
  { val: 64800, title: '45 Days' },
  { val: 86400, title: '60 Days' },
  { val: 108000, title: '75 Days' },
  { val: 129600, title: '90 Days' },
];
/** @enum {String} */
export const TIME_UNIT = {
  days: 'days',
  hours: 'hours',
};
export const TIME_UNIT_BOUNDARY = {
  [TIME_UNIT.days]: 200,
  [TIME_UNIT.hours]: 4800,
};

const minutesInADay = 1440;
const minutesInAnHour = 60;
const customSelectValue = 1;

/**
 *
 * @param {Object} props
 * @param {TIME_UNIT} [props.customValueUnit]
 * @param {String} [props.className]
 * @param {Boolean} [props.disabled]
 * @param {Function} props.handleChange
 * @param {String} props.name
 * @param {*} props.value
 * @param {String} [props.id]
 * @returns
 */
function MessageIntervalSelect({
  customValueUnit = TIME_UNIT.days,
  className = '',
  disabled = false,
  handleChange,
  name,
  value,
  id = undefined,
}) {
  const [isCustom, setIsCustom] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { addToast } = useToasts();
  const minutesPerUnit = useMemo(
    () => (customValueUnit === TIME_UNIT.days ? minutesInADay : minutesInAnHour),
    [customValueUnit],
  );
  const unitBoundary = useMemo(() => TIME_UNIT_BOUNDARY[customValueUnit], [
    customValueUnit,
  ]);

  useEffect(() => {
    if (!options.find((o) => o.val === parseInt(value, 10))) {
      setIsCustom(true);
      setInputValue(Math.floor(value / minutesPerUnit).toString() || '');
    } else {
      setIsCustom(false);
    }
  }, [minutesPerUnit, value]);

  const handleOnChange = useCallback(
    (e) => {
      if (e.target.value === customSelectValue) {
        setIsCustom(true);
      } else {
        handleChange(e);
      }
    },
    [handleChange],
  );

  const handleInputChange = useCallback(
    (e) => {
      setInputValue(e.target.value);
    },
    [],
  );

  const handleInputBlur = useCallback(
    (e) => {
      const eventInputValue = e.target.value;

      if (eventInputValue > 0 && eventInputValue < unitBoundary) {
        handleChange({
          target: {
            value: eventInputValue * minutesPerUnit,
          },
        });
      } else {
        addToast(`Please enter a value between 0 and ${unitBoundary}`, {
          appearance: 'warning',
          autoDismiss: true,
        });

        setInputValue('');
      }
    },
    [addToast, handleChange, minutesPerUnit, unitBoundary],
  );

  return (
    <div className={`message-interval-wrapper ${className}`}>
      <div className="input-wrapper">
        <select
          className="form-control message-interval-select"
          disabled={disabled}
          name={name}
          onChange={handleOnChange}
          value={value}
          id={id}
        >
          <option key="custom" value={customSelectValue}>
            Custom
          </option>

          {options.map((opt) => (
            <option key={opt.val} value={opt.val}>
              {opt.title}
            </option>
          ))}
        </select>

        <div className="icon" />
      </div>

      {isCustom && !disabled && (
        <div className="input-wrapper">
          <input
            className="form-control message-interval-select"
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            placeholder={`Enter Value (1-${unitBoundary})`}
            required
            type="number"
            value={inputValue}
          />

          <div className="days-label">
            {customValueUnit === TIME_UNIT.days ? 'Days' : 'Hours'}
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(MessageIntervalSelect);
