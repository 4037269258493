/* eslint-disable no-control-regex */
import { CUSTOMER_MESSAGE_PLACEHOLDERS } from '../constants';
import { MSG_TYPE_MMS, MSG_TYPE_SMS } from './messageType';

const MAX_CHAR_COUNT_PER_SEGMENT = 160;
const MMS_MAX_CHAR_COUNT_PER_SEGMENT = 1600;
const MAX_UNICODE_CHAR_COUNT_PER_SEGMENT = 70;

const hasUnicodeChar = (str) => /[^\u0000-\u00ff]/.test(str);

/**
 * @typedef {Object} SegmentInfo
 * @property {Number} segmentCount
 * @property {Number} charCountPerSegment
 * @property {String} segmentSuffix
 */

/**
 * Get segment related info by message text and type
 *
 * @param {String} text
 * @param {String} msgType Either one of "MMS" or "SMS"
 * @returns {SegmentInfo}
 */
export const countSegmentsInMessage = (text, msgType = MSG_TYPE_SMS) => {
  let charCountPerSegment = MAX_CHAR_COUNT_PER_SEGMENT;

  const length = text ? text.length : 0;

  if (length > 0 && hasUnicodeChar(text)) {
    charCountPerSegment = MAX_UNICODE_CHAR_COUNT_PER_SEGMENT;
  }

  let segmentCount = Math.ceil(text.length / charCountPerSegment);
  let segmentSuffix = `segment${segmentCount > 1 ? 's' : ''}`;

  if (msgType === MSG_TYPE_MMS) {
    charCountPerSegment = MMS_MAX_CHAR_COUNT_PER_SEGMENT;
    segmentCount = Math.ceil(text.length / charCountPerSegment);
    segmentSuffix = MSG_TYPE_MMS;

    const isMMSWithImageAndNoText = segmentCount === 0;

    if (isMMSWithImageAndNoText) {
      segmentCount = 1;
    }
  }

  return { segmentCount, charCountPerSegment, segmentSuffix };
};

const getMerchantPermalinkPath = (text) => (typeof text === 'string'
  ? text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
  : text);

/**
 *
 * @param {Object} merchant
 * @param {String} merchant.company
 * @param {String} message
 * @param {String} [productNames]
 * @param {String} [shortenedPermalink]
 * @returns
 */
export async function replacePlaceholderWithMockData(merchant, message, productNames, shortenedPermalink) {
  const { company } = merchant;
  const link = shortenedPermalink || `https://${getMerchantPermalinkPath(
    company,
  )}.blp.ai/p/xxxxxxxxxx`;

  return message
    .replace(`{${CUSTOMER_MESSAGE_PLACEHOLDERS.first_name}}`, 'Henry')
    .replace(`{${CUSTOMER_MESSAGE_PLACEHOLDERS.brand_name}}`, company)
    .replace(`{${CUSTOMER_MESSAGE_PLACEHOLDERS.recommended_products}}`, productNames || CUSTOMER_MESSAGE_PLACEHOLDERS.recommended_products)
    .replace(`{${CUSTOMER_MESSAGE_PLACEHOLDERS.replenishment_products}}`, productNames || CUSTOMER_MESSAGE_PLACEHOLDERS.replenishment_products)
    .replace(
      `{${CUSTOMER_MESSAGE_PLACEHOLDERS.link_to_checkout}}`,
      link,
    );
}
