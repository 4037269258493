import React, { memo, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useSelector } from 'react-redux';

import { PageBuilder } from '../../components/global/PageBuilder';
import { SEO } from '../../components/global/SEO';
import SmartLinkForm from '../../components/forms/SmartLinkForm';
import { getSmartlinks } from '../../queries/SmartLinks';
import { upsertSmartLink } from '../../mutations/SmartLinks';

/**
 * @typedef {import('../../redux/reducers').RootState} RootState
 * @typedef {import('../../utils/types').SmartLinksResult} SmartLinksResult
 * @typedef {import('../../utils/types').SmartLink} SmartLink
 */

const SmartLinksWizard = ({ match }) => {
  const providedId = match.params.id;
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const history = useHistory();

  const [smartLinkData, setSmartLinkData] = useState({});
  const [checkedCacheForSmartLink, setCheckedCacheForSmartLink] = useState(false);

  const { merchantData } = useSelector(
    (/** @type RootState} */ reducerState) => reducerState.MerchantReducer,
  );

  /**
   * @param {SmartLink[]} smartLinks
   * @param {Number} idToFind
   * @returns {Void}
   */
  const findAndSetSmartLinkData = (smartLinks, idToFind) => {
    const currentSmartLink = smartLinks.find(
      (smartLink) => `${smartLink.id}` === `${idToFind}`,
    );
    if (currentSmartLink) {
      setSmartLinkData(currentSmartLink);
    }
  };

  useQuery(['smartLinks', checkedCacheForSmartLink, providedId], getSmartlinks,
    {
      enabled: checkedCacheForSmartLink && !!providedId,
      onSuccess: (data) => {
        findAndSetSmartLinkData(data.smartLinks, providedId);
      },
    });

  const upsertSmartLinkMutation = useMutation(upsertSmartLink, {
    onError: (/** @type {Error} */ error) => {
      addToast(
        `Error occured while saving the Smart Link. ${error.message || ''}`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    },
    onSuccess: () => {
      addToast(
        'The Smart Link has been saved',
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );

      history.push('/smart-links');
    },
  });

  useEffect(() => {
    if (providedId) {
      const cachedSmartLinks = /** @type {SmartLinksResult} */ (queryClient.getQueryData('smartLinks'));
      if (cachedSmartLinks?.smartLinks) {
        findAndSetSmartLinkData(cachedSmartLinks.smartLinks, providedId);
      }

      setCheckedCacheForSmartLink(true);
    }
  }, [providedId, queryClient]);

  const onSubmitHandler = async (formData) => {
    await upsertSmartLinkMutation.mutateAsync({
      ...formData,
      ...(!!providedId && { id: providedId }),
    });
  };

  return (
    <PageBuilder>
      <SEO title="Smart Links | Blueprint" />
      <div className="a-view smart-links">
        <div className="container">
          <div className="a-view single-smart-link">
            <div className="container">
              <div className="top-bar">
                <h2 className="title">
                  <Link to="/smart-links">
                    Smart Links
                  </Link>
                  {' '}
                  {'>'}
                  {' '}
                  {providedId ? 'Update' : 'Create a new'}
                  {' '}
                  Smart Link
                </h2>
              </div>
              <SmartLinkForm
                smartLink={smartLinkData}
                merchantData={merchantData}
                onSubmit={onSubmitHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(SmartLinksWizard);
