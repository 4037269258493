import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import IntegrationStatusCard from '../../components/main/IntegrationsHub/IntegrationStatusCard';
import { IntegrationActionCreators } from '../../redux/actions/integrations';
import { APIKeysActionCreators } from '../../redux/actions/apiKeys';
import { useFeatureFlags } from '../../contexts';

const { getMerchantIntegrations } = IntegrationActionCreators;
const { getMerchantAPIKeys } = APIKeysActionCreators;

const IntegrationsHubPage = () => {
  const dispatch = useDispatch();
  const { isFeatureEnabled } = useFeatureFlags();

  const [isZendeskEnabled, setIsZendeskEnabled] = useState(false);
  const [isGorgiasEnabled, setIsGorgiasEnabled] = useState(false);
  const [isSmartrrEnabled, setIsSmartrrEnabled] = useState(false);

  const {
    integrationsLoading,
    integrationsLoaded,
    integrationsData,
    apiKeysLoading,
    apiKeysLoaded,
    apiKeysData,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */state) => (
      { ...state.IntegrationsReducer, ...state.APIKeysReducer }
    ),
  );

  useEffect(() => {
    const updateZendeskPageState = async () => {
      const isEnabled = await isFeatureEnabled(
        'zendesk',
        false,
      );

      setIsZendeskEnabled(isEnabled);
    };

    updateZendeskPageState();
  }, [isFeatureEnabled]);

  useEffect(() => {
    const updateGorgiasPageState = async () => {
      const isEnabled = await isFeatureEnabled(
        'gorgias',
        false,
      );

      setIsGorgiasEnabled(isEnabled);
    };

    updateGorgiasPageState();
  }, [isFeatureEnabled]);

  useEffect(() => {
    const updateSmartrrPageState = async () => {
      const isEnabled = await isFeatureEnabled(
        'smartrr',
        false,
      );

      setIsSmartrrEnabled(isEnabled);
    };

    updateSmartrrPageState();
  }, [isFeatureEnabled]);

  useEffect(() => {
    if (!integrationsLoaded && !integrationsLoading) {
      dispatch(getMerchantIntegrations());
    }
  }, [dispatch, integrationsLoaded, integrationsLoading]);

  useEffect(() => {
    if (!apiKeysLoaded && !apiKeysLoading) {
      dispatch(getMerchantAPIKeys());
    }
  }, [dispatch, apiKeysLoaded, apiKeysLoading]);

  return (
    <PageBuilder>
      <SEO title="Integrations | Blueprint" />
      <div className="a-view integrations-hub">
        <div className="container">
          <div className="top-bar">
            <h2 className="title">Integrations</h2>
          </div>

          <h3>Available Integrations</h3>

          {integrationsLoaded ? (
            <div className="card-deck">
              <IntegrationStatusCard
                type="klaviyo"
                active={integrationsData?.klaviyo?.active}
              />
              <IntegrationStatusCard
                type="recharge"
                active={integrationsData?.recharge?.active}
              />
              <div className="w-100 d-lg-none mb-3">{/* wrap every 2 on < lg */}</div>
              <IntegrationStatusCard
                type="privy"
                active={integrationsData?.privy?.active}
              />
              <div className="w-100 d-none d-lg-block d-xl-none mb-3">{/* wrap every 3 on lg  */}</div>
              { isZendeskEnabled && (
              <IntegrationStatusCard
                type="zendesk"
                active={integrationsData?.zendesk?.active}
              />
              ) }
              <div className="w-100 d-lg-none mb-3">{/* wrap every 2 on < md */}</div>
              { isGorgiasEnabled && (
              <IntegrationStatusCard
                type="gorgias"
                active={integrationsData?.gorgias?.active}
              />
              ) }
              <div className="w-100 d-none d-xl-block mb-3">{/*  wrap every 5 on xl */}</div>
              <IntegrationStatusCard
                type="bold"
                active={integrationsData?.bold?.active}
              />
              <div className="w-100 d-lg-none mb-3">{/* wrap every 2 on < lg */}</div>
              <div className="w-100 d-none d-lg-block d-xl-none mb-3">{/* wrap every 3 on lg  */}</div>
              { isSmartrrEnabled && (
              <IntegrationStatusCard
                type="smartrr"
                active={integrationsData?.smartrr?.active}
              />
              ) }
              <IntegrationStatusCard type="custom-api" active={apiKeysData.length > 0} />
              <div className="w-100 d-lg-none mb-3">{/* wrap every 2 on < md */}</div>
            </div>
          ) : (
            <span>loading...</span>
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(IntegrationsHubPage);
