import React, {
  memo, useState, useCallback,
} from 'react';
import {
  useQuery,
} from 'react-query';

import { getListGroups } from '../../redux/actions/GroupMessagingAPI';

/**
 * @typedef List
 * @property {Number} id
 * @property {String} name
 */

/**
 *
 * @param {Object} props
 * @param {Function} props.onClose
 * @param {Function} props.onChange
 * @param {Number[]} props.value
 */
const ListSelector = ({ onClose, onChange, value = [] }) => {
  const {
    isLoading, isError, data: lists,
  } = useQuery('listGroups', getListGroups);
  const [selectedLists, setSelectedLists] = useState(value);

  const selectList = useCallback((listId) => {
    setSelectedLists((currentSelectedListIds) => [...currentSelectedListIds, listId]);
  }, []);

  const deselectList = useCallback((listId) => {
    setSelectedLists((currentSelectedListIds) => currentSelectedListIds.filter(
      (selectedListId) => selectedListId !== listId,
    ));
  }, []);

  const handleSave = useCallback(() => {
    onChange(lists.filter((list) => selectedLists.includes(list.id)));
    onClose();
  }, [onChange, lists, onClose, selectedLists]);

  return (
    <>
      <div className="product-list-container mb-3">
        {isError && (
          <span className="mb-3 ml-3">Could not load lists, please try again.</span>
        )}
        {!isLoading && !isError ? (
          <div className="list-group">
            {lists.map((list) => {
              const checked = selectedLists.some((listId) => listId === list.id);
              return (
                <div key={list.id}>
                  <label className="list-group-item list-group-item-action" htmlFor={`list-item-${list.id}`}>
                    <div className="d-flex w-100 justify-content-between">
                      <div className="d-flex justify-content-start align-items-center">
                        <input
                          id={`list-item-${list.id}`}
                          type="checkbox"
                          className="check-box mr-3"
                          checked={checked}
                          onChange={() => {
                            if (checked) {
                              deselectList(list.id);
                            } else {
                              selectList(list.id);
                            }
                          }}
                        />
                        {list.name}
                      </div>
                    </div>
                  </label>
                </div>
              );
            })}
          </div>
        )
          : <span className="mb-3 ml-3">Loading Lists...</span>}
      </div>
      <div className="row">
        <div className="col float-right mr-3">
          <button className="btn btn-primary btn-sm float-right ml-2" onClick={handleSave} type="button">
            Select
          </button>
          <button className="btn btn-outline-secondary btn-sm float-right" onClick={() => { onClose(); }} type="button">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default memo(ListSelector);
