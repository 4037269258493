import React, { memo, useCallback, useState } from 'react';

const AddGroup = ({ groups, onSelectGroup }) => {
  const [selected, setSelected] = useState(0);

  const handleGroupSelection = useCallback(
    (event) => {
      onSelectGroup(parseInt(event.target.value, 10));
      setSelected(0);
    },
    [onSelectGroup, setSelected],
  );

  return (
    <select
      className="form-control btn"
      placeholder="Add to list"
      onChange={handleGroupSelection}
      value={selected}
    >
      <option disabled value={0}>
        Add to list
      </option>

      {groups.map((g) => (
        <option key={`select-group-${g.id}`} value={g.id}>
          {g.name}
        </option>
      ))}
    </select>
  );
};

export default memo(AddGroup);
