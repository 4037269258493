import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { useParams, Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import EditList from '../../components/main/GroupMessaging/EditList/EditList';

import {
  getGroupDetails,
  getGroupCustomers,
  getAllOptedInCustomers,
} from '../../redux/actions/GroupMessagingAPI';
import LoadingMessage from '../../components/main/GroupMessaging/Loading';

const GroupList = () => {
  /** @type {import('./GroupMessaging').Group} */
  const initialGroup = {
    id: undefined,
    name: '',
    description: '',
    readOnly: undefined,
    type: undefined,
    customers: [],
  };

  const [group, setGroup] = useState(initialGroup);
  const [allCustomers, setAllCustomers] = useState([]);
  const { groupId } = useParams();
  const { addToast } = useToasts();

  const fetchGroupDetails = useCallback(async () => {
    try {
      const groupDetails = await getGroupDetails(groupId);

      const customers = await getGroupCustomers(groupId);

      setGroup({
        ...groupDetails,
        customers,
      });
    } catch (e) {
      addToast('The list details could not be retrieved.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, groupId]);

  const fetchAllSubscribers = useCallback(async () => {
    const allOptedInCustomers = await getAllOptedInCustomers();

    setAllCustomers(allOptedInCustomers);
  }, []);

  useEffect(() => {
    try {
      fetchAllSubscribers();
    } catch (e) {
      addToast('The list subscribers could not be retrieved.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    if (groupId) {
      fetchGroupDetails();
    } else {
      setGroup({
        id: undefined,
        name: '',
        description: '',
        readOnly: 0,
        type: 0,
        customers: [],
      });
    }
  }, [addToast, fetchAllSubscribers, fetchGroupDetails, groupId]);

  return (
    <PageBuilder>
      <SEO title="Segmentation Test | Blueprint" />
      <div className="a-view group-segment">
        <div className="container">
          <div className="top-bar">
            <h2 className="title">
              <Link to="/group-messaging">Group Messaging</Link>
              {' '}
              {'>'}
              {' '}
              {groupId ? 'Edit' : 'Create'}
              {' '}
              List
            </h2>
          </div>

          <LoadingMessage loading={!group.customers} />

          {group.customers && (
            <EditList allCustomers={allCustomers} group={group} />
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(GroupList);
