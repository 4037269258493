import React, {
  memo,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';

import { BPToggle } from '../../../main/BPToggle';
import Loading from '../Loading';
import { SKIPPABLE_INTEGRATIONS } from '../../../main/AutoMessages/helpers';
import { MerchantActionCreators } from '../../../../redux/actions/merchant';

const {
  updateMerchantsChatbotSettings: updateSubscriptionSettingsAction,
} = MerchantActionCreators;

/** @enum {String} */
const DelayChargeUnits = {
  DAY: 'day',
  DAYS: 'day',
  WEEK: 'week',
  WEEKS: 'week',
  MONTH: 'month',
  MONTHS: 'month',
};

/**
 * @param {DelayChargeUnits} unit
 * @param {Number} value
 */
const getNextChargeAsDays = (unit, value) => {
  switch (unit) {
    case DelayChargeUnits.DAY:
    case DelayChargeUnits.DAYS: {
      return value;
    }
    case DelayChargeUnits.WEEK:
    case DelayChargeUnits.WEEKS: {
      return value * 7;
    }
    case DelayChargeUnits.MONTH:
    case DelayChargeUnits.MONTHS: {
      return value * 30;
    }
    default: {
      return value;
    }
  }
};

const SubscriptionsForm = ({ chatbotSettings, isLoading, merchantIntegrations }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [isReplyToDelayEnabled, setIsReplyToDelayEnabled] = useState(chatbotSettings.delayChargeEnabled);
  const [isReplyToSkipEnabled, setIsReplyToSkipEnabled] = useState(chatbotSettings.skipChargeEnabled);
  const [delayTimeFrame, setDelayTimeFrame] = useState(
    getNextChargeAsDays(
      chatbotSettings?.delayChargeConfig?.defaultDelayChargeSpan?.nextChargeUnit,
      chatbotSettings?.delayChargeConfig?.defaultDelayChargeSpan?.nextCharge,
    ) || 7,
  );

  const toggleReplyToDelayEnabled = () => {
    setIsReplyToDelayEnabled(!isReplyToDelayEnabled);
  };

  const toggleReplyToSkipEnabled = () => {
    setIsReplyToSkipEnabled(!isReplyToSkipEnabled);
  };

  const handleDelayChange = useCallback(({ target: { value } }) => {
    setDelayTimeFrame(value);
  }, [setDelayTimeFrame]);

  const isSettingsAvailable = useMemo(() => Object.values(merchantIntegrations)
    .some((integration) => SKIPPABLE_INTEGRATIONS.includes(integration.type) && integration.active),
  [merchantIntegrations]);

  const updateSubscriptionSettings = useCallback(async () => {
    if (!isLoading) {
      setIsSaving(true);
      await dispatch(
        updateSubscriptionSettingsAction({
          isReplyToSkipEnabled,
          isReplyToDelayEnabled,
          defaultDelayChargeSpan: {
            nextChargeUnit: DelayChargeUnits.DAY,
            nextCharge: delayTimeFrame,
          },
        }),
      );
      setIsSaving(false);
    }
  }, [
    isLoading,
    dispatch,
    isReplyToSkipEnabled,
    isReplyToDelayEnabled,
    delayTimeFrame,
  ]);

  const handleIntegrationClick = useCallback((e) => {
    e.preventDefault();
    history.push('/integrations');
  }, [history]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="subscription-settings">

        {!isSettingsAvailable && (
          <p>
            No integrations currently enabled. Please
            {' '}
            <a onClick={handleIntegrationClick} href="/integrations">go here to connect</a>
          </p>
        )}

        <div className="toggle">
          <BPToggle
            active={isReplyToSkipEnabled && isSettingsAvailable}
            handleClick={toggleReplyToSkipEnabled}
            disabled={!isSettingsAvailable}
          />
          {' '}
          <span>Enable reply &apos;SKIP&apos; to skip next subscription charge</span>
        </div>
        <div className="toggle">
          <BPToggle
            active={isReplyToDelayEnabled && isSettingsAvailable}
            handleClick={toggleReplyToDelayEnabled}
            disabled={!isSettingsAvailable}
          />
          {' '}
          <span>Enable reply &apos;DELAY&apos; to delay next subscription charge</span>
        </div>
        <div className="input">
          <label htmlFor="delay-time-frame">Delay time frame</label>
          <input
            id="delay-time-frame"
            className="form-control message-interval-select"
            onChange={handleDelayChange}
            placeholder="Enter Value"
            defaultValue={7}
            required
            type="number"
            value={delayTimeFrame}
            disabled={!isSettingsAvailable}
          />
          {' '}
          <span>Days</span>
        </div>

        <div className="actions">
          <button
            className="btn btn-primary"
            disabled={isLoading || isSaving || !isSettingsAvailable}
            onClick={updateSubscriptionSettings}
            type="button"
          >
            {isSaving
              ? 'Saving'
              : 'Save Changes'}
          </button>
        </div>
      </div>
    </>
  );
};

export default memo(SubscriptionsForm);
