import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import cloneDeep from 'lodash.clonedeep';
import { DemoPhone } from '../DemoPhone';
import { MerchantActionCreators } from '../../../redux/actions/merchant';
import { getMerchantIntegrations } from '../../../redux/actions/AutomatedMessagingAPI';
import {
  INTEGRATION_RECHARGE,
  getTemplatesByType,
  getDemoMessages,
  mapMessageTemplateToSave,
  filterForType,
} from './helpers';
import MessageTemplateEditor from '../MessageTemplateEditor';
import {
  defaultMessageTemplates,
  subscriptions,
  AUTO_MESSAGE_TYPES,
} from './defaults';
import { isValidInterval } from '../../../utils/messageInteval';
import Subscriptions from '../AutoMessageSubscriptions';
import { useFeatureFlags } from '../../../contexts';

/** @typedef {import('../../../redux/reducers').RootState} RootState */

const {
  getMerchantData: getMerchantDataAction,
  updateAutomatedMessages: updateAutomatedMessagesAction,
} = MerchantActionCreators;

const AutoMessages = () => {
  const [merchantIntegrations, setMerchantIntegrations] = useState({});
  const [hasFetchedIntegrations, setHasFetchedIntegrations] = useState(false);
  const [enableAutomationRecharge, setEnableAutomationRecharge] = useState(
    false,
  );
  const { isFeatureEnabled } = useFeatureFlags();

  const { addToast } = useToasts();
  const {
    merchantErr,
    merchantLoaded,
    merchantLoading,
    merchantData,
    automatedMessages: messageTemplates,
    updateAutomatedMessagesLoading: updateMessageTemplatesLoading,
  } = useSelector((/** @type {RootState} */ state) => state.MerchantReducer);
  const dispatch = useDispatch();

  const handleRechargeFeatureFlag = useCallback(async () => {
    const flagEnabled = await isFeatureEnabled(
      'automationRecharge',
      false,
    );

    setEnableAutomationRecharge(flagEnabled);
  }, [isFeatureEnabled]);

  useEffect(() => {
    if (merchantData.id) {
      handleRechargeFeatureFlag();
    }
  }, [handleRechargeFeatureFlag, merchantData]);

  const segmentsEnabled = useMemo(() => !!(
    merchantIntegrations[INTEGRATION_RECHARGE]
      && merchantIntegrations[INTEGRATION_RECHARGE].active
  ), [merchantIntegrations]);

  const [automatedMessageTemplates, setAutomatedMessageTemplates] = useState([
    ...defaultMessageTemplates,
    ...subscriptions,
  ]);

  useEffect(() => {
    setAutomatedMessageTemplates(
      (currentTemplates) => currentTemplates.map((defaultMessageTemplate) => {
        const messageTemplate = messageTemplates.find(
          ({ messageType }) => messageType === defaultMessageTemplate.messageType,
        ) || { messageContent: null, messageInterval: null, active: null };
        const messageContent = messageTemplate.messageContent !== null
          ? messageTemplate.messageContent
          : defaultMessageTemplate.messageContent;
        const messageInterval = messageTemplate.messageInterval !== null
          ? messageTemplate.messageInterval
          : defaultMessageTemplate.messageInterval;
        const active = messageTemplate.active !== null
          ? messageTemplate.active
          : defaultMessageTemplate.active;
        return {
          ...defaultMessageTemplate,
          ...messageTemplate,
          messageContent,
          messageInterval,
          active,
        };
      }),
    );
  }, [messageTemplates]);

  const getMerchantData = useCallback(() => {
    dispatch(getMerchantDataAction());
  }, [dispatch]);

  const updateMessageTemplates = useCallback(() => {
    if (!(updateMessageTemplatesLoading || merchantLoading)) {
      dispatch(
        updateAutomatedMessagesAction({
          messageTemplates: automatedMessageTemplates.map(
            // @ts-ignore
            mapMessageTemplateToSave,
          ),
        }),
      );
    }
  }, [
    automatedMessageTemplates,
    dispatch,
    merchantLoading,
    updateMessageTemplatesLoading,
  ]);

  useEffect(() => {
    if (!merchantLoaded) {
      getMerchantData();
    }
  }, [merchantLoaded, getMerchantData]);

  useEffect(() => {
    if (merchantErr) {
      addToast('Error getting messages', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, merchantErr]);

  const handleFetchMerchantIntegrations = useCallback(async () => {
    try {
      const integrations = await getMerchantIntegrations();

      setMerchantIntegrations(integrations);
    } catch (e) {
      addToast('The Merchants integrations could not be retrieved.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (merchantData.id && !hasFetchedIntegrations) {
      setHasFetchedIntegrations(true);
      handleFetchMerchantIntegrations();
    }
  }, [merchantData, hasFetchedIntegrations, handleFetchMerchantIntegrations]);

  const setMessageTemplate = useCallback(
    (editedMessageTemplate) => {
      const updatedMessageTemplates = cloneDeep(automatedMessageTemplates);

      const existingMessageTemplate = automatedMessageTemplates.find(
        (mt) => mt.messageType === editedMessageTemplate.messageType,
      );

      const editedMessageTemplateIndex = automatedMessageTemplates.findIndex(
        (mt) => mt.messageType === editedMessageTemplate.messageType,
      );

      updatedMessageTemplates.splice(editedMessageTemplateIndex, 1);

      updatedMessageTemplates.splice(editedMessageTemplateIndex, 0, {
        ...existingMessageTemplate,
        ...editedMessageTemplate,
      });

      setAutomatedMessageTemplates(updatedMessageTemplates);
    },
    [automatedMessageTemplates],
  );

  const disableSave = useMemo(() => {
    const currentTemplates = getTemplatesByType(automatedMessageTemplates);
    const previousTemplates = getTemplatesByType(messageTemplates);

    const invalidTemplates = Object.keys(previousTemplates).find((key) => {
      if (typeof previousTemplates[key] === 'undefined') {
        return true;
      }

      return (
        !!previousTemplates[key].messageInterval
        && !isValidInterval(currentTemplates[key].messageInterval)
      );
    });

    if (invalidTemplates) {
      return true;
    }

    return Object.keys(previousTemplates)
      .map((key) => {
        if (typeof previousTemplates[key] === 'undefined') {
          return true;
        }
        return (
          previousTemplates[key].messageContent
            === currentTemplates[key].messageContent
          && previousTemplates[key].messageInterval
            === currentTemplates[key].messageInterval
          && previousTemplates[key].active === currentTemplates[key].active
        );
      })
      .every(Boolean);
  }, [automatedMessageTemplates, messageTemplates]);

  const filteredDefaultMessageTemplates = automatedMessageTemplates.filter(
    filterForType(AUTO_MESSAGE_TYPES.DEFAULT),
  );
  const filteredSubscriptionMessageTemplates = automatedMessageTemplates.filter(
    filterForType(AUTO_MESSAGE_TYPES.SUBSCRIPTION),
  );

  return (
    <div className="row">
      <div className="col-md-8">
        {filteredDefaultMessageTemplates.map((automatedMessage) => (
          <MessageTemplateEditor
            id="defaultMessageTemplateEditor"
            setMessageTemplate={setMessageTemplate}
            key={automatedMessage.messageType}
            {...automatedMessage}
          />
        ))}

        {enableAutomationRecharge && (
          <Subscriptions enabled={segmentsEnabled}>
            {filteredSubscriptionMessageTemplates.map((automatedMessage) => (
              <>
                <MessageTemplateEditor
                  id="rechargeMessageTemplateEditor"
                  setMessageTemplate={setMessageTemplate}
                  enabled={segmentsEnabled}
                  key={automatedMessage.messageType}
                  {...automatedMessage}
                />
              </>
            ))}
          </Subscriptions>
        )}
        <div className="actions">
          <button
            className="btn btn-primary"
            disabled={disableSave || merchantLoading}
            onClick={updateMessageTemplates}
            type="button"
          >
            {updateMessageTemplatesLoading || merchantLoading
              ? 'Saving'
              : 'Save Changes'}
          </button>
        </div>
      </div>
      <div className="col-md-3 offset-md-1">
        <DemoPhone
          messages={getDemoMessages(filteredDefaultMessageTemplates)}
        />
        {enableAutomationRecharge && (
          <DemoPhone
            messages={getDemoMessages(filteredSubscriptionMessageTemplates)}
          />
        )}

      </div>
    </div>
  );
};

export default memo(AutoMessages);
