import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { useToasts } from 'react-toast-notifications';
import Groups from './Groups';
import AddGroup from './AddGroup';
import {
  getGroups,
  getCustomerGroups,
  addCustomerToList,
} from '../../../../redux/actions/GroupMessagingAPI';

const CustomerMessageGroups = ({ customerPhoneId }) => {
  const [groups, setGroups] = useState(/** @type {any[]} */ ([]));
  const [filteredGroups, setFilteredGroups] = useState(/** @type {any[]} */ ([]));
  const [customerLists, setCustomerLists] = useState(/** @type {any[]} */ ([]));
  const [customerSegments, setCustomerSegments] = useState(/** @type {any[]} */ ([]));
  const [loading, setLoading] = useState(true);
  const { addToast } = useToasts();

  const fetchMessageGroups = useCallback(async () => {
    setLoading(true);

    const [allGroup, customerGroups] = await Promise.all([
      getGroups(),
      getCustomerGroups(customerPhoneId),
    ]);

    setGroups(allGroup);
    setCustomerLists(customerGroups.lists);
    setCustomerSegments(customerGroups.segments);

    setLoading(false);
  }, [customerPhoneId]);

  useEffect(() => {
    if (customerPhoneId) {
      fetchMessageGroups();
    }
  }, [customerPhoneId, fetchMessageGroups]);

  useEffect(() => {
    if (groups.length > 0) {
      const customerNotSubscribedTo = groups.filter(
        (g) => !(customerLists || []).find((cg) => cg.id === g.id),
      );

      setFilteredGroups(customerNotSubscribedTo);
    }
  }, [groups, customerLists]);

  const handleGroupSelection = useCallback(
    async (groupId) => {
      const customerNotSubscribedTo = filteredGroups.filter(
        (g) => parseInt(groupId, 10) !== g.id,
      );
      const addedGroup = filteredGroups.find((g) => g.id === parseInt(groupId, 10));

      if (addedGroup) {
        try {
          await addCustomerToList(groupId, customerPhoneId);

          setFilteredGroups(customerNotSubscribedTo);

          setCustomerLists([...customerLists, addedGroup]);

          addToast('The customer has been successfully added to the list.', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (e) {
          addToast('The customer could not be added to the list.', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    },
    [filteredGroups, customerPhoneId, customerLists, addToast],
  );

  return (
    <div className="customer-message-groups">
      {loading ? (
        <h4>Loading groups...</h4>
      ) : (
        <>
          <h4>Segments</h4>
          <Groups groups={customerSegments} />

          <h4>Lists</h4>
          <Groups groups={customerLists} />

          <AddGroup
            groups={filteredGroups}
            onSelectGroup={handleGroupSelection}
          />
        </>
      )}
    </div>
  );
};

export default memo(CustomerMessageGroups);
