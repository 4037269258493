import React from 'react';
import { PageBuilder } from '../components/global/PageBuilder';
import { SEO } from '../components/global/SEO';
import CrossSells from '../components/main/CrossSells';
import { types } from '../utils/crossSells';

export const CrossSellsPage = () => (
  <PageBuilder>
    <SEO title="Cross-sells | Blueprint" />
    <div className="a-view cross-sells">
      <div className="container">
        <CrossSells type={types.CROSS_SELLS} />
      </div>
    </div>
  </PageBuilder>
);

export default CrossSellsPage;
