import React, {
  memo, useCallback, useMemo, useEffect, useState,
} from 'react';
import { DateTime, Duration } from 'luxon';
import humanizeDuration from 'humanize-duration';
import { Link, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { PageBuilder } from '../../components/global/PageBuilder';
import { SEO } from '../../components/global/SEO';
import GroupSubscribersActionBar from '../../components/main/GroupMessaging/GroupSubscribersActionBar';
import GroupConversation from '../../components/main/Conversation/GroupConversation';
import LoadingMessage from '../../components/main/GroupMessaging/Loading';
import SendGroupMessageModal from '../../components/main/GroupMessaging/SendGroupMessageModal';
import { CUSTOMER_MESSAGE_PLACEHOLDERS, GROUP_TYPE } from '../../constants';
import {
  getGroupDetails,
  getGroupCustomers,
  getGroupMessages,
  sendGroupMessage,
  scheduleGroupMessage,
  removeCustomerFromGroup,
} from '../../redux/actions/GroupMessagingAPI';
import { MerchantActionCreators } from '../../redux/actions/merchant';
import { MSG_TYPE_MMS, MSG_TYPE_SMS } from '../../utils/messageType';
import {
  MAX_CHAR_LIMIT_PER_MSG,
  MAX_NUMBER_OF_SUBSCRIBERS,
  MAX_NUMBER_OF_SUBSCRIBERS_V2,
} from '../../utils/settings';
import { cloneItem } from '../../components/main/GroupMessaging/EditSegment/helpers';
import {
  predicates,
} from '../../components/main/GroupMessaging/EditSegment/predicates';
import { useFeatureFlags } from '../../contexts';
import { SendTypes } from '../../components/main/GroupMessaging/SendGroupMessageModal/ModalContent';

/** @typedef {import('../../redux/reducers').RootState} RootState */
/** @typedef {import('packages/messaging/types').MessageGroupCustomer} MessageGroupCustomer */

/**
 * @typedef Predicate
 * @property {Predicate[]} predicates
 * @property {'and'|'or'} combinationMode
 */

/**
 * @typedef Group
 * @property {Number} [id]
 * @property {String} name
 * @property {String} description
 * @property {Number} [readOnly]
 * @property {Number} [type]
 * @property {Number} [count]
 * @property {Array<Object>} customers
 * @property {Predicate} [predicates]
 * @property {Array<Object>} [messages]
 */

/** @type {Group} */
export const initialGroup = {
  id: undefined,
  name: '',
  description: '',
  readOnly: undefined,
  type: undefined,
  count: undefined,
  customers: [],
  predicates: {
    combinationMode: 'and',
    predicates: [
      {
        combinationMode: 'or',
        predicates: [cloneItem(predicates[0])],
      },
    ],
  },
  messages: [],
};

const MANDATORY_MSG_PREFIX = '{brand_name}: ';

const getSendMessageError = (e) => {
  const internalServerErrorText = 'Internal Server Error';

  if (e?.data?.message && e?.data?.message !== internalServerErrorText) {
    return e.data.message;
  }

  if (typeof e.data === 'string' && e.data !== internalServerErrorText) {
    return e.data;
  }

  return 'The group message could not be sent';
};

const { getMerchantData: getMerchantDataAction } = MerchantActionCreators;

const scheduledGroupMessageMessageState = () => {
  const internals = {};

  const messageStartScheduled = 'Group message sending started.';
  const messageStartNow = 'Group message scheduled to send immediately.';
  const messageScheduled = 'has been successfully scheduled and';

  return {
    setScheduleType(type) {
      internals.type = type;
      return this;
    },
    setTotalMessagesNumber(totalMessagesNumber) {
      internals.messagesNumberText = `${totalMessagesNumber.toLocaleString()} message${totalMessagesNumber > 1 ? 's' : ''}`;
      return this;
    },
    setScheduleFrom(scheduleFrom) {
      if (scheduleFrom) {
        const timeDiff = DateTime.fromISO(scheduleFrom).diffNow().valueOf();
        if (timeDiff > 60000) {
          const humanDuration = humanizeDuration(timeDiff, { units: ['h', 'm'], round: true, delimiter: ' and ' });
          internals.scheduleFromText = `will be sent in ${humanDuration}`;
          return this;
        }
      }
      internals.scheduleFromText = 'will be sent immediately';
      return this;
    },
    setDurationEstimate(durationEstimate) {
      const duration = Duration.fromISOTime(durationEstimate);
      if (duration.as('minutes') > 1) {
        const humanDuration = humanizeDuration(duration.valueOf(), { units: ['h', 'm'], round: true, delimiter: ' and ' });
        internals.durationEstimateText = `and it will take around ${humanDuration} to complete`;
      }
      return this;
    },
    getMessage() {
      if (internals.type === SendTypes.SCHEDULED) {
        const messages = [
          messageStartScheduled,
          internals.messagesNumberText,
          messageScheduled,
          internals.scheduleFromText,
        ];
        if (internals.durationEstimateText) { messages.push(internals.durationEstimateText); }
        return messages.join(' ');
      }
      const messages = [
        messageStartNow,
      ];
      if (internals.durationEstimateText) { messages.push(internals.durationEstimateText); }
      return messages.join(' ');
    },
  };
};

const GroupMessaging = () => {
  const dispatch = useDispatch();

  const [group, setGroup] = useState(initialGroup);
  const [groupCustomers, setGroupCustomers] = useState(/** @type {MessageGroupCustomer[]} */([]));
  const [fetchingCustomers, setFetchingCustomers] = useState(false);
  const [fetchedCustomers, setFetchedCustomers] = useState(false);
  const [message, setMessage] = useState(MANDATORY_MSG_PREFIX);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [refreshCount, setRefreshCount] = useState(false);
  const [permalinkData, setPermalinkData] = useState(null);
  const [msgType, setMsgType] = useState(MSG_TYPE_SMS);
  const [mmsMediaURL, setMmsMediaURL] = useState(null);
  const [showMMSSection, setShowMMSSection] = useState(false);
  const [scheduled, setScheduled] = useState(SendTypes.NOW);
  const [scheduledTime, setScheduledTime] = useState('');
  const [scheduledGroupMessageIsActive, setScheduledGroupMessageIsActive] = useState(false);
  const { groupId } = useParams();
  const { addToast } = useToasts();
  // I don't know. - DI 15/05/21
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const merchantData = useSelector(
    (/** @type {RootState} */ state) => state.MerchantReducer.merchantData, shallowEqual,
  )
    || {};

  const getMerchantInfo = useCallback(() => {
    const needsToGetMerchantData = Object.keys(merchantData).length === 0;

    if (needsToGetMerchantData) {
      dispatch(getMerchantDataAction());
    }
  }, [dispatch, merchantData]);

  const fetchGroupDetails = useCallback(async () => {
    try {
      getMerchantInfo();
      const [groupDetails, messages] = await Promise.all([
        getGroupDetails(groupId),
        getGroupMessages(groupId),
      ]);

      setGroup({
        ...groupDetails,
        messages,
      });
    } catch (e) {
      addToast('The group details could not be retrieved.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, getMerchantInfo, groupId]);

  const handleFetchCustomers = useCallback(async () => {
    try {
      setFetchingCustomers(true);
      const customers = await getGroupCustomers(groupId);
      setGroupCustomers(customers);
      setFetchingCustomers(false);
      setFetchedCustomers(true);
    } catch (e) {
      addToast('The groups customers could not be retrieved.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [groupId, addToast]);

  const handleOnMsgTypeChange = ({ msgType: newMsgType, mediaURL = null }) => {
    setMsgType(newMsgType);
    setMmsMediaURL(mediaURL);
  };

  const addMMS = () => {
    if (mmsMediaURL) {
      setShowMMSSection(true);
    }
  };

  const handleMMSCancel = useCallback((showToast = true) => {
    setShowMMSSection(false);
    setMsgType(MSG_TYPE_SMS);
    setMmsMediaURL(null);

    if (showToast) {
      addToast('Switched to SMS', {
        appearance: 'info',
        autoDismiss: true,
      });
    }
  }, [addToast]);

  useEffect(() => {
    fetchGroupDetails();
  }, [fetchGroupDetails, groupId]);

  useEffect(() => {
    if (
      group.type === GROUP_TYPE.LIST
      && !fetchingCustomers
      && !fetchedCustomers
    ) {
      handleFetchCustomers();
    }
  }, [groupId, group, groupCustomers, fetchingCustomers, fetchedCustomers, handleFetchCustomers]);

  const handleModalClose = useCallback(() => {
    setConfirmModalOpen(false);
    setScheduled(SendTypes.NOW);
    setScheduledTime('');
  }, []);

  const handleOnDelete = useCallback(
    async (customerPhoneId) => {
      try {
        await removeCustomerFromGroup(groupId, customerPhoneId);

        setGroupCustomers(
          groupCustomers.filter((c) => c.customerPhoneId !== customerPhoneId),
        );

        addToast(
          'The subscriber has been successfully removed from the group.',
          {
            appearance: 'success',
            autoDismiss: true,
          },
        );
      } catch (e) {
        addToast('The subscriber could not be removed from the group.', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
    [addToast, groupCustomers, groupId],
  );

  const handleSend = useCallback(async () => {
    setConfirmModalOpen(true);
    setRefreshCount(true);
    if (group.type === GROUP_TYPE.SEGMENT && !fetchingCustomers) {
      await handleFetchCustomers();
      await fetchGroupDetails();
    }
    setRefreshCount(false);
  }, [group.type, fetchingCustomers, handleFetchCustomers, fetchGroupDetails]);

  const updateMessage = useCallback((newMessage) => {
    if (newMessage.length <= MAX_CHAR_LIMIT_PER_MSG && newMessage.startsWith(MANDATORY_MSG_PREFIX)) {
      setMessage(newMessage);
    }
  }, []);

  const handleMessageChange = useCallback(
    (e) => {
      updateMessage(e.target.value);
    },
    [updateMessage],
  );

  const { isFeatureEnabled } = useFeatureFlags();

  useEffect(() => {
    async function checkIsFeatureEnabled() {
      const isEnabled = await isFeatureEnabled(
        'groupmessagev2',
        false,
      );
      setScheduledGroupMessageIsActive(isEnabled);
    }
    checkIsFeatureEnabled();
  }, [isFeatureEnabled]);

  const handleSendMessage = useCallback(async () => {
    const maxNumberOfSubscribers = scheduledGroupMessageIsActive
      ? MAX_NUMBER_OF_SUBSCRIBERS_V2
      : MAX_NUMBER_OF_SUBSCRIBERS;

    if (
      group.type === GROUP_TYPE.LIST
      && (groupCustomers.length === 0
        || groupCustomers.length > maxNumberOfSubscribers)
    ) {
      addToast(
        `The group has an incorrect number of subscribers (minimum 1 and maximum ${maxNumberOfSubscribers} allowed).`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );

      return;
    }

    let groupMessageResponse;
    const isMMS = !!(msgType === MSG_TYPE_MMS && mmsMediaURL);

    try {
      if (scheduledGroupMessageIsActive) {
        const scheduledTimeUTC = DateTime.fromISO(scheduledTime).toUTC();
        groupMessageResponse = await scheduleGroupMessage(group.id, {
          content: message.trim(),
          permalinkData,
          mediaUrl: isMMS ? mmsMediaURL : null,
          scheduled,
          scheduledTime: (scheduledTimeUTC && scheduledTimeUTC.isValid)
            ? scheduledTimeUTC.toISO()
            : null,
        });
      } else {
        groupMessageResponse = await sendGroupMessage(group.id, {
          content: message.trim(),
          permalinkData,
          mediaUrl: isMMS ? mmsMediaURL : null,
        });
      }

      handleMMSCancel(false);
    } catch (e) {
      addToast(getSendMessageError(e), {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    } finally {
      handleModalClose();
    }

    try {
      setMessage(MANDATORY_MSG_PREFIX);

      const messages = await getGroupMessages(group.id);

      setGroup({
        ...group,
        messages,
      });

      if (scheduledGroupMessageIsActive) {
        const { scheduledFrom, durationEstimate, totalMessagesNumber } = groupMessageResponse;
        const toastMessage = scheduledGroupMessageMessageState()
          .setScheduleType(scheduled)
          .setTotalMessagesNumber(totalMessagesNumber)
          .setScheduleFrom(scheduledFrom)
          .setDurationEstimate(durationEstimate)
          .getMessage();
        addToast(toastMessage, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 8000,
        });
      } else {
        const { sentMessages, totalMessagesNumber } = groupMessageResponse;

        if (sentMessages !== totalMessagesNumber) {
          addToast(
            `The group message has been partially sent. \
          ${sentMessages}/${totalMessagesNumber} customer messages have been sent.`,
            {
              appearance: 'warning',
              autoDismiss: true,
            },
          );
        } else {
          addToast('The group message has been successfully sent.', {
            appearance: 'success',
            autoDismiss: true,
          });
        }
      }
    } catch (e) {
      addToast('The group details could not be retrieved.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [
    group,
    groupCustomers.length,
    msgType,
    mmsMediaURL,
    addToast,
    message,
    permalinkData,
    handleMMSCancel,
    scheduled,
    scheduledTime,
    scheduledGroupMessageIsActive,
    handleModalClose,
  ]);

  const updatePermalinkData = useCallback(
    (p) => {
      if (!p.products || p.products.length === 0) {
        setPermalinkData(null);

        return;
      }

      const hasPermalinkPlaceholder = message.includes(
        CUSTOMER_MESSAGE_PLACEHOLDERS.link_to_checkout,
      );

      if (!hasPermalinkPlaceholder) {
        const newText = `${message} {${CUSTOMER_MESSAGE_PLACEHOLDERS.link_to_checkout}}`;

        if (newText.length <= MAX_CHAR_LIMIT_PER_MSG) {
          setMessage(newText);

          setPermalinkData(p);
        }
      } else {
        setPermalinkData(p);
      }
    },
    [message],
  );

  useEffect(() => {
    const lastMessage = group && group.messages && group.messages[group.messages.length - 1];

    if (lastMessage) {
      const { sentCount, failedCount, totalMessageCount } = lastMessage;
      const lastMessagesStillSending = sentCount + failedCount < totalMessageCount;

      if (lastMessagesStillSending) {
        setTimeout(async () => {
          const messages = await getGroupMessages(group.id);

          setGroup({
            ...group,
            messages,
          });
        }, 10000);
      }
    }
  }, [group]);

  const customerCount = useMemo(() => {
    switch (group.type) {
      case GROUP_TYPE.LIST:
        return groupCustomers.length || 0;
      case GROUP_TYPE.SEGMENT:
        return group.count;
      default:
        return 0;
    }
  }, [group, groupCustomers]);

  return (
    <PageBuilder>
      <SEO title="Group Messaging | Blueprint" />
      <div className="a-view group-messaging">
        <div className="container">
          <div className="row back-btn-container">
            <div className="col-md-12">
              <Link to="/group-messaging">Back</Link>
            </div>
          </div>

          <LoadingMessage loading={!group.id} />

          {group.id && (
            <div className="row">
              <div className="col-md-7">
                <GroupConversation
                  customerCount={customerCount}
                  group={group}
                  messages={group.messages}
                  message={message}
                  onMessageChange={handleMessageChange}
                  updateMessage={updateMessage}
                  handleSend={handleSend}
                  permalinkData={permalinkData}
                  merchant={merchantData}
                  showMMSSection={showMMSSection}
                  mmsMediaURL={mmsMediaURL}
                  handleMMSCancel={handleMMSCancel}
                  handleOnMsgTypeChange={handleOnMsgTypeChange}
                  addMMS={addMMS}
                  msgType={msgType}
                />
              </div>

              <div className="col-md-5">
                <GroupSubscribersActionBar
                  customers={groupCustomers}
                  group={group}
                  onDelete={handleOnDelete}
                  permalinkData={permalinkData}
                  updatePermalinkData={updatePermalinkData}
                  subscribers={groupCustomers}
                  fetchingCustomers={fetchingCustomers}
                  fetchedCustomers={fetchedCustomers}
                  handleFetchCustomers={handleFetchCustomers}
                />
              </div>

              {confirmModalOpen && (
                <SendGroupMessageModal
                  refreshCount={refreshCount}
                  customerCount={customerCount}
                  group={group}
                  isOpen={confirmModalOpen}
                  merchantData={merchantData}
                  message={message}
                  mmsMediaURL={mmsMediaURL}
                  onClose={handleModalClose}
                  onConfirm={handleSendMessage}
                  setScheduled={setScheduled}
                  setScheduledTime={setScheduledTime}
                  scheduled={scheduled}
                  scheduledTime={scheduledTime}
                  scheduledGroupMessageIsActive={scheduledGroupMessageIsActive}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(GroupMessaging);
