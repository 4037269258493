import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import Modal from 'react-modal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { MerchantActionCreators } from '../../../redux/actions/merchant';
import { ProductActionCreators } from '../../../redux/actions/product';
import Cart from './Cart';
import { ProductGrid } from '../ProductGrid';

/** @typedef {import('../../../redux/reducers').RootState} RootState */

const { getMerchantData: getMerchantDataAction } = MerchantActionCreators;
const {
  getAllProducts: getAllProductsAction,
  resetProducts: resetProductsAction,
} = ProductActionCreators;

const CheckoutLinkModal = ({
  isOpen, onClose, permalinkData, updatePermalinkData,
}) => {
  const existingPermalinkProducts = permalinkData?.products || [];
  const [cart, setCart] = useState(existingPermalinkProducts);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    loadingData,
    loading: loadingLink,
    productErr: getAllProductsError,
    allProducts,
  } = useSelector((/** @type {RootState} */ state) => state.ProductReducer, shallowEqual);
  const merchantData = useSelector(
    (/** @type {RootState} */ state) => state.MerchantReducer.merchantData,
    shallowEqual,
  );
  const dispatch = useDispatch();

  const getProductsAndMerchantInfo = useCallback(() => {
    dispatch(getAllProductsAction());
    const canGetMerchantData = Object.keys(merchantData).length === 0
        && merchantData.constructor === Object;
    if (canGetMerchantData) {
      dispatch(getMerchantDataAction());
    }
  }, [dispatch, merchantData]);

  const cancelModal = useCallback(() => {
    onClose();
    dispatch(resetProductsAction);
  }, [onClose, dispatch]);

  useEffect(() => {
    if (isOpen) {
      getProductsAndMerchantInfo();
    }
  }, [getProductsAndMerchantInfo, isOpen]);

  useEffect(() => {
    let timer;
    if (!loadingLink) {
      timer = setTimeout(() => setLoading(loadingLink), 0);
    } else {
      setLoading(loadingLink);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [loadingLink]);

  useEffect(() => {
    const err = getAllProductsError || '';
    setError(err);
  }, [getAllProductsError]);

  const addToCart = useCallback(
    (variant) => {
      const foundVariant = cart.find((c) => c.id === variant.id);
      const newVariant = foundVariant
        ? {
          ...foundVariant,
          quantity: foundVariant.quantity + 1,
        }
        : {
          ...variant,
          quantity: 1,
        };
      const cartItems = foundVariant
        ? cart.filter((c) => c.id !== variant.id)
        : cart;
      setCart([...cartItems, newVariant]);
    },
    [cart],
  );

  const removeItem = useCallback(
    (item) => {
      setCart(cart.filter((c) => c.id !== item.id));
    },
    [cart],
  );

  const getCartTotal = useCallback(() => {
    const cartTotal = cart
      .map((x) => x.quantity * x.price)
      .reduce((currentCartTotal, itemTotal) => currentCartTotal + itemTotal, 0);
    return cartTotal.toFixed(2);
  }, [cart]);

  const handleSubmit = useCallback(() => {
    if (loading) {
      return;
    }

    updatePermalinkData({
      products: cart,
    });

    cancelModal();
  }, [loading, updatePermalinkData, cart, cancelModal]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal checkout-link"
      overlayClassName="modal-overlay"
      contentLabel="Checkout Link Modal"
    >
      <div>
        <h4>
          {existingPermalinkProducts.length > 0
            ? 'Update Selection'
            : 'Add Product(s)'}
        </h4>

        <ProductGrid
          currencySymbol={merchantData.currencySymbol}
          allProducts={allProducts}
          loading={loadingData}
          addToCart={addToCart}
        />
        <Cart
          cart={cart}
          currencySymbol={merchantData.currencySymbol}
          removeItem={removeItem}
        />
        <div className="bottom">
          <div className="selection">
            {error && <span className="form-error">{error}</span>}
            <div className="totals">
              <span className="title">Total:</span>
              <span className="value">
                {(merchantData.currencySymbol || '£') + getCartTotal()}
              </span>
            </div>
          </div>
          <div className="buttons">
            <button
              className="btn btn-link"
              onClick={cancelModal}
              disabled={loading}
              type="button"
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={loading}
              type="submit"
            >
              {(() => {
                if (loading) { return 'Loading'; }

                return existingPermalinkProducts.length > 0 ? 'Update link' : 'Add link';
              })()}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(CheckoutLinkModal);
