import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camelcase from 'camelcase';
import * as configcat from 'configcat-js';

import { MerchantActionCreators } from '../redux/actions/merchant';

const { getMerchantData } = MerchantActionCreators;

export function makeConfigCatKey(str) {
  // Forcing a replacement to "-" gives camelcase() a new word boundary
  const alphaNumericReplacement = str.replace(/\W/g, '-');

  return camelcase(alphaNumericReplacement);
}

const FeatureFlagContext = React.createContext({
  /**
   * isFeatureEnabled
   * @param { String } flag
   * @param { Boolean } defaultValue
   * @returns { Promise<Boolean> }
   */
  // eslint-disable-next-line no-unused-vars
  isFeatureEnabled: async (flag, defaultValue) => false,
  isMerchantDataReady: false,
});

const ccClient = configcat.createClient(
  /** @type {String} */ (process.env.REACT_APP_CONFIGCAT_SDK_KEY),
);

function FeatureFlagProvider({ children }) {
  const dispatch = useDispatch();
  const configCatClient = useRef(ccClient);

  const { merchantData, merchantLoaded, merchantLoading } = useSelector(
    (/** @type {import('../redux/reducers').RootState} */state) => state.MerchantReducer,
  );

  const featureFlagEnabled = useCallback(
    /**
     * isFeatureEnabled
     * @param { String } flag
     * @param { Boolean } defaultValue
     * @returns { Promise<Boolean> }
     */
    async (flag, defaultValue = false) => {
      const userObject = {
        identifier: makeConfigCatKey(
          `${merchantData.company}${merchantData.id}`,
        ),
      };

      return configCatClient.current.getValueAsync(
        flag,
        defaultValue,
        userObject,
      );
    }, [merchantData.company, merchantData.id],
  );

  useEffect(() => {
    if (!merchantLoading && !merchantLoaded) {
      dispatch(getMerchantData());
    }
  }, [merchantLoaded, merchantLoading, dispatch]);

  const value = useMemo(() => ({
    isFeatureEnabled: featureFlagEnabled,
    isMerchantDataReady: merchantLoaded,
  }), [featureFlagEnabled, merchantLoaded]);

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
}

function useFeatureFlags() {
  const context = React.useContext(FeatureFlagContext);

  if (context === undefined) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider');
  }

  return context;
}

export {
  FeatureFlagProvider, useFeatureFlags,
};
