import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn:
    'https://63cb79386fa24e559db45f5d0fd48ae2@o449747.ingest.sentry.io/5740893',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'dev',
});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
