import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useToasts } from 'react-toast-notifications';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import IntegrationSmartrrForm from '../../components/forms/IntegrationSmartrrForm';
import { IntegrationActionCreators } from '../../redux/actions/integrations';
import { isObjectEmpty } from '../../utils';

import { upsertIntegration, testIntegration, deleteIntegration } from '../../queries/Integrations/Smartrr';

const {
  getMerchantIntegrations,
} = IntegrationActionCreators;

const IntegrationsHubSmartrrPage = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const {
    integrationsLoading,
    integrationsLoaded,
    integrationsData,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */ state) => state.IntegrationsReducer,
  );

  const [smartrrIntegrationData, setSmartrrIntegrationData] = useState(integrationsData?.smartrr);

  useEffect(() => {
    if (!integrationsLoaded && !integrationsLoading) {
      dispatch(getMerchantIntegrations());
    } else if (!isObjectEmpty(integrationsData)) {
      setSmartrrIntegrationData(integrationsData.smartrr);
    }
  }, [dispatch, integrationsLoaded, integrationsLoading, integrationsData]);

  const upsertIntegrationMutation = useMutation(upsertIntegration, {
    onError: (/** @type {Error} */ error) => {
      addToast(
        `Error occured while saving the Smartrr integration info. ${error.message || ''}`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    },
    onSuccess: (data) => {
      const messageAdjective = data?.active
        ? 'updated'
        : 'activated';

      setSmartrrIntegrationData(data);

      addToast(
        `Your integration with Smartrr has been ${messageAdjective}`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
    },
  });

  const testIntegrationMutation = useMutation(testIntegration, {
    onError: (/** @type {Error} */ error) => {
      addToast(
        `Error occured while testing the Smartrr integration info. ${error.message || ''}`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    },
    onSuccess: (data) => {
      addToast(
        data.connectionIsValid ? data.message : 'The provided access token is invalid',
        {
          appearance: data.connectionIsValid ? 'success' : 'warning',
          autoDismiss: true,
        },
      );
    },
  });

  const deleteMutation = useMutation(deleteIntegration, {
    onError: (/** @type {Error} */ error) => {
      addToast(
        `Error occured while deleting the Smartrr integration. ${error.message || ''}`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    },
    onSuccess: () => {
      setSmartrrIntegrationData(null);

      addToast(
        'Your Smartrr integration has been deleted',
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
    },
  });

  const handleSubmit = async (formData) => {
    if (formData.isTesting) {
      await testIntegrationMutation.mutateAsync(formData.accessToken);
    } else if (formData.isDeleting) {
      await deleteMutation.mutateAsync({
        accessToken: formData.accessToken,
        publicId: formData.publicId,
      });
    } else {
      await upsertIntegrationMutation.mutateAsync(formData.accessToken);
    }
  };

  return (
    <PageBuilder>
      <SEO title="Smartrr | Integrations | Blueprint" />
      <div className="a-view integrations-hub">
        <div className="container">
          <div className="row back-btn-container">
            <div className="col-md-12">
              <Link to="/integrations">Back</Link>
            </div>
          </div>

          <div className="top-bar">
            <h2 className="title">
              Smartrr
              {' '}
              {smartrrIntegrationData?.active ? '✅' : ''}
            </h2>
          </div>

          {integrationsLoaded ? (
            <IntegrationSmartrrForm
              onSubmit={handleSubmit}
              isDeleted={deleteMutation.isSuccess}
              isSubmitting={upsertIntegrationMutation.isLoading}
              isTesting={testIntegrationMutation.isLoading}
              isDeleting={deleteMutation.isLoading}
              smartrrIntegrationData={smartrrIntegrationData}
            />
          ) : (
            <>Loading...</>
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(IntegrationsHubSmartrrPage);
