import React from 'react';

export const VariantSwitcher = (props) => {
  const { variants, value, handleChange } = props;
  if (variants.length < 2) {
    return null;
  }
  return (
    <select
      className="form-control variant-switcher"
      value={value}
      onChange={(e) => handleChange(Number(e.target.value))}
    >
      {variants.map((variant, index) => (
        <option key={variant.id} value={index}>
          {variant.variantName}
        </option>
      ))}
    </select>
  );
};

export default VariantSwitcher;
