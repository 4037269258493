import React, { memo } from 'react';

import { COMPARATORS } from '../../../../global/Predicates/ComparatorSelector';
import { NEGATE_MODES } from '../../../../global/Predicates/Negate';
import Filter, { createFilterChangeHandler } from '../Filter';
import TextField from '../ValueComponents/TextField';

/** @typedef {import('../../../../global/Predicates/Negate').NegateMode} NegateMode */
/** @typedef {import('../../../../global/Predicates/TypeSelector').Type} Type */

/** @type {Type[]} */
const MessageTextTypes = [
  {
    title: 'Message Text',
    value: 'messageText',
    comparators: [
      COMPARATORS.eq, COMPARATORS.stringContains,
    ],
  },
];

export const valueMapper = (values) => values.map(({ id, ...value }) => value);

const getDefaultNegate = () => ({ mode: NEGATE_MODES.no });
const getDefaultType = () => MessageTextTypes[0].value;
const getDefaultComparator = () => COMPARATORS.eq.value;
const getDefaultValue = () => '';

/**
 *
 * @param {Object} props
 * @param {String} props.id
 * @param {Function} props.onChange
 * @param {String} props.value
 * @param {NegateMode} props.negate
 * @param {String} props.type
 * @param {String} props.comparator
 */
const MessageFilter = (props) => {
  const {
    type = getDefaultType(),
    value = getDefaultValue(),
    negate = getDefaultNegate(),
    comparator = getDefaultComparator(),
    onChange,
    id,
  } = props;

  const messageType = MessageTextTypes.find((t) => t.value === type);

  const onFilterChange = createFilterChangeHandler({
    type, value, negate, comparator, onChange, id,
  });

  if (!messageType) {
    return null;
  }

  return (
    <Filter
      negate={negate}
      comparator={comparator}
      type={type}
      onChange={onFilterChange}
      types={MessageTextTypes}
      comparators={messageType.comparators}
      value={(
        <TextField value={value} onChange={onFilterChange} />
      )}
    />
  );
};

export default memo(MessageFilter);
