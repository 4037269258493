import { v4 as uuidv4 } from 'uuid';
import { countryPhoneData } from 'phone';

import { TRIGGER_EVENT_TYPES } from '../../../utils/Flows';
import { isObjectEmpty } from '../../../utils';
import { CustomFlowsActionTypes } from '../../types';
import { APIMethods, APIServiceConstants, BlueprintJSONAPI } from '../BlueprintAPI';
import { ProductActionCreators } from '../product';
import { getListGroups } from '../GroupMessagingAPI';
import { getSelectOptionsFromStatesFile } from '../../../components/main/CustomFlows/Filters/ContactFilters/AddressStateCode';
import { getSelectOptionsFromCountriesFile } from '../../../components/main/CustomFlows/Filters/ContactFilters/AddressCountryCode';

/** @typedef {import('../../../components/main/CustomFlows/Filters/ValueComponents/ProductPicker').BasketValue} BasketValue */

const { FLOWS } = APIServiceConstants;

const { getAllProducts: getAllProductsAction } = ProductActionCreators;

const parseOutgoingFlowPayload = (flow) => {
  const payload = { ...flow };

  if (!payload.exitEvent) {
    delete payload.exitEvent;
  }

  payload.allowMultipleEntries = !payload.allowMultipleEntries;

  delete payload.exitEventEnabled;
  delete payload.eventFilterEnabled;
  delete payload.contactFilterEnabled;
  delete payload.filters;
  delete payload.contactFilterNeedsProductsHydrating;
  delete payload.contactFilterNeedsListsHydrating;
  delete payload.contactFilterNeedsPhoneCountryCodeHydrating;
  delete payload.contactFilterNeedsDefaultAddressStateCodeHydrating;
  delete payload.contactFilterNeedsDefaultAddressCountryCodeHydrating;
  delete payload.eventFilterNeedsHydrating;

  return payload;
};

const parseIncomingFlowPayload = (payload) => {
  const flow = { ...payload };

  flow.exitEventEnabled = flow.exitEvent !== null;
  flow.allowMultipleEntries = !flow.allowMultipleEntries;

  if (flow.exitEvent === null) {
    flow.exitEvent = 0;
  }

  flow.filters = [{ id: uuidv4() }];
  flow.eventFilterEnabled = false;

  if (!isObjectEmpty(flow.triggerEventFilter)) {
    const triggerEvent = TRIGGER_EVENT_TYPES.find(
      (eventType) => flow.triggerEventType === eventType.value,
    );

    if (!triggerEvent) {
      return null;
    }

    flow.eventFilterEnabled = true;
    flow.filters = flow.triggerEventFilter[triggerEvent.key].map((filter) => ({
      ...filter,
      id: uuidv4(),
    }));
    flow.eventFilterNeedsHydrating = flow.triggerEventFilter[triggerEvent.key].some((filter) => filter.type === 'product');
  }

  if (!isObjectEmpty(flow.contactFilter)) {
    flow.contactFilter.predicates = flow.contactFilter.predicates.map((filter) => ({
      ...filter,
      id: uuidv4(),
    }));
    flow.contactFilterEnabled = true;
    flow.contactFilterNeedsProductsHydrating = flow.contactFilter.predicates.some((filter) => filter.type === 'product');
    flow.contactFilterNeedsListsHydrating = flow.contactFilter.predicates.some((filter) => filter.type === 'lists');
    flow.contactFilterNeedsPhoneCountryCodeHydrating = flow.contactFilter.predicates.some((filter) => filter.type === 'phoneCountryCode');
    flow.contactFilterNeedsDefaultAddressStateCodeHydrating = flow.contactFilter.predicates.some((filter) => filter.type === 'defaultAddressStateCode');
    flow.contactFilterNeedsDefaultAddressCountryCodeHydrating = flow.contactFilter.predicates.some((filter) => filter.type === 'defaultAddressCountryCode');
    flow.contactFilterNeedsActiveCampaignsHydrating = flow.contactFilter.predicates.some((filter) => filter.type === 'activeCampaigns');
  } else {
    flow.contactFilter = {
      predicates: [
        // Define the default fitler you see when you toggle the filter
        // This one must have a value otherwise the inflation process fails
        {
          id: uuidv4(),
          type: 'product',
          value: [],
        },
      ],
    };
  }

  return flow;
};

const hydrateProductDataIntoFlow = (flow, products) => {
  const basketFilterMapper = ({ value, ...filter }) => {
    if (filter.type === 'product') {
      return {
        ...filter,
        value: value.map((basketValue) => {
          if (basketValue.isVariant) {
            const variants = products.flatMap((product) => product.variants);
            const foundVariant = variants.find((variant) => variant.variantId === basketValue.id);

            if (!foundVariant) {
              throw new Error(`Could not find Variant with id ${basketValue.id} to hydrate Flow`);
            }

            /** @type {BasketValue} */
            return {
              ...basketValue,
              internalId: foundVariant.id,
              name: `${foundVariant.name} [${foundVariant.variantName}]`,
            };
          }

          const foundProduct = products.find((product) => product.productIdExternal === basketValue.id);

          if (!foundProduct) {
            throw new Error(`Could not find Product with id ${basketValue.id} to hydrate Flow`);
          }

          /** @type {BasketValue} */
          return {
            ...basketValue,
            internalId: foundProduct.id,
            name: foundProduct.name,
          };
        }),
      };
    }

    return { ...filter, value };
  };

  const hydratedFilters = flow.filters.map(basketFilterMapper);
  const hydratedContactFilters = flow.contactFilter.predicates.map(basketFilterMapper);

  return {
    ...flow,
    filters: hydratedFilters,
    contactFilter: {
      predicates: hydratedContactFilters,
    },
  };
};

const hydrateListsDataIntoFlow = (flow, lists) => {
  const listsFilterMapper = ({ value, ...filter }) => {
    if (filter.type === 'lists') {
      return {
        ...filter,
        value: value.map((listId) => {
          const foundList = lists.find((list) => list.id === listId);

          if (!foundList) {
            throw new Error(`Could not find List with id ${listId} to hydrate Flow`);
          }

          return {
            id: listId,
            name: foundList.name,
          };
        }),
      };
    }

    return { ...filter, value };
  };

  const hydratedContactFilters = flow.contactFilter.predicates.map(listsFilterMapper);

  return {
    ...flow,
    contactFilter: {
      predicates: hydratedContactFilters,
    },
  };
};

const hydrateListDataIntoFlow = (flow, lists, type) => {
  const listsFilterMapper = ({ value, ...filter }) => {
    if (filter.type === type) {
      return {
        ...filter,
        value: value.map((listId) => {
          const foundList = lists.find((list) => list.id === listId);

          if (!foundList) {
            throw new Error(`Could not find ${type} with id ${listId} to hydrate Flow`);
          }

          return {
            id: listId,
            name: foundList.name,
          };
        }),
      };
    }

    return { ...filter, value };
  };

  const hydratedPredicates = flow.contactFilter.predicates.map(listsFilterMapper);

  return {
    ...flow,
    contactFilter: {
      predicates: hydratedPredicates,
    },
  };
};

export const CustomFlowsActionCreators = {
  createFlow: (flow) => async (dispatch) => {
    dispatch({
      type: CustomFlowsActionTypes.CREATE_FLOW_REQ,
    });

    try {
      await BlueprintJSONAPI(APIMethods.POST, FLOWS, 'flows', { flow: { ...parseOutgoingFlowPayload(flow) } });
      dispatch({
        type: CustomFlowsActionTypes.CREATE_FLOW_RES,
        payload: true,
      });
    } catch (e) {
      console.error('Error - createFlow:', e);
      dispatch({
        type: CustomFlowsActionTypes.CREATE_FLOW_RES,
        payload: false,
        error: e.message,
      });
    }
  },
  updateFlow: (flowId, flow) => async (dispatch) => {
    dispatch({
      type: CustomFlowsActionTypes.UPDATE_FLOW_REQ,
    });

    try {
      await BlueprintJSONAPI(APIMethods.PUT, FLOWS, `flows/${flowId}`, { flow: { ...parseOutgoingFlowPayload(flow) } });
      dispatch({
        type: CustomFlowsActionTypes.UPDATE_FLOW_RES,
        payload: true,
      });
    } catch (e) {
      console.error('Error - updateFlow:', e);
      dispatch({
        type: CustomFlowsActionTypes.UPDATE_FLOW_RES,
        payload: false,
        error: e.message,
      });
    }
  },
  getAllFlows: (reset = true) => async (dispatch) => {
    dispatch({
      type: CustomFlowsActionTypes.GET_FLOWS_REQ,
    });
    try {
      const response = await BlueprintJSONAPI(APIMethods.GET, FLOWS, 'flows', {});

      dispatch({
        type: CustomFlowsActionTypes.GET_FLOWS_RES,
        payload: response.data.flows || [],
      });

      if (reset) {
        // Reset every time so that it doesn't show a toast when
        // attempting to create a new one
        dispatch({
          type: CustomFlowsActionTypes.RESET_FLOW_FORM,
          payload: null,
        });
      }
    } catch (e) {
      console.error('Error - getMerchantFlows:', e);
      dispatch({
        type: CustomFlowsActionTypes.GET_FLOWS_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
  getFlow: (flowId, queryClient) => async (dispatch, getState) => {
    dispatch({
      type: CustomFlowsActionTypes.GET_FLOW_REQ,
    });
    try {
      const response = await BlueprintJSONAPI(APIMethods.GET, FLOWS, `flows/${flowId}`, {});
      let flow = parseIncomingFlowPayload(response.data.flow);

      const flowNeedsProductHydration = flow?.eventFilterNeedsHydrating
        || flow?.contactFilterNeedsProductsHydrating;

      const flowNeedsListsHydration = flow?.contactFilterNeedsListsHydrating;
      const flowNeedsPhoneCountryCodeHydration = flow?.contactFilterNeedsPhoneCountryCodeHydrating;
      // eslint-disable-next-line max-len
      const flowNeedsDefaultAddressStateCodeHydration = flow?.contactFilterNeedsDefaultAddressStateCodeHydrating;
      const flowNeedsAddressCountryCodeHydration = flow?.contactFilterNeedsDefaultAddressCountryCodeHydrating;
      const flowNeedsActiveCampaignHydration = flow?.contactFilterNeedsActiveCampaignsHydrating;

      if (flowNeedsProductHydration) {
        /**
         * We can't guarantee that Products have been loaded by the time a Flow is loaded,
         * and we need to know Products in order to hydrate the form for the Product Picker.
         */

        /**
         * Additionally, we also need to fetch deleted products, which the rest of the Dashboard
         * never fetches. So we will force the inclusion of deleted products and fetch the list again.
         *
         * We need these deleted products in case a Flow has been created that includes a product, and
         * that product is later deleted. Without including deleted products we cannot access the Flow
         * to clean up the delete product from the Flow.
         *
         * There is a risk that other parts of the Dashboard will break when they encounter deleted
         * products in the Redux cache.
         *
         * DI - 12/06/22
         */
        const includeDeletedProducts = true;
        await dispatch(getAllProductsAction(includeDeletedProducts));

        const {
          ProductReducer: {
            allProducts,
          },
        } = getState();

        flow = hydrateProductDataIntoFlow(flow, allProducts);
      }

      if (flowNeedsListsHydration) {
        /**
         * We also need Groups of type List to be hydrated, and this data isn't stored in Redux
         */

        const lists = await queryClient.fetchQuery('listGroups', getListGroups);

        flow = hydrateListsDataIntoFlow(flow, lists);
      }

      if (flowNeedsPhoneCountryCodeHydration) {
        /**
         * We need to rehydrate the phoneCountryCode list properly, which has an id / country code attached.
         */
        const options = countryPhoneData.map((phone) => ({
          id: phone.alpha2,
          name: phone.country_name,
        }));

        flow = hydrateListDataIntoFlow(flow, options, 'phoneCountryCode');
      }

      if (flowNeedsDefaultAddressStateCodeHydration) {
        /**
         * We need to rehydrate the defaultAddressStateCode list properly, which has an id / country code attached.
         */
        const options = getSelectOptionsFromStatesFile();

        flow = hydrateListDataIntoFlow(flow, options, 'defaultAddressStateCode');
      }

      if (flowNeedsAddressCountryCodeHydration) {
        /**
         * We need to rehydrate the defaultAddressCountryCode list properly, which has an id / country code attached.
         */
        const options = getSelectOptionsFromCountriesFile();

        flow = hydrateListDataIntoFlow(flow, options, 'defaultAddressCountryCode');
      }

      if (flowNeedsActiveCampaignHydration) {
        const {
          CustomFlowsReducer: {
            flowsLoaded,
          },
        } = getState();

        let {
          CustomFlowsReducer: {
            flows,
          },
        } = getState();

        if (!flowsLoaded) {
          await dispatch(CustomFlowsActionCreators.getAllFlows(false));
          const state = getState();
          flows = state.CustomFlowsReducer.flows;
        }

        flow = hydrateListDataIntoFlow(flow, flows, 'activeCampaigns');
      }

      dispatch({
        type: CustomFlowsActionTypes.GET_FLOW_RES,
        payload: flow,
      });
    } catch (e) {
      console.error('Error - getMerchantFlow:', e);
      dispatch({
        type: CustomFlowsActionTypes.GET_FLOW_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
  deleteFlow: (flowId) => async (dispatch) => {
    dispatch({
      type: CustomFlowsActionTypes.DELETING_FLOW,
    });
    try {
      await BlueprintJSONAPI(APIMethods.DELETE, FLOWS, `flows/${flowId}`, {});

      dispatch({
        type: CustomFlowsActionTypes.DELETED_FLOW,
        payload: true,
      });
      dispatch(CustomFlowsActionCreators.getAllFlows());
    } catch (e) {
      console.error('Error - deleteFlow:', e);
      dispatch({
        type: CustomFlowsActionTypes.DELETED_FLOW,
        payload: false,
      });
    }
  },
  toggleActiveState: (value, flowId) => async (dispatch) => {
    dispatch({
      type: CustomFlowsActionTypes.TOGGLE_FLOW_REQ,
      payload: { flowId, isActive: value },
    });

    const PATH = value === true ? `flows/${flowId}/deactivate` : `flows/${flowId}/activate`;

    try {
      await BlueprintJSONAPI(APIMethods.PATCH, FLOWS, PATH, {});

      dispatch({
        type: CustomFlowsActionTypes.TOGGLE_FLOW_RES,
        payload: { flowId, isActive: value },
      });
    } catch (e) {
      console.error(`Error - ${PATH}`, e);

      dispatch({
        type: CustomFlowsActionTypes.TOGGLE_FLOW_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }

    dispatch(CustomFlowsActionCreators.getAllFlows());
  },
};

export default CustomFlowsActionCreators;
