import React, {
  memo, useCallback, useState, useEffect,
} from 'react';
import Modal from 'react-modal';
import { useToasts } from 'react-toast-notifications';
import { unsubscribeCustomer } from '../../../redux/actions/MessagingAPI';

const UnsubscribeModal = ({
  isOpen, customer, onClose, onSuccess,
}) => {
  const [saving, setSaving] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    setSaving(false);
  }, [isOpen]);

  const handleUnsubscribe = useCallback(async () => {
    if (customer?.customerPhone?.id) {
      setSaving(true);

      try {
        await unsubscribeCustomer(customer.customerPhone.id);

        onSuccess();

        addToast('The customer has been successfully unsubscribed.', {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (e) {
        addToast('An error has occurred while unsubscribing the customer.', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  }, [addToast, customer.customerPhone.id, onSuccess]);

  if (!isOpen || !customer?.customerPhone?.id) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal add-note text-center"
      overlayClassName="modal-overlay"
      contentLabel="Note Modal"
    >
      <h3>Unsubscribe</h3>

      <p>
        Are you sure you want to unsubscribe
        {' '}
        {customer.fullName}
        {' '}
        {customer.customerPhone.phone}
        ?
      </p>

      <div className="buttons-container">
        <button
          className="btn btn-danger"
          onClick={handleUnsubscribe}
          disabled={saving}
          type="button"
        >
          {saving ? 'Unsubscribing...' : 'Unsubscribe'}
        </button>

        <button className="cancel-btn" onClick={onClose} disabled={saving} type="button">
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default memo(UnsubscribeModal);
