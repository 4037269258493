import React from 'react';
import { useQuery } from 'react-query';
import getSubscriptionMetrics from '../../queries/Integrations/Analytics/getSubscriptionMetrics';
import { DashInfoCard } from '../../components/main/DashInfoCard';
import InfoNode from './InfoNode';

const SubscriptionStats = () => {
  const {
    data,
  } = useQuery(
    'subscriptionMetrics',
    getSubscriptionMetrics,
  );

  if (data) {
    const {
      delayMetrics,
      skipMetrics,
      churnMetrics,
      productAddOnsMetrics,
    } = data;
    return (
      <div className="row mt-3">
        <div className="col-lg-12">
          <h3>Subscription Updates</h3>
          <p>
            Over the last
            {' '}
            <strong>30</strong>
            {' '}
            days
          </p>
        </div>
        {delayMetrics && (
        <div className="col-lg-3">
          <DashInfoCard
            title="Delays"
            value={delayMetrics.amount || 0}
            delta={`${delayMetrics.delta.toFixed(2) || 0}%`}
            info={<InfoNode delta={delayMetrics.delta.toFixed(2)} />}
            extraInfo="Number of delay requests"
          />
        </div>
        )}
        {skipMetrics && (
        <div className="col-lg-3">
          <DashInfoCard
            title="Skips"
            value={skipMetrics.amount || 0}
            delta={`${skipMetrics.delta.toFixed(2) || 0}%`}
            info={<InfoNode delta={skipMetrics.delta.toFixed(2)} />}
            extraInfo="Number of skip requests"
          />
        </div>
        )}
        {productAddOnsMetrics && (
          <div className="col-lg-3">
            <DashInfoCard
              title="Product Add-ons"
              value={productAddOnsMetrics.amount || 0}
              delta={`${productAddOnsMetrics.delta.toFixed(2) || 0}%`}
              info={<InfoNode delta={productAddOnsMetrics.delta.toFixed(2)} />}
              extraInfo="Number of one time add-ons to a subscription"
            />
          </div>
        )}
        {churnMetrics && (
        <div className="col-lg-3">
          <DashInfoCard
            title="Churn"
            value={churnMetrics.amount || 0}
            delta={`${churnMetrics.delta.toFixed(2) || 0}%`}
            info={<InfoNode delta={churnMetrics.delta.toFixed(2)} invertedColour />}
            extraInfo="Number of subscriptions that have been cancelled"
          />
        </div>
        )}
      </div>
    );
  }
  return <></>;
};

export default SubscriptionStats;
