import React, { memo } from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import {
  useQuery,
} from 'react-query';

import Loading from './Loading';
import { CustomDomainEntry, DNSConfigurationReview, Status } from './Steps';
import { getCustomDomain } from '../../../queries/CustomDomain';

const CustomDomainForm = () => {
  const history = useHistory();
  const { path, url, isExact } = useRouteMatch();
  const { isLoading, isFetching, data } = useQuery(
    'customDomain',
    getCustomDomain,
  );

  if (!isExact && isLoading) {
    /**
     * We've arrived on this page for the first time (isLoading), but we haven't exactly matched the Route,
     * which means we've tried to jump into a subroute.
     */
    history.push(url);
  }

  // isLoading triggers the first time, but isFetching triggers when the cache is invalidated
  if (isLoading) {
    return <Loading />;
  }

  /**
   * Once we have loaded our data, check if we are on the right subroute
   */
  if (isExact) {
    if (data?.Domain) {
      if (data.ValidationStatus !== 'SUCCESS') {
        return history.push(`${url}/review`);
      }

      return history.push(`${url}/status`);
    }
  }

  return (
    <>
      {isFetching && (
        <div className="fetching-spinner spinner-border spinner-border-sm" role="status" />
      )}
      <Switch>
        <Route
          exact
          path={path}
          render={(routeProps) => (
            <CustomDomainEntry {...routeProps} formRootUrl={url} />
          )}
        />
        <Route
          exact
          path={`${path}/review`}
          render={(routeProps) => (
            <DNSConfigurationReview {...routeProps} formRootUrl={url} />
          )}
        />
        <Route
          exact
          path={`${path}/status`}
          render={(routeProps) => (
            <Status {...routeProps} formRootUrl={url} />
          )}
        />
      </Switch>
    </>
  );
};

export default memo(CustomDomainForm);
