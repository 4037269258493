import React from 'react';
import { useSelector } from 'react-redux';
import IntegrationsCustomAPITable from '../main/IntegrationsHub/IntegrationsCustomAPITable';

const IntegrationCustomAPIForm = ({
  onSubmit,
}) => {
  let canSubmitForm = false;

  const {
    apiKeysLoading,
    apiKeysData,
    apiKeysInsertLoading,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */state) => state.APIKeysReducer,
  );

  canSubmitForm = !apiKeysInsertLoading && !apiKeysLoading;

  return (
    <form onSubmit={onSubmit}>
      <h3 className="mb-3">API Keys</h3>
      {apiKeysData.length > 0
        ? <IntegrationsCustomAPITable />
        : (
          <p>
            Your account currently has no API keys
          </p>
        )}
      <button
        type="submit"
        className="btn btn-primary mr-2"
        disabled={!canSubmitForm}
      >
        New API Key
      </button>
    </form>
  );
};

export default IntegrationCustomAPIForm;
