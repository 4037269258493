import React, { memo, useCallback } from 'react';

const VALUE_MODE = {
  eq: 'eq',
  contains: 'contains',
  startsWith: 'startsWith',
  endsWith: 'endsWith',
};

const StringValue = ({ value, onChange }) => {
  const handleOnChange = useCallback(
    (event) => {
      onChange({
        mode: event.target.value,
        value: value.value,
      });
    },
    [onChange, value.value],
  );

  const updateProperty = useCallback(
    (event) => {
      onChange({
        mode: value.mode,
        value: event.target.value,
      });
    },
    [onChange, value.mode],
  );

  return (
    <div className="subquery">
      <select
        className="form-control"
        value={value.mode}
        onChange={handleOnChange}
      >
        <option value={VALUE_MODE.eq}>Equal to</option>
        <option value={VALUE_MODE.contains}>Contains</option>
        <option value={VALUE_MODE.startsWith}>Starts with</option>
        <option value={VALUE_MODE.endsWith}>Ends with</option>

      </select>

      <input
        className="form-control"
        placeholder="Text"
        value={value.value}
        name="stringValue"
        onChange={updateProperty}
      />
    </div>
  );
};

export default memo(StringValue);
