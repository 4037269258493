import { MarketingActionTypes } from '../../types';

export const initialState = {
  loading: false,
  waitListSuccess: null,
  waitListErr: null,
  callbackSuccess: null,
  callbackErr: null,
};

export const MarketingReducer = (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case MarketingActionTypes.JOIN_WAITING_REQ: {
      return {
        ...state,
        loading: true,
        waitListSuccess: null,
      };
    }
    case MarketingActionTypes.JOIN_WAITING_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          waitListErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        waitListErr: null,
        waitListSuccess: payload,
      };
    }
    case MarketingActionTypes.CALLBACK_REQ: {
      return {
        ...state,
        loading: true,
        callbackSuccess: null,
      };
    }
    case MarketingActionTypes.CALLBACK_RES: {
      if (error) {
        return {
          ...state,
          loading: false,
          callbackErr: error,
        };
      }
      return {
        ...state,
        loading: false,
        callbackErr: null,
        callbackSuccess: payload,
      };
    }
    default:
      return state;
  }
};
