import React, { memo, useRef } from 'react';

const ClipboardCopy = () => {
  const buttonRef = useRef(/** @type {HTMLSpanElement?} */(null));
  const messageRef = useRef(/** @type {HTMLSpanElement?} */(null));

  const handleClick = (e) => {
    e.preventDefault();

    const button = buttonRef.current;
    const message = messageRef.current;

    if (!button || !message) {
      return false;
    }

    const sibling = button.previousSibling || message.nextSibling;

    if (!sibling) {
      return false;
    }

    const selection = window.getSelection();

    if (!selection) {
      return false;
    }

    const range = document.createRange();

    range.selectNodeContents(sibling);
    selection.removeAllRanges();
    selection.addRange(range);

    navigator.clipboard.writeText(selection.toString());

    selection.removeAllRanges();

    message.style.display = 'block';

    const { offsetTop, offsetLeft, offsetWidth } = button;
    const messageHeight = parseFloat(getComputedStyle(message).height);
    const messageWidth = parseFloat(getComputedStyle(message).width);

    message.style.top = `${offsetTop - messageHeight}px`;
    message.style.left = `${(offsetLeft + (offsetWidth / 2)) - (messageWidth / 2)}px`;

    setTimeout(() => {
      message.style.display = '';
    }, 2500);

    return true;
  };

  return (
    <>
      <span className="ml-1 mr-1" onClick={handleClick} ref={buttonRef} role="button" tabIndex={0}>📋</span>
      <span className="copy-icon-message" ref={messageRef} aria-hidden="true">Copied!</span>
    </>
  );
};

export default memo(ClipboardCopy);
