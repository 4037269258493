import React, { useState, useEffect, useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';
import { useFeatureFlags } from '../../contexts';
import { getGroups, deleteGroup } from '../../redux/actions/GroupMessagingAPI';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import MessageGroups from '../../components/main/GroupMessaging/MessageGroups/MessageGroups';
import CreateGroupModal from '../../components/main/GroupMessaging/CreateGroupModal/CreateGroupModal';
import RemoveMessageGroupModal from '../../components/main/GroupMessaging/RemoveMessageGroupModal';
import LoadingMessage from '../../components/main/GroupMessaging/Loading';
import { initialGroup } from './GroupMessaging';

export default () => {
  const [groupToDelete, setGroupToDelete] = useState(initialGroup);
  const [showMessageGroupModal, setShowMessageGroupModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { isFeatureEnabled } = useFeatureFlags();

  const [groupMessagingListsEnabled, setGroupMessagingListsEnabled] = useState(
    true,
  );
  const [scheduledGroupMessageEnabled, setScheduledGroupMessageEnabled] = useState(false);

  const handleGroupMessagingListsFeature = useCallback(async () => {
    const flagEnabled = await isFeatureEnabled(
      'groupMessagingLists',
      false,
    );

    setGroupMessagingListsEnabled(flagEnabled);
  }, [isFeatureEnabled]);

  useEffect(() => {
    handleGroupMessagingListsFeature();
  }, [handleGroupMessagingListsFeature]);

  const handleScheduledGroupMessageFeature = useCallback(async () => {
    const scheduledGroupMessageIsActive = await isFeatureEnabled(
      'groupmessagev2',
      false,
    );

    setScheduledGroupMessageEnabled(scheduledGroupMessageIsActive);
  }, [isFeatureEnabled]);

  useEffect(() => {
    handleScheduledGroupMessageFeature();
  }, [handleScheduledGroupMessageFeature]);

  const getMessageGroups = useCallback(async () => {
    try {
      setLoading(true);

      const res = await getGroups();

      setGroups(res);
    } catch (e) {
      addToast('The groups could not be retrieved.', {
        appearance: 'error',
        autoDismiss: true,
      });
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    getMessageGroups();
    // I'm not sure what the pattern should be for exhaustive deps on "useEffect only once" empty deps arrays - DI 14/05/21
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleShowCreateNewGroupModal = useCallback(() => {
    setShowMessageGroupModal(!showMessageGroupModal);
  }, [showMessageGroupModal]);

  const deleteMessageGroup = useCallback(async () => {
    if (groupToDelete.id === undefined) {
      return;
    }

    try {
      await deleteGroup(groupToDelete.id);

      setGroupToDelete(initialGroup);

      addToast('The group has been successfully deleted.', {
        appearance: 'success',
        autoDismiss: true,
      });

      await getMessageGroups();
    } catch (e) {
      addToast('The group could not be deleted.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast, getMessageGroups, groupToDelete.id]);

  const CreateNewSegementOrListButton = () => {
    if (!groupMessagingListsEnabled) {
      return (
        <Link
          to="/group-messaging/segments"
          className="btn btn-primary"
        >
          Create New Segment
        </Link>
      );
    }

    return (
      <button
        className="btn btn-primary"
        onClick={() => toggleShowCreateNewGroupModal()}
        type="button"
      >
        Create New Group
      </button>
    );
  };

  const ScheduledCampaignsButton = () => {
    if (scheduledGroupMessageEnabled) {
      return (
        <Link
          to="/scheduled-messages"
          className="btn btn-primary mr-4"
        >
          Scheduled Messages
        </Link>
      );
    }
    return null;
  };

  return (
    <PageBuilder>
      <SEO title="Group Messaging | Blueprint" />

      <div className="a-view group-messaging">
        <div className="container">
          <LoadingMessage loading={loading} />

          {!loading && (
            <>
              <div className="top-bar">
                <div className="title-bar">
                  <h2 className="title">Group Messaging</h2>
                  <div className="buttons">
                    <ScheduledCampaignsButton />
                    <CreateNewSegementOrListButton />
                  </div>
                </div>
              </div>

              <MessageGroups
                loading={loading}
                groups={groups}
                onDelete={setGroupToDelete}
                groupMessagingListsEnabled={groupMessagingListsEnabled}
              />

              <RemoveMessageGroupModal
                isOpen={groupToDelete.id !== undefined}
                group={groupToDelete}
                onClose={() => setGroupToDelete(initialGroup)}
                onDelete={deleteMessageGroup}
              />

              {groupMessagingListsEnabled && (
                <CreateGroupModal
                  isOpen={showMessageGroupModal}
                  close={toggleShowCreateNewGroupModal}
                />
              )}
            </>
          )}
        </div>
      </div>
    </PageBuilder>
  );
};
