import React, { memo } from 'react';

const SECONDS_IN_A_MINUTE = 60;
const SECONDS_IN_A_HOUR = 3600;
const SECONDS_IN_A_DAY = 86400;

const TIME_UNITS = [
  {
    value: 'minutes', title: 'Minutes', divisor: SECONDS_IN_A_MINUTE, previousUnit: null,
  },
  {
    value: 'hours', title: 'Hours', divisor: SECONDS_IN_A_HOUR, previousUnit: 'minutes',
  },
  {
    value: 'days', title: 'Days', divisor: SECONDS_IN_A_DAY, previousUnit: 'hours',
  },
];

const findTimeUnitWithValue = (unitValue) => TIME_UNITS.find((unit) => unit.value === unitValue)
|| TIME_UNITS[0];

const getUnitValueForSeconds = (unit, value) => Math.round(
  (value / unit.divisor + Number.EPSILON) * 10,
) / 10;
export const getSecondsForUnitValue = (unit, value) => Math.round(
  (value * unit.divisor + Number.EPSILON) * 10,
) / 10;

const IntervalSelect = ({
  onChangeValue, onChangeUnit, disabled = false, value, unit,
}) => {
  const currentUnit = findTimeUnitWithValue(unit);
  const currentValue = getUnitValueForSeconds(currentUnit, value);

  const handleValueChange = (e) => {
    const newValue = parseFloat(e.target.value);

    const delay = getSecondsForUnitValue(currentUnit, newValue);

    if (delay < 0) {
      return null;
    }

    return onChangeValue(delay);
  };

  const handleUnitChange = (e) => {
    const newUnit = findTimeUnitWithValue(e.target.value);

    onChangeUnit(newUnit, currentValue);
  };

  return (
    <div className="row">
      <div className="col-md-2">
        <input
          className="form-control message-interval-select"
          onChange={handleValueChange}
          placeholder="Enter Value"
          required
          type="number"
          value={currentValue}
        />
      </div>
      <div className="col-md-4">
        <select
          className="form-control message-interval-select"
          disabled={disabled}
          onChange={handleUnitChange}
          value={currentUnit.value}
        >
          {TIME_UNITS.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.title}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default memo(IntervalSelect);
