import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  NavLink,
} from 'react-router-dom';

import { PageBuilder } from '../components/global/PageBuilder';
import { SEO } from '../components/global/SEO';
import { useFeatureFlags } from '../contexts';

import {
  General,
  Security,
  CustomDomain,
  Subscriptions,
} from './Settings/index';

export const Settings = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const [loaded, setLoaded] = useState(false);
  const [customDomainsEnabled, setCustomDomainsEnabled] = useState(false);

  useEffect(() => {
    if (loaded) {
      return;
    }

    const fetchFeatureFlags = async () => {
      const customDomainsFlag = await isFeatureEnabled('customDomains', false);

      setCustomDomainsEnabled(customDomainsFlag);
      setLoaded(true);
    };

    fetchFeatureFlags();
  }, [isFeatureEnabled, loaded]);

  return (
    <PageBuilder>
      <SEO title="Settings | Blueprint" />
      {!loaded && (
        <div>Loading...</div>
      )}

      {loaded && (
      <div className="a-view settings">
        <div className="container">
          <h2 className="title">Settings</h2>
          <div className="page-nav">
            <div className="row">
              <div className="col-md-8">
                <NavLink
                  exact
                  to="/settings"
                  className="link"
                  activeClassName="active"
                >
                  <span>GENERAL</span>
                </NavLink>
                <NavLink
                  exact
                  to="/settings/security"
                  className="link"
                  activeClassName="active"
                >
                  <span>SECURITY</span>
                </NavLink>
                {customDomainsEnabled && (
                  <NavLink
                    exact
                    to="/settings/custom_domain"
                    className="link"
                    activeClassName="active"
                  >
                    <span>CUSTOM DOMAIN</span>
                  </NavLink>
                )}
                <NavLink
                  exact
                  to="/settings/subscriptions"
                  className="link"
                  activeClassName="active"
                >
                  <span>SUBSCRIPTION MANAGEMENT</span>
                </NavLink>
              </div>
            </div>
          </div>
          <Switch>
            <Route
              exact
              path="/settings"
              render={(routeProps) => (
                <General {...routeProps} />
              )}
            />
            <Route
              exact
              path="/settings/security"
              render={(routeProps) => (
                <Security {...routeProps} />
              )}
            />
            {customDomainsEnabled && (
              <Route
                path="/settings/custom_domain"
                render={(routeProps) => (
                  <CustomDomain {...routeProps} />
                )}
              />
            )}
            <Route
              path="/settings/subscriptions"
              render={(routeProps) => (
                <Subscriptions {...routeProps} />
              )}
            />
          </Switch>
        </div>
      </div>
      )}
    </PageBuilder>
  );
};

export default Settings;
