import React, { memo, useCallback } from 'react';

const TIME_MODES = {
  all_time: 'all_time',
  in_last_n_days: 'in_last_n_days',
  before: 'before',
  after: 'after',
  between: 'between',
};

const TIME_ATTRIBUTES = {
  days: 'days',
  date: 'date',
  from: 'from',
  to: 'to',
};

const Time = ({ value, onChange }) => {
  const handleOnChange = useCallback(
    (event) => {
      const newMode = event.target.value;
      const obj = {
        mode: newMode,
      };

      switch (newMode) {
        case TIME_MODES.in_last_n_days:
          obj[TIME_ATTRIBUTES.days] = '1';
          break;
        case TIME_MODES.before:
        case TIME_MODES.after:
          obj[TIME_ATTRIBUTES.date] = '';
          break;
        case TIME_MODES.between:
          obj[TIME_ATTRIBUTES.from] = '';
          obj[TIME_ATTRIBUTES.to] = '';
          break;
        default:
          break;
      }

      onChange(obj);
    },
    [onChange],
  );

  const updateProperty = useCallback(
    (event) => {
      const eventValue = event.target.value;
      const newValue = {
        mode: value.mode,
      };

      switch (event.target.name) {
        case TIME_ATTRIBUTES.days:
          newValue[TIME_ATTRIBUTES.days] = eventValue;
          break;
        case TIME_ATTRIBUTES.date:
          newValue[TIME_ATTRIBUTES.date] = eventValue;
          break;
        case TIME_ATTRIBUTES.from:
          newValue[TIME_ATTRIBUTES.from] = eventValue;
          newValue[TIME_ATTRIBUTES.to] = value.to;
          break;
        case TIME_ATTRIBUTES.to:
          newValue[TIME_ATTRIBUTES.to] = eventValue;
          newValue[TIME_ATTRIBUTES.from] = value.from;
          break;
        default:
          throw new Error(`Recevied unexpected event.target.name ${event.target.name}`);
      }

      onChange(newValue);
    },
    [onChange, value.from, value.mode, value.to],
  );

  return (
    <div className="subquery">
      <select
        className="form-control"
        value={value.mode}
        onChange={handleOnChange}
      >
        <option value={TIME_MODES.all_time}>In all time</option>
        <option value={TIME_MODES.in_last_n_days}>In the last...</option>
        <option value={TIME_MODES.before}>Before</option>
        <option value={TIME_MODES.after}>After</option>
        <option value={TIME_MODES.between}>Between</option>
      </select>

      {value.mode === TIME_MODES.in_last_n_days && (
        <>
          <input
            className="form-control"
            placeholder="days"
            name={TIME_ATTRIBUTES.days}
            value={value[TIME_ATTRIBUTES.days]}
            onChange={updateProperty}
          />

          <span> days</span>
        </>
      )}

      {[TIME_MODES.before, TIME_MODES.after].includes(value.mode) && (
        <input
          className="form-control"
          type="date"
          placeholder="date"
          name={TIME_ATTRIBUTES.date}
          value={value[TIME_ATTRIBUTES.date]}
          onChange={updateProperty}
        />
      )}

      {value.mode === TIME_MODES.between && (
        <>
          <input
            className="form-control"
            type="date"
            placeholder="from"
            name={TIME_ATTRIBUTES.from}
            value={value[TIME_ATTRIBUTES.from]}
            onChange={updateProperty}
          />

          <span>and</span>

          <input
            className="form-control"
            type="date"
            placeholder="to"
            name={TIME_ATTRIBUTES.to}
            value={value[TIME_ATTRIBUTES.to]}
            onChange={updateProperty}
          />
        </>
      )}
    </div>
  );
};

export default memo(Time);
