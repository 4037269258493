import { MerchantActionTypes } from '../../types';
import {
  API,
  APIMethods,
  APIServiceConstants,
  BlueprintAPI,
  BlueprintJSONAPI,
  extractErrorMessageFromError,
} from '../BlueprintAPI';

const { SERVICE, BILLING, ANALYTICS } = APIServiceConstants;

export const MerchantActionCreators = {
  getUserData: () => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.GET_UDATA_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const data = { sessionToken };
      const response = await BlueprintAPI(SERVICE, 'getUserData', data);
      dispatch({
        type: MerchantActionTypes.GET_UDATA_RES,
        payload: response.data.userData || {},
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: MerchantActionTypes.GET_UDATA_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
  getMerchantData: () => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.GET_MDATA_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const data = { sessionToken };
      const response = await BlueprintAPI(SERVICE, 'getMerchantData', data);
      dispatch({
        type: MerchantActionTypes.GET_MDATA_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: MerchantActionTypes.GET_MDATA_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
  updateMerchantData: (data) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.UPDATE_MDATA_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const theData = { sessionToken, ...data };
      await BlueprintJSONAPI(APIMethods.POST, SERVICE, 'updateMerchantData', theData);
      dispatch({
        type: MerchantActionTypes.UPDATE_MDATA_RES,
        payload: null,
      });
    } catch (e) {
      console.error(e);
      dispatch({
        type: MerchantActionTypes.UPDATE_MDATA_RES,
        error: extractErrorMessageFromError(e),
      });
    }
  },
  updateAbandonedMessages: (data) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.UPDATE_ABCRTMES_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const theData = { sessionToken, ...data };
      await BlueprintAPI(SERVICE, 'updateMessageTemplates', theData);
      dispatch({
        type: MerchantActionTypes.UPDATE_ABCRTMES_RES,
        payload: null,
      });
      const response = await BlueprintAPI(SERVICE, 'getMerchantData', {
        sessionToken,
      });
      dispatch({
        type: MerchantActionTypes.GET_MDATA_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: MerchantActionTypes.UPDATE_ABCRTMES_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
  updateAutomatedMessages: (data) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.UPDATE_AUTOMES_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const theData = { sessionToken, ...data };
      await BlueprintAPI(SERVICE, 'updateMessageTemplates', theData);
      dispatch({
        type: MerchantActionTypes.UPDATE_AUTOMES_RES,
        payload: null,
      });
      const response = await BlueprintAPI(SERVICE, 'getMerchantData', {
        sessionToken,
      });
      dispatch({
        type: MerchantActionTypes.GET_MDATA_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: MerchantActionTypes.UPDATE_AUTOMES_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
  createStripeSubscription: (
    merchantId,
    stripePlanId,
    paymentMethodId,
    email,
  ) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.CREATE_SUB_REQ,
    });

    try {
      const sessionToken = await localStorage.getItem('sToken');
      const customer = await BlueprintAPI(BILLING, 'createStripeCustomer', {
        sessionToken,
        merchantId,
        email,
      });
      const { stripeCustomerId } = customer.data;
      const subscription = await BlueprintAPI(BILLING, 'createSubscription', {
        sessionToken,
        merchantId,
        stripeCustomerId,
        stripePlanId,
        paymentMethodId,
      });
      const { subscriptionItemId } = subscription.data;
      dispatch({
        type: MerchantActionTypes.CREATE_SUB_RES,
        payload: { subscriptionItemId },
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: MerchantActionTypes.CREATE_SUB_RES,
        error: (e.data && e.data.message) || 'Something went wrong',
      });
    }
  },
  retrieveStripeProductPlan: (stripePlanId) => async (dispatch) => {
    try {
      const sessionToken = await localStorage.getItem('sToken');
      const { data } = await BlueprintAPI(
        BILLING,
        'retrieveStripeProductPlan',
        {
          sessionToken,
          stripePlanId,
        },
      );
      dispatch({
        type: MerchantActionTypes.GET_PLAN_RES,
        payload: data.stripeProductPlan,
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: MerchantActionTypes.GET_PLAN_RES,
        error: (e.data && e.data.message) || 'Something went wrong',
      });
    }
  },
  getStripeSubscriptionData: () => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.GET_SUB_REQ,
    });
    try {
      const subscriptionUsage = await API(
        APIMethods.GET,
        BILLING,
        'subscription/usage',
      );
      dispatch({
        type: MerchantActionTypes.GET_SUB_RES,
        payload: subscriptionUsage.data,
      });
    } catch (e) {
      dispatch({
        type: MerchantActionTypes.GET_SUB_RES,
        error:
          e.data && e.data.message ? e.data.message : 'Something went wrong',
      });
    }
  },
  getSubscriptionAnalytics: () => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.GET_SUBS_ANALYTICS_REQ,
    });
    try {
      const subscriptionUsage = await API(
        APIMethods.GET,
        ANALYTICS,
        'subscriptionAnalytics',
      );
      dispatch({
        type: MerchantActionTypes.GET_SUBS_ANALYTICS_RES,
        payload: subscriptionUsage.data,
      });
    } catch (e) {
      dispatch({
        type: MerchantActionTypes.GET_SUBS_ANALYTICS_RES,
        error:
          e.data && e.data.message ? e.data.message : 'Something went wrong',
      });
    }
  },
  updateMerchantsChatbotSettings: (data) => async (dispatch) => {
    dispatch({
      type: MerchantActionTypes.UPDATE_M_SUBSCRIPTION_SETTINGS_REQ,
    });
    try {
      await BlueprintJSONAPI(APIMethods.POST, SERVICE, 'updateMerchantsChatbotSettings', data);
      dispatch({
        type: MerchantActionTypes.UPDATE_M_SUBSCRIPTION_SETTINGS_RES,
        payload: null,
      });
      const sessionToken = await localStorage.getItem('sToken');
      const response = await BlueprintAPI(SERVICE, 'getMerchantData', {
        sessionToken,
      });
      dispatch({
        type: MerchantActionTypes.GET_MDATA_RES,
        payload: response.data,
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: MerchantActionTypes.UPDATE_M_SUBSCRIPTION_SETTINGS_RES,
        error: e.data ? e.data.message : 'Something went wrong',
      });
    }
  },
};

export default MerchantActionCreators;
