import React, { useState, useCallback, useMemo } from 'react';
import MessageGroupSubscribers from '../MessageGroupSubscribers';
import RemoveCustomerFromMessageGroupModal from '../../RemoveCustomerFromMessageGroupModal';
import { HelpBubble } from '../../HelpBubble';
import CheckoutLinkModal from '../../CheckoutLinkModal';
import { GROUP_TYPE } from '../../../../constants';
import { formatDate } from '../../../../utils/dates';

/** @typedef {import('../SubscriberList/Customer').Customer} Customer */

export default ({
  customers = [],
  group,
  onDelete,
  permalinkData,
  updatePermalinkData,
  subscribers,
  handleFetchCustomers,
  fetchingCustomers,
  fetchedCustomers,
}) => {
  const [showCustomers, setShowCustomers] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(/** @type {Customer?} */ (null));
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const numberOfSelectedProducts = useMemo(
    () => (permalinkData?.products || []).reduce((qty, p) => qty + p.quantity, 0),
    [permalinkData],
  );

  const toggleCheckoutModal = useCallback(() => {
    setCheckoutModalOpen(!checkoutModalOpen);
  }, [checkoutModalOpen]);

  const toggleShowCustomers = useCallback(async () => {
    if (fetchingCustomers) {
      return;
    }
    if (!fetchedCustomers) {
      await handleFetchCustomers();
    }
    setShowCustomers(!showCustomers);
  }, [fetchingCustomers, fetchedCustomers, showCustomers, handleFetchCustomers]);

  const handleDelete = useCallback(
    (customerPhoneId) => {
      setCustomerToDelete(
        customers.find((c) => c.customerPhoneId === customerPhoneId),
      );
    },
    [customers],
  );

  const handleDeleteCustomerFromGroup = useCallback(() => {
    if (customerToDelete) {
      onDelete(customerToDelete.customerPhoneId);
      setCustomerToDelete(null);
    }
  }, [customerToDelete, onDelete]);

  const listTypeSubscribers = useCallback(
    () => (
      <>
        {showCustomers && (
          // How has this ever worked? toggleSubscribers is not passed down and not defaulted - DI 15/5/21
          // @ts-ignore
          <MessageGroupSubscribers
            showDateAdded
            handleDelete={handleDelete}
            customers={customers}
            actionTitle=""
            action="delete"
            subscribers={subscribers}
          />
        )}

        <RemoveCustomerFromMessageGroupModal
          isOpen={!!customerToDelete}
          customer={customerToDelete}
          onClose={() => setCustomerToDelete(null)}
          onDelete={handleDeleteCustomerFromGroup}
          group={group}
        />
      </>
    ),
    [
      showCustomers,
      handleDelete,
      customers,
      subscribers,
      customerToDelete,
      handleDeleteCustomerFromGroup,
      group,
    ],
  );

  const segmentTypeSubscribers = useCallback(
    () => (
      <>
        {showCustomers && (
          // How has this ever worked? toggleSubscribers is not passed down and not defaulted - DI 15/5/21
          // @ts-ignore
          <MessageGroupSubscribers
            showDateAdded={false}
            customers={customers}
            actionTitle=""
            action="show"
          />
        )}
      </>
    ),
    [showCustomers, customers],
  );

  const showFetchingState = useCallback(
    () => (fetchingCustomers && !fetchedCustomers
      ? 'fetching...'
      : 'Show subscriber list'),
    [fetchingCustomers, fetchedCustomers],
  );

  const subscribersList = useCallback(
    () => (
      <div className="subscribers-section">
        <div onClick={toggleShowCustomers} role="button" tabIndex={0}>
          <p className="text-center hide-customer">
            {showCustomers ? 'Hide subscriber list' : showFetchingState()}
          </p>
        </div>

        {group.type === GROUP_TYPE.LIST && listTypeSubscribers()}
        {group.type === GROUP_TYPE.SEGMENT && segmentTypeSubscribers()}
      </div>
    ),
    [
      toggleShowCustomers,
      showCustomers,
      showFetchingState,
      group.type,
      listTypeSubscribers,
      segmentTypeSubscribers,
    ],
  );

  const getLastUpdatedAt = useCallback(() => {
    if (group.type !== GROUP_TYPE.SEGMENT) {
      return null;
    }

    return `(Last updated ${formatDate(group.countUpdatedAt)})`;
  }, [group]);

  return (
    <div className="panel customer-search-bar">
      <div className="group-subscribers">
        <h3>
          Summary
          {getLastUpdatedAt()}
        </h3>
        <div className="checkout-link-section">
          <div className="checkout-sms">
            <div className="top">
              Create checkout link
              <HelpBubble
                text="Send customers a custom checkout link via sms. They can checkout using existing details or a custom link if they're a first time customer"
                left
                small
              />
            </div>

            <button className="btn btn-primary" onClick={toggleCheckoutModal} type="button">
              {numberOfSelectedProducts > 0
                ? 'Update Selection'
                : 'Add Product(s)'}
            </button>

            {numberOfSelectedProducts > 0 && (
              <div className="selected-products">
                <i>
                  {numberOfSelectedProducts}
                  {' '}
                  product(s) selected
                </i>
              </div>
            )}
          </div>
        </div>
        {subscribersList()}
      </div>

      {checkoutModalOpen && (
        <CheckoutLinkModal
          isOpen={checkoutModalOpen}
          onClose={toggleCheckoutModal}
          permalinkData={permalinkData}
          updatePermalinkData={updatePermalinkData}
        />
      )}
    </div>
  );
};
