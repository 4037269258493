import React from 'react';
import { TIMEZONES } from '../../assets/data/timezones';

export const TimezoneSelect = (props) => {
  const {
    input,
    disabled = false,
    readOnly = false,
    meta: { error },
  } = props;

  return (
    <div className="timezones-wrapper">
      <div className="icon" />
      <select
        {...input}
        className="form-control"
        disabled={disabled}
        readOnly={readOnly}
      >
        <option key="select" value="">
          Select...
        </option>
        {TIMEZONES.map((opt) => (
          <option key={opt} value={opt}>
            {opt}
          </option>
        ))}
      </select>
      {error && <span className="field-error">{error}</span>}
    </div>
  );
};

export default TimezoneSelect;
