export const wordings = {
  crossSell: {
    mainTitle: 'Cross-sell campaigns',
    eligibleProducts: 'Number of product variants that are commonly purchased as follow on purchases',
    productSuggestionTitle: 'Total cross-sell recommendations',
    genericTerm: 'cross-sell',
    genericTermSentenceCase: 'Cross-sell',
    listName: 'Cross-sells',
  },
  replenishment: {
    mainTitle: 'Replenishment campaigns',
    eligibleProducts: 'Number of products that are commonly purchased repeatidly by the same customer',
    productSuggestionTitle: 'Total replenishment recommendations',
    genericTerm: 'replenishment',
    genericTermSentenceCase: 'Replenishment',
    listName: 'Replenishments',
  },
};

export const settings = {
  crossSell: {
    mainLink: '/cross-sells',
    defaultMessage: '{brand_name}: Hey {first_name} 👋 After your last purchase we thought you’d really like to try {recommended_products} 📦 1 click purchase link below 👇 {link_to_checkout}',
  },
  replenishment: {
    mainLink: '/replenishments',
    defaultMessage: '{brand_name}: Hey {first_name} 👋 If you’re running low, we have {replenishment_products} ready to confirm in 1-click here 👇 {reorder_link}',
  },
};

/** @enum {String} */
export const types = {
  CROSS_SELLS: 'crossSell',
  REPLENISHMENTS: 'replenishment',
};
