import React from 'react';
import Customers from './Customers';

export default ({
  customers,
  subscribers = [],
  toggleSubscribers,
  handleDelete,
  showDateAdded,
  actionTitle = 'Click to Add',
  action,
}) => {
  const width = showDateAdded ? 'col-md-4' : 'col-md-9';
  return (
    <div className="table customers-list">
      <div className="head-wrapper">
        <div className="row head-row">
          <div className={width}>Customer</div>
          {showDateAdded && <div className="col-md-5">Date Imported</div>}
          <div className="col-md-3">{actionTitle}</div>
        </div>
      </div>
      <div className="body-wrapper">
        <Customers
          customers={customers}
          subscribers={subscribers}
          toggleSubscribers={toggleSubscribers}
          handleDelete={handleDelete}
          showDateAdded={showDateAdded}
          action={action}
        />
      </div>
    </div>
  );
};
