import React from 'react';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { DateTime } from 'luxon';

export const DEFAULT_RANGES = {
  Today: [
    DateTime.now().startOf('day').toJSDate(),
    DateTime.now().endOf('day').toJSDate(),
  ],
  Yesterday: [
    DateTime.now().minus({ days: 1 }).startOf('day').toJSDate(),
    DateTime.now().minus({ days: 1 }).endOf('day').toJSDate(),
  ],
  'Last 7 Days': [
    DateTime.now().minus({ days: 7 }).startOf('day').toJSDate(),
    DateTime.now().minus({ days: 1 }).endOf('day').toJSDate(),
  ],
  'Last 28 Days': [
    DateTime.now().minus({ days: 28 }).startOf('day').toJSDate(),
    DateTime.now().minus({ days: 1 }).endOf('day').toJSDate(),
  ],
  'This Month': [
    DateTime.now().startOf('month').toJSDate(),
    DateTime.now().endOf('month').toJSDate(),
  ],
  'Last Month': [
    DateTime.now().minus({ months: 1 }).startOf('month').toJSDate(),
    DateTime.now().minus({ months: 1 }).endOf('month').toJSDate(),
  ],
};

export const DateRangeSelect = ({ value, onChange }) => (
  <DateRangePicker
    onCallback={(start, end) => onChange({ start, end })}
    initialSettings={{
      startDate: value.start,
      endDate: value.end,
      opens: 'left',
      locale: {
        format: 'Do MMM YYYY',
      },
      ranges: DEFAULT_RANGES,
    }}
  >
    <input type="text" className="form-control date-range-select-input" />
  </DateRangePicker>
);
