import Fuse from 'fuse.js';

const FUSE_OPTIONS = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['name', 'number'],
};

export const sortLstMsgDesc = (a, b) => {
  if (b.lstMsg < a.lstMsg) {
    return -1;
  } if (b.lstMsg > a.lstMsg) {
    return 1;
  }
  return 0;
};

const getSearchResults = (contacts, query) => {
  const searcher = new Fuse([...contacts], FUSE_OPTIONS);
  return [...searcher.search(query).map((x) => x.item)];
};

export const getSearchContacts = (contacts, query) => {
  const hasQuery = query && query.length > 0;
  const searchedContacts = (hasQuery ? getSearchResults(contacts, query) : contacts) || [];
  const support = searchedContacts.filter((c) => c.supportFlagged === 1);
  const normal = searchedContacts.filter((c) => c.supportFlagged !== 1);
  return {
    support: support.sort(sortLstMsgDesc),
    normal: normal.sort(sortLstMsgDesc),
  };
};

export default getSearchContacts;
