export const getMessagesWithSentimentScore = (messages) => messages.map((m) => {
  let score;

  if (m.positive > m.negative) {
    score = 1 - (m.mixed + m.neutral - m.negative);
  } else {
    score = -1 * (1 - (m.mixed + m.neutral - m.positive));
  }

  return {
    ...m,
    score,
  };
});

export const getSentimentIcons = (score) => {
  const scoreFrom1to10 = score * 10;

  if (scoreFrom1to10 <= -2) {
    return new Array(Math.round((-1 * scoreFrom1to10) / 2)).fill('🙁').join('');
  } if (scoreFrom1to10 >= 2) {
    return new Array(Math.round(scoreFrom1to10 / 2)).fill('🙂').join('');
  }
  return '😐';
};
