import React, {
  memo, useEffect, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import { MerchantActionCreators } from '../../redux/actions/merchant';

import EngagementStats from './EngagementStats';
import SegmentedCustomerStats from './SegmentedCustomerStats';
import SubscriptionStats from './SubscriptionStats';

const splitName = (name) => (!name ? null : name.split(' ')[0]);
const { getUserData, getMerchantData } = MerchantActionCreators;

const BlueprintValueSnapshotPage = () => {
  const dispatch = useDispatch();

  const {
    merchantData,
    merchantLoaded,
    userData,
    userLoaded,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */state) => state.MerchantReducer,
  );

  const getUserDataCallback = useCallback(() => {
    dispatch(getUserData());
  }, [dispatch]);

  const getMerchantDataCallback = useCallback(() => {
    dispatch(getMerchantData());
  }, [dispatch]);

  useEffect(() => {
    if (!userLoaded) {
      getUserDataCallback();
    }
  }, [userData, userLoaded, getUserDataCallback]);

  useEffect(() => {
    if (!merchantLoaded) {
      getMerchantDataCallback();
    }
  }, [merchantData, merchantLoaded, getMerchantDataCallback]);

  const isLoaded = useMemo(() => merchantLoaded && userLoaded, [merchantLoaded, userLoaded]);

  return (
    <PageBuilder>
      <SEO title="Blueprint Value Snapshot | Blueprint" />
      <div className="a-view blueprint-value-snapshot">
        <div className="container">
          {!isLoaded && <p>Loading...</p>}
          {isLoaded && (
            <>
              <div className="top-bar">
                <h2 className="title">
                  Hey
                  {' '}
                  {splitName(userData.name) || 'there'}
                  {' '}
                  👋 Welcome to your
                  {' '}
                  <span className="btitle">blueprint snapshot</span>
                </h2>
              </div>
              <SegmentedCustomerStats merchantData={merchantData} />
              <EngagementStats />
              <SubscriptionStats />
            </>
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(BlueprintValueSnapshotPage);
