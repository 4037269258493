import React from 'react';
import { PageBuilder } from '../../components/global/PageBuilder';
import { SEO } from '../../components/global/SEO';
import ListReplenishments from './ListReplenlishments';

const CrossSells = () => (
  <PageBuilder>
    <SEO title="Replenishments | Blueprint" />
    <div className="a-view up-sells">
      <div className="container">
        <ListReplenishments />
      </div>
    </div>
  </PageBuilder>
);

export default CrossSells;
