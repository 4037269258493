import React from 'react';
import { useQuery } from 'react-query';
import { DashInfoCard } from '../../components/main/DashInfoCard';
import {
  APIServiceConstants,
  BlueprintAPI,
} from '../../redux/actions/BlueprintAPI';
import InfoNode from './InfoNode';

const { SMS } = APIServiceConstants;

const getEngagementStats = async () => {
  try {
    const result = await BlueprintAPI(SMS, 'blueprintValue/keyMetrics', {}, null, true, 'GET');

    return result?.data;
  } catch (error) {
    console.error(error);
  }

  return null;
};

const EngagementStats = () => {
  const {
    data: engagementStats,
  } = useQuery(
    'engagementStats',
    getEngagementStats,
    {
      initialData: null,
    },
  );

  return (
    <div className="row mt-5">
      <div className="col-lg-12">
        <h3>Engagement</h3>
        <p>
          Over the last
          {' '}
          <strong>30</strong>
          {' '}
          days
        </p>
      </div>
      {!engagementStats ? (
        <div className="col-lg-12">
          Loading...
        </div>
      ) : (
        <>
          <div className="col-lg-3">
            <DashInfoCard
              title="Conversations"
              value={engagementStats.oneToOneConversations || 0}
              info={<InfoNode delta={engagementStats.oneToOneConversationsDelta.toFixed(2)} />}
              extraInfo="Unique conversations in response to an incoming message"
            />
          </div>
          <div className="col-lg-3">
            <DashInfoCard
              title="Orders from Support"
              value={engagementStats.ordersFromSupport || 0}
              info={<InfoNode delta={engagementStats.ordersFromSupportDelta.toFixed(2)} />}
              extraInfo="Number of orders attributed to a support conversation"
            />
          </div>
          <div className="col-lg-3">
            <DashInfoCard
              title="Abandoned carts rescued"
              value={engagementStats.abandonedCartsRescued || 0}
              info={<InfoNode delta={engagementStats.abandonedCartsRescuedDelta.toFixed(2)} />}
              extraInfo="Number of orders placed via abandoned cart automation"
            />
          </div>
          <div className="col-lg-3">
            <DashInfoCard
              title="SMS engagement"
              value={`${engagementStats.smsEngagementRate.toFixed(2) || 0} %`}
              info={<InfoNode delta={engagementStats.smsEngagementRateDelta.toFixed(2)} />}
              extraInfo="Percentage of individuals that received a message and replied"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default EngagementStats;
