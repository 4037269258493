import { ProductActionTypes } from '../../types';
import {
  APIServiceConstants,
  BlueprintJSONAPI,
  APIMethods,
} from '../BlueprintAPI';

const { SERVICE } = APIServiceConstants;

export const ProductActionCreators = {
  getAllProducts: (includeDeleted = false) => async (dispatch) => {
    dispatch({
      type: ProductActionTypes.GET_PRODUCTS_REQ,
    });
    try {
      const response = await BlueprintJSONAPI(
        APIMethods.POST,
        SERVICE,
        'getAllProducts',
        {
          ...(includeDeleted && { deleted: true }),
        },
      );
      dispatch({
        type: ProductActionTypes.GET_PRODUCTS_RES,
        payload: response.data.products || [],
      });
    } catch (e) {
      console.log('e', e);
      dispatch({
        type: ProductActionTypes.GET_PRODUCTS_RES,
        error:
          e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : 'Something went wrong',
      });
    }
  },
  resetProducts: async (dispatch) => {
    dispatch({
      type: ProductActionTypes.RESET_PRODUCTS,
    });
  },
};

export default ProductActionCreators;
