import {
  BlueprintJSONAPI, APIMethods, APIServiceConstants,
} from '../../../redux/actions/BlueprintAPI';

/**
 * @typedef SubscriptionMetric
 * @property {Number} amount
 * @property {Number} delta
 */

/**
 * @typedef SubscriptionMetricResponse
 * @property {SubscriptionMetric} delayMetrics
 * @property {SubscriptionMetric} churnMetrics,
 * @property {SubscriptionMetric} skipMetrics,
 * @property {SubscriptionMetric} productAddOnsMetrics,
 */
/**
 * @returns {Promise<SubscriptionMetricResponse?>}
 */
const getSubscriptionMetrics = async () => {
  const response = await BlueprintJSONAPI(
    APIMethods.GET,
    APIServiceConstants.INTEGRATIONS,
    'subscriptionMetrics',
  );

  return response?.data;
};

export default getSubscriptionMetrics;
