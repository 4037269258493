import React, { memo, useMemo } from 'react';
import { dateIsMoreThan24HAgo } from '../../../utils/dates';

const allMessagesSent = ({
  totalMessageCount,
  sentCount,
  failedCount,
  skippedCount,
}) => sentCount + failedCount + skippedCount === totalMessageCount;

function GroupMessageDeliveryStatus({ message }) {
  const {
    totalMessageCount,
    sentCount,
    skippedCount,
    createdAt,
  } = message;

  const status = useMemo(() => {
    if (allMessagesSent(message) || dateIsMoreThan24HAgo(createdAt)) {
      return (
        <>
          <span className="sent">SENT</span>
          {' '}
          <span>
            to
            {sentCount}
            {' '}
            contacts
          </span>
          {sentCount !== totalMessageCount && (
            <>
              {' '}
              <span>
                (
                {totalMessageCount - skippedCount - sentCount}
                {' '}
                failed
                {skippedCount > 0 && (
                <>
                  ,
                  {skippedCount}
                  {' '}
                  skipped
                </>
                )}
                )
              </span>
            </>
          )}
        </>
      );
    }

    return (
      <>
        <span className="sending">SENDING...</span>
        <span>
          {' '}
          -
          {' '}
          {sentCount}
          /
          {totalMessageCount}
        </span>
      </>
    );
  }, [message, createdAt, sentCount, totalMessageCount, skippedCount]);

  return <div className="group-message-delivery-status">{status}</div>;
}

export default memo(GroupMessageDeliveryStatus);
