import React, {
  useEffect,
  useRef,
  forwardRef,
} from 'react';

/**
 * The type errors below do not affect functionality,
 * and the IndeterminateCheckbox component has been copied from
 * the react-table documentation.
 *
 * If anyone else can resolve the errors, please feel free!
 */
const IndeterminateCheckbox = forwardRef(
  // Missing types for the props of this component
  // @ts-ignore
  ({ disabled, indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      // The passed in `ref` is apparently a function instead of something with a .current property
      // @ts-ignore
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate, rest]);

    const attrs = { ...rest };

    return (
      <input type="checkbox" ref={resolvedRef} {...attrs} disabled={disabled} />
    );
  },
);

export default IndeterminateCheckbox;
