import React, { memo } from 'react';
import {
  UPSELL_TEXT_ALL_VARS_SELECTED,
  hasNoRecommendedProds,
} from '../../../utils';

import {
  types,
} from '../../../utils/crossSells';

const getFullProductName = (product) => `${product.name} [${product.variantName || UPSELL_TEXT_ALL_VARS_SELECTED}]`;

const getProductNames = (productsToMap) => productsToMap.map((product) => getFullProductName(product)).join(', ');

const CrossSellInfo = ({ triggerProducts, targetProducts, type }) => {
  if (type === types.CROSS_SELLS) {
    return (
      <div className="cross-sell-info text">
        <div>
          <b>When someone buys any of:</b>
        </div>
        <div>
          {getProductNames(triggerProducts)}
        </div>
        { !hasNoRecommendedProds(targetProducts)
          && (
          <>
            <br />
            <div>
              <b>Recommend these products:</b>
            </div>
            <div>
              {getProductNames(targetProducts)}
            </div>
          </>
          )}

      </div>
    );
  }

  if (type === types.REPLENISHMENTS) {
    return (
      <div className="cross-sell-info text">
        <div>
          <b>Replenishable products:</b>
        </div>
        <div>
          {getProductNames(triggerProducts)}
        </div>
      </div>
    );
  }

  return <></>;
};

export default memo(CrossSellInfo);
