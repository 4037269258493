import React from 'react';

import { HelpBubble } from '../../HelpBubble';
import {
  UPSELL_TRIGGER_HELP_TEXT,
  UPSELL_TEXT_ALL_VARS_SELECTED,
} from '../../../../utils';

const Triggers = ({ triggers }) => (
  <>
    <label htmlFor="triggersTable">
      <span className="section-head">Upsell triggers</span>
      <HelpBubble small text={UPSELL_TRIGGER_HELP_TEXT} />
      <span className="note">(cannot be updated)</span>
    </label>
    <table id="triggersTable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Variant (optional)</th>
        </tr>
      </thead>
      <tbody>
        {triggers.map((trigger, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={index}>
            <td>{trigger.name}</td>
            <td>{trigger.variantName || UPSELL_TEXT_ALL_VARS_SELECTED}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
);

export default Triggers;
