import React, { memo } from 'react';

const RepliesScatterChartTooltip = (props) => {
  const { payload } = props;

  if (!payload || payload.length !== 2) {
    return null;
  }

  return (
    <div className="custom-tooltip">
      <div>
        <strong>{payload[0].payload.customerName}</strong>
      </div>
      <br />
      <div>
        &quot;
        {payload[0].payload.message}
        &quot;
      </div>
      <br />
      <div>
        Lifetime Spent:
        {' '}
        {payload[0].payload.currency}
        {Math.round(payload[1].value)}
      </div>
      <div>
        Last purchased products:
        <br />
        {payload[0].payload.lastOrderItems}
      </div>
    </div>
  );
};

export default memo(RepliesScatterChartTooltip);
