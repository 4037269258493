import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

const IntegrationKlaviyoForm = ({
  klaviyoIntegrationData,
  onSubmit,
  connectionTestEventCallback,
  klaviyoLists,
  klaviyoListsLoading,
}) => {
  const [initialApiKeyData] = useState({
    privateKey: klaviyoIntegrationData?.authData?.private_api_key,
    publicKey: klaviyoIntegrationData?.authData?.public_api_key,
    smsConsentEnabled: klaviyoIntegrationData?.settingsData?.smsConsentEnabled || false,
    smsListsEnabled: klaviyoIntegrationData?.settingsData?.smsListsEnabled || false,
    // In the form the values are stored just as IDs, not as objects
    subscriptionLists: klaviyoIntegrationData?.settingsData?.subscriptionLists
      ? klaviyoIntegrationData.settingsData.subscriptionLists.map(({ id }) => id)
      : [],
    outboundSMSEventsEnabled: klaviyoIntegrationData?.settingsData?.outboundSMSEventsEnabled || false,
    outboundSubscriptionStatusEnabled:
      klaviyoIntegrationData?.settingsData?.outboundSubscriptionStatusEnabled || false,
  });
  let canPerformConnectionTest = false;
  let canSubmitForm = false;
  let shouldFormElementsBeDisabled = false;

  const {
    integrationsLoading,
    klaviyoUpsertLoading,
    klaviyoTestLoading,
    klaviyoTestSuccess,
    klaviyoTestError,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */state) => state.IntegrationsReducer,
  );

  /**
   * @param {String} listId
   * @returns {Boolean}
   */
  const isFlaggedForImport = (listId) => {
    const { subscriptionLists } = initialApiKeyData;
    if (subscriptionLists && Array.isArray(subscriptionLists)) {
      return subscriptionLists.findIndex(
        (listObj) => listObj.id === listId,
      ) > -1;
    }
    return false;
  };

  const {
    register, handleSubmit, formState, watch, getValues, control, reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialApiKeyData,
  });

  const { isDirty, dirtyFields } = formState;

  const handleFormSubmit = (formData) => {
    onSubmit(formData);
    /**
     * The form data needs to be reset, with the published form data,
     * or else the klaviyo fields will appear to be 'dirty' when a form submit is handled,
     * thereby deactivating the submit button when it should be active.
     */
    reset(formData);
  };

  const handleConnectionTest = (event) => {
    event.preventDefault();
    const [publicKey, privateKey] = getValues(['publicKey', 'privateKey']);

    return connectionTestEventCallback({ publicKey, privateKey });
  };

  canPerformConnectionTest = formState.isValid && !klaviyoTestLoading && !klaviyoUpsertLoading;

  const klaviyoConnectionStatus = (
    isDirty && (dirtyFields.publicKey || dirtyFields.privateKey)
  ) ? klaviyoTestSuccess : true;

  canSubmitForm = formState.isValid
    && !klaviyoUpsertLoading
    && !integrationsLoading
    && !klaviyoTestLoading
    && !klaviyoTestError
    && klaviyoConnectionStatus;

  shouldFormElementsBeDisabled = !klaviyoUpsertLoading && !klaviyoTestLoading;

  const listOptions = klaviyoLists.map((listObj) => (
    {
      value: listObj.list_id,
      label: listObj.list_name,
      isSelected: isFlaggedForImport(listObj.list_id),
    }
  ));

  const smsListsEnabled = watch('smsListsEnabled');

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="form-group">
        <label htmlFor="publicKey">Public Key</label>
        <div className="field">
          <input
            id="publicKey"
            type="text"
            className="form-control"
            placeholder="Public Key"
            disabled={!shouldFormElementsBeDisabled}
            {...register('publicKey', { required: true })}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="privateKey">Private Key</label>
        <div className="field">
          <input
            id="privateKey"
            type="text"
            className="form-control"
            placeholder="Private Key"
            disabled={!shouldFormElementsBeDisabled}
            {...register('privateKey', { required: true })}
          />
        </div>
      </div>
      <hr />
      <div className="form-group">
        <div className="form-check">
          <input
            id="smsConsentEnabled"
            type="checkbox"
            className="form-check-input mt-2"
            disabled={!shouldFormElementsBeDisabled}
            {...register('smsConsentEnabled', { required: false })}
          />
          <label htmlFor="smsConsentEnabled" className="form-check-label">Enable importing SMS subscribers (US only)</label>
        </div>
      </div>
      <hr />
      <div className="form-group">
        <div className="form-check">
          <Controller
            control={control}
            name="smsListsEnabled"
            render={({ field: { onChange, value } }) => (
              <input
                id="smsListsEnabled"
                type="checkbox"
                className="form-check-input mt-2"
                disabled={!shouldFormElementsBeDisabled}
                onChange={(e) => {
                  if (!e.target.checked) {
                    // If this box was unchecked, clear subscriptionLists and also set this box to be unchecked
                    return reset({
                      ...getValues(),
                      subscriptionLists: [],
                      smsListsEnabled: false,
                    });
                  }

                  return onChange(e);
                }}
                checked={value}
              />
            )}
            rules={{ required: false }}
          />
          <label htmlFor="smsListsEnabled" className="form-check-label">Enable importing all subscribers to certain lists</label>
        </div>
      </div>
      <div className="form-group">
        {klaviyoListsLoading ? 'Loading Klaviyo lists...'
          : (
            <>
              <label htmlFor="subscriptionLists">Subscription lists to import from</label>
              <Controller
                control={control}
                name="subscriptionLists"
                render={({ field: { onChange, ref, value } }) => (
                  <Select
                    id="subscriptionLists"
                    inputRef={ref}
                    onChange={(values) => {
                      onChange(values.map((selectedList) => selectedList.value));
                    }}
                    value={
                      // Map our selected options back into a Option type (label, value, etc)
                      value?.map(
                        (selectedValue) => listOptions.find(
                          (listOption) => listOption.value === selectedValue,
                        ),
                      )
                    }
                    options={listOptions}
                    isDisabled={!smsListsEnabled}
                    isMulti
                  />
                )}
              />
            </>
          )}
      </div>
      <hr />
      <div className="form-group">
        <div className="form-check">
          <input
            id="outboundSMSEventsEnabled"
            type="checkbox"
            className="form-check-input mt-2"
            disabled={!shouldFormElementsBeDisabled}
            {...register('outboundSMSEventsEnabled', { required: false })}
          />
          <label htmlFor="outboundSMSEventsEnabled" className="form-check-label">Send SMS events to Klaviyo</label>
        </div>
      </div>
      <hr />
      <div className="form-group">
        <div className="form-check">
          <input
            id="outboundSubscriptionStatusEnabled"
            type="checkbox"
            className="form-check-input mt-2"
            disabled={!shouldFormElementsBeDisabled}
            {...register('outboundSubscriptionStatusEnabled', { required: false })}
          />
          <label htmlFor="outboundSubscriptionStatusEnabled" className="form-check-label">Send subscription status property to Klaviyo</label>
        </div>
      </div>
      <hr />
      <button
        type="submit"
        className="btn btn-primary mr-2"
        disabled={!canSubmitForm}
      >
        {klaviyoIntegrationData?.active ? 'Update' : 'Activate'}
      </button>
      <button
        className="btn btn-info mr-2"
        onClick={handleConnectionTest}
        disabled={!canPerformConnectionTest}
        type="button"
      >
        Test Connection
      </button>
      {klaviyoTestSuccess && (
      <span>
        <span className="mr-1">✅</span>
        Connection OK!
      </span>
      )}
      {klaviyoTestError && (
      <span>
        <span className="mr-1">❌</span>
        {klaviyoTestError.toString()}
      </span>
      )}
    </form>
  );
};

export default IntegrationKlaviyoForm;
