import React, { useState } from 'react';
import { VariantSwitcher } from './VariantSwitcher';

export const Product = (props) => {
  const { product, currencySymbol, addToCart } = props;
  const [activeVariant, setVariant] = useState(0);
  if (product.variants.length === 0) {
    return null;
  }
  const stockLevel = product.variants[activeVariant].stockQuantity || 0;
  const stockLevelMessage = (stockLevel > 0) ? `${stockLevel} available` : '';
  return (
    <div className="col-md-6">
      <div className="product">
        <div className="product-image-container">
          <img
            className="product-image"
            src={
              product.imgSrc
              || 'https://static.blueprint.store/images/bp-no-image-found.png'
            }
            alt="Can't load"
          />
        </div>
        <div className="product-details">
          <span className="product-name">{product.name}</span>
          <div className="product-price-container">
            <span className="product-price">
              {(currencySymbol || '£')
                + Number(product.variants[activeVariant].price).toFixed(2)}
            </span>
            <span>
              {stockLevelMessage}
            </span>
          </div>
          <VariantSwitcher
            value={activeVariant}
            handleChange={setVariant}
            variants={product.variants}
          />
        </div>
        <div className="product-buttons">
          <button
            className="btn btn-secondary"
            onClick={() => addToCart({
              ...product.variants[activeVariant],
              productName: product.name,
            })}
            type="button"
          >
            Click to add
          </button>
        </div>
      </div>
    </div>
  );
};

export default Product;
