import React, {
  memo, useCallback, useState, useEffect,
} from 'react';
import Modal from 'react-modal';
import { useToasts } from 'react-toast-notifications';
import { addNode } from '../../../redux/actions/MessagingAPI';

const NoteModal = ({
  isOpen, customerPhoneId, onClose, onSuccess,
}) => {
  const { addToast } = useToasts();
  const [note, setNote] = useState('');
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setSaving(false);
  }, [isOpen]);

  const handleChange = useCallback((e) => {
    const {
      target: { value },
    } = e;
    if (value.length < 255) {
      setNote(value.replace(/\n/g, ''));
    }
  }, []);

  const handleAddNote = useCallback(async () => {
    if (note.length > 0 && customerPhoneId) {
      setSaving(true);

      try {
        const result = await addNode(customerPhoneId, note);

        onSuccess(result);
      } catch (e) {
        addToast('An error has occurred while adding the customer note.', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  }, [addToast, customerPhoneId, note, onSuccess]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal add-note text-center"
      overlayClassName="modal-overlay"
      contentLabel="Note Modal"
    >
      <h3>Add Customer Note</h3>

      <textarea className="form-control" value={note} onChange={handleChange} />

      <div className="buttons-container">
        <button
          className="btn btn-primary"
          onClick={handleAddNote}
          disabled={saving}
          type="button"
        >
          {saving ? 'Adding...' : 'Add Note'}
        </button>

        <button className="cancel-btn" onClick={onClose} disabled={saving} type="button">
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default memo(NoteModal);
