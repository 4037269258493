import React, { memo, useState, useCallback } from 'react';
import Modal from 'react-modal';

const RemoveMessageGroupModal = ({
  isOpen, onClose, group, onDelete,
}) => {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = useCallback(async () => {
    setDeleting(true);

    await onDelete();

    setDeleting(false);
  }, [onDelete]);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      className="modal remove-customer text-center"
      overlayClassName="modal-overlay"
      contentLabel="Remove Group Modal"
    >
      <h3>Remove Group</h3>

      <p>
        Are you sure you want to delete the
        {' '}
        <i>
          &quot;
          {group.name}
          &quot;
        </i>
        {' '}
        group?
      </p>

      <div className="buttons-container">
        <button className="btn btn-danger" onClick={handleDelete} type="button">
          {deleting ? 'Removing...' : 'Remove'}
        </button>

        <button className="cancel-btn" onClick={onClose} type="button">
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default memo(RemoveMessageGroupModal);
