import {
  API, BlueprintJSONAPI, APIMethods, APIServiceConstants,
} from './BlueprintAPI';

export const createGroup = async (messageGroup) => {
  const response = await API(
    APIMethods.POST,
    APIServiceConstants.MESSAGING,
    'groups',
    {
      messageGroup,
    },
  );

  return response?.data?.groupId;
};

export const updateGroup = async (messageGroup) => {
  await API(
    APIMethods.PUT,
    APIServiceConstants.MESSAGING,
    `groups/${messageGroup.id}`,
    {
      messageGroup,
    },
  );
};

export const getGroupDetails = async (messageGroupId) => {
  const response = await API(
    APIMethods.GET,
    APIServiceConstants.MESSAGING,
    `groups/${messageGroupId}`,
  );

  return response?.data?.messageGroup || {};
};

export const getGroupMessages = async (messageGroupId) => {
  const response = await API(
    APIMethods.GET,
    APIServiceConstants.MESSAGING,
    `groups/${messageGroupId}/messages`,
  );

  return response.data.messageGroupMessages || [];
};

export const getGroupCustomers = async (messageGroupId) => {
  const response = await API(
    APIMethods.GET,
    APIServiceConstants.MESSAGING,
    `groups/${messageGroupId}/members`,
  );

  return response?.data?.messageGroupCustomers || [];
};

export const sendGroupMessage = async (messageGroupId, message) => {
  const response = await API(
    APIMethods.POST,
    APIServiceConstants.MESSAGING,
    `groups/${messageGroupId}/send`,
    {
      message,
    },
  );

  return {
    sentMessages: response.data.sentMessages || 0,
    totalMessagesNumber: response.data.totalMessagesNumber || 0,
  };
};

export const scheduleGroupMessage = async (messageGroupId, message) => {
  const response = await BlueprintJSONAPI(
    APIMethods.POST,
    APIServiceConstants.MESSAGING,
    `groups/${messageGroupId}/schedule`,
    {
      message,
    },
  );

  return {
    scheduledFrom: response.data.scheduledFrom,
    durationEstimate: response.data.durationEstimate || 0,
    totalMessagesNumber: response.data.totalMessageCount || 0,
  };
};

export const getScheduledCampaigns = async () => {
  const response = await BlueprintJSONAPI(
    APIMethods.GET,
    APIServiceConstants.MESSAGING,
    'messages/scheduled',
  );

  return response?.data?.campaigns || [];
};

export const cancelScheduledCampaign = async (id) => {
  const response = await BlueprintJSONAPI(
    APIMethods.DELETE,
    APIServiceConstants.MESSAGING,
    `messages/scheduled/${id}`,
  );

  return response?.data?.message;
};

export const removeCustomerFromGroup = async (
  messageGroupId,
  customerPhoneId,
) => {
  await API(
    APIMethods.DELETE,
    APIServiceConstants.MESSAGING,
    `groups/${messageGroupId}/members/${customerPhoneId}`,
  );
};

export const getAllOptedInCustomers = async () => {
  const response = await API(
    APIMethods.GET,
    APIServiceConstants.MESSAGING,
    'merchant/subscribers',
  );

  return response.data.subscribers || [];
};

export const getGroups = async () => {
  const response = await API(
    APIMethods.GET,
    APIServiceConstants.MESSAGING,
    'groups',
  );

  return response.data.messageGroups || [];
};

export const deleteGroup = async (messageGroupId) => {
  await API(
    APIMethods.DELETE,
    APIServiceConstants.MESSAGING,
    `groups/${messageGroupId}`,
  );
};

export const getSegmentCount = async (predicates) => {
  const response = await API(
    APIMethods.POST,
    APIServiceConstants.MESSAGING,
    'segments/count',
    {
      predicates,
    },
  );

  return response?.data?.count || 0;
};

export const getCustomerGroups = async (customerPhoneId) => {
  const response = await API(
    APIMethods.GET,
    APIServiceConstants.MESSAGING,
    `groups/members/${customerPhoneId}`,
  );

  return (
    response?.data || {
      segments: [],
      lists: [],
    }
  );
};

export const addCustomerToList = async (messageGroupId, customerPhoneId) => {
  await API(
    APIMethods.POST,
    APIServiceConstants.MESSAGING,
    `lists/${messageGroupId}/members/${customerPhoneId}`,
  );
};

export const getListGroups = async () => {
  let allGroups = [];

  try {
    allGroups = await getGroups();
  } catch (err) {
    throw new Error('Failed to load Lists');
  }

  return allGroups.filter((group) => group.type === 0);
};
