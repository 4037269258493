import React, { memo } from 'react';
import Predicate from './Predicate';

const PredicateSelection = ({
  id,
  onAddPredicate,
  onDeletePredicate,
  onPredicateChange,
  selectedPredicates,
  predicateGroupIndex,
  products,
}) => (
  <div className="predicate-selection panels" id={id}>
    <div className="panel">
      {selectedPredicates.map((p, index) => (
        <Predicate
          // eslint-disable-next-line react/no-array-index-key
          key={`predicate-${predicateGroupIndex}-${index}`}
          p={p}
          index={index}
          onChange={(newP) => onPredicateChange(index, newP)}
          onDelete={(deletedP) => onDeletePredicate(index, deletedP)}
          products={products}
        />
      ))}

      <button
        className="minimal-blue-btn"
        onClick={onAddPredicate}
        type="button"
      >
        + OR
      </button>
    </div>
  </div>
);

export default memo(PredicateSelection);
