import {
  APIServiceConstants,
  BlueprintAPI,
} from '../../redux/actions/BlueprintAPI';

const { SERVICE } = APIServiceConstants;

const getMerchantData = async () => {
  const result = await BlueprintAPI(SERVICE, 'getMerchantData');

  return result?.data?.merchantData;
};

export default getMerchantData;
