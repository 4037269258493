import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Logo } from './Logo';
import { MerchantActionCreators } from '../../redux/actions/merchant';
import { ProfileDropdown } from './ProfileDropdown';
import SegmentCountDown from './SegmentCountDown';
import SubscribersCount from './SubscribersCount';

/** @typedef {import('../../redux/reducers').RootState} RootState */

const Header = ({ noCall = false }) => {
  const dispatch = useDispatch();

  const {
    customerSubscriptionUsageLoading,
    customerSubscriptionUsageLoaded,
    customerSubscriptionUsage,
    totalSubscribers,
    merchantLoading,
    merchantData,
    userData,
  } = useSelector((/** @type {RootState} */state) => state.MerchantReducer);

  const getUserDataCallback = useCallback(() => {
    const { getUserData } = MerchantActionCreators;
    dispatch(getUserData());
  }, [dispatch]);

  const getStripeSubscriptionDataCallback = useCallback(() => {
    const { getStripeSubscriptionData } = MerchantActionCreators;
    dispatch(getStripeSubscriptionData());
  }, [dispatch]);

  useEffect(() => {
    if (!noCall) {
      getUserDataCallback();
    }
  }, [noCall, getUserDataCallback]);

  useEffect(() => {
    if (
      !noCall
      && !customerSubscriptionUsageLoading
      && !customerSubscriptionUsageLoaded
    ) {
      getStripeSubscriptionDataCallback();
    }
  }, [
    noCall,
    merchantData,
    customerSubscriptionUsageLoading,
    customerSubscriptionUsageLoaded,
    getStripeSubscriptionDataCallback,
  ]);

  return (
    <header className="a-header">
      <NavLink to="/app">
        <Logo small />
      </NavLink>
      <div className="header-components">
        {/* <NavLink to="/contacts?optin=optedIn"> */}
        <SubscribersCount count={totalSubscribers} loading={merchantLoading} />
        {/* </NavLink> */}
        <SegmentCountDown
          usage={customerSubscriptionUsage}
          loading={customerSubscriptionUsageLoading}
        />
      </div>
      <ProfileDropdown userData={userData} />
    </header>
  );
};

export default memo(Header);
