import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { useToasts } from 'react-toast-notifications';
import { fetchToken } from './fetchToken';

const RETRY_INTERVAL = 2000;
const MAX_RETRY_ATTEMPTS = 5;
const NO_OP = () => {};

/**
 * @returns {[(data: any) => void, number]}
 */
export const useWebSocket = (options) => {
  const { onOpen = NO_OP, onMessage = NO_OP, onTokenError = NO_OP } = options || {};
  const { addToast } = useToasts();
  const socketRef = useRef(/** @type {WebSocket?} */ (null));
  const optionsRef = useRef({
    onOpen,
    onMessage,
    onTokenError,
  });
  const [retryCount, setRetryCount] = useState(0);
  const [token, setToken] = useState('');
  const [readyState, setReadyState] = useState(WebSocket.CONNECTING);

  const sendMessage = useCallback(
    (data) => {
      const message = JSON.stringify(data);
      if (!socketRef.current) {
        console.error(
          `socketRef is not set yet and ready state is: ${readyState}`,
          socketRef.current,
        );
        return;
      }
      if (readyState === WebSocket.OPEN) {
        socketRef.current.send(message);
        return;
      }
      addToast('Connection status is not connected', {
        appearance: 'error',
        autoDismiss: true,
      });
    },
    [addToast, readyState],
  );

  useEffect(() => {
    if (readyState === WebSocket.OPEN || token) {
      return;
    }

    // eslint-disable-next-line no-shadow
    const { onTokenError } = optionsRef.current;

    async function getToken() {
      try {
        const currentToken = await fetchToken();
        if (currentToken) {
          setToken(currentToken);
        }
      } catch (err) {
        onTokenError(err);
      }
    }
    getToken();
  }, [readyState, token]);

  useEffect(() => {
    const setupWebSocket = socketRef.current === null && token.length !== 0;
    if (!setupWebSocket) {
      return undefined;
    }

    // eslint-disable-next-line no-shadow
    const { onMessage, onOpen } = optionsRef.current;

    socketRef.current = new WebSocket(
      `${window.blueprintGetWSApiBaseURL()}?token=${token}`,
    );
    setReadyState(socketRef.current.readyState);

    socketRef.current.onopen = (e) => {
      setReadyState(WebSocket.OPEN);
      onOpen(e);
    };
    socketRef.current.onclose = (e) => {
      setReadyState(WebSocket.CLOSED);
      console.warn('onclose', e);
      socketRef.current = null;
      const canRetry = retryCount < MAX_RETRY_ATTEMPTS;
      if (canRetry) {
        setTimeout(() => setRetryCount(retryCount + 1), RETRY_INTERVAL);
      } else {
        addToast('Unable to connect, try refreshing page', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    };
    socketRef.current.onerror = (e) => {
      setReadyState(WebSocket.CLOSED);
      console.error('onerror', e);
      addToast('Something went wrong', {
        appearance: 'error',
        autoDismiss: true,
      });
    };
    socketRef.current.onmessage = ({ data }) => {
      const message = JSON.parse(data || '{}');
      console.info('onmessage', message);
      onMessage(message);
    };

    return () => {
      if (!socketRef.current) {
        return;
      }

      socketRef.current.close();
      socketRef.current = null;
    };
  }, [addToast, retryCount, token]);

  return [sendMessage, readyState];
};

export default useWebSocket;
