import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import UpSellsTableRow from './UpSellsTableRow';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const UpSellsTable = ({
  upsells,
  analytics,
  toggleUpSellState,
  handleEdit,
  handleDelete,
  handlePriorityChange,
}) => {
  const [upsellsLocal, setUpsellsLocal] = useState(upsells);

  useEffect(() => {
    setUpsellsLocal(upsells);
  }, [upsells]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { draggableId, destination, source } = result;

    if (destination.index === source.index) {
      return;
    }

    const reorderResult = reorder(
      upsellsLocal,
      source.index,
      destination.index,
    );

    setUpsellsLocal(reorderResult);
    handlePriorityChange({
      upsellsByNewOrder: reorderResult,
      upsellId: parseInt(draggableId, 10), // because draggableId will always be String
    });
  };

  const toggleUpSellStateLocally = ({ id }) => {
    const indexToChange = upsellsLocal.findIndex((upsell) => upsell.id === id);
    const updatedUpSellsData = [...upsellsLocal];
    const currentState = updatedUpSellsData[indexToChange].active;

    updatedUpSellsData[indexToChange].active = !currentState;

    setUpsellsLocal(updatedUpSellsData);
    toggleUpSellState({ upsellId: id, newState: !currentState });
  };

  if (!upsellsLocal) {
    return null;
  }

  const tableRows = upsellsLocal.map((upsell, index) => (
    <UpSellsTableRow
      upsell={upsell}
      analytics={analytics}
      key={upsell.id}
      index={index}
      toggleUpSellState={toggleUpSellStateLocally}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
  ));

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <table className="table table-hover">
              <thead className="thead-dark">
                <tr className="d-flex">
                  <th className="col-1">Priority</th>
                  <th className="col-1">Name</th>
                  <th className="col-2">Upsell Trigger</th>
                  <th className="col-2">Upsell Products</th>
                  <th className="col-1">Send After</th>
                  <th className="col-1">Sent</th>
                  <th className="col-1">Ordered</th>
                  <th className="col-1">Earned</th>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th className="col-1" />
                  <th className="col-1 centered">Live</th>
                </tr>
              </thead>
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {tableRows}
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default UpSellsTable;
