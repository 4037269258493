import React from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  Line,
  Label,
} from 'recharts';

const SmsOptinsAnalyticsTimeline = ({ data }) => (
  <div className="dash-info-card analytics-timeline">
    <h3>SMS marketing audience over time</h3>
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        height={250}
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 10,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="1 1" stroke="#f5f5f5" />
        <XAxis dataKey="monthLabel" />
        <YAxis>
          <Label
            angle={270}
            position="insideLeft"
            style={{
              textAnchor: 'middle',
            }}
          >
            SMS Marketing subscribers
          </Label>
        </YAxis>
        <Tooltip />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="nSmsOptinsTotal" name="#Total SMS Marketing Audience" fill="#3D99DD" />
        <Line dataKey="nSmsOptinsOptedIn" name="#SMS Opted Ins" stroke="#0da" fill="#0da" strokeWidth={2} />
        <Line dataKey="nSmsOptinsOptedOut" name="#SMS Opted Outs" stroke="#f66" fill="#f66" strokeWidth={2} />
      </ComposedChart>
    </ResponsiveContainer>
  </div>
);

export default SmsOptinsAnalyticsTimeline;
