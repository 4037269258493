import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import IntegrationPrivyForm from '../../components/forms/IntegrationPrivyForm';
import { IntegrationActionCreators } from '../../redux/actions/integrations';

const {
  getMerchantIntegrations,
  upsertPrivyIntegration,
  resetPrivyIntegrationState,
} = IntegrationActionCreators;

const IntegrationsHubPrivyPage = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const {
    integrationsLoading,
    integrationsLoaded,
    integrationsData,
    privyUpsertSuccess,
    privyUpsertError,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */ state) => state.IntegrationsReducer,
  );

  useEffect(() => {
    if (!integrationsLoaded && !integrationsLoading) {
      dispatch(getMerchantIntegrations());
    }
  }, [dispatch, integrationsLoaded, integrationsLoading]);

  useEffect(() => {
    if (privyUpsertSuccess) {
      const messageAdjective = integrationsData?.privy?.active
        ? 'updated'
        : 'activated';

      addToast(
        `Your integration with Privy has been ${messageAdjective}`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
    }

    return () => {
      dispatch(resetPrivyIntegrationState());
    };
  }, [addToast, dispatch, integrationsData?.privy?.active, privyUpsertSuccess]);

  const handleSubmit = async () => {
    await dispatch(upsertPrivyIntegration());
    return dispatch(getMerchantIntegrations());
  };

  return (
    <PageBuilder>
      <SEO title="Privy | Integrations | Blueprint" />
      <div className="a-view integrations-hub">
        <div className="container">
          <div className="row back-btn-container">
            <div className="col-md-12">
              <Link to="/integrations">Back</Link>
            </div>
          </div>

          <div className="top-bar">
            <h2 className="title">
              Privy
              {' '}
              {integrationsData?.privy?.active ? '✅' : ''}
            </h2>
          </div>

          {privyUpsertError && (
            <div className="row">
              <div className="col-lg-12">
                <div className="alert alert-danger">{privyUpsertError}</div>
              </div>
            </div>
          )}

          <p>
            Your account is ready to connect to Privy.
            Please note the webhook URL below and follow
            {' '}
            <a href="https://help.blueprint.store/privy-integration" target="_blank" rel="noreferrer">these</a>
            {' '}
            instructions.
          </p>

          {integrationsLoaded ? (
            <IntegrationPrivyForm
              onSubmit={handleSubmit}
            />
          ) : (
            <>Loading...</>
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(IntegrationsHubPrivyPage);
