import {
  TYPE_ORDER_CONFIRMED,
  TYPE_FIRST_ORDER_CONFIRMED,
  TYPE_POST_ORDER_CHECKIN,
  TYPE_REORDER_PROMPT,
  TYPE_ORDER_FULFILLED,
  TYPE_RECHARGE_NEW_SUBSCRIPTION,
  TYPE_RECHARGE_RECURRING_CHARGE,
  TYPE_RECHARGE_UPCOMING_CHARGE,
  TYPE_RECHARGE_NEW_SUBSCRIPTION_AFTER_X_DAYS,
} from '../../../utils/messageTemplateTypes';

export const AUTO_MESSAGE_TYPES = {
  DEFAULT: 'default',
  SUBSCRIPTION: 'subscription',
};

export const defaultMessageTemplates = [
  {
    messageType: TYPE_ORDER_CONFIRMED,
    messageContent: '',
    messageInterval: 0,
    active: false,
    canSetActive: true,
    canSetInterval: false,
    title: 'Order Confirmation',
    help:
      'This message will be sent after the customers complete orders facilitated by blueprint.',
    type: AUTO_MESSAGE_TYPES.DEFAULT,
  },
  {
    messageType: TYPE_FIRST_ORDER_CONFIRMED,
    messageContent: '',
    messageInterval: 0,
    active: false,
    canSetActive: true,
    canSetInterval: false,
    title: 'Order Confirmation (1st order only)',
    help:
      'This message will be sent after a customer completes their first order using blueprint. It will inform them how to reorder automatically in conversation and opt-out.',
    type: AUTO_MESSAGE_TYPES.DEFAULT,
  },
  {
    messageType: TYPE_ORDER_FULFILLED,
    messageContent: '',
    messageInterval: 0,
    active: false,
    canSetActive: true,
    canSetInterval: false,
    title: 'Order Fulfillment',
    help:
      'This message will be sent after the customers orders is set to fulfilled.',
    type: AUTO_MESSAGE_TYPES.DEFAULT,
  },
  {
    messageType: TYPE_POST_ORDER_CHECKIN,
    messageContent: '',
    messageInterval: 0,
    active: false,
    canSetActive: true,
    canSetInterval: true,
    title: 'Post order check-in (1st order only)',
    help:
      'This message is designed to be sent after the customer has received the goods to check-in and make sure everything is correct. It will also allow for customers to give feedback and ask any questions they may have.',
    type: AUTO_MESSAGE_TYPES.DEFAULT,
  },
  {
    messageType: TYPE_REORDER_PROMPT,
    messageContent: '',
    messageInterval: 0,
    active: false,
    canSetActive: true,
    canSetInterval: true,
    title: 'Re-order prompt (1st order only)',
    help:
      'This message is designed to prompt customers to reorder when they’re likely to need more.',
    type: AUTO_MESSAGE_TYPES.DEFAULT,
  },
];

export const subscriptions = [
  {
    messageType: TYPE_RECHARGE_NEW_SUBSCRIPTION,
    messageContent: '',
    messageInterval: 0,
    active: false,
    canSetActive: true,
    canSetInterval: false,
    title: 'New subscriber (1st subscription only)',
    help: 'Sent to customers when they first sign up for a subscription.',
    type: AUTO_MESSAGE_TYPES.SUBSCRIPTION,
  },
  {
    messageType: TYPE_RECHARGE_RECURRING_CHARGE,
    messageContent: '',
    messageInterval: 0,
    active: false,
    canSetActive: true,
    canSetInterval: false,
    title: 'Recurring charge confirmation',
    help: 'Sent to customers when their recurring payment is processed.',
    type: AUTO_MESSAGE_TYPES.SUBSCRIPTION,
  },
  {
    messageType: TYPE_RECHARGE_UPCOMING_CHARGE,
    messageContent: '',
    messageInterval: 0,
    active: false,
    canSetActive: true,
    canSetInterval: false,
    title: 'Upcoming charge',
    help: 'Sent to customers 3 days before their card is charged for renewal.',
    type: AUTO_MESSAGE_TYPES.SUBSCRIPTION,
  },
  {
    messageType: TYPE_RECHARGE_NEW_SUBSCRIPTION_AFTER_X_DAYS,
    messageContent: 'Subscription Activation',
    messageInterval: 0,
    active: false,
    canSetActive: true,
    canSetInterval: true,
    title: 'New subscriber check-in (1st subscription only)',
    help:
      'Sent to customers after the selected time frame to check in with customers regarding their first subscription delivery.',
    type: AUTO_MESSAGE_TYPES.SUBSCRIPTION,
  },
];
