import React, { memo } from 'react';
import { getDeliveryStatus } from './helpers';

function IndividualMessageDeliveryStatus({
  channel,
  deliveryStatus,
  direction,
  sendSkipped,
}) {
  if (!channel) {
    return null;
  }

  const status = getDeliveryStatus(deliveryStatus, sendSkipped, direction);

  return (
    <div className="individual-message-delivery-status">
      <span>{channel}</span>
      <span className={`status ${status.toLowerCase()}`}>
        {status ? ` - ${status}` : null}
      </span>
    </div>
  );
}

export default memo(IndividualMessageDeliveryStatus);
