import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React, { useCallback, useState } from 'react';
import Modal from 'react-modal';
import UpsellEditor from './UpsellEditor';

const UpSellEditor = ({
  isOpen,
  close,
  upsell,
  handleUpdateSubmit,
  upsellUpdating,
}) => {
  const [controller] = useState(new AbortController());
  const setClose = useCallback(() => {
    controller.abort();
    close(false);
  }, [close, controller]);

  const isForCreating = !upsell;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={setClose}
      className="modal wide"
      overlayClassName="modal-overlay"
      contentLabel="Edit Upsell"
    >
      <div className="upsell-modal">
        <button className="close" onClick={setClose} type="button">
          close
        </button>
        <span className="title">
          {isForCreating ? 'Create New' : 'Edit'}
          {' '}
          Upsell
        </span>
        <UpsellEditor
          upsell={upsell}
          isForCreating={isForCreating}
          handleUpdateSubmit={handleUpdateSubmit}
          upsellUpdating={upsellUpdating}
        />
      </div>
    </Modal>
  );
};

export default UpSellEditor;
