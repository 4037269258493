import Occurrence from './SubQueries/Occurrence';
import Time from './SubQueries/Time';
import Value from './SubQueries/Value';
import Lifecycle from './SubQueries/Lifecycle';
import CommerceSubscription from './SubQueries/CommerceSubscription';
import Negate from '../../../global/Predicates/Negate';
import PurchasedProduct from './SubQueries/PurchasedProduct';
import IncomingSMS from './SubQueries/IncomingSMS';
import Discount from './SubQueries/Discount';
import StringValue from './SubQueries/StringValue';
import CountryChoice from './SubQueries/CountryChoice';
import StateChoice from './SubQueries/StateChoice';

const PREDICATES = {
  purchased: 'purchased',
  spent: 'spent',
  purchasedProduct: 'purchasedProduct',
  averageOrderValue: 'averageOrderValue',
  purchasedUsingDiscountCode: 'purchasedUsingDiscountCode',
  purchasedUsingDiscount: 'purchasedUsingDiscount',
  dateOfFirstOrder: 'dateOfFirstOrder',
  dateOfLastOrder: 'dateOfLastOrder',
  lastOutgoingMessageDate: 'lastOutgoingMessageDate',
  lastIncomingMessageDate: 'lastIncomingMessageDate',
  currentlyIsChurned: 'currentlyIsChurned',
  commerceSubscription: 'commerceSubscription',
  incomingSMS: 'incomingSMS',
  defaultAddressCountryCode: 'defaultAddressCountryCode',
  defaultAddressStateCode: 'defaultAddressStateCode',
  defaultAddressCityName: 'defaultAddressCityName',
  defaultAddressPostCode: 'defaultAddressPostCode',
};

const SUBQUERIES_METADATA = {
  lifecycle: { component: Lifecycle },
  commerceSubscription: { component: CommerceSubscription },
  occurrence: { component: Occurrence },
  time: { component: Time },
  value: { component: Value },
  negate: { component: Negate },
  purchase: { component: PurchasedProduct },
  smsFilter: { component: IncomingSMS },
  stringValue: { component: StringValue },
  countryValue: { component: CountryChoice },
  stateValue: { component: StateChoice },
  discount: { component: Discount },
};

const PREDICATES_METADATA = {
  [PREDICATES.purchased]: {
    title: 'Purchased',
  },
  [PREDICATES.spent]: {
    title: 'Spent',
  },
  [PREDICATES.purchasedProduct]: {
    title: 'Purchased Product',
  },
  [PREDICATES.averageOrderValue]: {
    title: 'Average Order Value',
  },
  [PREDICATES.purchasedUsingDiscountCode]: {
    title: 'Purchased with Specific Discount Code',
  },
  [PREDICATES.purchasedUsingDiscount]: {
    title: 'Purchased with Discount',
  },
  [PREDICATES.dateOfFirstOrder]: {
    title: 'Date of First Order',
  },
  [PREDICATES.dateOfLastOrder]: {
    title: 'Date of Last Order',
  },
  [PREDICATES.lastOutgoingMessageDate]: {
    title: 'Date of Last Outgoing Message',
  },
  [PREDICATES.lastIncomingMessageDate]: {
    title: 'Date of Last Incoming Message',
  },
  [PREDICATES.currentlyIsChurned]: {
    title: 'Current Lifecycle Status',
  },
  [PREDICATES.commerceSubscription]: {
    title: 'Current Commerce Subscriber Status',
  },
  [PREDICATES.incomingSMS]: {
    title: 'Sent Incoming SMS Message',
  },
  [PREDICATES.defaultAddressCountryCode]: {
    title: 'Default Address Country',
  },
  [PREDICATES.defaultAddressStateCode]: {
    title: 'Default Address State (US only)',
  },
  [PREDICATES.defaultAddressCityName]: {
    title: 'Default Address City',
  },
  [PREDICATES.defaultAddressPostCode]: {
    title: 'Default Address Zip/Postcode',
  },
};

export const defaultNegateQuery = { negate: { mode: 'no' } };

export const defaultPredicates = [
  {
    type: PREDICATES.purchased,
    subQueries: {
      negate: { mode: 'no' },
      occurrence: { mode: 'at_least_once' },
      time: { mode: 'all_time' },
    },
  },
  {
    type: PREDICATES.spent,
    subQueries: {
      negate: { mode: 'no' },
      value: { mode: 'eq', value: 1 },
      time: { mode: 'all_time' },
    },
  },
  {
    type: PREDICATES.purchasedProduct,
    subQueries: {
      negate: { mode: 'no' },
      purchase: { productId: '', variantId: '' },
      occurrence: { mode: 'at_least_once' },
      time: { mode: 'all_time' },
    },
  },
  {
    type: PREDICATES.averageOrderValue,
    subQueries: {
      negate: { mode: 'no' },
      value: { mode: 'eq', value: 1 },
      time: { mode: 'all_time' },
    },
  },
  {
    type: PREDICATES.purchasedUsingDiscount,
    subQueries: {
      negate: { mode: 'no' },
      occurrence: { mode: 'at_least_once' },
      time: { mode: 'all_time' },
    },
  },
  {
    type: PREDICATES.purchasedUsingDiscountCode,
    subQueries: {
      negate: { mode: 'no' },
      discount: { mode: 'stringContains', value: '' },
    },
  },
  {
    type: PREDICATES.dateOfFirstOrder,
    subQueries: {
      negate: { mode: 'no' },
      time: { mode: 'all_time' },
    },
  },
  {
    type: PREDICATES.dateOfLastOrder,
    subQueries: {
      negate: { mode: 'no' },
      time: { mode: 'all_time' },
    },
  },
  {
    type: PREDICATES.lastOutgoingMessageDate,
    subQueries: {
      negate: { mode: 'no' },
      time: { mode: 'all_time' },
    },
  },
  {
    type: PREDICATES.lastIncomingMessageDate,
    subQueries: {
      negate: { mode: 'no' },
      time: { mode: 'all_time' },
    },
  },
  {
    type: PREDICATES.currentlyIsChurned,
    subQueries: {
      negate: { mode: 'no' },
      lifecycle: { mode: 'active' },
    },
  },
  {
    type: PREDICATES.commerceSubscription,
    subQueries: {
      negate: { mode: 'no' },
      commerceSubscription: { mode: 'active' },
    },
  },
  {
    type: PREDICATES.incomingSMS,
    subQueries: {
      negate: { mode: 'no' },
      smsFilter: { mode: 'stringContains', value: '' },
      time: { mode: 'all_time' },
    },
  },
  {
    type: PREDICATES.defaultAddressCountryCode,
    subQueries: {
      negate: { mode: 'no' },
      countryValue: { value: '' },
    },
  },
  {
    type: PREDICATES.defaultAddressStateCode,
    subQueries: {
      negate: { mode: 'no' },
      stateValue: { mode: 'eq', value: '' },
    },
  },
  {
    type: PREDICATES.defaultAddressCityName,
    subQueries: {
      negate: { mode: 'no' },
      stringValue: { mode: 'eq', value: '' },
    },
  },
  {
    type: PREDICATES.defaultAddressPostCode,
    subQueries: {
      negate: { mode: 'no' },
      stringValue: { mode: 'eq', value: '' },
    },
  },
];

export const predicates = defaultPredicates.map((p) => ({
  ...p,
  title: PREDICATES_METADATA[p.type].title,
  subQueries: Object.entries(p.subQueries).reduce(
    (res, [key, val]) => ({
      ...res,
      [key]: {
        ...val,
        ...SUBQUERIES_METADATA[key],
      },
    }),
    {},
  ),
}));

export const addComponentNames = (group) => group.map((g) => ({
  ...g,
  predicates: g.predicates.map((p) => ({
    ...p,
    subQueries: Object.entries(p.subQueries).reduce(
      (res, [key, val]) => ({
        ...res,
        [key]: {
          ...val,
          ...SUBQUERIES_METADATA[key],
        },
      }),
      {},
    ),
  })),
}));
