import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { SEO } from '../../components/global/SEO';
import { PageBuilder } from '../../components/global/PageBuilder';
import IntegrationZendeskForm from '../../components/forms/IntegrationZendeskForm';
import { IntegrationActionCreators } from '../../redux/actions/integrations';

const {
  getMerchantIntegrations,
  generateZendeskIntegration,
  upsertZendeskIntegration,
  deleteZendeskIntegration,
  resetZendeskIntegrationState,
} = IntegrationActionCreators;

const IntegrationsHubZendeskPage = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const {
    integrationsLoading,
    integrationsLoaded,
    integrationsData,
    zendeskUpsertSuccess,
    zendeskUpsertError,
    zendeskDeleteSuccess,
  } = useSelector(
    (/** @type {import('../../redux/reducers').RootState} */ state) => state.IntegrationsReducer,
  );

  useEffect(() => {
    if (!integrationsLoaded && !integrationsLoading) {
      dispatch(getMerchantIntegrations());
    }
  }, [dispatch, integrationsLoaded, integrationsLoading]);

  useEffect(() => {
    if (zendeskUpsertSuccess) {
      const messageAdjective = integrationsData?.zendesk?.active
        ? 'updated'
        : 'activated';

      addToast(
        `Your integration with Zendesk has been ${messageAdjective}`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
    }

    return () => {
      dispatch(resetZendeskIntegrationState());
    };
  }, [addToast, dispatch, integrationsData?.zendesk?.active, zendeskUpsertSuccess]);

  useEffect(() => {
    if (zendeskDeleteSuccess) {
      addToast(
        'Your integration with Zendesk has been deleted',
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
    }

    return () => {
      dispatch(resetZendeskIntegrationState());
    };
  }, [addToast, dispatch, integrationsData?.zendesk?.active, zendeskDeleteSuccess]);

  const handleSubmit = async (formData) => {
    if (formData.performDeletionOnSubmit) {
      await dispatch(deleteZendeskIntegration(formData.publicId));
    } else if (formData.performOnlyUpdate) {
      await dispatch(upsertZendeskIntegration(formData));
    } else {
      await dispatch(generateZendeskIntegration());
    }
    return dispatch(getMerchantIntegrations());
  };

  return (
    <PageBuilder>
      <SEO title="Zendesk | Integrations | Blueprint" />
      <div className="a-view integrations-hub">
        <div className="container">
          <div className="row back-btn-container">
            <div className="col-md-12">
              <Link to="/integrations">Back</Link>
            </div>
          </div>

          <div className="top-bar">
            <h2 className="title">
              Zendesk
              {' '}
              {integrationsData?.zendesk?.active ? '✅' : ''}
            </h2>
          </div>

          {zendeskUpsertError && (
            <div className="row">
              <div className="col-lg-12">
                <div className="alert alert-danger">{zendeskUpsertError}</div>
              </div>
            </div>
          )}

          {integrationsLoaded
            ? (
              <p>
                Your account is ready to connect to Zendesk.&nbsp;
                {integrationsData?.zendesk?.active ? (
                  <>
                    Please note the Public ID below and follow
                    {' '}
                    <a href="https://help.blueprint.store/zendesk-integration" target="_blank" rel="noreferrer">these</a>
                    {' '}
                    instructions.
                  </>
                ) : (
                  <>
                    Please activate the Integration below.
                  </>
                )}
              </p>
            ) : ''}

          {integrationsLoaded ? (
            <IntegrationZendeskForm
              onSubmit={handleSubmit}
            />
          ) : (
            <>Loading...</>
          )}
        </div>
      </div>
    </PageBuilder>
  );
};

export default memo(IntegrationsHubZendeskPage);
