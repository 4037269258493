import React, { memo, useEffect, useState } from 'react';
import { TimeSelect } from '../main/TimeSelect';

import {
  AWAY_OPTION_ONLINE,
  AWAY_OPTION_OFFLINE,
  AWAY_OPTION_ALL_DAY,
  DEFAULT_AWAY_START,
  DEFAULT_AWAY_END,
  MIDNIGHT,
  END_OF_DAY,
  DEFAULT_AWAY_OFFLINE,
} from '../../utils/settings';

const days = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

const options = [
  { value: AWAY_OPTION_ONLINE, label: 'Online' },
  { value: AWAY_OPTION_ALL_DAY, label: 'Online (all day)' },
  { value: AWAY_OPTION_OFFLINE, label: 'Offline' },
];

const formatTime = (value) => (typeof value === 'string' ? value.substring(0, 5) : value);

const getRowData = (dayOfWeek, startsAt, endsAt) => ({
  dayOfWeek,
  startsAt,
  endsAt,
});

const AwayHoursRow = ({ input, meta: { error } }) => {
  const [dayOfWeek, setDayOfWeek] = useState(null);
  const [startsAt, setStartsAt] = useState('');
  const [endsAt, setEndsAt] = useState('');

  useEffect(() => {
    if (input.value) {
      setDayOfWeek(input.value.dayOfWeek);
      setStartsAt(formatTime(input.value.startsAt));
      setEndsAt(formatTime(input.value.endsAt));
    }
  }, [input.value]);

  let currentOption = AWAY_OPTION_ONLINE;

  if (startsAt === MIDNIGHT && endsAt === END_OF_DAY) {
    currentOption = AWAY_OPTION_ALL_DAY;
  } else if (startsAt === DEFAULT_AWAY_OFFLINE && endsAt === DEFAULT_AWAY_OFFLINE) {
    currentOption = AWAY_OPTION_OFFLINE;
  }

  const timeEnabled = currentOption === AWAY_OPTION_ONLINE;

  const makeChange = (newDayOfWeek, newStartsAt, newEndsAt) => {
    if (input && input.onChange) {
      input.onChange(getRowData(newDayOfWeek, newStartsAt, newEndsAt));
    }
  };

  const changeOption = (e) => {
    switch (e.target.value) {
      case AWAY_OPTION_ONLINE:
        makeChange(dayOfWeek, DEFAULT_AWAY_START, DEFAULT_AWAY_END);
        break;

      case AWAY_OPTION_OFFLINE:
        makeChange(dayOfWeek, DEFAULT_AWAY_OFFLINE, DEFAULT_AWAY_OFFLINE);
        break;

      case AWAY_OPTION_ALL_DAY:
        makeChange(dayOfWeek, MIDNIGHT, END_OF_DAY);
        break;
      default:
        throw new Error(`Got unexpected option ${e.target.value}`);
    }
  };

  const changeStartsAt = (e) => {
    makeChange(dayOfWeek, e.target.value, endsAt);
  };

  const changeEndsAt = (e) => {
    makeChange(dayOfWeek, startsAt, e.target.value);
  };

  return (
    <div className="row away-hours">
      <div className="col-sx-12 col-sm-6">
        <div className="form-group form-inline weekday">
          <label>{days[dayOfWeek]}</label>
          <select
            className={`form-control away-option ${currentOption}`}
            onChange={changeOption}
            value={currentOption}
          >
            {options.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col">
        <div className="form-group form-inline">
          <label className="time d-sm-none d-md-flex" htmlFor="fromTime">From:</label>
&nbsp;
          <TimeSelect
            id="fromTime"
            input={{ value: startsAt, onChange: changeStartsAt }}
            meta={{}}
            disabled={!timeEnabled}
          />
          <label className="time" htmlFor="toTime">To:</label>
&nbsp;
          <TimeSelect
            id="toTime"
            input={{ value: endsAt, onChange: changeEndsAt }}
            meta={{}}
            disabled={!timeEnabled}
          />
        </div>
        {error && error.startsAt && (
          <div className="field-error time">
            <small>{error.startsAt}</small>
          </div>
        )}
      </div>
      <div className="clearfix visible-xs-block" />
    </div>
  );
};

export default memo(AwayHoursRow);
