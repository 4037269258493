import React from 'react';
import { DashInfoCard } from '../../components/main/DashInfoCard';

import {
  formatAsMoney,
  formatValueAsPercentage,
} from '../../utils/numerical';

const MAX_AMOUNT_TO_DISPLAY_WITH_TWO_DECIMAL_POINTS = 100000;

const SMSAnalyticsKPIs = ({ totals, currency }) => (
  <>
    <div className="col-lg-3">
      <DashInfoCard
        title="Revenue from SMS"
        value={formatAsMoney(
          totals.totalRevenueAttributed,
          currency,
          totals.totalRevenueAttributed < MAX_AMOUNT_TO_DISPLAY_WITH_TWO_DECIMAL_POINTS ? 2 : 0,
        )}
        info={
            `${formatAsMoney(totals.revenuePerMessage, currency)
            } revenue per message`
          }
        extraInfo="Total revenue from all orders attributed to SMS campaigns"
      />
    </div>
    <div className="col-lg-3">
      <DashInfoCard
        title="Orders from SMS"
        value={totals.numOrdersAttributed}
        info={
            `${formatValueAsPercentage(totals.conversionRate)
            } SMS conversion rate`
          }
        extraInfo="Number of orders attributed to SMS campaigns"
        clickThroughHref="/attributed-orders"
        clickThroughText="View Orders"
      />
    </div>
    <div className="col-lg-3">
      <DashInfoCard
        title="Total messages sent"
        value={totals.numMessagesSent}
        info={`${totals.totalSegmentsSent} SMS segments sent`}
        extraInfo="Total number of SMS messages and segments sent"
      />
    </div>
    <div className="col-lg-3">
      <DashInfoCard
        title="SMS reply rate"
        value={formatValueAsPercentage(totals.replyRate)}
        info={`${formatValueAsPercentage(totals.optoutRate)} Optout rate`}
        extraInfo="Percentage of individuals that received a message and replied"
      />
    </div>
  </>
);

export default SMSAnalyticsKPIs;
