import {
  APIServiceConstants,
  BlueprintAPI,
} from '../../redux/actions/BlueprintAPI';

const { ANALYTICS } = APIServiceConstants;

const getContactStats = async () => {
  try {
    const result = await BlueprintAPI(ANALYTICS, 'getContactStats', {}, null, true, 'GET');

    return result.data;
  } catch (err) {
    if (err.status === 404) {
      return null;
    }

    throw err;
  }
};

export default getContactStats;
