import React, { memo } from 'react';

import { countryPhoneData } from 'phone';
import { NEGATE_MODES } from '../../../../global/Predicates/Negate';
import Filter, { createFilterChangeHandler } from '../Filter';
import SimpleListPicker from '../ValueComponents/SimpleListPicker';

/** @typedef {import('../../../../global/Predicates/Negate').NegateMode} NegateMode */
/** @typedef {import('../../../../global/Predicates/TypeSelector').Type} Type */
/** @typedef {import('../../../../global/Predicates/ComparatorSelector').Comparator} Comparator */

const getDefaultNegate = () => ({ mode: NEGATE_MODES.no });
const getDefaultComparator = (comparators) => comparators[0].value;
const getDefaultValue = () => [];

export const valueMapper = ({
  id, value, ...rest
}) => ({
  ...(value && { value: value.map((item) => (item.id)) }),
  ...rest,
});

const SELECT_OPTIONS = countryPhoneData.map((phone) => ({
  id: phone.alpha2,
  name: phone.country_name,
})).sort((a, b) => a.name.localeCompare(b.name));

/**
 * @param {Object} props
 * @param {String} props.id
 * @param {Function} props.onChange
 * @param {String} props.value
 * @param {NegateMode} props.negate
 * @param {String} props.type
 * @param {Type[]} props.types
 * @param {String} props.comparator
 * @param {Comparator[]} props.comparators
 */
const PhoneCountryCode = (props) => {
  const {
    type,
    types,
    value = getDefaultValue(),
    negate = getDefaultNegate(),
    comparators,
    onChange,
    id,
  } = props;

  // Destructure these seperately because we need to know the `type` to get the defaults
  const {
    comparator = getDefaultComparator(comparators),
  } = props;

  const onFilterChange = createFilterChangeHandler({
    id, type, value, negate, comparator, onChange,
  });

  return (
    <Filter
      negate={negate}
      comparator={comparator}
      type={type}
      onChange={onFilterChange}
      types={types}
      comparators={comparators}
      value={(
        <SimpleListPicker
          label="Phone Number Region"
          name="phone country code"
          value={value}
          onChange={onFilterChange}
          options={SELECT_OPTIONS}
        />
      )}
    />
  );
};

export default memo(PhoneCountryCode);
