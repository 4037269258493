import { DateTime, Duration } from 'luxon';

const setZone = process.env.NODE_ENV === 'test';

const parse = (date) => DateTime.fromISO(date, { setZone });

export const compareDateAsc = (d1, d2) => {
  if (parse(d1) < parse(d2)) {
    return -1;
  }
  if (parse(d1) > parse(d2)) {
    return 1;
  }
  return 0;
};

export const formatDate = (date, format = 'dd/MM/yy HH:mm:ss') => DateTime.fromISO(date).toFormat(format);

export const formatDateFromSecs = (date, format = 'dd/MM/yy HH:mm:ss') => DateTime.fromSeconds(date).toFormat(format);

export const minutesToDaysAndHours = (minutes) => Duration.fromObject({ minutes, days: 0, hours: 0 })
  .normalize()
  .toObject();

/**
 * Return a string representation "x day(s?) y hour(s?)" of a given amount of minutes
 * @param {Number} minutes
 * @returns {String}
 */
export const getDaysAndHours = (minutes) => {
  const durationObj = minutesToDaysAndHours(minutes);

  const hoursStr = (durationObj.hours || 0) > 0
    ? `${durationObj.hours} hour${(durationObj.hours || 0) > 1 ? 's' : ''}`
    : '';

  let daysStr = '';
  if ((durationObj.days || 0) > 0) {
    daysStr = `${durationObj.days} day${(durationObj.days || 0) > 1 ? 's ' : ' '}`;
  }

  return `${daysStr}${hoursStr}`;
};

export const dateIsMoreThan24HAgo = (date) => {
  const end = DateTime.now();
  const start = DateTime.fromISO(date);

  return end.diff(start, 'hours').hours > 24;
};
