import React, { memo, useCallback, useState } from 'react';
import UnsubscribeModal from './UnsubscribeModal';

const UnsubscribeBtn = ({ customer, onUnsubscribe }) => {
  const [showModal, setShowModal] = useState(false);

  const handleUnsubscribe = useCallback(async () => {
    setShowModal(false);
    onUnsubscribe();
  }, [onUnsubscribe]);

  const toggleShowModal = useCallback(async () => {
    setShowModal(!showModal);
  }, [showModal]);

  return (
    <>
      <button
        className="btn btn-outline-danger unsubscribe-btn"
        onClick={toggleShowModal}
        type="button"
      >
        Unsubscribe
      </button>

      {showModal && (
        <UnsubscribeModal
          isOpen={showModal}
          customer={customer}
          onSuccess={handleUnsubscribe}
          onClose={toggleShowModal}
        />
      )}
    </>
  );
};

export default memo(UnsubscribeBtn);
