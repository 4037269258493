import React from 'react';

export const BPToggle = (props) => {
  const {
    active, handleClick, disabled, id = null,
  } = props;

  const additionalProps = {};
  if (id) {
    additionalProps.id = id;
  }

  return (
    <div
      {...additionalProps}
      className={
        `bp-toggle ${active ? 'active' : ''}${disabled ? ' disabled' : ''}`
      }
      onClick={disabled ? () => null : handleClick}
      role="switch"
      aria-checked={active}
      tabIndex={0}
    >
      <div className="slider" />
    </div>
  );
};

export default BPToggle;
