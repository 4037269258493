import {
  BlueprintJSONAPI, APIMethods, APIServiceConstants,
} from '../../../redux/actions/BlueprintAPI';

const testIntegration = async (accessToken) => {
  const response = await BlueprintJSONAPI(
    APIMethods.POST,
    APIServiceConstants.INTEGRATIONS,
    'smartrr/test',
    {
      accessToken,
    },
  );

  return response?.data;
};

export default testIntegration;
