import React from 'react';
import { BImage } from './BImage';

export const BRadio = (props) => {
  const {
    selected, onChange, text, value, disabled = false,
  } = props;

  return (
    <div
      className="radio-container"
      role="button"
      tabIndex={0}
      onClick={() => {
        if (!disabled) {
          onChange(value);
        }
      }}
    >
      <BImage
        src={
          `https://static.blueprint.store/illustrations/bp-${
            selected ? 'radio-active' : 'radio'
          }.svg`
        }
        alt={`Radio${selected ? ' active' : ''}`}
      />
      <div className={disabled ? 'helper-text disabled' : 'helper-text'}>
        {text}
      </div>
    </div>
  );
};

export default BRadio;
