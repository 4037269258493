import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SEO } from '../components/global/SEO';
import InfoModal from '../components/global/InfoModal';
import { AuthActionCreators } from '../redux/actions/auth';

const {
  login,
} = AuthActionCreators;

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [hasBillingError, setHasBillingError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    // This is the wrong way to use redux thunks. We should be subscribing to the store and looking for errors in there
    const result = await dispatch(login({ email, password }));
    setLoading(false);

    // @ts-ignore
    if (!result.error) {
      const searchParams = new URLSearchParams(location.search);
      const returnUrl = searchParams.get('return_url');
      if (returnUrl) {
        return history.push(returnUrl);
      }

      return history.push('/');
    }

    // @ts-ignore
    setError(result.error);

    // @ts-ignore
    if (result.errorCode === 402) {
      setHasBillingError(true);
    }

    return null;
  };

  const toggleInfoModal = () => setHasBillingError(!hasBillingError);

  return (
    <div className="m-view login">
      <SEO
        title="Login | Blueprint"
        slug=""
        description=""
        image=""
        creator=""
      />

      <div className="login-card">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-container">
            <div className="form-content">
              <div className="wl-input-group">
                <input
                  type="email"
                  className="form-control"
                  required
                  placeholder="Business Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="wl-input-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {error && <div className="form-error">{error}</div>}
              <p className="privacy">
                By logging in you are agreeing to our
                <br />
                <a
                  href="https://blueprint.store/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? 'Logging In...' : 'Login'}
              </button>
            </div>
          </div>
        </form>
      </div>
      {hasBillingError && (
        <InfoModal
          isOpen={hasBillingError}
          close={toggleInfoModal}
          title="Payment details are needed to continue"
          text="Please contact your account manager to provide payment details and access the platform"
        />
      )}
    </div>
  );
};

export default Login;
